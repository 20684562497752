import { Info, Plus } from '@phosphor-icons/react'
import Button from '../../../components/button'
import Content from '../../../components/content'
import { useEffect, useState } from 'react'
import { useListProfiles } from '../../../hooks/useProfile'
import SearchField from '../../../components/search'
import Table from '../../../components/table'
import InputSkeleton from '../../../components/input/skeleton'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../routes/path'
import { useTranslation } from 'react-i18next'

const AccessProfile = () => {
  const { t } = useTranslation('profiles')
  const [filter, setFilter] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)

  const { data, isLoading, isFetching } = useListProfiles(currentPage, 10, filter)
  const navigate = useNavigate()

  useEffect(() => {
    if (data) {
      setTotalPages(Math.ceil(data.totalItems / 10))
    }
  }, [data])

  const columns = [
    { header: 'Nome do Perfil', accessor: 'name' },
    { header: '', accessor: 'actions', className: 'sm:max-w-[120px]' }
  ]

  const tableData =
    data?.data?.map(profile => ({
      name: profile.name,
      actions: (
        <Button
          size='xs'
          label='Detalhes'
          variant='secondary'
          iconLeft={<Info size={18} color='#007BBD' />}
          onClick={() => {
            navigate(ROUTES.profile.form.replace(':id', profile?.id || ''), {
              state: 'detail'
            })
          }}
        />
      )
    })) ?? []

  return (
    <Content
      title={t('title')}
      rightContent={
        <Button
          onClick={() => {
            navigate(ROUTES.profile.form.replace(':id', 'new'))
          }}
          size='sm'
          iconLeft={<Plus size={12} />}
          label='Novo perfil de acesso'
        />
      }
    >
      <div className='flex h-[calc(100%-70px)] flex-col'>
        <div className='mb-4 sm:w-64'>
          {isLoading ? (
            <InputSkeleton isRounded />
          ) : (
            <SearchField
              placeholder='Procure pelo nome do perfil...'
              onChange={({ target }) => {
                setCurrentPage(0)
                setFilter(target.value)
              }}
              value={filter}
              maxLength={100}
            />
          )}
        </div>

        <Table
          data={tableData}
          columns={columns}
          isLoading={isFetching}
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      </div>
    </Content>
  )
}

export default AccessProfile
