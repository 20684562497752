import { useTranslation } from 'react-i18next'
import Radio from '../../../components/radio'
import { Question } from '@phosphor-icons/react'
import { useEffect, useMemo, useState } from 'react'
import { useAuth } from '../../../contexts/auth'
import UnitPermissionsEnum from '../../../types/unit-permissions'
import { CARE_TYPE } from '../../../types'

interface Props {
  value: string
  id: string
  care_type: CARE_TYPE
  onChangeValue: (field: string, value: unknown, shouldValidate?: boolean) => void
}

const ConsultationType: React.FC<Props> = ({ value, id, care_type, onChangeValue }) => {
  const { user } = useAuth()
  const { t } = useTranslation('consultation_form')
  const [hoveredType, setHoveredType] = useState<string | null>(null)
  const [showOptions, setShowOptions] = useState(false)

  const getUnitPermissionByType = (care_type: CARE_TYPE) => {
    switch (care_type) {
      case CARE_TYPE.CHAT:
        return UnitPermissionsEnum.chat_consultation
      case CARE_TYPE.ELECTIVE_CARE:
        return UnitPermissionsEnum.elective_care_consultation
      case CARE_TYPE.EMERGENCY_CARE:
        return UnitPermissionsEnum.emergency_care_consultation
    }
  }

  const hasTeleconsultation = useMemo(
    () =>
      !!user?.clients?.some(client =>
        client.units?.some(
          item =>
            item.unit?.permissions?.includes(UnitPermissionsEnum.teleconsultation_type) &&
            item.unit?.permissions?.includes(getUnitPermissionByType(care_type))
        )
      ),
    [user, care_type]
  )
  const hasTeleinterconsultation = useMemo(
    () =>
      !!user?.clients?.some(client =>
        client.units?.some(
          item =>
            item.unit?.permissions?.includes(UnitPermissionsEnum.teleinterconsultation_type) &&
            item.unit?.permissions?.includes(getUnitPermissionByType(care_type))
        )
      ),
    [user, care_type]
  )

  useEffect(() => {
    if (hasTeleconsultation && !hasTeleinterconsultation) {
      onChangeValue(id, 'TELECONSULTATION')
    }
    if (hasTeleinterconsultation && !hasTeleconsultation) {
      onChangeValue(id, 'TELEINTERCONSULTATION')
    }
    if (hasTeleconsultation && hasTeleinterconsultation) {
      setShowOptions(true)
    }
  }, [hasTeleinterconsultation, hasTeleconsultation, id, onChangeValue])

  if (!hasTeleinterconsultation && !hasTeleconsultation)
    return (
      <div className='text-body-md text-highlightRed-pure'>
        {'Nenhuma unidade atribuida a você possui teleconsulta ou teleinterconsulta'}
      </div>
    )

  if (!showOptions)
    return value === 'TELECONSULTATION' ? (
      <div className='font-bold'>{'Teleconsulta'}</div>
    ) : (
      <div className='font-bold'>{'Teleinterconsulta'}</div>
    )

  return (
    <>
      <h2 className='text-heading-xs font-extrabold'>{t('fields.type')}</h2>
      <div className='mt-4 flex items-center gap-4'>
        <label className='relative flex items-center gap-2'>
          <Radio
            size='md'
            selected={value === 'TELECONSULTATION'}
            state='enabled'
            onChange={() => onChangeValue(id, 'TELECONSULTATION')}
          />
          Teleconsulta
          <button
            type='button'
            onMouseEnter={() => setHoveredType('TELECONSULTATION')}
            onMouseLeave={() => setHoveredType(null)}
            className='relative'
          >
            <Question className='text-primaryBrand-primary' size={20} />
          </button>
          {hoveredType === 'TELECONSULTATION' && (
            <div className='absolute left-1/2 top-full z-50 mt-2 w-40 -translate-x-1/2 rounded bg-gray-800 p-2 text-sm text-white shadow-lg'>
              A Teleconsulta é uma consulta médica realizada à distância, utilizando vídeo chamada
            </div>
          )}
        </label>
        <label className='relative flex items-center gap-2'>
          <Radio
            size='md'
            selected={value === 'TELEINTERCONSULTATION'}
            state='enabled'
            onChange={() => onChangeValue(id, 'TELEINTERCONSULTATION')}
          />
          Teleinterconsulta
          <button
            type='button'
            onMouseEnter={() => setHoveredType('TELEINTERCONSULTATION')}
            onMouseLeave={() => setHoveredType(null)}
            className='relative'
          >
            <Question className='text-primaryBrand-primary' size={20} />
          </button>
          {hoveredType === 'TELEINTERCONSULTATION' && (
            <div className='absolute left-1/2 top-full z-50 mt-2 w-48 -translate-x-1/2 rounded bg-gray-800 p-2 text-sm text-white shadow-lg '>
              A Teleinterconsulta é uma consulta médica realizada à distância, utilizando chat.
            </div>
          )}
        </label>
      </div>
    </>
  )
}

export default ConsultationType
