const consultation_follow_up_ptBR = {
  title_pending: 'Atendimentos Pendentes',
  title_in_progress: 'Atendimentos Em Andamento',

  search_input_placeholder: 'Procurar',

  contact_info: 'Informações de contato',
  principal_email: 'E-mail principal',
  cell_number: 'Número de celular',
  no_email: 'E-mail não informado',
  no_phone: 'Telefone não informado',

  modal_consultation_detail: {
    consultation_title: 'Atendimento #{{code}}',
    detail: 'Detalhes',
    participants: 'Participantes',
    toast: {
      success: 'Atendimento finalizado com sucesso',
      error: 'Erro ao finalizar atendimento'
    }
  },

  in_progress: 'Em andamento',
  in_waiting: ' em espera',
  start_in: 'Iniciado há ',
  start_in_waiting: 'Iniciará em ',

  date_not_available: 'Data não disponível',
  actions: {
    full_screen: 'Tela cheia',
    clear_filters: 'Limpar tudo',
    finish_consultation: 'Finalizar atendimento',
    send_whatsapp: 'Enviar WhatsApp'
  },
  types: {
    chat: 'Chat',
    elective_care: 'Eletivo',
    emergency_care: 'Pronto atendimento'
  },
  person: {
    requester: 'Solicitante',
    specialist: 'Executante',
    patient: 'Paciente'
  }
}

export default consultation_follow_up_ptBR
