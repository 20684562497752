/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import Accordion from '../../components/accordion'
import ColorPicker from '../../components/color-picker'
import Radio, { RadioSize } from '../../components/radio'
import Stepper from '../../components/stepper'
import SpecialtyModal from '../../components/modal/specialty-modal'
import Button from '../../components/button'
import ProfilesModal from '../../components/modal/profiles-modal'
import Tag from '../../components/tag'
import TagSelector from '../../components/tag-selector'

const ShowcasePage = () => {
  const [color, setColor] = useState<string>('#000000')
  const [selectedSize, setSelectedSize] = useState<RadioSize | null>(null)
  const [step, setStep] = useState(1)

  const [isModalSpecialtyOpen, setIsModalSpecialtyOpen] = useState(false)
  const [selectedSpecialties, setSelectedSpecialties] = useState<any[]>([])

  const [isModalProfileOpen, setIsModalProfileOpen] = useState(false)
  const [selectedProfiles, setSelectedProfiles] = useState<any[]>([])

  const handleRadioChange = (size: RadioSize) => {
    setSelectedSize(size)
  }

  const handleOpenModalSpecialty = () => {
    setIsModalSpecialtyOpen(true)
  }

  const handleCloseModalSpecialty = () => {
    setIsModalSpecialtyOpen(false)
  }

  const handleSuccess = (selectedValues: any[]) => {
    setSelectedSpecialties(selectedValues)
    setIsModalSpecialtyOpen(false)
  }

  const handleOpenModalProfile = () => {
    setIsModalProfileOpen(true)
  }

  const handleCloseModalProfile = () => {
    setIsModalProfileOpen(false)
  }

  const handleSuccessProfile = (selectedValues: any[]) => {
    setSelectedProfiles(selectedValues)
    setIsModalProfileOpen(false)
  }

  const handleSelectSpecialties = () => {}

  const handleRemoveSpecialty = (specialty: string) => {
    setSelectedSpecialties(selectedSpecialties.filter(s => s !== specialty))
  }

  return (
    <>
      <div className='container mx-auto bg-white p-4'>
        <TagSelector
          label='Especialidades'
          selectedItems={selectedSpecialties}
          onSelect={handleSelectSpecialties}
          onRemove={handleRemoveSpecialty}
        />
        <Tag label='Label' color='blue' variant='secondary' onClose={() => {}} size={'sm'} />
        <Button label='Selecionar Especialidades' onClick={handleOpenModalSpecialty} />
        <SpecialtyModal
          isOpen={isModalSpecialtyOpen}
          selectedValues={selectedSpecialties}
          onClose={handleCloseModalSpecialty}
          onSuccess={handleSuccess}
        />

        <Button label='Selecionar Perfis' onClick={handleOpenModalProfile} />
        <ProfilesModal
          isOpen={isModalProfileOpen}
          selectedValues={selectedProfiles}
          onClose={handleCloseModalProfile}
          onSuccess={handleSuccessProfile}
        />

        <Accordion
          title={
            <div>
              <input type='checkbox' id='permission1' className='mr-2' />
              <span>Permissões</span>
            </div>
          }
          subtitle='0/8 Permissões'
        >
          <div>
            <input type='checkbox' id='permission1' className='mr-2' />
            <label htmlFor='permission1'>Permissão 1</label>
          </div>
          <div>
            <input type='checkbox' id='permission2' className='mr-2' />
            <label htmlFor='permission2'>Permissão 2</label>
          </div>
        </Accordion>
        <Accordion
          title={
            <div>
              <input type='checkbox' id='permission1' className='mr-2' />
              Teste
            </div>
          }
        >
          <div>
            <img src='https://via.placeholder.com/200' alt='Placeholder' />
          </div>
        </Accordion>

        {/* DATEPICKER */}
        {/* <div className='my-4 flex w-[400px] justify-center'>
            <DatePicker
              name='startDate'
              label='Data de Início >> teste'
              placeholder='Selecione uma data'
              onChange={val => formik.setFieldValue('startDate', val)}
              onBlur={formik.handleBlur}
              value={formik.values.startDate}
              disabled={formik.isSubmitting}
              error={formik.errors.startDate}
              touched={formik.touched.startDate}
            />
          </div> */}

        {/* StepperNumber */}
        {/* <div className='my-4 flex w-[400px] justify-center'>
          <StepperNumber
            hint={formik.touched.quantity && formik.errors.quantity ? formik.errors.quantity : ''}
            name='quantity'
            value={formik.values.quantity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            min={1}
            max={100}
            step={1}
            state={'default'}
          />
        </div> */}
        <div className='p-4'>
          <button
            onClick={() => {
              if (step <= 4) setStep(oldValue => oldValue + 1)
            }}
          >
            clique pra alterar o step
          </button>
          <Stepper title='Nome do passo' currentStep={step} totalSteps={5} />
          {/* <Stepper
            title='Nome do passo'
            subtitle='Subtítulo personalizado'
            currentStep={3}
            totalSteps={5}
          /> */}
        </div>

        <div className='flex h-screen items-center justify-center'>
          <ColorPicker value={color} onChange={setColor} label='' />
        </div>

        <div className='flex space-x-4'>
          <Radio
            size='lg'
            state='enabled'
            selected={selectedSize === 'lg'}
            onChange={() => handleRadioChange('lg')}
          />
          <Radio
            size='md'
            selected={selectedSize === 'md'}
            state='enabled'
            onChange={() => handleRadioChange('md')}
          />
          <Radio
            size='sm'
            selected={selectedSize === 'sm'}
            state='enabled'
            onChange={() => handleRadioChange('sm')}
          />

          <Radio size='lg' selected state='disabled' />
          <Radio size='md' selected state='disabled' />
          <Radio size='sm' selected={false} state='disabled' />
        </div>
      </div>
    </>
  )
}

export default ShowcasePage
