import React, { useEffect, useState } from 'react'
import ConsultationService from '../../../../../services/consultation.service'
import { ConsultationMessage } from '../../../../../types'
import { ChatMessages } from '../../../chat/ChatConnection'
import { Loading } from 'semente-js'

export const ChatHistory: React.FC<{
  consultation_id: string
}> = ({ consultation_id }) => {
  const [messages, setMessages] = useState<ConsultationMessage[]>([])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    ConsultationService.getMessagesByConsultationId(consultation_id)
      .then(setMessages)
      .finally(() => setLoading(false))
  }, [consultation_id])

  if (isLoading) return <Loading />

  return (
    <div className='flex flex-col gap-4'>
      <ChatMessages messages={messages} />
    </div>
  )
}
