import PermissionEnum from './permissions'
import UnitPermissionsEnum from './unit-permissions'
import { UseQueryResult } from '@tanstack/react-query'

/* eslint-disable @typescript-eslint/no-explicit-any */
export type PaginationParams = {
  page?: number
  pageSize?: number
  filter?: string
}

export type Dashboard = {
  id: string
  name: string
  icon: string
  permission: PermissionEnum
  superset_id: string
  created_at?: string
}

export type PaginationResponse<T> = {
  [x: string]: any
  data: T[]
  totalItems: number
  page: number
  pageSize: number
  filter: string
}

export type UnitType = {
  id?: string

  name: string

  created_at?: string
  updated_at?: string
}

export type Council = {
  id?: string

  name?: string
  document_name?: string
  is_active?: boolean

  specialties?: Specialty[]

  created_at?: string
  updated_at?: string
}

export type Specialty = {
  id?: string

  name?: string
  council?: Council
  council_id?: string
  document_name?: string
  is_active?: boolean

  created_at?: string
  updated_at?: string
}

export enum UserType {
  INTERNAL = 'INTERNAL',
  CLIENT = 'CLIENT'
}

export type User = {
  id?: string
  type?: UserType
  is_client?: boolean
  client?: Client
  client_id?: string
  profile?: Profile
  profile_id?: string
  document?: string
  phone_type?: string
  force_update_password?: boolean
  rg?: string
  cpf?: string
  cns?: string
  name?: string
  sex?: string
  race?: string
  email?: string
  phone?: string
  gender?: string
  code_cbo?: string
  birthdate?: string
  birth_city?: string
  nationality?: string
  social_name?: string
  mother_name?: string
  mother_unknown?: boolean
  birth_state?: string

  image?: string
  image_id?: string

  document_type?: string
  issue_agency?: string
  description_cbo?: string
  professional_council?: string
  council_registration_number?: string

  naturalization_date?: string
  passport_country?: string
  passport_number?: string
  user_from_client?: boolean

  is_active?: boolean

  is_health_professional?: boolean
  specialties?: UserSpecialty[]
  councils?: UserCouncil[]

  clients?: UserClient[]

  agendas?: Agenda[]
  consultations?: Consultation[]
} & Address

export enum CONSULTATION_TYPE {
  TELECONSULTATION = 'TELECONSULTATION',
  TELEINTERCONSULTATION = 'TELEINTERCONSULTATION'
}

export enum CARE_TYPE {
  CHAT = 'chat',
  ELECTIVE_CARE = 'elective_care',
  EMERGENCY_CARE = 'emergency_care'
}

export enum CONSULTATION_STATUS {
  LATED = 'LATED',
  NOT_FINISHED = 'NOT_FINISHED',
  SCHEDULED = 'SCHEDULED',
  WAITING = 'WAITING',
  FINISHED = 'FINISHED',
  IN_ROOM = 'IN_ROOM'
}

export type File = {
  id?: string
  file_name?: string
  size?: number
  key?: string
  location?: string
  uploaded_by_id?: string
  user?: User
  created_at?: Date
}

export type Consultation = {
  id?: string

  type?: CONSULTATION_TYPE
  care_type?: CARE_TYPE
  unit_id?: string
  client_id?: string
  end_date?: string
  start_date?: string
  specialty_id?: string
  patient_id?: string
  patient_entered_at?: string
  specialist_id?: string
  specialist_entered_at?: string
  requester_id?: string
  requester_entered_at?: string

  patient?: Patient
  specialty?: Specialty
  specialist?: User
  temp_specialist?: User
  unit?: Unit
  requester?: User
  created_by?: User
  files?: File[]

  code?: string
  status?: CONSULTATION_STATUS

  anamnesis?: Anamnesis

  initial_diagnosis?: string
  final_diagnosis?: string

  messages?: ConsultationMessage[]

  started_at?: string
  created_at?: string
}

export type ConsultationUploadFile = {
  id?: string
  code?: number
  consultation_id?: string
  generated_by_id?: string
  consultation?: Consultation
  generated_by?: User
  created_at?: string
  uploaded_at?: string
}

export enum MessageType {
  LOG_ENTERED = 'LOG_ENTERED',
  LOG_EXITED = 'LOG_EXITED',
  USER = 'USER'
}

export type ConsultationMessage = {
  id?: string
  user?: User
  type?: MessageType
  message?: string
  user_id?: string
  consultation_id?: string
  consultation?: Consultation
  created_at?: string
}

export type UserCouncil = {
  id?: string
  user_id?: string
  user?: User
  council_id?: string
  council?: Council
  document?: string
  uf?: string
}

export type UserSpecialty = {
  id?: string
  user_id?: string
  user?: User
  specialty_id?: string
  specialty?: Specialty
  document?: string
}

export type UserClient = {
  id?: string
  user_id?: string
  profile_id?: string
  client_id?: string
  units?: UserClientUnit[]
  user?: User
  profile?: Profile
  client?: Client
  created_at?: Date
}

export type UserClientUnit = {
  id?: string
  user_client_id?: string
  unit_id?: string
  user_client?: UserClient
  unit?: Unit
}

export type Profile = {
  id?: string
  name?: string
  type?: 'requester' | 'specialist' | 'other'
  permissions?: PermissionEnum[]
}

export type ClientProfileItem = {
  id: string
  name: string
  permissions: string[]
  created_at: string
  updated_at: string
}

export type ClientProfile = {
  id?: string
  client_id?: string
  profile_id?: string
  created_at?: Date
  profile?: Profile
}

export type ServerSession = {
  createdAt: number
  sessionId: string
  connections: ServerConnection[]
  activeConnections: ServerConnection[]
}

export type LocalSession = {
  id?: string
  created_at?: string
  name: string
  created_by?: User
}

export type ServerConnection = {
  connectionId: string
  createdAt: number
  token: string
  role: 'PUBLISHER' | 'SUBSCRIBER' | 'MODERATOR'
  serverData: string
}

export type Client = {
  id?: string
  name?: string
  cnes?: string
  cnpj?: string
  cpf?: string
  units?: Unit[]
  email?: string
  phone?: string
  manager?: string
  profiles?: ClientProfile[]
  users?: User[]
  units_limit?: number
  permissions?: ClientPermission[]
  specialties?: ClientSpecialty[]
  created_at?: Date
  updated_at?: Date
  type?: 'PJ' | 'PF'
  allow_profile_access_to_client?: boolean
  primary_color?: string

  image?: string
  image_id?: string

  contract_start?: string
  contract_end?: string
  is_active?: boolean
} & Address

export type ClientPermission = {
  id?: string
  client_id?: string
  permission?: PermissionEnum
  client?: Client
  created_at?: string
  updated_at?: string
}

export type ClientSpecialty = {
  id?: string
  client_id?: string
  specialty_id?: string
  client?: Client
  specialty?: Specialty
  created_at?: Date
}

export type Unit = {
  id?: string
  name?: string
  cnes?: string
  cnpj?: string
  email?: string
  phone?: string
  client?: Client
  client_id?: string
  unit_type?: UnitType
  is_active?: boolean
  unit_type_id?: string
  specialties?: UnitSpecialty[]
  user_clients?: User[]
  permissions?: UnitPermissionsEnum[]
  technical_manager_name?: string
  technical_manager_council_id?: string
  technical_manager_doc?: string
  technical_manager_council_doc?: string
  technical_manager_council_uf?: string
  cep?: string
  address?: string
  address_number?: string
  district?: string
  state?: string
  city?: string
  created_at?: Date
  updated_at?: Date
} & Address

export type UnitSpecialty = {
  id?: string
  unit_id?: string
  specialty_id?: string
  unit?: Unit
  specialty?: Specialty
  created_at?: Date
}

export type Address = {
  cep?: string
  cepUnknown?: boolean
  city?: string
  state?: string
  address?: string
  district?: string
  complement?: string
  address_number?: string
}

export type Agenda = {
  id?: string
  type?: 'FIXED' | 'CUSTOM'
  user?: User
  user_id?: string
  start_date?: string
  end_date?: string
  recurrent_events?: AgendaEvent[]
  simple_events?: AgendaEvent[]
  created_at?: string
  updated_at?: string
}

export type AgendaEvent = {
  id?: string
  key?: string
  agenda_id?: string

  day_of_week?: number

  unit_id?: string
  start_time?: string
  end_time?: string
  slot_time?: number

  agenda?: Agenda
  clients?: EventClient[]
  created_at?: Date
  updated_at?: Date
}

export type EventClient = {
  id?: string
  recurrent_event_id?: string
  simple_event_id?: string
  client_id?: string

  client?: Client
  simple_event?: Event
  recurrent_event?: Event
  units?: EventClientUnit[]
  created_at?: Date
}

export type EventClientUnit = {
  id?: string
  unit_id?: string
  event_client_id?: string

  unit?: Unit
  event_client?: EventClient
  created_at?: Date
}

export interface DayPeriod {
  from: Date | null
  until: Date | null
  duration: number | null
}

export type DaysOfWeek =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'

export interface PeriodsState {
  monday: number[]
  tuesday: number[]
  wednesday: number[]
  thursday: number[]
  friday: number[]
  saturday: number[]
  sunday: number[]
}

export type PatientSurgicalHistory = {
  id?: string
  patient_id?: string
  value?: string
  patient?: Patient
  created_at?: Date
}

export type PatientMedicine = {
  id?: string
  patient_id?: string
  name?: string
  dosage?: string
  dosage_instructions?: string
  patient?: Patient
  created_at?: Date
}

export type PatientHealthConditions = {
  id?: string
  patient_id?: string
  value?: string
  patient?: Patient
  created_at?: Date
}

export type PatientChronicDiseases = {
  id?: string
  patient_id?: string
  value?: string
  patient?: Patient
  created_at?: Date
}

export type PatientAllergies = {
  id?: string
  patient_id?: string
  value?: string
  patient?: Patient
  created_at?: Date
}

export type Patient = {
  id?: string
  cpf?: string
  cns_number?: string
  name?: string
  social_name?: string
  birthdate?: Date
  gender?: string
  document_type?: string
  document_number?: string
  document_agency?: string
  document_date?: Date
  email?: string
  primary_phone?: string
  secondary_phone?: string
  address?: string
  address_number?: string
  address_complement?: string
  address_neighborhood?: string
  address_state?: string
  address_city?: string
  address_code?: string
  mother_name?: string
  race?: string
  nationality?: string
  birth_state?: string
  birth_city?: string
  guardian_name?: string
  guardian_state?: string
  guardian_cpf?: string
  height?: number
  weight?: number
  document_validity?: string
  allergies?: PatientAllergies[]
  chronic_diseases?: PatientChronicDiseases[]
  health_conditions?: PatientHealthConditions[]
  surgical_history?: PatientSurgicalHistory[]
  consultations?: Consultation[]
  medicines?: PatientMedicine[]
  created_at?: Date
  updated_at?: Date
}

export type QuestionOption = {
  id?: string
  type?: 'checkbox' | 'text' | 'scale' | 'date' | 'textarea' | 'radio' | string
  title?: string
  required?: boolean
  options?: string[]
  min?: number
  max?: number
  yes_no?: boolean
}

export type AnamnesisSectionDefault = {
  title: string
  questions: QuestionOption[]
}

export type Anamnesis = {
  id?: string
  default_answers?: any
  specialty_answers?: any
  consultation_id?: string
  consultation?: Consultation
}

export type AnamnesisTemplate = {
  id?: string
  unit_id?: string
  specialty_id?: string
  client_id?: string

  unit?: Unit
  specialty?: Specialty
  client?: Client

  questions?: QuestionOption[]
}

export enum FOLLOW_UP_STATUS {
  IN_PROGRESS = 'in_progress',
  PENDING = 'pending'
}

export enum FOLLOW_UP_DISPLAY_MODE {
  CARD = 'carc',
  LIST = 'list'
}

export type MevoFile = {
  id?: string
  category?: string
  patient_id?: string
  file_name?: string
  size?: number
  key?: string
  location?: string
  prescriber_id?: string
  consultation_id?: string
  prescriber?: User
  patient?: Patient
  consultation?: Consultation
  created_at?: string
}

export type RefetchType = UseQueryResult<Consultation | undefined, Error>['refetch']

export type ScaleTimeInterval = {
  start_time: number
  start_date?: string
  end_time: number
  end_date?: string
  scale_id?: string
  scale_specialist_id?: string
  id: string
  created_at: string
  updated_at: string
}

export type ScaleUnit = {
  unit_id: string
  scale_id: string
  id: string
  unit?: Unit
  created_at: string
  updated_at: string
}

export type ScaleSpecialist = {
  specialist_id: string
  price: number
  time_intervals: ScaleTimeInterval[]
  scale_id?: string
  id?: string
  specialist: User
  created_at?: string
  updated_at?: string
}

export type Scale = {
  date: string
  units: ScaleUnit[]
  status: 'DRAFT' | 'PUBLISHED'
  duration: number
  specialty?: Specialty
  specialists: ScaleSpecialist[]
  specialty_id: string
  time_intervals: ScaleTimeInterval[]
  id: string
  consultations?: Consultation[]
  created_at: string
  updated_at: string
}

export type ScaleForm = {
  specialty_id: string
  client_id?: string
  duration: number
  dates: string[]
  units: string[]
  time_intervals: Partial<ScaleTimeInterval>[]
  specialists: ScaleSpecialist[]
  status?: 'DRAFT' | 'PUBLISHED'
  id?: string
}

export type Document = {
  id: string
  category: string
  patient_id: string
  file_name: string
  key: string
  location: string
  prescriber_id?: string
  consultation_id?: string
  created_at: string
}

export type CID = {
  id: string
  name: string
  created_at: string
  updated_at: string
}
