import { useUserDocumentFormik } from '../../../hooks/forms/useUserForm'
import ButtonSkeleton from '../../../components/button/skeleton'
import InputSkeleton from '../../../components/input/skeleton'
import ContentFooter from '../../../components/content/footer'
import ContentForm from '../../../components/content/form'
import InputField from '../../../components/input'
import Button from '../../../components/button'
import { useTranslation } from 'react-i18next'
import { User } from '../../../types'

type Props = {
  isUpdate: boolean
  isLoading: boolean
  user: Partial<User>
  onCancel: () => void
  onSave: (values: Partial<User>) => void
  onUpdate: (values: Partial<User>) => void
}

const UserDocumentForm: React.FC<Props> = ({
  user,
  onSave,
  onCancel,
  isUpdate,
  onUpdate,
  isLoading
}) => {
  const { t } = useTranslation('user_form')
  const formik = useUserDocumentFormik(isUpdate ? onUpdate : onSave, user)

  return (
    <form onSubmit={formik.handleSubmit}>
      {isLoading ? (
        <Skeleton />
      ) : (
        <ContentForm hasSubtitle hasBackButton>
          <InputField
            type='cpf'
            id='document'
            name='document'
            onBlur={formik.handleBlur}
            label={t('fields.document')}
            onChange={formik.handleChange}
            value={formik.values.document!}
            state={formik.touched.document && formik.errors.document ? 'error' : 'default'}
            hint={
              formik.touched.document && formik.errors.document ? formik.errors.document : undefined
            }
          />

          <InputField
            id='cns'
            name='cns'
            type='cns'
            label={t(`fields.cns`)}
            onBlur={formik.handleBlur}
            value={formik.values.cns!}
            onChange={formik.handleChange}
            state={formik.touched.cns && formik.errors.cns ? 'error' : 'default'}
            hint={formik.touched.cns && formik.errors.cns ? formik.errors.cns : undefined}
          />
        </ContentForm>
      )}
      <ContentFooter>
        {isLoading ? (
          <ButtonSkeleton className='ml-0 mr-auto' />
        ) : (
          <Button
            size='md'
            type='button'
            onClick={onCancel}
            variant='secondary'
            label={t('actions.cancel')}
            isLoading={formik.isSubmitting}
          />
        )}
        {isLoading ? (
          <ButtonSkeleton />
        ) : (
          <Button
            size='md'
            type='submit'
            className='ml-auto'
            isLoading={formik.isSubmitting}
            state={!formik.isValid && formik.errors.document ? 'disable' : 'enable'}
            label={t(isUpdate ? 'actions.save' : 'actions.continue')}
          />
        )}
      </ContentFooter>
    </form>
  )
}

const Skeleton = () => {
  return (
    <ContentForm hasSubtitle hasBackButton>
      <InputSkeleton showLabel /> <InputSkeleton showLabel />
    </ContentForm>
  )
}

export default UserDocumentForm
