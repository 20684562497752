import React, { useEffect, useState } from 'react'
import { useGetPatient } from '../../hooks/forms/usePatientForm'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes/path'
import { Patient } from '../../types'
import SearchField from '../search'
import Button from '../button'
import Modal from '.'
import { validateCPF } from '../../helpers/validateCPF'
import ProtectedComponent from '../protected'
import PermissionEnum from '../../types/permissions'

interface Props {
  label: string
  title: string
  isOpen: boolean
  placeholder?: string
  onClose: () => void
  onSelect: (patient: Patient) => void
}

const PatientModal: React.FC<Props> = ({
  label,
  title,
  isOpen,
  onClose,
  onSelect,
  placeholder = ''
}) => {
  const navigate = useNavigate()
  const [document, setDocument] = useState('')
  const [invalidCPF, setInvalidCPF] = useState(false)

  useEffect(() => {
    if (document.length === 11) setInvalidCPF(!validateCPF(document))
    else setInvalidCPF(false)
  }, [document])

  const { data: patient, isFetching, isFetched } = useGetPatient(invalidCPF ? '' : document)

  const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocument(event.target.value)
  }

  return (
    <Modal isOpen={isOpen} title={title} onClose={onClose}>
      <div className='w-[350px] p-2'>
        <h2 className='mb-4 text-lg font-semibold'>{label}</h2>

        <div className='mb-4'>
          <SearchField
            type='cpf'
            value={document}
            placeholder={placeholder}
            errorMessage={invalidCPF ? 'CPF Inválido' : ''}
            className={invalidCPF ? 'border-highlightRed-pure' : ''}
            onChange={handleDocumentChange}
          />
        </div>

        {isFetching && (
          <div className='text-center'>
            <p>Carregando...</p>
          </div>
        )}

        {!isFetching && isFetched && !patient?.id && (
          <div className='mb-4 rounded bg-neutralBackground-secondary p-4'>
            <p className='text-body-md text-neutralContent-secondary'>
              Nenhum paciente cadastrado com o CPF informado.
            </p>
            <ProtectedComponent permission={PermissionEnum.register_patient}>
              <>
                <p className='text-body-md text-neutralContent-secondary'>
                  Deseja cadastrar um novo paciente?
                </p>

                <Button
                  size={'xs'}
                  className='mt-2'
                  label='Cadastrar paciente'
                  onClick={() => navigate(ROUTES.patient.new)}
                />
              </>
            </ProtectedComponent>
          </div>
        )}

        {!isFetching && patient?.id && (
          <div className='mb-4 flex items-center justify-between gap-2 rounded-xl border p-4'>
            <div className='flex-1'>
              <p className='text-lg font-semibold'>{patient?.name}</p>
              <p className='text-gray-600'>{patient.cpf}</p>
            </div>
            <Button
              label='Selecionar'
              size={'sm'}
              variant='secondary'
              onClick={() => {
                onSelect(patient)
                onClose()
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  )
}

export default PatientModal
