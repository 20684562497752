import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { PaginationParams } from '../types'
import CidsService from '../services/cids.service'
import { useToastContext } from '../contexts/toast'

export const useListCids = (params: PaginationParams) => {
  return useQuery({
    queryKey: ['listCids', params],
    queryFn: () => CidsService.list(params),
    placeholderData: prev => prev
  })
}

export const useUpdateDiagnosis = (id: string) => {
  const queryClient = useQueryClient()
  const { toast } = useToastContext()

  return useMutation({
    mutationFn: (values: { initial_diagnosis_id: string; final_diagnosis_id: string }) =>
      CidsService.update(id, values),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getConsultation', { id }] })
    },
    onError: error => {
      toast.error(error.message || 'Algo deu errado')
    }
  })
}
