import { useEffect, useState, FocusEvent } from 'react'
import { CID } from '../../types'
import Dropdown from '.'
import { useListCids } from '../../hooks/useCids'

type Props = {
  label: string
  name: string
  placeholder?: string
  disabled?: boolean
  hint?: string
  value: string
  onChange: (cid?: CID) => void
  onBlur: (e: FocusEvent<unknown, Element>) => void
  error?: boolean
}

const CidsSelect: React.FC<Props> = ({ onChange, label, ...props }) => {
  const [filter, setFilter] = useState('')
  const [hasMore, setHasMore] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const [options, setOptions] = useState<{ value: string; label: string }[]>([])

  const { data: cids, isFetching } = useListCids({
    page: currentPage,
    filter,
    pageSize: 20
  })

  useEffect(() => {
    if (cids && cids.data) {
      setOptions(current => [
        ...(cids.page >= 1 ? current : []),
        ...cids.data.map((cid: CID) => ({
          value: cid.id || '',
          label: cid.name || ''
        }))
      ])
      setHasMore(cids.totalItems > (cids.page + 1) * cids.pageSize)
    }
  }, [cids])

  const loadMoreOptions = () => {
    if (hasMore) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handleInputChange = (inputValue: string) => {
    setFilter(inputValue)
    setCurrentPage(0)
  }

  const handleSelect = (option: { value: string; label: string } | null) => {
    if (!option) onChange(undefined)

    const cid = cids?.data.find(item => item.id === option?.value)
    onChange(cid)
  }

  const handleMenuOpen = () => {
    // Reseta o filtro e a páginação ao abrir o menu
    setFilter('')
    setCurrentPage(0)
  }

  return (
    <Dropdown
      label={label}
      options={options}
      onChangeValue={handleSelect}
      loadMoreOptions={loadMoreOptions}
      onInputChange={handleInputChange}
      onMenuOpen={handleMenuOpen}
      noOptionsMessage={() => {
        if (isFetching) return 'Carregando...'
        return 'Nenhum resultado encontrado'
      }}
      {...props}
    />
  )
}

export default CidsSelect
