import { QRCodeSVG } from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import Modal from '../../../../../../components/modal'
import Button from '../../../../../../components/button'
import { ConsultationUploadFile } from '../../../../../../types'

export const DocumentByQrCodeModal: React.FC<{
  closeModal: VoidFunction
  consultationUploadFile?: ConsultationUploadFile
}> = ({ consultationUploadFile, closeModal }) => {
  const { t } = useTranslation('consultation_details')

  const handleCancel = () => {
    closeModal()
  }

  const generateQRCodeValue = () => {
    const url = `${process.env.REACT_APP_UPLOAD_FILE_URL}?upload_file_id=${consultationUploadFile?.id}&consultation_id=${consultationUploadFile?.consultation_id}`
    return url
  }

  return (
    <Modal
      onClose={handleCancel}
      isOpen={!!consultationUploadFile}
      title={t('interactions.modal.title')}
    >
      <div className='flex w-[445px] flex-col'>
        <span className='w-[390px] text-body-md text-neutralContent-tertiary'>
          {t('interactions.modal.description_qrcode')}
        </span>

        {!consultationUploadFile ? (
          <>Carregando...</>
        ) : (
          <>
            <div className='mt-12 flex justify-center'>
              <QRCodeSVG
                value={generateQRCodeValue()}
                size={248}
                bgColor={'#ffffff'}
                fgColor={'#000000'}
                level={'L'}
                marginSize={0}
              />
            </div>
            <div className='mt-12 flex w-[70px] self-center rounded-xl bg-primaryBrand-light p-6'>
              <span className='text-label-lg text-primaryBrand-primary'>
                {consultationUploadFile.code}
              </span>
            </div>
          </>
        )}

        <div className='mt-12 flex justify-end'>
          <Button
            label={t('actions.cancel')}
            className={'text-neutral-900'}
            variant='tertiary'
            onClick={handleCancel}
          />
        </div>
      </div>
    </Modal>
  )
}
