import {
  addDays,
  addMonths,
  differenceInDays,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  startOfMonth,
  startOfWeek
} from 'date-fns'
import Tag from '../../tag'
import { formatMonthYear } from '../../../helpers/formatMonthYear'
import { ArrowRight } from '@phosphor-icons/react/dist/ssr'
import { ArrowLeft } from '@phosphor-icons/react'
import { useState } from 'react'

type Props = {
  dateOfMonth: Date
  selectedDate?: Date
  selectedDates?: Date[]
  onUpdateDates?: (days: Date[]) => void
  onSelectDate?: (day: Date) => void
}

const SelectMonthCalendar: React.FC<Props> = ({
  dateOfMonth,
  selectedDate,
  selectedDates,
  onUpdateDates,
  onSelectDate
}) => {
  const [date, setDate] = useState(dateOfMonth)
  const daysOfWeek = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']

  const generateCalendarDays = (): Date[] => {
    const monthStart = startOfMonth(date)
    const monthEnd = endOfMonth(date)
    const startDate = startOfWeek(monthStart, { weekStartsOn: 0 })
    const endDate = endOfWeek(monthEnd, { weekStartsOn: 0 })

    const totalDays = differenceInDays(endDate, startDate) + 1

    return Array.from({ length: totalDays }, (_, i) => addDays(startDate, i))
  }

  const previousMonth = () => {
    setDate(addMonths(date, -1))
  }

  const nextMonth = () => {
    setDate(addMonths(date, 1))
  }

  return (
    <div>
      <span className='mb-6 flex items-center gap-1 text-heading-sm text-neutralContent-primary'>
        <button onClick={previousMonth}>
          <ArrowLeft size={20} />
        </button>
        <div className='flex-1 text-center'>{formatMonthYear(date, false)}</div>

        <button onClick={nextMonth}>
          <ArrowRight size={20} />
        </button>
      </span>
      <div className='text-primary grid grid-cols-7 items-center text-center text-sm font-bold'>
        {daysOfWeek.map((day, index) => (
          <div key={index}>{day}</div>
        ))}
      </div>

      <div className='grid grid-cols-7 gap-2'>
        {generateCalendarDays().map((day, index) => (
          <div
            key={index}
            className={`items-center justify-center rounded-full py-1 text-center text-body-xs ${
              day.getMonth() === date.getMonth()
                ? 'cursor-pointer text-neutralContent-primary'
                : 'cursor-default text-neutralContent-tertiary'
            } ${
              selectedDates?.some(selectedDate => isSameDay(selectedDate, day)) ||
              (selectedDate && isSameDay(selectedDate, day))
                ? 'bg-highlightBlue-light text-primaryBrand-primary'
                : ''
            }`}
            onClick={
              day.getMonth() === date.getMonth() && onSelectDate
                ? () => onSelectDate(day)
                : undefined
            }
          >
            {day.getMonth() === date.getMonth() ? format(day, 'd') : ''}
          </div>
        ))}
      </div>

      <div className='mt-6 inline-flex w-full flex-wrap justify-center gap-2 '>
        {!!selectedDates &&
          onUpdateDates &&
          selectedDates.length > 0 &&
          selectedDates.map(date => (
            <Tag
              label={`${format(date, 'dd/MM/yyyy')}`}
              key={date.toString()}
              onClose={() => onUpdateDates(selectedDates.filter(d => d !== date))}
              color='blue'
              variant='secondary'
              size={'xs'}
            />
          ))}
      </div>
    </div>
  )
}

export default SelectMonthCalendar
