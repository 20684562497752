import { useToastContext } from '../../contexts/toast'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import UserService from '../../services/user.service'
import { User, UserType } from '../../types'
import { validateCPF } from '../../helpers/validateCPF'

export const useUserPersonalInformationFormik = (
  onSubmit: (values: Partial<User>) => void,
  user: Partial<User>
) => {
  const validationSchema = Yup.object({
    name: Yup.string().required('Nome é obrigatório'),
    social_name: Yup.string().optional(),
    birthdate: Yup.string()
      .required('Data de nascimento é obrigatório')
      .max(new Date().getTime(), 'Não é possível incluir uma data futura'),
    mother_unknown: Yup.boolean(),
    image: Yup.string().optional(),
    mother_name: Yup.string().when('mother_unknown', {
      is: false,
      then: schema => schema.required('Nome da mãe é obrigatório'),
      otherwise: schema => schema.optional()
    }),
    sex: Yup.string().required('Sexo é obrigatório'),
    gender: Yup.string().optional(),
    race: Yup.string().optional(),
    nationality: Yup.string().required('Nacionalidade é obrigatório'),
    birth_city: Yup.string().when('nationality', {
      is: 'BR',
      then: schema => schema.required('Cidade de nascimento é obrigatório'),
      otherwise: schema => schema.optional()
    }),
    birth_state: Yup.string().when('nationality', {
      is: 'BR',
      then: schema => schema.required('Estado do nascimento é obrigatório'),
      otherwise: schema => schema.optional()
    }),
    naturalization_date: Yup.string().when('nationality', {
      is: 'BR',
      then: schema => schema.optional(),
      otherwise: schema => schema.required('Data de naturalização é obrigatório')
    }),
    passport_country: Yup.string().when('nationality', {
      is: 'BR',
      then: schema => schema.optional(),
      otherwise: schema => schema.required('País emissor do passaporte é obrigatório')
    }),
    passport_number: Yup.string().when('nationality', {
      is: 'BR',
      then: schema => schema.optional(),
      otherwise: schema => schema.required('Número do passaporte é obrigatório')
    }),
    type: Yup.string().required('Tipo de usuário é obrigatório'),
    clients: Yup.array().when('type', {
      is: UserType.CLIENT,
      then: schema =>
        schema.min(1).of(
          Yup.object().shape({
            client: Yup.object().required(),
            units: Yup.array().min(1)
          })
        ),
      otherwise: schema => schema.optional()
    })
  })

  const initialValues = {
    name: user.name || '',
    type: user.type,
    clients: user?.clients || [],
    social_name: user?.social_name || '',
    birthdate: user?.birthdate || '',
    mother_unknown: user?.mother_unknown || false,
    image: user?.image || '',
    mother_name: user?.mother_name || '',
    sex: user?.sex || '',
    gender: user?.gender || '',
    race: user?.race || '',
    nationality: user?.nationality || 'BR',
    birth_state: user?.birth_state || '',
    birth_city: user?.birth_city || '',
    naturalization_date: user?.naturalization_date || '',
    passport_country: user?.passport_country || '',
    passport_number: user?.passport_number || ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit
  })

  return formik
}

export const useUserContactFormik = (
  onSubmit: (values: Partial<User>) => void,
  user: Partial<User>
) => {
  const validationSchema = Yup.object({
    email: Yup.string().required('E-mail é obrigatório'),
    phone: Yup.string().required('Telefone é obrigatório'),
    phone_type: Yup.string().required('Tipo de contato é obrigatório')
  })

  const initialValues = {
    email: user.email || '',
    phone: user.phone || '',
    phone_type: user.phone_type || 'cell'
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit
  })

  return formik
}

export const useUserAddressFormik = (
  onSubmit: (values: Partial<User>) => void,
  user: Partial<User>
) => {
  const validationSchema = Yup.object({
    cep: Yup.string().optional(),
    address: Yup.string().optional(),
    address_number: Yup.string().optional(),
    district: Yup.string().optional(),
    state: Yup.string().optional(),
    city: Yup.string().optional(),
    complement: Yup.string().optional()
  })

  const initialValues = {
    cep: user.cep || '',
    address: user.address || '',
    address_number: user.address_number || '',
    district: user.district || '',
    state: user.state || '',
    city: user.city || '',
    complement: user.complement || ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit
  })

  return formik
}

export const useUserDocumentFormik = (
  onSubmit: (values: Partial<User>) => void,
  user: Partial<User>
) => {
  const validationSchema = Yup.object({
    document: Yup.string()
      .required('CPF é obrigatório')
      .test('is-valid-cpf', 'CPF inválido', value => validateCPF(value)),
    cns: Yup.string().optional()
  })

  const initialValues = {
    document: user.document || '',
    cns: user.cns || ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit
  })

  return formik
}

export const useUserProfessionalInformationFormik = (
  onSubmit: (values: Partial<User>) => void,
  user: Partial<User>
) => {
  const validationSchema = Yup.object({
    is_health_professional: Yup.boolean(),
    specialties: Yup.array().when('is_health_professional', {
      is: true,
      then: schema =>
        schema.min(1).of(
          Yup.object().shape({
            specialty: Yup.object().required()
          })
        ),
      otherwise: schema => schema.optional()
    }),
    councils: Yup.array().when('is_health_professional', {
      is: true,
      then: schema =>
        schema.min(1).of(
          Yup.object().shape({
            council: Yup.object().required(),
            document: Yup.string().required(),
            uf: Yup.string().required()
          })
        ),
      otherwise: schema => schema.optional()
    })
  })

  const initialValues: Partial<User> = {
    is_health_professional: user.is_health_professional || false,
    specialties: user.specialties || [],
    councils: user.councils || []
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit
  })

  return formik
}

export const useUpdatePasswordFormik = (token?: string, onSuccess?: () => void) => {
  const { toast } = useToastContext()

  const validationSchema = Yup.object({
    password: Yup.string().required('Nova senha é obrigatória'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), undefined], 'As senhas não coincidem')
      .required('A confirmação da senha é obrigatória')
  })

  const initialValues = {
    password: '',
    confirmPassword: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async ({ password }: { password: string; confirmPassword: string }) => {
      try {
        await UserService.updatePassword({ password }, token)

        if (onSuccess) {
          onSuccess()
        } else {
          toast.success('Senha atualizada com sucesso!')
        }
        formik.resetForm()
      } catch (err) {
        toast.error((err as Error).message || '')
      }
    }
  })

  return formik
}
