import Modal from '../../../components/modal'
import { useTranslation } from 'react-i18next'
import Button from '../../../components/button'

import Avatar from '../../../components/avatar'
import { DeviceMobile, Envelope } from '@phosphor-icons/react'

type UserProps = {
  name?: string
  email?: string
  phone?: string
  primary_phone?: string
}

interface Props {
  user: UserProps
  specialty?: string
  document?: string
  isOpen: boolean
  onClose: () => void
}
const PersonDetailModal: React.FC<Props> = ({ user, specialty, document, isOpen, onClose }) => {
  const { t } = useTranslation('consultation_follow_up')

  const phone = user?.phone || user?.primary_phone

  const sendWhatsapp = () => {
    if (!phone) {
      return
    }

    const sanitizedPhone = phone.replace(/[^\d]/g, '')

    const phoneWithCountryCode = sanitizedPhone.startsWith('55')
      ? sanitizedPhone
      : `55${sanitizedPhone}`

    window.open(`https://wa.me/${phoneWithCountryCode}`, '_blank')
  }

  return (
    <Modal isOpen={isOpen} title={''} onClose={onClose} className='w-96'>
      <div className='mb-[70px] flex h-[350px] flex-col'>
        <Avatar name={user?.name} size='xl' color='neutral' />
        <span className='mt-4 text-heading-xs text-neutralContent-primary'>{user?.name}</span>
        {!!specialty && (
          <div className='flex gap-2 text-body-sm text-neutralContent-secondary'>
            {specialty} {document && `• CRM ${document}`}
          </div>
        )}

        <div className='mt-9'>
          <span className='text-heading-xs text-neutralContent-primary'>{t('contact_info')}</span>
          <div className='mt-2 flex items-center gap-3'>
            <Envelope size={20} />
            <div className='flex flex-col gap-1'>
              <span className='text-label-sm'>{t('principal_email')}</span>
              <span className='text-body-sm text-neutralContent-secondary'>
                {!!user?.email ? user?.email : t('no_email')}
              </span>
            </div>
          </div>

          <div className='mt-3 flex items-center gap-3'>
            <DeviceMobile size={20} />
            <div className='flex flex-col gap-1'>
              <span className='text-label-sm'>{t('cell_number')}</span>
              <span className='text-body-sm text-neutralContent-secondary'>
                {phone ? phone : t('no_phone')}
              </span>
            </div>
          </div>
        </div>
      </div>

      {phone && (
        <Button
          label={t('actions.send_whatsapp')}
          width={'350'}
          className='flex justify-center self-center '
          onClick={sendWhatsapp}
        />
      )}
    </Modal>
  )
}

export default PersonDetailModal
