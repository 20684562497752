import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import ScaleService, { ListScaleParams } from '../services/scale.service'
import { useToastContext } from '../contexts/toast'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../routes/path'
import { ScaleForm } from '../types'
import { useFormik } from 'formik'
import * as Yup from 'yup'

export const useListScalesByClient = (params: ListScaleParams) => {
  return useQuery({
    queryKey: ['ListScalesByClient', params],
    queryFn: () => (params.client_id ? ScaleService.listScales(params) : null)
  })
}

export const useGetScaleById = (id?: string) => {
  return useQuery({
    queryKey: ['getScaleById', id],
    queryFn: () => (id && id !== 'new' ? ScaleService.getById(id) : null),
    enabled: !!id
  })
}

export const useNewScaleForm = (
  client_id: string,
  mode: 'create' | 'update' = 'create',
  initialScale?: ScaleForm
) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { toast } = useToastContext()

  const initialValues: ScaleForm = initialScale || {
    dates: [],
    units: [],
    client_id,
    duration: 30,
    specialty_id: '',
    time_intervals: [
      {
        start_time: 7,
        end_time: 19
      }
    ],
    specialists: []
  }

  const validationSchema = Yup.object().shape({
    dates: Yup.array()
      .of(Yup.string().required('A data é obrigatória'))
      .min(1, 'Selecione ao menos uma data'),
    duration: Yup.number().required('Duração é obrigatória'),
    specialty_id: Yup.string().required('Especialidade é obrigatória'),
    units: Yup.array().min(1, 'Selecione ao menos uma unidade'),
    time_intervals: Yup.array()
      .of(
        Yup.object().shape({
          start_time: Yup.number().required('Horário inicial é obrigatório'),
          end_time: Yup.number().required('Horário final é obrigatório')
        })
      )
      .min(1, 'Defina ao menos um intervalo de tempo'),
    specialists: Yup.array().of(
      Yup.object().shape({
        specialist_id: Yup.string().required('Especialista é obrigatório'),
        price: Yup.number().required('Preço é obrigatório'),
        time_intervals: Yup.array()
          .of(
            Yup.object().shape({
              start_time: Yup.number().required('Horário inicial é obrigatório'),
              end_time: Yup.number().required('Horário final é obrigatório')
            })
          )
          .min(1, 'Defina ao menos um intervalo de tempo para o especialista')
      })
    )
  })

  const mutationCreate = useMutation({
    mutationFn: (values: ScaleForm) => ScaleService.create(values),
    onSuccess: () => {
      formik.setSubmitting(false)
      toast.success('Escala criada com sucesso')
      navigate(ROUTES.agenda.calendar.replace(':client_id', client_id))
    },
    onError: (error: { message: string }) => {
      toast.error(error.message || 'Algo deu errado')
      formik.setSubmitting(false)
    }
  })

  const mutationUpdate = useMutation({
    mutationFn: (values: ScaleForm) => ScaleService.update(values.id!, values),
    onSuccess: () => {
      toast.success('Escala atualizada com sucesso')
      queryClient.invalidateQueries({ queryKey: ['scales'] })
      navigate(ROUTES.agenda.calendar.replace(':client_id', client_id))
    },
    onError: (error: { message: string }) => {
      toast.error(error.message || 'Algo deu errado')
      formik.setSubmitting(false)
    }
  })

  const formik = useFormik<ScaleForm>({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => {
      if (mode === 'create') {
        mutationCreate.mutate(values)
      } else {
        mutationUpdate.mutate(values)
      }
    }
  })

  return formik
}

export const usePublishScale = (onComplete: VoidFunction) => {
  const queryClient = useQueryClient()
  const { toast } = useToastContext()

  return useMutation({
    mutationFn: ({ id }: { id: string }) => ScaleService.publish(id),
    onSuccess: _data => {
      toast.success('Escala publicada com sucesso')
      queryClient.invalidateQueries({ queryKey: ['ListScalesByClient'] })
      onComplete()
    },
    onError: error => {
      toast.error(error.message || 'Algo deu errado')
    }
  })
}

export const useDeleteScale = (id: string, onComplete: VoidFunction) => {
  const { toast } = useToastContext()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: () => ScaleService.delete(id),
    onSuccess: () => {
      toast.success('Escala excluída com sucesso')
      queryClient.invalidateQueries({ queryKey: ['listAgendas'] })
      onComplete()
    },
    onError: error => {
      toast.error(error.message || 'Algo deu errado')
    }
  })

  return mutation
}
