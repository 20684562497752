import React from 'react'
import { TToast } from './type'
import { styleVariant, styleClasses } from './utils'
import { AnimatePresence, motion } from 'framer-motion'
import { CheckCircle, Info, WarningCircle, XCircle } from '@phosphor-icons/react'

const IconComponentMap = {
  brand: Info,
  positive: CheckCircle,
  warning: WarningCircle,
  negative: XCircle
}

export const Toast: React.FC<TToast> = ({
  content,
  open,
  icon,
  comeFrom = 'bottom-center',
  variant = 'positive',
  className = '',
  handleClose
}) => {
  const from = comeFrom.split('-')[0] as 'top' | 'bottom'

  const Icon = IconComponentMap[variant]

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={styleClasses[from].initial}
          animate={styleClasses[from].animate}
          exit={styleClasses[from].exit}
          className={'fixed z-50 ml-8 w-[361px] md:ml-0'}
          style={{
            left: 'calc(50vw - 175px)',
            bottom: '30px'
          }}
        >
          <div
            role='alert'
            className={`flex w-full max-w-full ${styleVariant[variant].style} place-content-between rounded-lg p-4 ${className}`}
          >
            <div className='mr-4 flex items-center gap-2'>
              {icon ? icon : <Icon size='24px' color={variant !== 'warning' ? 'white' : 'black'} />}
              {content}
            </div>
            <button
              onClick={handleClose}
              aria-label='Fechar toast'
              className={`${open ? 'block' : 'hidden'}`}
            >
              Fechar
            </button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
