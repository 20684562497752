/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { CARE_TYPE, Client, Specialty, Unit } from '../../../types'
import { ArrowLeft, CaretRight, Chats, Webcam, X } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'
import Checkbox from '../../../components/checkbox'
import Tag from '../../../components/tag'
import { removeDuplicates } from '../../../helpers/removeDuplicates'
import SpecialtiesFilterModal from './specialties-filter-modal'
import ClientUnitsFilterModal, { ClientItem } from './client-units-filter-modal'
import { useListClients } from '../../../hooks/useClient'

export type FilterType = 'care_type' | 'specialty' | 'unit' | 'client'

type Props = {
  isOpen: boolean
  toggleDropdown: () => void
  handleUnitsFilter: (
    units: { id: string; name: string }[],
    updatedSelectedClientsUnits: ClientItem[]
  ) => Promise<void>
  handleSpecialtiesFilter: (specialties: Specialty[]) => Promise<void>
  handleCareTypesFilter: (care_types: CARE_TYPE[]) => Promise<void>
  selectedCareTypes: CARE_TYPE[]
  selectedSpecialties: Specialty[]
  selectedClientsUnits: ClientItem[]
  setSelectedClientsUnits: (clientsUnits: ClientItem[]) => void
}

const Filters: React.FC<Props> = ({
  isOpen,
  toggleDropdown,
  handleUnitsFilter,
  handleSpecialtiesFilter,
  handleCareTypesFilter,
  selectedCareTypes,
  selectedSpecialties,
  selectedClientsUnits,
  setSelectedClientsUnits
}) => {
  const { t } = useTranslation('consultation_follow_up')
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [filter, setFilter] = useState<FilterType | null>(null)
  const [isModalSpecialtyOpen, setIsModalSpecialtyOpen] = useState(false)
  const [isClientUnitsModalOpen, setIsClientUnitsModalOpen] = useState(false)

  const [filterClients, setFilterClients] = useState('')

  const { data: clientsData } = useListClients({
    page: 0,
    filter: filterClients,
    pageSize: 100
  })

  const clients = useMemo(() => {
    return (
      clientsData?.data.map(client => ({
        client_id: client.id,
        client: client,
        units: []
      })) || []
    )
  }, [clientsData])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        toggleDropdown()
        setFilter(null)
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, toggleDropdown])

  const filterOptions = [
    {
      label: 'Tipo de atendimento',
      handler: () => setFilter('care_type')
    },
    {
      label: 'Especialidade',
      handler: () => {
        setFilter('specialty')
        setIsModalSpecialtyOpen(true)
      }
    },
    {
      label: 'Cliente e Unidade',
      handler: () => {
        setFilter('client')
        setIsClientUnitsModalOpen(true)
      }
    }
  ]

  const handleSuccessSpecialties = (selectedValues: Specialty[]) => {
    const uniqueSelectedValues = removeDuplicates(selectedValues, 'id')
    handleSpecialtiesFilter(uniqueSelectedValues)
    toggleDropdown()
    setFilter(null)
  }

  const handleClientUnitsSelect = (updatedSelectedClientsUnits: ClientItem[]) => {
    const units = updatedSelectedClientsUnits
      .flatMap(clientItem => clientItem.units?.map(unitItem => unitItem.unit) || [])
      .filter((unit): unit is Unit => Boolean(unit) && unit?.id !== undefined)
      .map(unit => ({ id: unit.id!, name: unit.name! }))

    handleUnitsFilter(units, updatedSelectedClientsUnits).catch(error => {
      console.error('Erro ao filtrar unidades:', error)
    })

    setSelectedClientsUnits(updatedSelectedClientsUnits)

    toggleDropdown()
    setFilter('unit')
  }

  const careTypeOptions = () => (
    <div className='flex flex-col gap-1'>
      {Object.values(CARE_TYPE).map(type => {
        const isChecked = selectedCareTypes.includes(type)

        const handleCheckboxClick = (checked: boolean) => {
          let updatedCareTypes: CARE_TYPE[]
          if (checked) {
            updatedCareTypes = [...selectedCareTypes, type]
          } else {
            updatedCareTypes = selectedCareTypes.filter(t => t !== type)
          }
          handleCareTypesFilter(updatedCareTypes)
        }

        return (
          <div
            key={type}
            className='flex cursor-pointer items-center justify-between px-2 py-2 hover:rounded-xl hover:bg-highlightBlue-light'
            onClick={() => handleCheckboxClick(!isChecked)}
          >
            <div className='flex gap-2'>
              <Tag
                label={type === CARE_TYPE.CHAT ? <Chats size={12} /> : <Webcam size={12} />}
                variant='secondary'
                color={type === CARE_TYPE.ELECTIVE_CARE ? 'blue' : 'red'}
              />
              <span className='text-body-md text-neutralContent-primary'>{t(`types.${type}`)}</span>
            </div>
            <Checkbox isChecked={isChecked} onClick={handleCheckboxClick} />
          </div>
        )
      })}
    </div>
  )

  const listFilterOptions = () => (
    <div className='flex flex-col gap-1'>
      {filterOptions.map((option, index) => (
        <div
          key={index}
          className='flex cursor-pointer items-center justify-between px-2 py-2 hover:rounded-xl hover:bg-highlightBlue-light'
          onClick={option.handler}
        >
          <span className='text-body-md text-neutralContent-primary'>{option.label}</span>
          <CaretRight />
        </div>
      ))}
    </div>
  )

  const title = () => {
    switch (filter) {
      case 'care_type':
        return 'Tipo de atendimento'
      case 'specialty':
        return 'Especialidades'
      case 'unit':
        return 'Clientes e Unidades'
      default:
        return 'Adicionar Filtros'
    }
  }

  return (
    <div className='relative z-50 inline-block text-left' ref={dropdownRef}>
      {isOpen && (
        <div className='absolute right-0 mt-10 w-80 origin-top-right rounded-xl bg-white px-2 pb-2 shadow-lg ring-1 ring-black ring-opacity-5'>
          <div className='py-1'>
            <div className='flex justify-between border-b py-2'>
              <div className='flex items-center gap-2'>
                {!!filter && <ArrowLeft onClick={() => setFilter(null)} />}
                <span className='text-label-md'>{title()}</span>
              </div>
              <X
                onClick={() => {
                  toggleDropdown()
                  setFilter(null)
                }}
              />
            </div>
          </div>
          {!filter && listFilterOptions()}
          {filter === 'care_type' && careTypeOptions()}
        </div>
      )}

      <SpecialtiesFilterModal
        isOpen={isModalSpecialtyOpen}
        selectedValues={selectedSpecialties}
        onClose={() => {
          setIsModalSpecialtyOpen(false)
          setFilter(null)
        }}
        onSuccess={handleSuccessSpecialties}
        onBack={() => {
          setIsModalSpecialtyOpen(false)
          setFilter(null)
        }}
      />

      <ClientUnitsFilterModal
        isOpen={isClientUnitsModalOpen}
        onClose={() => {
          setIsClientUnitsModalOpen(false)
          setFilter(null)
        }}
        onSelect={(selectedItems: ClientItem[]) => {
          handleClientUnitsSelect(selectedItems)
        }}
        clients={clients}
        onBack={() => {
          setIsClientUnitsModalOpen(false)
          setFilter(null)
        }}
        selectedClientsUnits={selectedClientsUnits}
        setFilter={setFilterClients}
        filter={filterClients}
      />
    </div>
  )
}

export default Filters
