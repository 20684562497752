import { validate } from 'uuid'
import { PaginationParams, PaginationResponse, Profile } from '../types'
import api from './api.service'

class ProfileService {
  static create({ name, permissions }: Partial<Profile>) {
    return api.post<Profile>('/profile', {
      name,
      permissions
    })
  }

  static async list(params: PaginationParams) {
    const response = await api.get<PaginationResponse<Profile>>('/profile', {
      params
    })

    return response.data
  }

  static update(id: string, data: Partial<Profile>) {
    return api.put<void>('/profile/' + id, data)
  }

  static delete(id: string) {
    return api.delete<void>('/profile/' + id)
  }

  static async getById(id: string) {
    if (!validate(id)) return undefined

    const response = await api.get<Profile>('/profile/' + id)
    return response.data
  }
}

export default ProfileService
