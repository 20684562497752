import React, { useContext, useState } from 'react'
import { TToast } from '../../components/toast/type'
import { Toast } from '../../components/toast'

export type TToastVariants = {
  error: (content: string, additionalProps?: Partial<TToast>) => void
  success: (content: string, additionalProps?: Partial<TToast>) => void
  custom: (content: string, additionalProps?: Partial<TToast>) => void
}

export type TToastContext = {
  toast: TToastVariants
}

export const ToastContext = React.createContext({} as TToastContext)

interface Props {
  children: React.ReactNode
}

const TIME_TO_DISSAPPEAR = 5000

export const ToastContextProvider = ({ children }: Props) => {
  const [showToast, setShowToast] = useState<Partial<TToast> | undefined>()
  const [isOpen, setIsOpen] = useState(false)
  const [timeout, settimeout] = useState<NodeJS.Timeout>()

  const handleShowToast = (additionalProps?: Partial<TToast>) => {
    setShowToast({
      comeFrom: 'top-center',
      ...additionalProps
    })

    setIsOpen(true)

    const time = setTimeout(() => {
      setIsOpen(false)
      setShowToast({
        comeFrom: 'top-center'
      })
    }, additionalProps?.timeToDisappear || TIME_TO_DISSAPPEAR)
    settimeout(time)
  }

  const showCustomToast = (content: string, additionalProps?: Partial<TToast>) => {
    handleShowToast({
      content,
      ...additionalProps
    })
  }

  const showErrorToast = (content: string, additionalProps?: Partial<TToast>) => {
    handleShowToast({
      content,
      variant: 'negative',
      ...additionalProps
    })
  }

  const showSuccessToast = (content: string, additionalProps?: Partial<TToast>) => {
    handleShowToast({
      content,
      variant: 'positive',
      ...additionalProps
    })
  }

  const handleClose = () => {
    clearTimeout(timeout)
    setIsOpen(false)
  }

  return (
    <ToastContext.Provider
      value={{
        toast: {
          error: showErrorToast,
          success: showSuccessToast,
          custom: showCustomToast
        }
      }}
    >
      <>
        {children}
        <Toast
          icon={showToast?.icon}
          content={showToast?.content}
          open={isOpen}
          handleClose={handleClose}
          {...showToast}
        />
      </>
    </ToastContext.Provider>
  )
}

export const useToastContext = () => {
  const context = useContext(ToastContext)

  if (context) return context

  throw new Error('useToastContext must be used within a ToastContextProvider.')
}
