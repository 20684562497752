const agenda_ptBR = {
  title: 'Agenda',
  search_placeholder: 'Procure por nome ou CPF',
  columns: {
    professional: 'Profissional',
    cpf: 'CPF',
    type_of_agenda: 'Tipo de Agenda',
    types: {
      fixed: 'Fixa',
      custom: 'Personalizada'
    },
    details: 'Ver agenda'
  },
  actions: {
    new: 'Criar agenda'
  }
}

export default agenda_ptBR
