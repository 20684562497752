import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../../../../../../components/modal'
import { Laptop, QrCode } from '@phosphor-icons/react'
import { ImagePreviewModal } from './ImagePreviewModal'
import { Loading } from 'semente-js'

export const ChooseDocumentType: React.FC<{
  isOpen: boolean
  closeModal: () => void
  handleQRCodeButton: () => Promise<void>
  consultationId: string
}> = ({ isOpen, closeModal, handleQRCodeButton, consultationId }) => {
  const { t } = useTranslation('consultation_details')

  const [isLoading, setIsLoading] = useState(false)
  const [fileImages, setFileImages] = useState<File[]>([])
  const [isOpenPreviewImageModal, setIsOpenPreviewImageModal] = useState<boolean>(false)

  const handleCancelDelete = () => {
    closeModal()
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0 && consultationId) {
      const filesArray = Array.from(event.target.files)
      setFileImages(filesArray)
      setIsOpenPreviewImageModal(true)
    }
  }

  const handleCustomButtonClick = () => {
    if (!isLoading) document.getElementById('file-input')?.click()
  }

  const onClickQRCode = async () => {
    setIsLoading(true)
    await handleQRCodeButton()
    setIsLoading(false)
  }

  return (
    <Modal title={t('interactions.modal.title')} isOpen={isOpen} onClose={handleCancelDelete}>
      <div className='flex items-stretch gap-6'>
        <div
          onClick={handleCustomButtonClick}
          className='column flex cursor-pointer flex-col items-center justify-center rounded-xl border-2 border-primaryBrand-light bg-primaryBrand-light p-[53px] hover:border-primaryBrand-primary'
        >
          <input
            multiple
            type='file'
            id='file-input'
            accept='image/*,video/*'
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />

          <Laptop className='size-12 text-primaryBrand-primary' />

          <span className=' text-label-lg text-primaryBrand-primary'>
            {t('interactions.modal.from_my_computer')}
          </span>
        </div>

        <div
          className='column flex cursor-pointer flex-col items-center justify-center rounded-xl border-2 border-primaryBrand-light bg-primaryBrand-light p-[53px] hover:border-primaryBrand-primary'
          onClick={onClickQRCode}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <QrCode className='size-12 text-primaryBrand-primary ' />
              <span className='text-label-lg text-primaryBrand-primary'>
                {t('interactions.modal.qr_code')}
              </span>
              <span className='text-label-lg text-primaryBrand-primary'>
                {t('interactions.modal.only_image')}
              </span>
            </>
          )}
        </div>
      </div>

      <ImagePreviewModal
        isOpen={isOpenPreviewImageModal}
        closeModal={() => setIsOpenPreviewImageModal(false)}
        consultation_id={consultationId}
        files={fileImages!}
      />
    </Modal>
  )
}
