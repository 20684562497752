import React, { useEffect, useRef } from 'react'
import Button from '../../../../components/button'
import { useTranslation } from 'react-i18next'
import { useToastContext } from '../../../../contexts/toast'

interface Props {
  onStart: () => void
}

const CameraPreview: React.FC<Props> = ({ onStart }) => {
  const { t } = useTranslation('consultation_details')
  const { toast } = useToastContext()

  const videoRef = useRef<HTMLVideoElement | null>(null)
  const mediaStreamRef = useRef<MediaStream | null>(null)

  useEffect(() => {
    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        mediaStreamRef.current = stream
        if (videoRef.current) {
          videoRef.current.srcObject = stream
        }
      } catch (err) {
        console.error('Error accessing camera and microphone:', err)
        toast.error(
          'Erro ao acessar a câmera e o microfone. Verifique as permissões do dispositivo.'
        )
      }
    }

    startCamera()

    return () => {
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach(track => track.stop())
        mediaStreamRef.current = null
      }
    }
  }, [toast])

  return (
    <div className='absolute bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center bg-white'>
      <div className='overflow-hidden rounded-lg'>
        <video
          muted
          autoPlay
          playsInline
          ref={videoRef}
          className='max-h-[calc(100vh-300px)] w-full '
        />
      </div>
      <Button
        label={t('actions.entry_call')}
        size='md'
        width={320}
        variant='primary'
        onClick={onStart}
        className='mx-auto mt-4'
      />
    </div>
  )
}

export default CameraPreview
