import React from 'react'
import {
  PencilSimple,
  File,
  Phone,
  MapPin,
  Buildings,
  Envelope,
  Hospital,
  CaretRight,
  IdentificationCard
} from '@phosphor-icons/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetClient, useGetCount } from '../../../hooks/useClient'
import Content from '../../../components/content'
import { ROUTES } from '../../../routes/path'
import Button from '../../../components/button'
import Avatar from '../../../components/avatar'
import BlankCard from '../../../components/blank-card'
import Item from '../../../components/item'
import Tag from '../../../components/tag'
import { formatDate } from '../../../helpers/formatDate'
import Accordion from '../../../components/accordion'
import ClientDetailsSkeleton from './detail-skeleton'
import ProtectedComponent from '../../../components/protected'
import PermissionEnum from '../../../types/permissions'
import { useAuth } from '../../../contexts/auth'
import { CLIENT_FORM_STEPS } from '../client-form'

const ClientDetailsPage: React.FC = () => {
  const navigate = useNavigate()
  const { hasAccess } = useAuth()

  const { id } = useParams<{ id: string }>()

  const { data: client, isFetching } = useGetClient(id!)
  const { data: count, isFetching: isFetchingCount } = useGetCount(id!)

  const handleEdit = (step: string) => {
    navigate(`${ROUTES.client.form.replace(':id', id || '')}?step=${step}`)
  }

  const handleBack = () => {
    navigate(ROUTES.client.list)
  }

  const handleRedirectUnits = () => {
    if (hasAccess(PermissionEnum.list_units))
      navigate(ROUTES.client.unit.list.replace(':client_id', id || ''))
  }

  return (
    <>
      {isFetching ? (
        <ClientDetailsSkeleton />
      ) : (
        <Content
          title={client?.name || ''}
          leftContent={<Avatar size='lg' imageID={client?.image_id} />}
          onBack={handleBack}
        >
          <div className='mx-[-24px] max-h-[calc(100vh-190px)] overflow-auto px-6'>
            <div className='mt-1 flex w-full flex-col'>
              <div className='mb-4 flex items-center justify-between'>
                <h1 className='text-heading-sm text-neutralContent-primary'>Dados do cadastro</h1>
                <ProtectedComponent permission={PermissionEnum.register_client}>
                  <Button
                    variant='secondary'
                    size='sm'
                    label='Editar'
                    onClick={() => handleEdit(CLIENT_FORM_STEPS.GENERAL_INFORMATION)}
                    iconLeft={<PencilSimple size={18} />}
                  />
                </ProtectedComponent>
              </div>
              <BlankCard className='grid w-full grid-cols-1 gap-4 md:grid-cols-2'>
                <Item
                  leftContent={<File size={24} className='mr-3' />}
                  title='Vigência de contrato'
                  subtitle={
                    client?.contract_start && client?.contract_end
                      ? `${formatDate(client.contract_start)} até ${formatDate(client.contract_end)}`
                      : 'sem data de vigência'
                  }
                />
                <Item
                  leftContent={<Phone size={24} className='mr-3' />}
                  title='Telefone'
                  subtitle={client?.phone}
                />
                <Item
                  leftContent={<MapPin size={24} className='mr-3' />}
                  title='Endereço'
                  subtitle={`${client?.address}, ${client?.address_number} - ${client?.district}`}
                />
                <Item
                  leftContent={<Buildings size={24} className='mr-3' />}
                  title={client?.cnpj ? 'CNPJ' : 'CPF'}
                  subtitle={client?.cnpj ? client?.cnpj : client?.cpf}
                />
                <Item
                  leftContent={<Envelope size={24} className='mr-3' />}
                  title='Email'
                  subtitle={client?.email}
                />
              </BlankCard>
            </div>

            <div className='my-10 flex w-full flex-col'>
              <div className='mb-4 flex items-center justify-between'>
                <h1 className='text-heading-sm text-neutralContent-primary'>
                  Personalização e Permissões
                </h1>
                <ProtectedComponent permission={PermissionEnum.register_client}>
                  <Button
                    variant='secondary'
                    size='sm'
                    label='Editar'
                    iconLeft={<PencilSimple size={18} />}
                    onClick={() => handleEdit('PROFILE')}
                  />
                </ProtectedComponent>
              </div>

              <ProtectedComponent permission={PermissionEnum.list_units}>
                <BlankCard className='my-2 w-full'>
                  <Item
                    title='Gerenciar unidades'
                    onClick={handleRedirectUnits}
                    className='w-full cursor-pointer justify-between'
                    leftContent={<Hospital size={24} className='mr-3' />}
                    subtitle='Crie, edite ou exclua as unidades associadas a esse cliente'
                    rightContent={
                      <div className='flex items-center'>
                        <Tag
                          label={isFetchingCount ? '.../...' : `${count?.total}/${count?.limit}`}
                          variant='secondary'
                          size='sm'
                          color='neutral'
                        />
                        <CaretRight size={24} />
                      </div>
                    }
                  />
                </BlankCard>
              </ProtectedComponent>

              <Accordion
                className='my-2 w-full'
                title={
                  <div className='flex items-center'>
                    <IdentificationCard size={24} className='mr-3' />
                    <label className='text-label-md text-neutralContent-primary'>
                      Especialidade
                    </label>
                  </div>
                }
              >
                {client?.specialties?.map(item => (
                  <div key={item.id} className='grid w-full grid-cols-1 gap-2 md:grid-cols-2'>
                    <Item className='py-2' title={item.specialty?.name ?? ''} />
                  </div>
                ))}
              </Accordion>
            </div>

            <div className='pb-[200px]' />
          </div>
        </Content>
      )}
    </>
  )
}

export default ClientDetailsPage
