import { io, Socket } from 'socket.io-client'
import StorageService from './storage.service'

export type ReceiveFile = { fileName: string; file: ArrayBuffer; upload_file_id: string }

class FileUploadServerSocket {
  socket?: Socket

  constructor(consultation_id: string) {
    const token = StorageService.getToken()

    this.socket = io(process.env.REACT_APP_API_URL!, {
      auth: {
        token: token
      }
    })

    this.socket?.emit('joinUploadFiles', consultation_id)
  }
}

export default FileUploadServerSocket
