import { Plus } from '@phosphor-icons/react/dist/ssr'
import Content from '../../components/content'
import ProtectedComponent from '../../components/protected'
import PermissionEnum from '../../types/permissions'
import { ROUTES } from '../../routes/path'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/button'
import { useEffect, useState } from 'react'
import { AnamnesisTemplate, PaginationParams } from '../../types'
import { useTranslation } from 'react-i18next'
import InputSkeleton from '../../components/input/skeleton'
import SearchField from '../../components/search'
import Table from '../../components/table'
import { DotsThree, PencilSimple, Trash } from '@phosphor-icons/react'
import Tag from '../../components/tag'
import FloatingDropdown from '../../components/dropdown/floating-dropdown'
import Modal from '../../components/modal'
import AnamnesisTemplateService from '../../services/anamnesis-template.service'
import { useToastContext } from '../../contexts/toast'
import { useListAnamnesisTemplate } from '../../hooks/useAnamnesis'
const pageSize = 10

const AnamnesisTemplatePage = () => {
  const { t } = useTranslation('anamnesis_list')

  const { toast } = useToastContext()
  const navigate = useNavigate()
  const [params, setParams] = useState<PaginationParams>({ filter: '', page: 0, pageSize })
  const [totalPages, setTotalPages] = useState(0)
  const [dropdownOpen, setDropdownOpen] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [templateToDelete, setTemplateToDelete] = useState<AnamnesisTemplate>()

  const { data, isFetching, refetch } = useListAnamnesisTemplate(params)

  useEffect(() => {
    if (data) {
      setTotalPages(Math.ceil(data.totalItems / pageSize))
    }
  }, [data])

  const handlePage = (newPage: number) => {
    setParams(oldState => ({ ...oldState, page: newPage }))
  }

  const handleNavigateFormTemplate = (id?: string) => {
    navigate(ROUTES.anamnesis_template.form.replace(':id', id || 'new'))
  }

  const handleFilter = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setParams({ filter: value, page: 0, pageSize })
  }

  const handleDeleteTemplate = async () => {
    setIsLoading(true)
    try {
      if (templateToDelete) await AnamnesisTemplateService.delete(templateToDelete.id!)
      toast.success('Template de anamnese deletado com sucesso.')
      setTemplateToDelete(undefined)
      refetch()
    } catch (err) {
      toast.error((err as Error).message || 'Algo deu errado.')
    }
    setIsLoading(false)
  }

  const columns = [
    { header: t('columns.specialty'), accessor: 'name' },
    {
      header: t('columns.client'),
      accessor: 'client',
      className: 'sm:max-w-[200px] flex justify-center '
    },
    {
      header: t('columns.unit'),
      accessor: 'unit',
      className: 'sm:max-w-[200px] flex justify-center '
    },
    {
      header: t('columns.status'),
      accessor: 'status',
      className: 'sm:max-w-[200px] flex justify-center '
    },
    { header: '', accessor: 'actions', className: 'sm:max-w-[120px] overflow-visible' }
  ]

  const tableData =
    data?.data?.map((template: AnamnesisTemplate) => ({
      name: template.specialty?.name,
      client: template.client?.name,
      unit: template.unit?.name,
      status: (
        <div>
          <Tag label={t('fields.published')} variant='secondary' size='sm' color={'green'} />
        </div>
      ),

      actions: (
        <>
          <Button
            size='xs'
            variant='secondary'
            iconLeft={<DotsThree size={16} />}
            onClick={() => setDropdownOpen(template.id)}
          />
          <FloatingDropdown
            isOpen={dropdownOpen === template.id}
            toggleDropdown={() => setDropdownOpen(undefined)}
            actions={[
              {
                label: 'Editar',
                icon: <PencilSimple size={20} />,
                className: 'text-neutralContent-primary',
                onClick: () => handleNavigateFormTemplate(template.id!)
              },
              {
                label: 'Excluir',
                icon: <Trash size={20} />,
                className: 'text-highlightRed-pure',
                onClick: () => setTemplateToDelete(template)
              }
            ]}
          />
        </>
      )
    })) ?? []

  return (
    <Content
      title={t('title')}
      rightContent={
        <ProtectedComponent permission={PermissionEnum.report_patient}>
          <Button
            size='sm'
            label={t('actions.new')}
            iconLeft={<Plus size={12} />}
            onClick={() => handleNavigateFormTemplate()}
          />
        </ProtectedComponent>
      }
    >
      <div className={`flex h-[calc(100dvh-250px)] flex-col`}>
        <div className='pb-4 sm:max-w-64 '>
          {false ? (
            <InputSkeleton isRounded />
          ) : (
            <div className='w-[300px]'>
              <SearchField
                maxLength={100}
                value={params.filter || ''}
                onChange={handleFilter}
                placeholder={t('fields.search')}
              />
            </div>
          )}
        </div>
        <Table
          data={tableData}
          columns={columns}
          isLoading={isFetching}
          totalPages={totalPages}
          currentPage={params.page}
          onPageChange={handlePage}
        />
      </div>
      <Modal
        isOpen={!!templateToDelete}
        onClose={() => setTemplateToDelete(undefined)}
        title='Deletar template de anamnese'
      >
        <div className='mb-5 mr-4'>
          Você tem certeza que quer deletar esse template de anamnese?
        </div>
        <div className='flex w-full justify-end gap-2'>
          <Button
            label='Cancelar'
            variant='secondary'
            isLoading={isLoading}
            onClick={() => setTemplateToDelete(undefined)}
          />
          <Button
            label='Deletar'
            state='error'
            isLoading={isLoading}
            onClick={() => handleDeleteTemplate()}
          />
        </div>
      </Modal>
    </Content>
  )
}

export default AnamnesisTemplatePage
