/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next'
import { useGetClinicalInformation } from '../../../../hooks/usePatient'
import { Patient } from '../../../../types'
import BlankCard from '../../../../components/blank-card'

const ClinicalInformation: React.FC<{
  id: string
}> = ({ id }) => {
  const { data } = useGetClinicalInformation(id as string)
  const { t } = useTranslation('patient_detail')
  return (
    <div className='mt-4'>
      <div className='mb-8 flex flex-col flex-wrap gap-3'>
        <span className='text-heading-xs'>{t('medications_in_use')}</span>
        {!!data?.medicines && data?.medicines.length > 0 ? (
          data?.medicines?.map(medicine => (
            <BlankCard
              key={medicine.id?.toString()}
              className='flex h-[60px] w-[308px] flex-col gap-2'
            >
              <span className='text-label-sm'>
                {medicine.name} {medicine.dosage}
              </span>
              <span className='text-label-sm text-neutralContent-secondary'>
                {medicine.dosage_instructions}
              </span>
            </BlankCard>
          ))
        ) : (
          <>{t('no_info')}</>
        )}
      </div>

      <div className='mb-8 flex flex-col flex-wrap gap-3'>
        <span className='text-heading-xs'>{t('allergies')}</span>
        {!!data?.allergies && data?.allergies.length > 0 ? (
          data?.allergies?.map(allergy => (
            <BlankCard
              key={allergy.id?.toString()}
              className='flex h-[60px] w-[308px] flex-col gap-2'
            >
              <span className='text-label-sm'>{allergy.value}</span>
            </BlankCard>
          ))
        ) : (
          <>{t('no_info')}</>
        )}
      </div>

      <div className='mb-8 flex flex-col flex-wrap gap-3'>
        <span className='text-heading-xs'>{t('cronic_diseases')}</span>
        {!!data?.chronic_diseases && data?.chronic_diseases.length > 0 ? (
          data?.chronic_diseases?.map(chronic => (
            <BlankCard
              key={chronic.id?.toString()}
              className='flex h-[60px] w-[308px] flex-col gap-2'
            >
              <span className='text-label-sm'>{chronic.value}</span>
            </BlankCard>
          ))
        ) : (
          <>{t('no_info')}</>
        )}
      </div>

      <div className='flex flex-col flex-wrap gap-3'>
        <span className='text-heading-xs'>{t('surgical_history')}</span>
        {!!data?.surgical_history && data?.surgical_history.length > 0 ? (
          data?.surgical_history?.map(surgical => (
            <BlankCard
              key={surgical.id?.toString()}
              className='flex h-[60px] w-[308px] flex-col gap-2'
            >
              <span className='text-label-sm'>{surgical.value}</span>
            </BlankCard>
          ))
        ) : (
          <>{t('no_info')}</>
        )}
      </div>
    </div>
  )
}

export default ClinicalInformation
