/* eslint-disable @typescript-eslint/no-unused-vars */
import { Document, PaginationParams, PaginationResponse, Patient } from '../types'
import api from './api.service'

class PatientService {
  static async list(params: PaginationParams) {
    const response = await api.get<PaginationResponse<Patient>>('/patient', {
      params
    })
    return response.data
  }

  static async getByDocument(document: string): Promise<Patient | null> {
    if (!document) {
      throw new Error('Documento inválido')
    }
    try {
      const response = await api.get<Patient>('/patient/' + document)
      return response.data || null
    } catch (error) {
      return null
    }
  }

  static async getConsultation(document: string): Promise<Patient | null> {
    if (!document) {
      throw new Error('Documento inválido')
    }
    try {
      const response = await api.get<Patient>('/patient/' + document + '/consultations')
      return response.data || null
    } catch (error) {
      return null
    }
  }

  static async getClinicalInformation(document: string): Promise<Patient | null> {
    if (!document) {
      throw new Error('Documento inválido')
    }
    try {
      const response = await api.get<Patient>('/patient/' + document + '/clinical-information')
      return response.data || null
    } catch (error) {
      return null
    }
  }

  static update(data: Partial<Patient>) {
    return api.put<Patient>('/patient', data)
  }

  static create(data: Partial<Patient>) {
    return api.post<Patient>('/patient', data)
  }

  static validateParams(body: Partial<Patient>) {
    return api.post<Patient>('/patient/validate', body)
  }

  static async getDocuments(document: string): Promise<Document[] | null> {
    if (!document) {
      throw new Error('Documento inválido')
    }
    try {
      const response = await api.get<Document[]>('/patient/' + document + '/documents')
      return response.data || null
    } catch (error) {
      return null
    }
  }
}

export default PatientService
