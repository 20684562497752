/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'
import Accordion from '../../../../components/accordion'
import { useGetAnamnesisByConsultation } from '../../../../hooks/useAnamnesis'
import AnyQuestion from '../default/any-questions'
import { AnamnesisTemplate } from '../../../../types'

const AnamnesisQuestionsSpecialty: React.FC<{
  template: AnamnesisTemplate
  id: string
  specialtyValues: any
  setSpecialtyValues: (values: any) => void
}> = ({ template, id, specialtyValues, setSpecialtyValues }) => {
  const { data: answers, isFetching: isLoadingAnswers } = useGetAnamnesisByConsultation(id)

  useEffect(() => {
    if (answers?.specialty_answers && Object.keys(specialtyValues).length === 0) {
      setSpecialtyValues(answers.specialty_answers)
    }
  }, [answers?.specialty_answers, setSpecialtyValues, specialtyValues])

  return (
    <>
      {isLoadingAnswers ? (
        <span>Carregando</span>
      ) : (
        <>
          {template.questions?.map(question => (
            <div key={question.title} className='col-span-2 py-2'>
              <Accordion title={question.title}>
                <div
                  key={question.id}
                  className='border-b pb-10 pt-10 first:pt-0 last:border-b-0 last:pb-0'
                >
                  <span className='mb-6 block text-label-lg font-bold'>{question.title}</span>
                  <AnyQuestion
                    question={question}
                    values={specialtyValues}
                    handleValue={setSpecialtyValues}
                  />
                </div>
              </Accordion>
            </div>
          ))}
        </>
      )}
    </>
  )
}

export default AnamnesisQuestionsSpecialty
