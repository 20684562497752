import React, { useState } from 'react'
import Modal from '../../../../components/modal'
import BlankCard from '../../../../components/blank-card'
import { Client } from '../../../../types'
import Avatar from '../../../../components/avatar'
import MonthSelectCalendar from '../../../../components/custom-week-calendar/month-select-calendar'
import Tag from '../../../../components/tag'
import { useListScalesByClient } from '../../../../hooks/useScale'
import {
  eachDayOfInterval,
  endOfMonth,
  format,
  isSameMonth,
  startOfWeek,
  endOfWeek,
  addMonths,
  subMonths,
  startOfMonth,
  parse,
  isSameDay
} from 'date-fns'
import { useTranslation } from 'react-i18next'
import { scaleNumberToTime } from '../../../../helpers/scaleTime'

interface Props {
  isOpen: boolean
  onClose: () => void
  clientData: Client
}

const daysOfWeek = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']

const FullScaleModal: React.FC<Props> = ({ isOpen, onClose, clientData }) => {
  const { t } = useTranslation('agendav2_list')

  const [currentMonth, setCurrentMonth] = useState(new Date())
  const startDate = startOfWeek(startOfMonth(currentMonth), { weekStartsOn: 0 })
  const endDate = endOfWeek(endOfMonth(currentMonth), { weekStartsOn: 0 })

  const { data: scales, isFetching } = useListScalesByClient({
    client_id: clientData.id,
    start_date: startDate.toISOString(),
    end_date: endDate.toISOString()
  })

  const days = eachDayOfInterval({ start: startDate, end: endDate })

  const goToPreviousMonth = () => setCurrentMonth(subMonths(currentMonth, 1))
  const goToNextMonth = () => setCurrentMonth(addMonths(currentMonth, 1))

  return (
    <Modal isOpen={isOpen} title='' onClose={onClose} contentClassName='m-0 p-0'>
      <div className='m-2 max-h-[90vh] overflow-hidden '>
        <div className='flex items-center justify-between px-4 pb-4'>
          <div className='flex items-center gap-2'>
            <BlankCard className='h-14'>
              <div className='mt-[-6px] flex items-center justify-center gap-3'>
                <Avatar name={clientData?.name} size='sm' />
                <span className='text-label-md text-neutralContent-primary'>
                  {clientData?.name || ''}
                </span>
              </div>
            </BlankCard>
            <MonthSelectCalendar
              date={currentMonth}
              onClickNextMonth={goToNextMonth}
              onClickPreviousMonth={goToPreviousMonth}
            />
          </div>
          <div className='inline-flex items-center gap-2'>
            <Tag label={t('has_specialist')} color='green' variant='secondary' size='sm' />
            <Tag label={t('specialist_pending')} color='yellow' variant='secondary' size='sm' />
          </div>
        </div>
        <div className='flex h-full max-h-[80vh] min-w-[80vw] flex-col gap-4 overflow-y-auto px-4 pb-4'>
          {/* Calendar */}
          <div className='mt-4 grid grid-cols-7 gap-2'>
            {daysOfWeek.map(day => (
              <div key={day} className='mb-[-10px] border p-3 text-center '>
                <span className='text-body-md text-neutralContent-tertiary'>{day}</span>
              </div>
            ))}

            {days.map(day => {
              const dayScales = scales?.filter(scale =>
                isSameDay(parse(scale.date, 'yyyy-MM-dd', new Date()), day)
              )
              const isCurrentMonth = isSameMonth(day, currentMonth)

              return (
                <div
                  key={day.toISOString()}
                  className={`h-48 rounded border p-2 ${
                    isCurrentMonth ? 'bg-white' : 'bg-gray-100 text-neutralContent-disabled'
                  }`}
                >
                  <div className='flex w-full justify-center'>
                    <span className='text-body-md text-neutralContent-primary'>
                      {format(day, 'd')}
                    </span>
                  </div>
                  <div className='mt-2 flex flex-col gap-1'>
                    {isFetching &&
                      isCurrentMonth &&
                      Array.from({ length: 2 }).map((_, index) => (
                        <div
                          key={index}
                          className='h-4 animate-pulse rounded bg-neutralBorder-default'
                        />
                      ))}
                    {!isFetching &&
                      dayScales?.map((scale, index) => (
                        <Tag
                          key={index}
                          label={`${scale.specialty?.name} - ${scale.time_intervals
                            .map(
                              interval =>
                                `${scaleNumberToTime(interval.start_time)} - ${scaleNumberToTime(interval.end_time)}`
                            )
                            .join(', ')}`}
                          color={scale.specialists.length > 0 ? 'green' : 'yellow'}
                          variant='secondary'
                          className='truncate'
                          size='xs'
                        />
                      ))}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default FullScaleModal
