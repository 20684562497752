import { MevoFile } from '../types'
import api from './api.service'

class MevoService {
  static async start(consultation_id: string) {
    const response = await api.post(`/mevo/start/${consultation_id}`)
    return response.data
  }
  static async getFiles(consultation_id: string) {
    const response = await api.get<MevoFile[]>(`/mevo/files/${consultation_id}`)
    return response.data
  }
}

export default MevoService
