import { forwardRef, useEffect } from 'react'
import InputField from '../../../../../components/input'
import { Props } from '../types'
import Checkbox from '../../../../../components/checkbox'
import { usePatientDetailFormik } from '../../../../../hooks/forms/usePatientForm'
import Dropdown from '../../../../../components/dropdown'
import { useTranslation } from 'react-i18next'
import Chip from '../../../../../components/chip'
import StateDropdown from '../../../../../components/dropdown/state-dropdown'
import CityDropdown from '../../../../../components/dropdown/city-dropdown'
import { SpinnerGap } from '@phosphor-icons/react'

const races = ['white', 'black', 'brown', 'yellow', 'indigenous', 'no_information']

const PatientDetail = forwardRef<HTMLButtonElement, Props>(
  ({ handleSubmit, handleStateSubmit, value }, ref) => {
    const formik = usePatientDetailFormik(handleSubmit, value)
    const onChangeNationality = (nationality: string) => {
      if (nationality === 'BR') {
        formik.setFieldValue('nationality', 'BR')
      } else {
        formik.setFieldValue('nationality', '')
        formik.setFieldValue('birth_state', '')
        formik.setFieldValue('birth_city', '')
      }
    }

    useEffect(() => {
      handleStateSubmit(!formik.isValid)
    }, [formik.isValid, handleStateSubmit])

    const { t } = useTranslation('patient_form')
    console.log(formik.values)

    return formik.isSubmitting ? (
      <div className='flex h-full w-full items-center justify-center'>
        <SpinnerGap size={20} className='animate-spin' />
      </div>
    ) : (
      <form onSubmit={formik.handleSubmit}>
        <div className='flex w-full flex-col gap-8'>
          <div className=''>
            <InputField
              type='text'
              id='mother_name'
              name='mother_name'
              placeholder={t('fields.mother_name')}
              onBlur={formik.handleBlur}
              label={t('fields.mother_name')}
              value={formik.values.mother_name!}
              onChange={formik.handleChange}
              state={
                formik.values.mother_unknown
                  ? 'disabled'
                  : formik.touched.mother_name && formik.errors.mother_name
                    ? 'error'
                    : 'default'
              }
              hint={
                formik.touched.mother_name && formik.errors.mother_name
                  ? formik.errors.mother_name
                  : undefined
              }
            />
            <div className='mt-2 flex items-center gap-2'>
              <Checkbox
                size='sm'
                isChecked={formik.values.mother_unknown!}
                onClick={isChecked => {
                  if (isChecked) formik.setFieldValue('mother_name', '')
                  formik.setFieldValue('mother_unknown', isChecked)
                }}
              />
              {t('fields.mother_unknown')}
            </div>
          </div>

          <div className='grid grid-cols-2 gap-6'>
            <Dropdown
              name='race'
              label={t('fields.race')}
              value={formik.values.race || ''}
              onBlur={formik.handleBlur}
              onChangeValue={option => formik.setFieldValue('race', option?.value)}
              options={races.map(value => ({ value, label: t(`fields.selects.races.${value}`) }))}
            />

            <div>
              <label className='label-md text-neutralContent-primary'>
                {t('fields.nationality')}
              </label>
              <div className='flex w-full gap-2'>
                <Chip
                  size='xl'
                  className='flex-1'
                  label={t('selects.nationality.BR')}
                  isSelected={formik.values.nationality === 'BR'}
                  onClick={() => onChangeNationality('BR')}
                />

                <Chip
                  size='xl'
                  className='flex-1'
                  label={t('selects.nationality.OTHER')}
                  isSelected={formik.values.nationality !== 'BR'}
                  onClick={() => onChangeNationality('')}
                />
              </div>
            </div>
          </div>

          {formik.values.nationality === 'BR' && (
            <div className='grid grid-cols-2 gap-6'>
              <StateDropdown
                label={t('fields.birth_state')}
                onBlur={formik.handleBlur}
                value={formik.values.birth_state || ''}
                hint={formik.submitCount > 0 ? formik.errors.birth_state : ''}
                error={formik.submitCount > 0 && !!formik.errors.birth_state}
                onChange={option => formik.setFieldValue('birth_state', option?.value)}
              />

              <CityDropdown
                label={t('fields.birth_city')}
                onBlur={formik.handleBlur}
                value={formik.values.birth_city || ''}
                uf={formik.values.birth_state!}
                error={formik.submitCount > 0 && !!formik.errors.birth_city}
                hint={formik.submitCount > 0 ? formik.errors.birth_city : ''}
                onChange={option => formik.setFieldValue('birth_city', option?.value)}
              />
            </div>
          )}

          <div className=''>
            <InputField
              type='text'
              id='guardian_name'
              name='guardian_name'
              onBlur={formik.handleBlur}
              label={t('fields.representative_legal_name')}
              placeholder={t('fields.representative_legal_name')}
              value={formik.values.guardian_name!}
              onChange={formik.handleChange}
              state={
                formik.values.representative_legal_unknown
                  ? 'disabled'
                  : formik.touched.guardian_name && formik.errors.guardian_name
                    ? 'error'
                    : 'default'
              }
              hint={
                formik.touched.guardian_name && formik.errors.guardian_name
                  ? formik.errors.guardian_name
                  : undefined
              }
            />
            <div className='mt-2 flex items-center gap-2'>
              <Checkbox
                size='sm'
                isChecked={formik.values.representative_legal_unknown!}
                onClick={isChecked => {
                  if (isChecked) {
                    formik.setFieldValue('guardian_name', '')
                    formik.setFieldValue('guardian_cpf', '')
                    formik.setFieldValue('guardian_state', '')
                  }
                  formik.setFieldValue('representative_legal_unknown', isChecked)
                }}
              />
              {t('fields.not_applicable')}
            </div>
          </div>

          <div className='grid grid-cols-2 gap-6'>
            <InputField
              type='text'
              id='guardian_state'
              name='guardian_state'
              onBlur={formik.handleBlur}
              label={t('fields.representative_legal_proximity.label', 'Grau de Parentesco')}
              placeholder={t('fields.representative_legal_proximity.placeholder', 'Selecione')}
              value={formik.values.guardian_state!}
              onChange={formik.handleChange}
              state={
                formik.values.representative_legal_unknown
                  ? 'disabled'
                  : formik.touched.guardian_state && formik.errors.guardian_state
                    ? 'error'
                    : 'default'
              }
              hint={
                formik.touched.guardian_state && formik.errors.guardian_state
                  ? formik.errors.guardian_state
                  : undefined
              }
            />

            <InputField
              type='cpf'
              id='guardian_cpf'
              name='guardian_cpf'
              onBlur={formik.handleBlur}
              label={t(
                'fields.representative_legal_document.label',
                'CPF do Guardião ou Representante Legal'
              )}
              placeholder={t(
                'fields.representative_legal_document.placeholder',
                'CPF do Guardião ou Representante Legal'
              )}
              value={formik.values.guardian_cpf!}
              onChange={formik.handleChange}
              state={
                formik.values.representative_legal_unknown
                  ? 'disabled'
                  : formik.touched.guardian_cpf && formik.errors.guardian_cpf
                    ? 'error'
                    : 'default'
              }
              hint={
                formik.touched.guardian_cpf && formik.errors.guardian_cpf
                  ? formik.errors.guardian_cpf
                  : undefined
              }
            />
          </div>
        </div>
        <button ref={ref} type='submit' className='hidden' />
      </form>
    )
  }
)

PatientDetail.displayName = 'PatientDetail'
export default PatientDetail
