import React, { ReactNode, useEffect, useState } from 'react'
import { convertArrayBufferToFile } from '../../helpers/convertArrayBufferToFile'
import ConsultationService from '../../services/consultation.service'
import { useToastContext } from '../../contexts/toast'
import { ImagePreviewModal } from '../consultation/consultation-details/tabs/Interactions/modals/ImagePreviewModal'
import Logo from '../../assets/images/logo.png'
import InputField from '../../components/input'
import Content from '../../components/content'
import { useFile } from '../../contexts/file'
import Button from '../../components/button'

enum UPLOAD_STATUS {
  WAITING_CODE = 'WAITING_CODE',
  INVALID_CODE = 'INVALID_CODE',
  EXPIRED_UPLOAD_FILE = 'EXPIRED_UPLOAD_FILE',
  WAITING_FILE = 'WAITING_FILE',
  FINISHED = 'FINISHED'
}

export const ExternalUploadFileByQRCode = () => {
  const [uploadStatus, setUploadStatus] = useState<UPLOAD_STATUS>(UPLOAD_STATUS.WAITING_CODE)
  const [code, setCode] = useState('')
  const [token, setToken] = useState<string>()
  const { selectedFiles, setSelectedFiles } = useFile()
  const [isOpenPreviewImageModal, setIsOpenPreviewImageModal] = useState<boolean>(false)
  const [isUploadedFiles, setIsUploadedFiles] = useState(false)

  const { toast } = useToastContext()

  const searchParams = new URLSearchParams(location.search)
  const upload_file_id = searchParams.get('upload_file_id')
  const consultation_id = searchParams.get('consultation_id')

  const validateCode = async () => {
    if (!!upload_file_id && code) {
      try {
        const token = await ConsultationService.validateConsultationUploadFileCode(
          upload_file_id!,
          Number(code)
        )
        setToken(token)
        setUploadStatus(UPLOAD_STATUS.WAITING_FILE)
      } catch (err) {
        toast.error(`${(err as Error).message}`)
        setUploadStatus((err as Error).message as UPLOAD_STATUS)
      }
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!token || !upload_file_id) return

    if (event.target.files && event.target.files.length > 0) {
      const filesArray = Array.from(event.target.files)
      const selectedFilesArray: File[] = []

      filesArray.forEach(file => {
        const reader = new FileReader()
        reader.onload = (e: ProgressEvent<FileReader>) => {
          const fileBuffer = e.target?.result as ArrayBuffer
          const convertedFile = convertArrayBufferToFile({
            fileName: file.name,
            file: fileBuffer,
            upload_file_id
          }) as File

          selectedFilesArray.push(convertedFile)

          if (selectedFilesArray.length === filesArray.length) {
            setSelectedFiles(selectedFilesArray)
            setIsOpenPreviewImageModal(true)
          }
        }
        reader.readAsArrayBuffer(file)
      })
    }
  }

  useEffect(() => {
    if (isUploadedFiles) {
      setToken('')
      setUploadStatus(UPLOAD_STATUS.FINISHED)
      setCode('')
    }
  }, [isUploadedFiles])

  const handleCustomButtonClick = () => {
    document.getElementById('file-input')?.click()
  }

  if (
    uploadStatus === UPLOAD_STATUS.WAITING_CODE ||
    uploadStatus === UPLOAD_STATUS.INVALID_CODE ||
    uploadStatus === UPLOAD_STATUS.EXPIRED_UPLOAD_FILE
  ) {
    return (
      <Container title='Informe o número exibido abaixo do QRCode para prosseguir' subtitle=''>
        <div className='flex flex-col gap-2'>
          <InputField
            onChange={e => setCode(e.target.value)}
            value={code}
            placeholder='Digite o código'
            hint={uploadStatus === UPLOAD_STATUS.INVALID_CODE ? 'Código ou Link inválido' : ''}
            state={uploadStatus === UPLOAD_STATUS.INVALID_CODE ? 'error' : 'default'}
          />
          <Button label='Validar código' type='button' onClick={validateCode} disabled={!code} />
        </div>
      </Container>
    )
  }

  return (
    <>
      <Container
        title={
          !isUploadedFiles
            ? 'Escolha imagens ou vídeos da sua galeria'
            : 'Imagens enviadas com sucesso!'
        }
        subtitle={
          !isUploadedFiles
            ? 'Você pode anexar até 15 arquivos (apenas imagens e vídeos)'
            : ' Arquivos foram enviados com sucesso, pode voltar para pagina principal'
        }
      >
        <div className='flex flex-col gap-2 '>
          <input
            id='file-input'
            type='file'
            accept='image/*,video/*'
            multiple
            onChange={handleFileChange}
            style={{ display: 'none' }}
            aria-hidden='true'
          />

          {!isUploadedFiles && (
            <Button onClick={handleCustomButtonClick} label='Escolher arquivos' width={'200'} />
          )}

          <ImagePreviewModal
            allowAddFiles
            token={token}
            files={selectedFiles!}
            upload_file_id={upload_file_id!}
            isUploadedFiles={isUploadedFiles}
            consultation_id={consultation_id!}
            setIsUploadedFiles={setIsUploadedFiles}
            isOpen={isOpenPreviewImageModal && !!selectedFiles?.length}
            closeModal={() => {
              setIsOpenPreviewImageModal(false)
            }}
            onFinish={() => {
              setUploadStatus(UPLOAD_STATUS.FINISHED)
            }}
          />
        </div>
      </Container>
    </>
  )
}

const Container: React.FC<{
  children: ReactNode
  title?: string
  subtitle?: string
  confirmedCode?: boolean
}> = ({ children, title, subtitle }) => (
  <>
    <div
      className={`z-50 flex h-16 items-center border-b border-neutralBorder-default bg-white px-9 py-4`}
    >
      <img src={Logo} alt='L2D' className='h-7' />
    </div>
    <Content title={title} subtitle={subtitle}>
      {children}
    </Content>
  </>
)
