import { useTranslation } from 'react-i18next'
import Modal from '../../../../../../components/modal'
import Button from '../../../../../../components/button'
import FileService from '../../../../../../services/file.service'
import { useToastContext } from '../../../../../../contexts/toast'
import { useEffect, useState } from 'react'
import { Trash } from '@phosphor-icons/react'

import { convertArrayBufferToFile } from '../../../../../../helpers/convertArrayBufferToFile'

export const ImagePreviewModal: React.FC<{
  isOpen: boolean
  closeModal: () => void
  consultation_id: string
  files: File[]
  onFinish?: VoidFunction
  token?: string
  isUploadedFiles?: boolean
  setIsUploadedFiles?: (value: boolean) => void
  allowAddFiles?: boolean
  upload_file_id?: string
}> = ({
  isOpen,
  closeModal,
  consultation_id,
  files,
  onFinish,
  token,
  setIsUploadedFiles,
  allowAddFiles = false,
  upload_file_id
}) => {
  const { t } = useTranslation('consultation_details')
  const { toast } = useToastContext()
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)
  const [selectedFileIndex, setSelectedFileIndex] = useState<number>(0)
  const [fileThumbnails, setFileThumbnails] = useState<string[]>([])
  const [currentFiles, setCurrentFiles] = useState<File[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setCurrentFiles(files || [])
  }, [files])

  useEffect(() => {
    if (currentFiles.length > 0) {
      const thumbnails = currentFiles.map(file => URL.createObjectURL(file))
      setFileThumbnails(thumbnails)
      setSelectedFileIndex(0)
      setPreviewUrl(thumbnails[0])
    } else {
      setFileThumbnails([])
      setPreviewUrl(null)
    }

    return () => {
      fileThumbnails.forEach(url => URL.revokeObjectURL(url))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFiles])

  const handleThumbnailClick = (index: number) => {
    setSelectedFileIndex(index)
    setPreviewUrl(fileThumbnails[index])
  }

  const handleRemoveFile = (index: number) => {
    const newFiles = currentFiles.filter((_, i) => i !== index)
    const newThumbnails = fileThumbnails.filter((_, i) => i !== index)

    if (newFiles.length > 0) {
      setCurrentFiles(newFiles)
      setFileThumbnails(newThumbnails)
      setSelectedFileIndex(0)
      setPreviewUrl(newThumbnails[0])
    } else {
      setCurrentFiles([])
      setFileThumbnails([])
      setPreviewUrl(null)
      closeModal()
    }
  }

  const handleAddFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!allowAddFiles) return
    if (!token || !upload_file_id) return

    if (event.target.files && event.target.files.length > 0) {
      const newFilesArray = Array.from(event.target.files)
      const selectedFilesArray: File[] = []

      newFilesArray.forEach(file => {
        const reader = new FileReader()
        reader.onload = (e: ProgressEvent<FileReader>) => {
          const fileBuffer = e.target?.result as ArrayBuffer

          const convertedFile = convertArrayBufferToFile({
            fileName: file.name,
            file: fileBuffer,
            upload_file_id
          }) as File

          selectedFilesArray.push(convertedFile)

          if (selectedFilesArray.length === newFilesArray.length) {
            const allFiles = currentFiles.concat(selectedFilesArray)
            setCurrentFiles(allFiles)
          }
        }
        reader.readAsArrayBuffer(file)
      })
    }
  }

  const sendFiles = async () => {
    if (currentFiles.length === 0) {
      toast.error('Nenhum arquivo para enviar.')
      return
    }

    setIsLoading(true)
    try {
      await FileService.uploadMultipleFiles(currentFiles, consultation_id, '', token)
      toast.success('Arquivos enviados com sucesso!')
      setIsUploadedFiles?.(true)
      closeModal()
      onFinish?.()
    } catch (error) {
      toast.error(`Erro ao fazer upload dos arquivos: ${error}`)
    } finally {
      setIsLoading(false)
      closeModal()
    }
  }

  return (
    <Modal title={t('interactions.modal.title')} isOpen={isOpen} onClose={closeModal}>
      <div className='flex w-full max-w-[500px] flex-col p-4 sm:w-[90vw] sm:max-w-[400px]'>
        {previewUrl && (
          <div className='mb-4 flex justify-center'>
            {currentFiles[selectedFileIndex].type.startsWith('video/') ? (
              <video src={previewUrl} controls className='max-h-[300px] w-full object-contain' />
            ) : (
              <img src={previewUrl} alt='Preview' className='max-h-[300px] w-full object-contain' />
            )}
          </div>
        )}

        <div className='mb-4 flex justify-center gap-2'>
          {currentFiles.map((file, index) => {
            const thumbnailUrl = fileThumbnails[index]
            const isVideo = file.type.startsWith('video/')

            return (
              <div key={index} className='relative'>
                {isVideo ? (
                  <video
                    src={thumbnailUrl}
                    className={`h-[50px] w-[50px] cursor-pointer object-cover ${
                      selectedFileIndex === index ? 'border-2 border-blue-500' : ''
                    }`}
                    onClick={() => handleThumbnailClick(index)}
                    muted
                  />
                ) : (
                  <img
                    src={thumbnailUrl}
                    alt={`Thumb ${index}`}
                    className={`h-[50px] w-[50px] cursor-pointer object-cover ${
                      selectedFileIndex === index ? 'border-2 border-blue-500' : ''
                    }`}
                    onClick={() => handleThumbnailClick(index)}
                  />
                )}
                <button
                  className='absolute right-0 top-0 rounded-full bg-red-500 p-1 text-white'
                  onClick={() => handleRemoveFile(index)}
                >
                  <Trash />
                </button>
              </div>
            )
          })}

          {allowAddFiles && (
            <>
              <div className='relative'>
                <div
                  className='flex h-[50px] w-[50px] cursor-pointer items-center justify-center border border-dashed border-gray-400'
                  onClick={() => document.getElementById('file-input-modal')?.click()}
                >
                  <span className='text-2xl text-neutralBorder-brand'>+</span>
                </div>
              </div>

              <input
                id='file-input-modal'
                type='file'
                accept='image/*,video/*'
                multiple
                onChange={handleAddFiles}
                style={{ display: 'none' }}
                aria-hidden='true'
              />
            </>
          )}
        </div>

        <div className='mt-6 flex justify-end'>
          <Button
            label={t('actions.cancel')}
            className={'text-neutral-900'}
            variant='tertiary'
            onClick={closeModal}
          />
          <Button isLoading={isLoading} label={t('actions.attach')} onClick={sendFiles} />
        </div>
      </div>
    </Modal>
  )
}
