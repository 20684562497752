import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ArrowRight, Check, DoorOpen } from '@phosphor-icons/react'
import Modal from '../../../../components/modal'
import Button from '../../../../components/button'
import { CONSULTATION_STATUS } from '../../../../types'
import { NPSModal } from '../NPSModal'

interface ConfirmationFinishCallModalProps {
  open: boolean
  onClose: () => void
  onFinish: () => void
}

interface ConfirmationStartCallModalProps {
  open: boolean
  onClose: () => void
  onContinue: () => void
  status?: CONSULTATION_STATUS
}

interface ConfirmationExitCallModalProps {
  open: boolean
  onClose: () => void
  onExit: () => void
}

export const ConfirmationFinishCallModal: React.FC<ConfirmationFinishCallModalProps> = ({
  onClose,
  onFinish,
  open
}) => {
  const { t } = useTranslation('consultation_details')
  const [openNpsModal, setOpenNpsModal] = useState(false)

  return (
    <Modal title={t('modal_confirmation_finish_call.title')} isOpen={open} onClose={onClose}>
      <div className='w-[380px] max-w-[90vw]'>
        <p className='text-body-md text-neutralContent-tertiary'>
          {t('modal_confirmation_finish_call.description')}
        </p>

        <div className='mt-4 flex justify-end'>
          <Button
            type='button'
            label={t('actions.cancel')}
            size='md'
            variant='tertiary'
            onClick={onClose}
          />
          <Button
            size='md'
            label={t('actions.finish')}
            variant='primary'
            state='error'
            onClick={() => {
              onFinish()
              setOpenNpsModal(true)
            }}
            iconLeft={<Check />}
          />
        </div>
      </div>

      <NPSModal
        open={openNpsModal}
        onClose={() => {
          setOpenNpsModal(false)
          onClose()
        }}
      />
    </Modal>
  )
}

export const ConfirmationStartCallModal: React.FC<ConfirmationStartCallModalProps> = ({
  open,
  status,
  onClose,
  onContinue
}) => {
  const { t } = useTranslation('consultation_details')

  return (
    <Modal title={t(`modal_confirmation.title_${status}`)} isOpen={open} onClose={onClose}>
      <div className='w-[380px] max-w-[90vw]'>
        <p className='text-body-md text-neutralContent-tertiary'>
          {t(`modal_confirmation.description_${status}`)}
        </p>

        <div className='mt-4 flex justify-end'>
          <Button
            type='button'
            label={t('actions.cancel')}
            size='md'
            variant='tertiary'
            onClick={onClose}
          />
          <Button
            size='md'
            label={t('actions.start')}
            variant='primary'
            onClick={() => {
              onContinue()
              onClose()
            }}
            iconRight={<ArrowRight />}
          />
        </div>
      </div>
    </Modal>
  )
}

export const ConfirmationExitCallModal: React.FC<ConfirmationExitCallModalProps> = ({
  onClose,
  onExit,
  open
}) => {
  const { t } = useTranslation('consultation_details')

  return (
    <Modal title={t('modal_confirmation_exit_call.title')} isOpen={open} onClose={onClose}>
      <div className='w-[380px] max-w-[90vw]'>
        <p className='text-body-md text-neutralContent-tertiary'>
          {t('modal_confirmation_exit_call.description')}
        </p>

        <div className='mt-4 flex justify-end'>
          <Button
            type='button'
            label={t('actions.cancel')}
            size='md'
            variant='tertiary'
            onClick={onClose}
          />
          <Button
            size='md'
            label={t('actions.exit')}
            variant='primary'
            onClick={() => {
              onExit()
              onClose()
            }}
            iconLeft={<DoorOpen />}
          />
        </div>
      </div>
    </Modal>
  )
}
