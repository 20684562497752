import { forwardRef, useEffect } from 'react'
import InputField from '../../../../../components/input'
import { Props } from '../types'
import { usePatientMedicineFormik } from '../../../../../hooks/forms/usePatientForm'
import { useTranslation } from 'react-i18next'
import SelectionModal from './selection-modal'
import { allergy, chronicDiseases, healthConditions, surgicalHistory } from './common'
import MedicinesForm from './medicines'
import { HandbagSimple, ArrowsVertical, SpinnerGap } from '@phosphor-icons/react'

const PatientMedical = forwardRef<HTMLButtonElement, Props>(
  ({ handleSubmit, handleStateSubmit, value }, ref) => {
    const { t } = useTranslation('patient_form')
    const formik = usePatientMedicineFormik(handleSubmit, value)

    useEffect(() => {
      handleStateSubmit(!formik.isValid)
    }, [formik.isValid, handleStateSubmit])

    return formik.isSubmitting ? (
      <div className='flex h-full w-full items-center justify-center'>
        <SpinnerGap size={20} className='animate-spin' />
      </div>
    ) : (
      <form onSubmit={formik.handleSubmit}>
        <div className='flex w-full flex-col gap-8'>
          <div className='flex gap-6'>
            <InputField
              id='weight'
              name='weight'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              label={t('fields.weight') || 'Peso'}
              className='max-w-[228px]'
              placeholder={t('placeholders.weight') || 'kg'}
              iconLeft={<HandbagSimple />}
              value={formik.values.weight?.toString() || ''}
              hint={
                formik.touched.weight && formik.errors.weight ? formik.errors.weight : undefined
              }
              state={formik.touched.weight && formik.errors.weight ? 'error' : 'default'}
            />

            <InputField
              id='height'
              name='height'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              label={t('fields.height') || 'Altura'}
              iconLeft={<ArrowsVertical />}
              className='max-w-[228px]'
              placeholder={t('placeholders.height') || 'cm'}
              value={formik.values.height?.toString() || ''}
              hint={
                formik.touched.height && formik.errors.height ? formik.errors.height : undefined
              }
              state={formik.touched.height && formik.errors.height ? 'error' : 'default'}
            />
          </div>

          <div className='grid grid-cols-2 grid-rows-2 gap-6'>
            <SelectionModal
              items={allergy}
              title={t('fields.allergy') || 'Alergias conhecidas'}
              onChange={values =>
                formik.setFieldValue(
                  'allergies',
                  values.map(value => ({ value }))
                )
              }
              value={formik.values.allergies?.map(allergy => allergy.value || '') || []}
            />

            <SelectionModal
              items={chronicDiseases}
              title={t('fields.chronic-diseases') || 'Doenças crônicas'}
              onChange={values =>
                formik.setFieldValue(
                  'chronic_diseases',
                  values.map(value => ({ value }))
                )
              }
              value={formik.values.chronic_diseases?.map(allergy => allergy.value || '') || []}
            />

            <SelectionModal
              items={healthConditions}
              title={t('fields.health-conditions') || 'Condições de Saúde'}
              onChange={values =>
                formik.setFieldValue(
                  'health_conditions',
                  values.map(value => ({ value }))
                )
              }
              value={formik.values.health_conditions?.map(allergy => allergy.value || '') || []}
            />

            <SelectionModal
              items={surgicalHistory}
              title={t('fields.surgical-history') || 'Histórico Cirúrgico'}
              onChange={values =>
                formik.setFieldValue(
                  'surgical_history',
                  values.map(value => ({ value }))
                )
              }
              value={formik.values.surgical_history?.map(allergy => allergy.value || '') || []}
            />
          </div>
        </div>

        <div className='mt-8 flex w-full flex-col gap-2'>
          {formik.values.medicines?.map((medicine, index) => (
            <MedicinesForm
              key={medicine?.id || index}
              onChange={value => {
                const newMedicines = formik.values.medicines || []
                newMedicines[index] = value
                formik.setFieldValue('medicines', newMedicines)
              }}
              handleAddMore={
                (formik?.values?.medicines?.length || 0) - 1 === index
                  ? () =>
                      formik.setFieldValue('medicines', [...(formik.values.medicines || []), {}])
                  : undefined
              }
              value={medicine}
            />
          ))}
        </div>

        <button ref={ref} className='hidden' type='submit' />
      </form>
    )
  }
)

PatientMedical.displayName = 'PatientMedical'

export default PatientMedical
