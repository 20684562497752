import React from 'react'
import Content from '../../../../components/content'

const UnitDetailSkeleton: React.FC = () => {
  return (
    <Content
      title={<div className='h-8 w-44 animate-pulse rounded-lg bg-gray-300' />}
      rightContent={
        <div className='flex items-center gap-4'>
          <div className='h-12 w-32 animate-pulse rounded-500 bg-gray-300' />
          <div className='h-12 w-44 animate-pulse rounded-500 bg-gray-300' />
        </div>
      }
      onBack={() => {}}
    >
      <div className='p-4'>
        <div className='my-14 grid  grid-cols-1 gap-4 md:grid-cols-3 md:gap-6'>
          {Array.from({ length: 3 }).map((_, index) => (
            <div key={index} className='space-y-2'>
              <div className='h-6 w-3/4 animate-pulse rounded-lg bg-gray-300' />
              <div className='h-4 w-1/2 animate-pulse rounded-lg bg-gray-200' />
            </div>
          ))}
        </div>
        <div className='my-14 grid  grid-cols-1 gap-4 md:grid-cols-3 md:gap-6'>
          {Array.from({ length: 3 }).map((_, index) => (
            <div key={index} className='space-y-2'>
              <div className='h-6 w-3/4 animate-pulse rounded-lg bg-gray-300' />
              <div className='h-4 w-1/2 animate-pulse rounded-lg bg-gray-200' />
            </div>
          ))}
        </div>
        <div className='my-14 grid  grid-cols-1 gap-4 md:grid-cols-3 md:gap-6'>
          {Array.from({ length: 3 }).map((_, index) => (
            <div key={index} className='space-y-2'>
              <div className='h-6 w-3/4 animate-pulse rounded-lg bg-gray-300' />
              <div className='h-4 w-1/2 animate-pulse rounded-lg bg-gray-200' />
            </div>
          ))}
        </div>
        <div className='my-14 grid  grid-cols-1 gap-4 md:grid-cols-3 md:gap-6'>
          {Array.from({ length: 3 }).map((_, index) => (
            <div key={index} className='space-y-2'>
              <div className='h-6 w-3/4 animate-pulse rounded-lg bg-gray-300' />
              <div className='h-4 w-1/2 animate-pulse rounded-lg bg-gray-200' />
            </div>
          ))}
        </div>
        <div className='mt-4'>
          <h3 className='mb-2 h-6 w-64 animate-pulse rounded-lg bg-gray-300' />
          <div className='flex flex-wrap gap-2'>
            {Array.from({ length: 2 }).map((_, index) => (
              <div key={index} className='h-6 w-20 animate-pulse rounded-lg bg-gray-200' />
            ))}
          </div>
        </div>
      </div>
    </Content>
  )
}

export default UnitDetailSkeleton
