const client_list_ptBR = {
  title: 'Clientes',
  search_placeholder: 'Procure pelo nome...',
  columns: {
    name: 'Nome',
    contract_period: 'Vigência do Contrato',
    status: 'Status',
    units_qty: 'Nº de Unidades',
    actions: ''
  },
  actions: {
    details: 'Detalhes',
    new_client: 'Novo cliente'
  },
  tags: {
    active: 'Ativo',
    inactive: 'Inativo'
  },
  contract_period: {
    no_date: 'sem data de vigência',
    from_to: 'até'
  }
}

export default client_list_ptBR
