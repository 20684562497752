import { useEffect, useState } from 'react'
import { Client, Patient, User } from '../../types'
import FileService from '../../services/file.service'

type Props = {
  user?: User | Patient | null
  client?: Client
  name?: string
  className?: string
  layout?: 'circle' | 'square'
  size?: 'xxxl' | 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'xxs'
  color?: 'default' | 'neutral'
  imageUrl?: string
  imageID?: string
}

const getColorLayout = (color: Props['color']) => {
  switch (color) {
    case 'neutral':
      return 'bg-neutralBackground-tertiary border border-neutralBorder-default text-neutralContent-primary'
    default:
      return 'bg-primaryBrand-primary text-white'
  }
}

const getSizeLayout = (size: Props['size']) => {
  switch (size) {
    case 'xxxl':
      return 'w-28 h-28 text-display-md'
    case 'xxl':
      return 'w-24 h-24 text-display-sm'
    case 'xl':
      return 'w-20 h-20 text-heading-xl'
    case 'lg':
      return 'w-16 h-16 text-heading-md'
    case 'md':
      return 'w-12 h-12 text-heading-xs'
    case 'sm':
      return 'h-9 w-9 text-label-md'
    case 'xs':
      return 'h-6 w-6 text-label-xs'
    case 'xxs':
      return 'h-5 w-5 text-label-xs'
    default:
      return ''
  }
}

const getLayout = (layout: Props['layout']) => {
  switch (layout) {
    case 'square':
      return 'rounded-[25%]'
    default:
      return 'rounded-full'
  }
}

const Avatar: React.FC<Props> = ({
  client,
  user,
  className,
  layout,
  size = 'md',
  imageUrl,
  imageID,
  name,
  color = 'default'
}) => {
  const initialName = (user || client)?.name?.[0] || name?.[0] || ''
  const [image, setImage] = useState(imageUrl)

  useEffect(() => {
    if (imageID || client?.image_id) {
      FileService.get(imageID || client!.image_id!).then(setImage)
    }
  }, [imageID, client])

  useEffect(() => {
    setImage(imageUrl)
  }, [imageUrl])

  return (
    <div
      className={`flex items-center justify-center ${getColorLayout(color)} ${getSizeLayout(size)} ${getLayout(layout)} ${className}`}
    >
      {image ? (
        <img
          src={image}
          alt='Avatar'
          className={`object-cover ${getLayout(layout)} ${getSizeLayout(size)}`}
        />
      ) : (
        initialName
      )}
    </div>
  )
}

export default Avatar
