import React from 'react'
import { useGetCitiesByState } from '../../hooks/useLocation'
import Dropdown from '.'
import InputField from '../input'

interface CityDropdownProps {
  uf: string
  value: string
  hint?: string
  label?: string
  error?: boolean
  disabled?: boolean
  placeholder?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur: (event: React.FocusEvent<any>) => void
  onChange: (option: { value: string; label: string } | null) => void
}

const CityDropdown: React.FC<CityDropdownProps> = ({
  uf,
  hint,
  value,
  label,
  error,
  onBlur,
  onChange,
  disabled = false,
  placeholder = 'Selecione'
}) => {
  const { data: cities } = useGetCitiesByState(uf)

  const options = cities
    ? cities.map((city: { id: string | number; nome: string }) => ({
        value: city.nome,
        label: city.nome
      }))
    : []

  if (!cities)
    return <InputField onChange={() => {}} state='disabled' value='' label={label || 'Cidade'} />

  return (
    <Dropdown
      name='city'
      label={label || 'Cidade'}
      placeholder={placeholder}
      onBlur={onBlur}
      value={value}
      onChangeValue={onChange}
      options={options}
      disabled={disabled}
      error={error}
      hint={hint}
    />
  )
}

export default CityDropdown
