import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import enUS from './langs/en-US'
import ptBR from './langs/pt-BR'

i18next.use(initReactI18next).init({
  resources: {
    enUS,
    ptBR
  },
  fallbackLng: 'ptBR',
  interpolation: {
    escapeValue: false
  }
})

export default i18next
