import Radio from '../../../components/radio'

interface ClientPersonTypeProps {
  value: string
  id: string
  onChangeValue: (field: string, value: unknown, shouldValidate?: boolean) => void
}

const ClientPersonType: React.FC<ClientPersonTypeProps> = ({ value, id, onChangeValue }) => {
  return (
    <div className='flex items-center gap-4'>
      <label className='flex items-center gap-2'>
        <Radio
          size='md'
          selected={value === 'PF'}
          state='enabled'
          onChange={() => onChangeValue(id, 'PF')}
        />
        Pessoa Física
      </label>
      <label className='flex items-center gap-2'>
        <Radio
          size='md'
          selected={value === 'PJ'}
          state='enabled'
          onChange={() => onChangeValue(id, 'PJ')}
        />
        Pessoa Jurídica
      </label>
    </div>
  )
}

export default ClientPersonType
