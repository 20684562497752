import { PencilSimple, Trash, ArrowUp, ArrowDown } from '@phosphor-icons/react'
import BlankCard from '../../../components/blank-card'
import Checkbox from '../../../components/checkbox'
import Radio from '../../../components/radio'
import Dropdown from '../../../components/dropdown'
import TextareaField from '../../../components/text-area'

interface Props {
  title: string
  type: 'textarea' | 'checkbox' | 'radio' | 'dropdown'
  options?: string[]
  onMoveUp: () => void
  onMoveDown: () => void
  onEdit: () => void
  onDelete: () => void
}

function QuestionPreview({
  title,
  type,
  options = [],
  onMoveUp,
  onMoveDown,
  onEdit,
  onDelete
}: Props) {
  const renderOptionPreview = () => {
    switch (type) {
      case 'checkbox':
        return options.map((option, index) => (
          <div key={index} className='flex items-center gap-2'>
            <Checkbox isChecked={false} state='disable' onClick={() => {}} />
            <span>{option}</span>
          </div>
        ))
      case 'radio':
        return options.map((option, index) => (
          <div key={index} className='flex items-center gap-2'>
            <Radio size='md' selected={false} state='enabled' />
            <span>{option}</span>
          </div>
        ))
      case 'dropdown':
        return (
          <Dropdown
            name='dropdown-preview'
            label=''
            value=''
            options={options.map(option => ({ value: option, label: option }))}
            onChangeValue={() => {}}
            placeholder='Selecione uma opção'
          />
        )
      case 'textarea':
        return (
          <div className='w-[400px]'>
            <TextareaField
              onChange={() => {}}
              value=''
              className='w-full rounded border border-gray-300 p-2'
              placeholder='Resposta aberta'
            />
          </div>
        )
      default:
        return null
    }
  }

  return (
    <BlankCard className='mb-4 '>
      <span className='text-body-md font-bold text-neutralContent-primary'>{title}</span>

      <div className='flex items-center justify-between px-4 py-2'>
        <div className='flex w-full items-center gap-6'>
          <div className='flex items-center gap-2'>
            <ArrowUp size={22} className='cursor-pointer' onClick={onMoveUp} />
            <ArrowDown size={22} className='cursor-pointer' onClick={onMoveDown} />
          </div>
          <div className='mt-2 flex items-center gap-4'>{renderOptionPreview()}</div>
        </div>

        <div className='flex items-center gap-4'>
          <PencilSimple
            size={22}
            className='cursor-pointer text-primaryBrand-primary'
            onClick={onEdit}
          />
          <Trash size={22} className='cursor-pointer text-highlightRed-pure' onClick={onDelete} />
        </div>
      </div>
    </BlankCard>
  )
}

export default QuestionPreview
