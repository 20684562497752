const consultation_list_ptBR = {
  title_elective_care: 'Atendimentos eletivos',
  title_emergency_care: 'Pronto atendimentos',
  title_chat: 'Atendimentos por chat',
  subtitle: 'Visualize e agende atendimentos com especialistas',
  columns: {
    code: 'Código',
    date: 'Data',
    patient: 'Paciente',
    specialty: 'Especialidade',
    consultation: 'Atendimento',
    requester: 'Solicitante',
    created_by: 'Criado por',
    specialist: 'Executante',
    consultation_type: {
      elective_care: 'Eletivo',
      emergency_care: 'Urgencia'
    },
    status: 'Status',
    status_type: {
      SCHEDULED: 'Agendado',
      WAITING: 'Em espera',
      FINISHED: 'Finalizado',
      IN_ROOM: 'Em andamento'
    },
    unit: 'Unidade'
  },
  actions: {
    new_consultation: 'Novo atendimento',
    transfer: 'Transferir atendimentos'
  }
}

export default consultation_list_ptBR
