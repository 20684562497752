import { CheckCircle, Info, WarningCircle, XCircle } from '@phosphor-icons/react'
import { TPosition, TVariant } from './type'

export const styleVariant: Record<TVariant, { iconName: React.ComponentType; style: string }> = {
  brand: {
    iconName: Info,
    style: 'bg-background-brand text-white'
  },
  positive: {
    iconName: CheckCircle,
    style: 'bg-background-positive text-white'
  },
  warning: {
    iconName: WarningCircle,
    style: 'bg-background-warning text-black'
  },
  negative: {
    iconName: XCircle,
    style: 'bg-background-negative text-white'
  }
}

export const styleClasses: Record<
  'top' | 'bottom',
  {
    initial: Record<string, number | string>
    animate: Record<string, number | string>
    exit: Record<string, number | string>
  }
> = {
  top: {
    initial: { opacity: 0, scale: 0.3 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.5 }
  },
  bottom: {
    initial: { opacity: 0, scale: 0.3 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.5 }
  }
}

export const position: Record<TPosition, Record<string, number | string>> = {
  center: { left: 'calc(50vw - 175px)' },
  right: { right: '30px' },
  left: { left: '30px' },
  bottom: { bottom: '30px' },
  top: { top: '30px' }
}
