import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../../../../components/modal'
import Button from '../../../../components/button'
import { Slider } from '../../../../components/slider'
import TextareaField from '../../../../components/text-area'
import { useSendNpsFeedback } from '../../../../hooks/useConsultation'

interface NPSModalProps {
  open: boolean
  onClose: () => void
}

export const NPSModal: React.FC<NPSModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation('consultation_details')
  const [score, setScore] = useState(5)
  const [description, setDescription] = useState('')
  const { mutate: sendFeedback, isLoading } = useSendNpsFeedback(onClose)

  const handleSubmit = () => {
    sendFeedback({ score, description })
  }

  return (
    <Modal title={t(`modal_nsp_modal.title`)} isOpen={open} onClose={onClose}>
      <div className='w-[380px] max-w-[90vw]'>
        <p className='text-body-md text-neutralContent-tertiary'>
          {t(`modal_nsp_modal.description`)}
        </p>
        <div className='p-4'>
          <Slider min={0} max={5} step={1} onChange={setScore} value={score} />
        </div>
        <div className='p-2'>
          <TextareaField
            placeholder='Comentário adicional'
            onChange={event => setDescription(event.target.value)}
            value={description}
          />
        </div>

        <div className='mt-4 flex justify-end'>
          <Button
            type='button'
            label={t('actions.cancel')}
            size='md'
            variant='tertiary'
            onClick={onClose}
          />
          <Button
            isLoading={isLoading}
            size='md'
            label={t('actions.sent')}
            variant='primary'
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  )
}
