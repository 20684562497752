import { ArrowLeft, X } from '@phosphor-icons/react'
import React from 'react'

export interface ModalProps {
  title?: string | React.ReactNode
  isOpen: boolean
  onClose?: () => void
  children: React.ReactNode
  className?: string
  contentClassName?: string
  onBack?: () => void
}

const Modal: React.FC<ModalProps> = ({
  title,
  isOpen,
  onClose,
  children,
  className,
  onBack,
  contentClassName
}) => {
  if (!isOpen) return null

  const renderTitle = () => (
    <div>
      {typeof title === 'string' ? (
        <h2 className='text-label-md text-neutralContent-primary'>{title}</h2>
      ) : (
        title
      )}
    </div>
  )

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center bg-neutralBackground-tertiary bg-opacity-50'>
      <div className={`relative overflow-hidden rounded-2xl bg-white shadow-lg ${className}`}>
        <div className='flex items-center justify-between px-4 pt-4'>
          <div className='flex items-center gap-3'>
            {!!onBack && <ArrowLeft onClick={onBack} />}
            {renderTitle()}
          </div>
          {onClose && (
            <button type='button' onClick={onClose} className=' text-gray-500 hover:text-gray-700'>
              <X size={20} weight='bold' />
            </button>
          )}
        </div>
        <div className={`flex-grow items-center ${contentClassName || 'p-4'}`}>{children}</div>
      </div>
    </div>
  )
}

export default Modal
