import BlankCardSkeleton from '../../../../components/blank-card/skeleton'
import ConsultationDetailSkeleton from './ConsultationDetailSkeleton'
import AnamnesisTabSkeleton from './AnamnesisTemplateSkeleton'

export const ConsultationDetailsSkeleton = () => {
  return (
    <>
      <BlankCardSkeleton />

      <ConsultationDetailSkeleton />

      <AnamnesisTabSkeleton />
    </>
  )
}
