import { Eye, EyeSlash, WarningCircle } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { formatValue } from './format'

interface InputFieldProps {
  id?: string
  state?: 'default' | 'disabled' | 'error'
  label?: string
  placeholder?: string
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  hint?: string
  type?:
    | 'text'
    | 'password'
    | 'email'
    | 'number'
    | 'cpf'
    | 'cnpj'
    | 'phone'
    | 'cep'
    | 'cns'
    | 'cnes'
    | 'passport'
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  value: string
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  name?: string
  maxLength?: number
  className?: string
}

const InputField: React.FC<InputFieldProps> = ({
  state = 'default',
  label,
  placeholder,
  iconLeft,
  iconRight,
  hint,
  type = 'text',
  onChange,
  value,
  onBlur,
  name,
  maxLength,
  className
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const isPassword = type === 'password'

  const getBorderClasses = () => {
    switch (state) {
      case 'disabled':
        return 'border-neutralBackground-disabled bg-neutralBackground-disabled'
      case 'error':
        return 'border-2 border-highlightRed-pure'
      default:
        return 'border border-neutralBorder-default focus-within:border-2 focus-within:border-primaryBrand-dark'
    }
  }

  const getTextClasses = () => {
    switch (state) {
      case 'disabled':
        return 'text-neutralContent-disabled'
      default:
        return 'text-neutralContent-primary'
    }
  }

  const getPlaceholderClasses = () => {
    switch (state) {
      case 'disabled':
        return 'text-neutralContent-disabled'
      default:
        return 'text-neutralContent-quartenary'
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      type === 'number' ||
      type === 'cpf' ||
      type === 'phone' ||
      type === 'cnpj' ||
      type === 'cep' ||
      type === 'cns' ||
      type === 'cnes' ||
      type === 'passport'
    ) {
      event.target.value = formatValue(type, event.target.value)
    }
    onChange(event)
  }

  return (
    <div className={`flex w-full flex-col gap-2 ${className}`}>
      {!!label && <label className={`label-md ${getTextClasses()}`}>{label}</label>}
      <div
        className={`p-[12px 16px] flex h-12 items-center gap-[16px] rounded-[12px] ${getBorderClasses()}`}
      >
        {iconLeft && <span className='ml-3'>{iconLeft}</span>}
        <input
          name={name}
          value={value}
          onBlur={onBlur}
          autoComplete='off'
          maxLength={maxLength}
          onChange={handleChange}
          placeholder={placeholder}
          disabled={state === 'disabled'}
          aria-disabled={state === 'disabled'}
          type={isPassword ? (isPasswordVisible ? 'text' : 'password') : 'text'}
          className={`body-md flex-grow bg-transparent ${value ? getTextClasses() : getPlaceholderClasses()} ${
            state !== 'disabled' && 'focus:outline-none'
          } ${!iconLeft && ' pl-3'}`}
        />
        {isPassword && (
          <span onClick={() => setIsPasswordVisible(!isPasswordVisible)} className='mr-3'>
            {isPasswordVisible ? <EyeSlash size={16} /> : <Eye size={16} />}
          </span>
        )}
        {iconRight && <span>{iconRight}</span>}
      </div>
      {state === 'error' && hint && (
        <div className='body-sm flex items-center text-highlightRed-pure'>
          <WarningCircle size={16} />
          <span className='ml-2'>{hint}</span>
        </div>
      )}
    </div>
  )
}

export default InputField
