import ButtonSkeleton from '../../../../components/button/skeleton'
import Content from '../../../../components/content'
import ContentFooter from '../../../../components/content/footer'
import ContentForm from '../../../../components/content/form'
import InputSkeleton from '../../../../components/input/skeleton'

const UnitFormSkeleton: React.FC = () => {
  return (
    <Content
      title={<div className='h-8 w-44 animate-pulse rounded-lg bg-gray-300' />}
      onBack={() => {}}
    >
      <div className='col-span-2'>
        <ContentForm>
          <>
            <div className='col-span-2 h-4 w-48  animate-pulse rounded-lg bg-gray-300' />
            {Array.from({ length: 7 }).map((_, index) => (
              <InputSkeleton showLabel key={index} />
            ))}

            <div className='col-span-2 h-4 w-48  animate-pulse rounded-lg bg-gray-300' />
            {Array.from({ length: 7 }).map((_, index) => (
              <InputSkeleton showLabel key={index} />
            ))}
          </>

          <ContentFooter>
            <ButtonSkeleton />
          </ContentFooter>
        </ContentForm>
      </div>
    </Content>
  )
}

export default UnitFormSkeleton
