export const formatMonthYear = (initialDate: Date, hasDay?: boolean) => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    year: 'numeric'
  }

  if (hasDay) {
    options.day = '2-digit'
  }

  const formattedDate = initialDate.toLocaleDateString('pt-BR', options)

  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
}
