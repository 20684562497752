import React from 'react'
import { format } from 'date-fns'
import { CARE_TYPE, Consultation } from '../../../../types'
import { useTranslation } from 'react-i18next'
import Tag from '../../../../components/tag'

interface Props {
  consultation: Consultation
}

const ConsultationSummary: React.FC<Props> = ({ consultation }) => {
  const { t } = useTranslation('consultation_details')

  function formatTimeRange(start: string, end: string) {
    const startTime = format(new Date(start), 'HH:mm')
    const endTime = format(new Date(end), 'HH:mm')
    return `${startTime} - ${endTime}`
  }

  return (
    <div className='flex flex-wrap gap-4 py-8'>
      <div className='flex items-center gap-2'>
        <div className='text-label-sm text-neutralContent-quartenary'>{t('code')}</div>
        <Tag label={consultation?.code ?? ''} size='sm' variant='secondary' color='neutral' />
      </div>

      <div className='flex items-center gap-2'>
        <div className='text-label-sm text-neutralContent-quartenary'>{t('consultation')}</div>
        <Tag
          label={t(`consultation_type.${consultation?.start_date ? 'elective' : 'urgency'}`)}
          variant='secondary'
          size='sm'
          color={consultation.care_type === CARE_TYPE.EMERGENCY_CARE ? 'red' : 'blue'}
        />
      </div>

      <div className='flex items-center gap-2'>
        <div className='text-label-sm text-neutralContent-quartenary'>{t('date')}</div>
        <Tag
          label={
            consultation && consultation.care_type !== CARE_TYPE.EMERGENCY_CARE
              ? format(new Date(consultation.start_date!), 'dd/MM/yyyy')
              : format(new Date(consultation.created_at!), 'dd/MM/yyyy')
          }
          color='neutral'
          variant='secondary'
          size='sm'
        />
      </div>

      <div className='flex items-center gap-2'>
        <div className='text-label-sm text-neutralContent-quartenary'>{t('hour')}</div>
        <Tag
          label={
            consultation && consultation.care_type !== CARE_TYPE.EMERGENCY_CARE
              ? formatTimeRange(consultation.start_date!, consultation.end_date!)
              : format(new Date(consultation.created_at!), 'HH:mm')
          }
          color='neutral'
          variant='secondary'
          size='sm'
        />
      </div>

      <div className='flex items-center gap-2'>
        <div className='text-label-sm text-neutralContent-quartenary'>{t('unit')}</div>
        <Tag
          label={consultation?.unit?.name ?? '-'}
          color='neutral'
          variant='secondary'
          size='sm'
        />
      </div>

      <div className='flex items-center gap-2'>
        <div className='text-label-sm text-neutralContent-quartenary'>{t('requester')}</div>
        <Tag
          label={consultation?.requester?.name ?? '-'}
          color='neutral'
          variant='secondary'
          size='sm'
        />
      </div>

      <div className='flex items-center gap-2'>
        <div className='text-label-sm text-neutralContent-quartenary'>{t('specialist')}</div>

        <Tag
          label={consultation?.temp_specialist?.name || consultation?.specialist?.name || '-'}
          color='neutral'
          variant='secondary'
          size='sm'
        />
      </div>

      <div className='flex items-center gap-2'>
        <div className='text-label-sm text-neutralContent-quartenary'>{t('specialty')}</div>

        <Tag
          label={consultation?.specialty?.name ?? '-'}
          color='neutral'
          variant='secondary'
          size='sm'
        />
      </div>
    </div>
  )
}

export default ConsultationSummary
