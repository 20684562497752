import { PaginationParams, PaginationResponse, UnitType } from '../types'
import api from './api.service'

class UnitTypeService {
  static createUnitType(unitType: Partial<UnitType>) {
    return api.post<UnitType>('/unit-type', unitType)
  }
  static async listTypes(params: PaginationParams) {
    const response = await api.get<PaginationResponse<UnitType>>('/unit-type', {
      params
    })
    return response.data
  }

  static updateUnitType(id: string, unitType: Partial<UnitType>) {
    return api.put<void>('/unit-type/' + id, unitType)
  }

  static deleteUnitType(id: string) {
    return api.delete<void>('/unit-type/' + id)
  }
}

export default UnitTypeService
