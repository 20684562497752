import { useTranslation } from 'react-i18next'

export const ChatHeader: React.FC = () => {
  const { t } = useTranslation('consultation_details')

  return (
    <div className='relative mb-[-20px] p-4'>
      <h1 className='text-heading-xs font-extrabold'>{t('chat.title')}</h1>
    </div>
  )
}
