/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { MagnifyingGlass } from '@phosphor-icons/react'
import { useGetAddressByCep } from '../../hooks/useLocation'
import InputField from '.'
import Button from '../button'
import Checkbox from '../checkbox'

interface CepFieldProps {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onAddressFetched: (address: any) => void
  state?: 'default' | 'disabled' | 'error'
  error?: string
  hint?: string
  className?: string
  cepUnknown?: boolean
  handleCepUnknown?: (value: boolean) => void
  id?: string
  name?: string
  label?: string
}

const CepField: React.FC<CepFieldProps> = ({
  hint,
  value,
  error,
  onBlur,
  onChange,
  className,
  cepUnknown,
  onAddressFetched,
  handleCepUnknown,
  state = 'default',
  id,
  name,
  label
}) => {
  const [isCepUnknown, setIsCepUnknown] = useState(cepUnknown || false)
  const { data: address, isFetching: isFetchingAddress } = useGetAddressByCep(
    value.replace(/\D/g, '')
  )

  useEffect(() => {
    if (address && !isFetchingAddress) {
      onAddressFetched(address)
    }
  }, [address, isFetchingAddress])

  const handleCheckboxChange = () => {
    setIsCepUnknown(!isCepUnknown)
    if (handleCepUnknown) handleCepUnknown(!isCepUnknown)
  }

  return (
    <div>
      <div className={`flex items-center ${className}`}>
        <InputField
          id={id || 'cep'}
          name={name || 'cep'}
          type='cep'
          label={label || 'CEP'}
          hint={hint}
          value={value}
          maxLength={9}
          onBlur={onBlur}
          className='w-56'
          onChange={onChange}
          state={isCepUnknown ? 'disabled' : !!Boolean(error) ? 'error' : state}
        />
        <div className='mt-6 items-center'>
          <Button
            type='button'
            label='Procurar'
            size='sm'
            variant='secondary'
            iconLeft={<MagnifyingGlass size={12} />}
            className='mx-2 items-center self-center'
            onClick={() => {}}
            isLoading={isFetchingAddress}
            disabled={isCepUnknown}
          />
        </div>
      </div>
      <div className='mt-2 flex items-center gap-2'>
        <Checkbox isChecked={isCepUnknown} onClick={handleCheckboxChange} />
        <span>Não sei o cep</span>
      </div>
    </div>
  )
}

export default CepField
