/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate, useParams } from 'react-router-dom'
import ContentForm from '../../../../components/content/form'
import { useGetUnitClient } from '../../../../hooks/useClient'
import { useEffect, useState } from 'react'
import InputField from '../../../../components/input'
import UnitTypeSelect from '../../../../components/dropdown/unit-type-dropdrown'
import Content from '../../../../components/content'
import CepField from '../../../../components/input/cep-field'
import CityDropdown from '../../../../components/dropdown/city-dropdown'
import StateDropdown from '../../../../components/dropdown/state-dropdown'
import TagSelector from '../../../../components/tag-selector'
import SpecialtyModal from './specialty-unit-modal'
import BlankCard from '../../../../components/blank-card'
import { useTranslation } from 'react-i18next'
import Checkbox from '../../../../components/checkbox'
import UnitPermissionsEnum from '../../../../types/unit-permissions'
import ContentFooter from '../../../../components/content/footer'
import Button from '../../../../components/button'
import { ROUTES } from '../../../../routes/path'
import { Specialty, Unit } from '../../../../types'
import UnitFormSkeleton from './skeleton'
import { CepAddress } from '../../../../services/location.service'
import CouncilSelect from '../../../../components/dropdown/council-dropdown'
import { useUnitFormik } from '../../../../hooks/forms/useUnitForm'
import Dropdown from '../../../../components/dropdown'
import { UFs } from '../../../follow-up/constants'

const UnitFormPage: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['unit_form', 'permissions_units'])

  const params = useParams<{ id: string; client_id: string }>()
  const { id, client_id } = params || {}
  const unitFormik = useUnitFormik(id, client_id)

  const { data: unit, isFetching: isFetchingUnit } = useGetUnitClient(id || '', client_id || '')

  const [isModalSpecialtyOpen, setIsModalSpecialtyOpen] = useState(false)

  const [displaySpecialties, setDisplaySpecialties] = useState<Unit['specialties']>([])

  const [permissionsChecked, setPermissionsChecked] = useState<string[]>([])

  const handleCheckboxChange = (permission: string) => {
    const updatedPermissions = permissionsChecked.includes(permission)
      ? permissionsChecked.filter(p => p !== permission)
      : [...permissionsChecked, permission]
    setPermissionsChecked(updatedPermissions)
    unitFormik.setFieldValue('permissions', updatedPermissions)
  }

  const handleOpenModalSpecialty = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setIsModalSpecialtyOpen(true)
  }

  const handleCloseModalSpecialty = () => {
    setIsModalSpecialtyOpen(false)
  }

  const handleRemoveSpecialty = (specialty: Specialty) => {
    const updatedDisplaySpecialties = displaySpecialties?.filter((s: any) => s.id !== specialty.id)
    const updatedSpecialties = unitFormik.values.specialties?.filter(
      (s: any) => s.specialty_id !== specialty.id
    )
    setDisplaySpecialties(updatedDisplaySpecialties)
    unitFormik.setFieldValue('specialties', updatedSpecialties)
  }

  const handleSuccessSpecialties = (selectedValues: any[]) => {
    const formattedSpecialties = selectedValues.map(specialty => ({ specialty_id: specialty.id }))
    setIsModalSpecialtyOpen(false)
    setDisplaySpecialties(selectedValues)
    unitFormik.setFieldValue('specialties', formattedSpecialties)
  }

  useEffect(() => {
    if (id && unit) {
      const formattedSpecialties: Unit[] | undefined = unit.specialties?.map((s: any) => ({
        specialty_id: s.specialty.id,
        ...s.specialty
      }))
      unitFormik.setValues({
        ...unit,
        cepUnknown: !unit.cep,
        permissions: unit.permissions || [],
        specialties: formattedSpecialties || []
      })
      setDisplaySpecialties(formattedSpecialties || [])
      setPermissionsChecked(unit.permissions || [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, unit])

  const handleAddressFetched = async (address: CepAddress) => {
    if (address.logradouro !== unitFormik.values.address) {
      await unitFormik.setFieldValue('address', address.logradouro)
    }
    if (address.bairro !== unitFormik.values.district) {
      await unitFormik.setFieldValue('district', address.bairro)
    }

    if (address.uf !== unitFormik.values.state) {
      await unitFormik.setFieldValue('state', address.uf)
    }

    if (address.localidade !== unitFormik.values.city) {
      await unitFormik.setFieldValue('city', address.localidade)
    }
  }

  const handleBack = () => {
    if (id !== 'new') {
      navigate(
        ROUTES.client.unit.detail.replace(':client_id', client_id || '').replace(':id', id || '')
      )
    } else {
      navigate(ROUTES.client.unit.list.replace(':client_id', client_id || ''))
    }
  }

  const PERMISSIONS_CONSULTATION_CARE_TYPES = [
    UnitPermissionsEnum.elective_care_consultation,
    UnitPermissionsEnum.emergency_care_consultation,
    UnitPermissionsEnum.chat_consultation
  ]

  const PERMISSIONS_CONSULTATION_TYPES = [
    UnitPermissionsEnum.teleconsultation_type,
    UnitPermissionsEnum.teleinterconsultation_type
  ]

  const PERMISSIONS_UNIT = [
    UnitPermissionsEnum.write_permission,
    UnitPermissionsEnum.invite_patient,
    UnitPermissionsEnum.invite_professional
  ]

  return (
    <>
      {isFetchingUnit ? (
        <UnitFormSkeleton />
      ) : (
        <Content
          onBack={handleBack}
          title={id && id !== 'new' ? t('fields.edit_unit') : t('fields.create_unit')}
        >
          <form onSubmit={unitFormik.handleSubmit} className='h-full'>
            <ContentForm hasBackButton>
              <>
                <h1 className='text-heading-xs text-neutralContent-primary sm:col-span-2'>
                  {t('fields.unit_details')}
                </h1>

                <InputField
                  id='name'
                  name='name'
                  type='text'
                  label={t('fields.name')}
                  onBlur={unitFormik.handleBlur}
                  onChange={unitFormik.handleChange}
                  value={unitFormik.values.name ?? ''}
                  state={unitFormik.touched.name && unitFormik.errors.name ? 'error' : 'default'}
                  hint={
                    unitFormik.touched.name && unitFormik.errors.name
                      ? unitFormik.errors.name
                      : undefined
                  }
                />
                <UnitTypeSelect
                  name='unit_type_id'
                  label={t('fields.unit_type')}
                  onBlur={unitFormik.handleBlur}
                  value={unitFormik.values.unit_type_id || ''}
                  error={Boolean(unitFormik.touched.unit_type_id && unitFormik.errors.unit_type_id)}
                  onChange={option => {
                    unitFormik.setFieldValue('unit_type_id', option ? option.id : '')
                  }}
                  hint={
                    unitFormik.touched.unit_type_id && unitFormik.errors.unit_type_id
                      ? unitFormik.errors.unit_type_id
                      : undefined
                  }
                />

                <InputField
                  id='cnpj'
                  name='cnpj'
                  type='cnpj'
                  label={t('fields.cnpj')}
                  onBlur={unitFormik.handleBlur}
                  onChange={unitFormik.handleChange}
                  value={unitFormik.values.cnpj ?? ''}
                  state={unitFormik.touched.cnpj && unitFormik.errors.cnpj ? 'error' : 'default'}
                  hint={
                    unitFormik.touched.cnpj && unitFormik.errors.cnpj
                      ? unitFormik.errors.cnpj
                      : undefined
                  }
                />
                <InputField
                  id='cnes'
                  name='cnes'
                  type='cnes'
                  label={t('fields.cnes')}
                  onBlur={unitFormik.handleBlur}
                  onChange={unitFormik.handleChange}
                  value={unitFormik.values.cnes ?? ''}
                  state={unitFormik.touched.cnes && unitFormik.errors.cnes ? 'error' : 'default'}
                  hint={
                    unitFormik.touched.cnes && unitFormik.errors.cnes
                      ? unitFormik.errors.cnes
                      : undefined
                  }
                />

                <InputField
                  id='email'
                  name='email'
                  type='email'
                  label={t('fields.email')}
                  onBlur={unitFormik.handleBlur}
                  onChange={unitFormik.handleChange}
                  value={unitFormik.values.email ?? ''}
                  state={unitFormik.touched.email && unitFormik.errors.email ? 'error' : 'default'}
                  hint={
                    unitFormik.touched.email && unitFormik.errors.email
                      ? unitFormik.errors.email
                      : undefined
                  }
                />

                <InputField
                  id='phone'
                  name='phone'
                  type='phone'
                  label={t('fields.phone')}
                  onBlur={unitFormik.handleBlur}
                  onChange={unitFormik.handleChange}
                  value={unitFormik.values.phone || ''}
                  state={unitFormik.touched.phone && unitFormik.errors.phone ? 'error' : 'default'}
                  hint={
                    unitFormik.touched.phone && unitFormik.errors.phone
                      ? unitFormik.errors.phone
                      : undefined
                  }
                />

                <h1 className='text-heading-xs text-neutralContent-primary sm:col-span-2'>
                  {t('fields.technical_manager')}
                </h1>

                <div className='col-span-2 grid grid-cols-3 gap-3 lg:grid-cols-[1fr,300px,1fr,200px,150px]'>
                  <InputField
                    type='text'
                    id='technical_manager_name'
                    name='technical_manager_name'
                    onBlur={unitFormik.handleBlur}
                    onChange={unitFormik.handleChange}
                    className='col-span-2 lg:col-span-1'
                    label={t('fields.technical_manager_name')}
                    value={unitFormik.values.technical_manager_name || ''}
                    state={
                      unitFormik.touched.technical_manager_name &&
                      unitFormik.errors.technical_manager_name
                        ? 'error'
                        : 'default'
                    }
                    hint={
                      unitFormik.touched.technical_manager_name &&
                      unitFormik.errors.technical_manager_name
                        ? unitFormik.errors.technical_manager_name
                        : undefined
                    }
                  />

                  <InputField
                    type='cpf'
                    id='technical_manager_doc'
                    name='technical_manager_doc'
                    onBlur={unitFormik.handleBlur}
                    onChange={unitFormik.handleChange}
                    label={t('fields.technical_manager_doc')}
                    value={unitFormik.values.technical_manager_doc || ''}
                    state={
                      unitFormik.touched.technical_manager_doc &&
                      unitFormik.errors.technical_manager_doc
                        ? 'error'
                        : 'default'
                    }
                    hint={
                      unitFormik.touched.technical_manager_doc &&
                      unitFormik.errors.technical_manager_doc
                        ? unitFormik.errors.technical_manager_doc
                        : undefined
                    }
                  />

                  <CouncilSelect
                    label='Conselho'
                    name='technical_manager_council_id'
                    onBlur={unitFormik.handleBlur}
                    placeholder='Selecione o conselho'
                    onChange={option =>
                      unitFormik.setFieldValue('technical_manager_council_id', option?.id || '')
                    }
                    value={unitFormik.values.technical_manager_council_id || ''}
                    error={Boolean(
                      unitFormik.touched.technical_manager_council_id &&
                        unitFormik.errors.technical_manager_council_id
                    )}
                    hint={
                      unitFormik.touched.technical_manager_council_id &&
                      unitFormik.errors.technical_manager_council_id
                        ? unitFormik.errors.technical_manager_council_id
                        : ''
                    }
                  />

                  <InputField
                    type='text'
                    onBlur={unitFormik.handleBlur}
                    id='technical_manager_council_doc'
                    onChange={unitFormik.handleChange}
                    name='technical_manager_council_doc'
                    label={t('fields.technical_manager_council_doc')}
                    value={unitFormik.values.technical_manager_council_doc || ''}
                    state={
                      unitFormik.touched.technical_manager_council_doc &&
                      unitFormik.errors.technical_manager_council_doc
                        ? 'error'
                        : 'default'
                    }
                    hint={
                      unitFormik.touched.technical_manager_council_doc &&
                      unitFormik.errors.technical_manager_council_doc
                        ? unitFormik.errors.technical_manager_council_doc
                        : undefined
                    }
                  />

                  <Dropdown
                    label={'UF'}
                    name='uf'
                    value={unitFormik.values.technical_manager_council_uf || ''}
                    options={UFs}
                    placeholder='UF'
                    onChangeValue={option =>
                      unitFormik.setFieldValue('technical_manager_council_uf', option?.value)
                    }
                  />
                </div>

                <h1 className='text-heading-xs text-neutralContent-primary sm:col-span-2'>
                  {t('fields.unit_address')}
                </h1>

                <div className='col-span-2 grid grid-cols-3 gap-3'>
                  <CepField
                    onBlur={unitFormik.handleBlur}
                    onChange={unitFormik.handleChange}
                    value={unitFormik.values.cep || ''}
                    onAddressFetched={handleAddressFetched}
                    state={unitFormik.touched.cep && unitFormik.errors.cep ? 'error' : 'default'}
                    cepUnknown={unitFormik.values.cepUnknown}
                    handleCepUnknown={async value => {
                      await unitFormik.setFieldValue('cepUnknown', value)
                      await unitFormik.setFieldValue('cep', '')
                    }}
                    error={
                      unitFormik.touched.cep && unitFormik.errors.cep
                        ? unitFormik.errors.cep
                        : undefined
                    }
                    hint={
                      unitFormik.touched.cep && unitFormik.errors.cep
                        ? unitFormik.errors.cep
                        : undefined
                    }
                  />
                </div>

                <div className='col-span-2 flex gap-4'>
                  <InputField
                    type='text'
                    id='address'
                    name='address'
                    label={t('fields.address')}
                    onBlur={unitFormik.handleBlur}
                    onChange={unitFormik.handleChange}
                    value={unitFormik.values.address || ''}
                    state={
                      unitFormik.touched.address && unitFormik.errors.address ? 'error' : 'default'
                    }
                    hint={
                      unitFormik.touched.address && unitFormik.errors.address
                        ? unitFormik.errors.address
                        : undefined
                    }
                  />

                  <div>
                    <InputField
                      type='text'
                      id='address_number'
                      name='address_number'
                      onBlur={unitFormik.handleBlur}
                      label={t('fields.address_number')}
                      value={unitFormik.values.address_number || ''}
                      onChange={unitFormik.handleChange}
                      state={
                        unitFormik.touched.address_number && unitFormik.errors.address_number
                          ? 'error'
                          : 'default'
                      }
                      hint={
                        unitFormik.touched.address_number && unitFormik.errors.address_number
                          ? unitFormik.errors.address_number
                          : undefined
                      }
                    />
                  </div>
                </div>

                <div className='col-span-2 grid grid-cols-3 gap-4'>
                  <InputField
                    id='district'
                    name='district'
                    label={t('fields.district')}
                    onBlur={unitFormik.handleBlur}
                    onChange={unitFormik.handleChange}
                    value={unitFormik.values.district || ''}
                    state={
                      unitFormik.touched.district && unitFormik.errors.district
                        ? 'error'
                        : 'default'
                    }
                    hint={
                      unitFormik.touched.district && unitFormik.errors.district
                        ? unitFormik.errors.district
                        : undefined
                    }
                  />

                  <StateDropdown
                    label={t('fields.state')}
                    onBlur={unitFormik.handleBlur}
                    value={unitFormik.values.state || ''}
                    error={Boolean(unitFormik.touched.state && unitFormik.errors.state)}
                    hint={
                      unitFormik.touched.state && unitFormik.errors.state
                        ? unitFormik.errors.state
                        : undefined
                    }
                    onChange={async option => {
                      await unitFormik.setFieldValue('state', option ? option.value : '')
                      await unitFormik.setFieldValue('city', '')
                    }}
                  />

                  <CityDropdown
                    label={t('fields.city')}
                    onBlur={unitFormik.handleBlur}
                    uf={unitFormik.values.state || ''}
                    value={unitFormik.values.city || ''}
                    error={Boolean(unitFormik.touched.city && unitFormik.errors.city)}
                    onChange={option =>
                      unitFormik.setFieldValue('city', option ? option.value : '')
                    }
                    hint={
                      unitFormik.touched.city && unitFormik.errors.city
                        ? unitFormik.errors.city
                        : undefined
                    }
                  />
                </div>

                <div className='col-span-2'>
                  <TagSelector
                    label={t('fields.specialties')}
                    onRemove={handleRemoveSpecialty}
                    onSelect={handleOpenModalSpecialty}
                    selectedItems={displaySpecialties || []}
                    buttonLabel={t('fields.select_specialties')}
                  />
                </div>

                <div className='col-span-2 flex flex-col gap-6'>
                  <BlankCard className='flex flex-col'>
                    <h1 className='text-label-md text-neutralContent-primary'>
                      {t('fields.permission_type_care')}
                    </h1>

                    <div className='mt-5 flex flex-wrap items-center gap-4'>
                      {PERMISSIONS_CONSULTATION_CARE_TYPES.map(permission => (
                        <div key={permission} className=' mr-3 flex items-center'>
                          <Checkbox
                            isChecked={permissionsChecked.includes(permission)}
                            onClick={() => handleCheckboxChange(permission)}
                          />
                          <label className='ml-2 text-label-sm font-bold'>
                            {t(`permissions_units:permissions.${permission}`)}
                          </label>
                        </div>
                      ))}
                    </div>
                  </BlankCard>
                  <BlankCard className='flex flex-col'>
                    <h1 className='text-label-md text-neutralContent-primary'>
                      {t('fields.permission_type')}
                    </h1>

                    <div className='mt-5 flex flex-wrap items-center gap-4'>
                      {PERMISSIONS_CONSULTATION_TYPES.map(permission => (
                        <div key={permission} className=' mr-3 flex items-center'>
                          <Checkbox
                            isChecked={permissionsChecked.includes(permission)}
                            onClick={() => handleCheckboxChange(permission)}
                          />
                          <label className='ml-2 text-label-sm font-bold'>
                            {t(`permissions_units:permissions.${permission}`)}
                          </label>
                        </div>
                      ))}
                    </div>
                  </BlankCard>
                  <BlankCard className='flex flex-col'>
                    <h1 className='text-label-md text-neutralContent-primary'>
                      {t('fields.permissions')}
                    </h1>

                    <div className='mt-5 flex flex-wrap items-center gap-4'>
                      {PERMISSIONS_UNIT.map(permission => (
                        <div key={permission} className=' mr-3 flex items-center'>
                          <Checkbox
                            isChecked={permissionsChecked.includes(permission)}
                            onClick={() => handleCheckboxChange(permission)}
                          />
                          <label className='ml-2 text-label-sm font-bold'>
                            {t(`permissions_units:permissions.${permission}`)}
                          </label>
                        </div>
                      ))}
                    </div>
                  </BlankCard>
                </div>

                <ContentFooter className='justify-end gap-2'>
                  <Button
                    size='md'
                    type='button'
                    variant='secondary'
                    onClick={handleBack}
                    label={t('actions.cancel')}
                  />
                  <Button
                    size='md'
                    type='submit'
                    isLoading={unitFormik.isSubmitting}
                    state={!unitFormik.isValid ? 'disable' : 'enable'}
                    label={!!id && id !== 'new' ? t('actions.save') : t('actions.create')}
                  />
                </ContentFooter>
              </>
            </ContentForm>

            <SpecialtyModal
              client_id={client_id ?? ''}
              isOpen={isModalSpecialtyOpen}
              onClose={handleCloseModalSpecialty}
              onSuccess={handleSuccessSpecialties}
              selectedValues={displaySpecialties as Specialty[]}
            />
          </form>
        </Content>
      )}
    </>
  )
}

export default UnitFormPage
