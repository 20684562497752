/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, FocusEvent } from 'react'
import { useListSpecialtiesFromUnit } from '../../hooks/useSpecialty'
import InputSkeleton from '../input/skeleton'
import { Specialty } from '../../types'
import Dropdown from '.'

type Props = {
  name: string
  hint?: string
  value: string
  label: string
  error?: boolean
  unit_id?: string
  disabled?: boolean
  placeholder?: string
  onChange: (specialty?: Specialty) => void
  onBlur: (e: FocusEvent<unknown, Element>) => void
}

const UnitSpecialtiesSelect: React.FC<Props> = ({
  onChange,
  label,
  value,
  disabled,
  unit_id,
  ...props
}) => {
  const [options, setOptions] = useState<{ value: string; label: string }[]>([])

  const { data: specialties, isFetching } = useListSpecialtiesFromUnit(unit_id)

  useEffect(() => {
    setOptions(() => specialties?.map(item => ({ label: item.name!, value: item.id! })) || [])
  }, [specialties])

  useEffect(() => {
    if (!unit_id) onChange(undefined)
  }, [unit_id])

  const handleSelect = (option: { value: string; label: string } | null) => {
    if (!Boolean(option)) onChange(undefined)

    const specialty = specialties?.find(item => item.id === option?.value)
    onChange(specialty)
  }

  if (isFetching) return <InputSkeleton showLabel className='flex-1' />

  return (
    <Dropdown
      value={value}
      label={label}
      options={options}
      disabled={disabled || !unit_id}
      onChangeValue={handleSelect}
      {...props}
    />
  )
}

export default UnitSpecialtiesSelect
