/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import ClientSelect from '../../components/dropdown/client-dropdown'
import { useListConsultations } from '../../hooks/useConsultation'
import UnitSelect from '../../components/dropdown/unit-dropdown'
import { Plus } from '@phosphor-icons/react'
import ProtectedComponent from '../../components/protected'
import { useNavigate, useParams } from 'react-router-dom'
import PermissionEnum from '../../types/permissions'
import SearchField from '../../components/search'
import { useTranslation } from 'react-i18next'
import Content from '../../components/content'
import Button from '../../components/button'
import Table from '../../components/table'
import Tag from '../../components/tag'
import { format } from 'date-fns'
import {
  Unit,
  Client,
  CARE_TYPE,
  Consultation,
  PaginationParams,
  CONSULTATION_STATUS
} from '../../types'
import UnitPermissionsEnum from '../../types/unit-permissions'

const pageSize = 10

const ConsultationPage: React.FC = () => {
  const navigate = useNavigate()
  const [totalPages, setTotalPages] = useState(0)
  const { t } = useTranslation('consultation_list')
  const { care_type } = useParams<{ care_type: CARE_TYPE }>()

  const [params, setParams] = useState<PaginationParams>({ filter: '', page: 0, pageSize })
  const [client, setClient] = useState<Client>()
  const [unit, setUnit] = useState<Unit>()

  const { data: consultations, isFetching } = useListConsultations({
    ...params,
    care_type,
    unit_id: unit?.id,
    client_id: client?.id
  })

  useEffect(() => {
    if (consultations?.totalItems) {
      setTotalPages(Math.ceil(consultations.totalItems / pageSize))
    }
  }, [consultations])

  const handlePage = (newPage: number) => {
    setParams(oldState => ({ ...oldState, page: newPage }))
  }

  const getColorByStatus = (status: CONSULTATION_STATUS) => {
    switch (status) {
      case CONSULTATION_STATUS.WAITING:
        return 'text-highlightOrange-pure'
      case CONSULTATION_STATUS.FINISHED:
        return 'text-highlightGreen-pure'
      default:
        return 'text-primaryBrand-primary'
    }
  }

  const getUnitPermissionByType = (care_type: CARE_TYPE) => {
    switch (care_type) {
      case CARE_TYPE.CHAT:
        return UnitPermissionsEnum.chat_consultation
      case CARE_TYPE.ELECTIVE_CARE:
        return UnitPermissionsEnum.elective_care_consultation
      case CARE_TYPE.EMERGENCY_CARE:
        return UnitPermissionsEnum.emergency_care_consultation
    }
  }

  const columns = [
    { header: t('columns.code'), accessor: 'code', className: 'sm:max-w-24' },
    { header: t('columns.date'), accessor: 'date', className: 'sm:max-w-40' },
    { header: t('columns.status'), accessor: 'status', className: 'sm:max-w-40' },
    { header: t('columns.patient'), accessor: 'patient' },
    { header: t('columns.specialty'), accessor: 'specialty' },
    { header: t('columns.requester'), accessor: 'requester' },
    { header: t('columns.specialist'), accessor: 'specialist' },
    { header: t('columns.created_by'), accessor: 'created_by' },
    { header: t('columns.unit'), accessor: 'unit' }
  ]

  const onNavigate = (row: { id?: string }) => {
    navigate(row.id!)
  }

  const tableData =
    consultations?.data?.map((consultation: Consultation) => ({
      id: consultation.id,
      rawDate: new Date(consultation.start_date || consultation.created_at!),
      date: format(
        new Date(consultation.start_date || consultation.created_at!),
        'dd/MM/yyyy HH:mm'
      ),
      code: <Tag variant='secondary' color='neutral' label={consultation.code || ''} size='sm' />,
      patient: consultation.patient?.name,
      specialty: consultation.specialty?.name,
      created_by: consultation.created_by?.name,
      specialist: consultation?.temp_specialist?.name || consultation?.specialist?.name,
      requester: consultation.requester?.name,
      status: (
        <span className={getColorByStatus(consultation.status!)}>
          ● {t(`columns.status_type.${consultation.status}`)}
        </span>
      ),
      unit: consultation.unit?.name,
      care_type: consultation.care_type
    })) || []

  return (
    <Content
      subtitle={t('subtitle')}
      title={t(`title_${care_type}`)}
      rightContent={
        <div className='flex gap-4'>
          <ProtectedComponent
            permissions={[
              PermissionEnum.service_create_to_other_users,
              PermissionEnum.service_requester
            ]}
          >
            <Button
              size='sm'
              iconLeft={<Plus size={16} />}
              label={t('actions.new_consultation')}
              onClick={() => navigate('new')}
            />
          </ProtectedComponent>
        </div>
      }
    >
      <div className={`flex flex-col`}>
        <div className='mb-4'>
          <SearchField
            value={params.filter || ''}
            placeholder='Busque por um paciente ou código de atendimento'
            onChange={event => setParams(old => ({ ...old, filter: event.target.value }))}
          />
        </div>

        <Table
          //@ts-expect-error
          data={tableData}
          columns={columns}
          isLoading={isFetching}
          totalPages={totalPages}
          currentPage={params.page}
          onPageChange={handlePage}
          onClickRow={row => onNavigate(row)}
          className='max-h-[calc(100dvh-300px)] md:max-h-[calc(100dvh-320px)]'
          sort={[
            'date',
            'patient',
            'specialty',
            'status',
            'unit',
            'created_by',
            'specialist',
            'requester',
            'code'
          ]}
        />
      </div>
    </Content>
  )
}

export default ConsultationPage
