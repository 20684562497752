import React, { useEffect, useState } from 'react'
import Modal from '../../../components/modal'
import Dropdown, { DropdownOption } from '../../../components/dropdown'
import InputField from '../../../components/input'
import Switch from '../../../components/switch'
import Button from '../../../components/button'
import { XCircle, ArrowUp, ArrowDown } from '@phosphor-icons/react'
import Checkbox from '../../../components/checkbox'
import Radio from '../../../components/radio'
import { QuestionOption } from '../../../types'

const TYPES_WITH_OPTIONS = ['radio', 'checkbox', 'dropdown']

interface QuestionModalProps {
  isOpen: boolean
  onClose: () => void
  onAddQuestion: (question: QuestionOption) => void
  questionToEdit?: QuestionOption
}

const questionType: DropdownOption[] = [
  { value: 'textarea', label: 'Resposta aberta' },
  { value: 'checkbox', label: 'Múltipla escolha' },
  { value: 'radio', label: 'Caixa de seleção' },
  { value: 'dropdown', label: 'Lista suspensa' }
]

const QuestionModal: React.FC<QuestionModalProps> = ({
  isOpen,
  onClose,
  onAddQuestion,
  questionToEdit
}) => {
  const [newQuestion, setNewQuestion] = useState<QuestionOption>()

  useEffect(() => {
    if (!isOpen) {
      setNewQuestion(undefined)
    } else if (questionToEdit) {
      setNewQuestion({
        title: questionToEdit.title,
        type: questionToEdit.type,
        required: questionToEdit.required,
        options: questionToEdit.options
      })
    } else {
      setNewQuestion({
        type: '',
        title: '',
        options: [],
        required: false
      })
    }
  }, [isOpen, questionToEdit])

  const handleOptionChange = (index: number, value: string) => {
    const options = [...(newQuestion?.options || [])]
    options[index] = value
    setNewQuestion({ ...newQuestion, options })
  }

  const handleAddOption = () => {
    setNewQuestion({
      ...newQuestion,
      options: [...(newQuestion?.options || []), '']
    })
  }

  const handleRemoveOption = (index: number) => {
    const options = newQuestion?.options!.filter((_, i) => i !== index)
    setNewQuestion({ ...newQuestion, options })
  }
  const moveOptionUp = (index: number) => {
    if (index > 0) {
      const options = [...(newQuestion?.options || [])]
      const temp = options[index - 1]
      options[index - 1] = options[index]
      options[index] = temp
      setNewQuestion({ ...newQuestion, options })
    }
  }

  const moveOptionDown = (index: number) => {
    if (newQuestion && index < newQuestion.options!.length - 1) {
      const options = [...newQuestion.options!]
      const temp = options[index + 1]
      options[index + 1] = options[index]
      options[index] = temp
      setNewQuestion({ ...newQuestion, options })
    }
  }

  const handleAddQuestion = () => {
    if (newQuestion) onAddQuestion(newQuestion)
    onClose()
  }

  const renderOptionPreview = (type: string) => {
    switch (type) {
      case 'checkbox':
        return <Checkbox isChecked={false} state='disable' className='ml-2' onClick={() => {}} />
      case 'radio':
        return <Radio size='md' selected={false} state='enabled' className='ml-2' />
      default:
        return null
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={questionToEdit ? 'Editar Questão' : 'Adicionar Questão'}
    >
      {newQuestion && (
        <div className='w-[370px] space-y-4 p-4'>
          <Dropdown
            onChangeValue={option => setNewQuestion({ ...newQuestion, type: option?.value || '' })}
            value={newQuestion.type || ''}
            name='type'
            label='Tipo da questão'
            options={questionType}
          />

          <InputField
            name='title'
            value={newQuestion.title || ''}
            onBlur={() => {}}
            onChange={e => setNewQuestion({ ...newQuestion, title: e.target.value })}
            label='Título da questão'
          />
          {TYPES_WITH_OPTIONS.includes(newQuestion.type || '') && (
            <div className='w-[400px]'>
              <label className='label-md block font-bold text-neutralContent-primary'>Opções</label>
              {newQuestion.options?.map((option, index) => (
                <div key={index} className='mb-2 flex items-center'>
                  <div className='flex justify-between'>
                    <div className='flex items-center'>
                      <div className='flex gap-2'>
                        <ArrowUp size={18} onClick={() => moveOptionUp(index)} />
                        <ArrowDown size={18} onClick={() => moveOptionDown(index)} />
                      </div>
                      {renderOptionPreview(newQuestion.type!)}
                      <InputField
                        value={option}
                        onChange={e => handleOptionChange(index, e.target.value)}
                        className='ml-2 flex-1'
                      />
                    </div>
                    <div className='ml-2 flex items-center'>
                      <Button
                        label=''
                        variant='tertiary'
                        onClick={() => handleRemoveOption(index)}
                        iconLeft={<XCircle size={20} />}
                        className='ml-2'
                      />
                    </div>
                  </div>
                </div>
              ))}
              <Button label='+ Adicionar opção' variant='tertiary' onClick={handleAddOption} />
            </div>
          )}
          <div className='flex items-center gap-2'>
            <Switch
              isChecked={!!newQuestion.required}
              onChange={() => setNewQuestion({ ...newQuestion, required: !newQuestion.required })}
            />
            <span className='text-body-md'>Obrigatória</span>
          </div>
          <div className='flex justify-end gap-2'>
            <Button label='Cancelar' variant='tertiary' onClick={onClose} />
            <Button
              type='button'
              label={questionToEdit ? 'Salvar' : 'Adicionar'}
              variant='primary'
              onClick={handleAddQuestion}
            />
          </div>
        </div>
      )}
    </Modal>
  )
}

export default QuestionModal
