import React from 'react'
import { FileContentProps } from './types'
import { ImageContent } from './image-content'

export const FileContent: React.FC<FileContentProps> = ({
  fileUrl,
  type,
  document,
  handleError,
  isSupported
}) => {
  if (!isSupported)
    return (
      <p className={'m-auto text-center'}>Não é possível visualizar esse formato de arquivo. </p>
    )

  if (!fileUrl) {
    return <p className={'m-auto text-center'}>Carregando arquivo...</p>
  }

  if (type === 'video') {
    return (
      <video
        src={fileUrl}
        controls
        className='m-auto max-h-[500px] max-w-full object-contain'
        onError={handleError}
      />
    )
  }

  return <ImageContent fileUrl={fileUrl} document={document} handleError={handleError} />
}
