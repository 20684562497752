import React from 'react'

const AnamnesisTabSkeleton: React.FC = () => {
  return (
    <div className='col-span-2 mb-3 mt-3 flex flex-col gap-2'>
      {/* Skeleton for Tabs */}
      <div className='flex items-center gap-4'>
        <div className='h-8 w-24 animate-pulse rounded bg-neutralBorder-default' />
        <div className='h-8 w-32 animate-pulse rounded bg-neutralBorder-default' />
      </div>
      {/* Skeleton for BlankCard */}
      <div className='mt-4 w-full animate-pulse rounded-xl border border-neutralBorder-default p-4'>
        <div className='space-y-4'>
          <div className='h-4 w-3/4 rounded bg-neutralBorder-default' />
          <div className='h-4 w-1/2 rounded bg-neutralBorder-default' />
          <div className='h-4 w-full rounded bg-neutralBorder-default' />
        </div>
      </div>

      {/* Skeleton for ContentFooter */}
      <div className='mt-4 flex justify-end'>
        <div className='h-10 w-32 animate-pulse rounded bg-neutralBorder-default' />
      </div>
    </div>
  )
}

export default AnamnesisTabSkeleton
