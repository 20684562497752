const CheckboxSkeleton: React.FC = () => {
  return (
    <div className='flex items-center'>
      <div className='mr-2 h-4 w-4 rounded bg-gray-300' />
      <div className='h-5 w-36 rounded bg-gray-300' />
    </div>
  )
}

export default CheckboxSkeleton
