import { CARE_TYPE, PaginationParams, PaginationResponse, User } from '../types'
import api from './api.service'
import { validate } from 'uuid'

class UserService {
  static create(body: Partial<User>) {
    return api.post<User>('/user', body)
  }

  static async getById(id: string) {
    if (!validate(id)) return undefined

    const response = await api.get<User>('/user/' + id)
    return response.data
  }

  static async listRequesters(care_type: CARE_TYPE, unit_id: string) {
    const response = await api.get<User[]>(`user/requester/${care_type}/${unit_id}`)
    return response.data
  }

  static async listSpecialists(params: {
    name?: string
    unit_id?: string
    unit_ids?: string[]
    specialties?: string[]
    without_fix_agenda?: boolean
    without_custom_agenda?: boolean
  }) {
    const response = await api.get<User[]>(`user/specialist`, {
      params
    })
    return response.data
  }

  static async list(
    params: PaginationParams & {
      is_health_professional?: boolean
      without_agenda?: boolean
      with_agenda?: boolean
      client_id?: string
      unit_id?: string
    }
  ) {
    const response = await api.get<PaginationResponse<User>>(`/user`, { params })
    return response.data
  }

  static update(id: string, body: Partial<User>) {
    return api.put<User>('/user/' + id, body)
  }

  static updateMe(body: User) {
    return api.put<User>('/user', body)
  }

  static updatePassword(body: { password: string }, token?: string) {
    return api.patch<User>(
      '/user/password',
      body,
      token ? { headers: { Authorization: `Bearer ${token}` } } : undefined
    )
  }
}

export default UserService
