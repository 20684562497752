const consultation_form_enUS = {
  title_update_elective_care: 'Edit elective care consultation',
  title_elective_care: 'New elective care consultation',
  title_update_emergency_care: 'Edit emergency care consultation',
  title_emergency_care: 'New emergency care consultation',
  title_update_chat: 'Edit chat consultation',
  title_chat: 'New chat consultation',

  subtitle: 'View and manage consultations',
  fields: {
    type: 'Consultation type',
    client: 'Client',
    unit: 'Unit',
    patient: 'Patient',
    symptoms: 'Symptoms',
    clinical_history: 'Clinical history',
    specialty: 'Specialty',
    requester: 'Requester'
  },
  placeholder: {
    symptoms: "Describe the patient's symptoms",
    clinical_history: "Briefly describe the patient's clinical history"
  },
  actions: {
    select_an_option: 'Select an option',
    select_pacient: 'Select patient',
    save: 'Save consultation',
    cancel: 'Cancel',
    exclude: 'Delete consultation',
    confirm: 'Confirm'
  },

  modal_confirmation_update_consultation: {
    title: 'Edit consultation',
    decription1: 'Are you sure you want to edit this consultation?',
    decription2: "To save the new date/time, please type the patient's name:",
    input_placeholder: "Patient's name"
  }
}

export default consultation_form_enUS
