import React from 'react'
import DatePicker from '../../../components/datepicker'
import Button from '../../../components/button'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthService from '../../../services/auth.service'
import { useParams } from 'react-router-dom'
import { CARE_TYPE } from '../../../types'
import StorageService from '../../../services/storage.service'

interface Props {
  onSuccess: () => void
}

const BirthdateValidator: React.FC<Props> = ({ onSuccess }) => {
  const { id: consultationId } = useParams<{ id: string; care_type: CARE_TYPE }>()
  const formik = useFormik({
    initialValues: {
      birthdate: ''
    },
    validationSchema: Yup.object({
      birthdate: Yup.string().required('Data de nascimento é obrigatório')
    }),
    enableReinitialize: true,
    onSubmit: async (values, { setErrors }) => {
      await AuthService.patientLogin({
        consultationId: consultationId!,
        birthdate: new Date(values.birthdate).toISOString().split('T')[0]
      })
        .then(response => {
          StorageService.saveToken(response.data.token)
          onSuccess()
        })
        .catch(() => {
          setErrors({ birthdate: 'Data de nascimento incorreta' })
        })
    }
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='mx-auto flex h-full  w-full flex-col items-center gap-[48px] px-6 sm:w-full md:w-[50%] lg:w-[50%]'>
        <div className='mt-[20vh] flex flex-col gap-[30px] text-center text-xl font-bold leading-8 sm:text-xl md:text-xl lg:text-2xl'>
          <div>
            Para garantir a privacidade dos dados médicos e o acesso seguro à sua consulta, pedimos
            uma confirmação simples.
          </div>
          <div>Insira sua data de nascimento para continuar:</div>
        </div>

        <DatePicker
          name='birthdate'
          className='w-full md:w-[30vw] lg:w-[30vw]'
          placeholder='Data de nascimento'
          onChange={val => formik.setFieldValue('birthdate', val)}
          onBlur={formik.handleBlur}
          value={!!formik.values.birthdate ? new Date(formik.values.birthdate) : null}
          disabled={formik.isSubmitting}
          error={formik.errors.birthdate}
          touched={formik.touched.birthdate}
        />

        <Button
          type='submit'
          className='mx-auto w-full md:w-[30vw] lg:w-[30vw]'
          isLoading={formik.isSubmitting}
          state={formik.isValid ? 'enable' : 'disable'}
          label={'Acessar videochamada'}
        />
      </div>
    </form>
  )
}

export default BirthdateValidator
