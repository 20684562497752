import React from 'react'
import { File } from '../../../../../../types'
import { useToastContext } from '../../../../../../contexts/toast'
import FileService from '../../../../../../services/file.service'
import { saveAs } from 'file-saver'
import Tag from '../../../../../../components/tag'
import { DownloadSimple, Eye } from '@phosphor-icons/react'
import { Tooltip } from 'react-tooltip'

interface Props {
  document: File
  onOpenFile: () => void
}

export const FileTag: React.FC<Props> = ({ document, onOpenFile }) => {
  const { toast } = useToastContext()

  const handleViewClick = async () => {
    onOpenFile()
  }

  const handleDownloadClick = async () => {
    try {
      const response = await FileService.download(document.id!)

      const blob = new Blob([response.data], { type: response.data.type })
      saveAs(blob, document.file_name ?? 'document')
    } catch (error) {
      toast.error(`Erro ao tentar baixar o documento ${error}`)
    }
  }

  return (
    <>
      <Tag
        label={document.file_name ?? ''}
        color='blue'
        variant='secondary'
        rightContent={
          <>
            <span
              data-tooltip-id='download-tooltip'
              data-tooltip-content='Baixar documento'
              onClick={handleDownloadClick}
              style={{ cursor: 'pointer' }}
            >
              <DownloadSimple />
            </span>
            <span
              data-tooltip-id='view-tooltip'
              data-tooltip-content='Visualizar documento'
              onClick={handleViewClick}
              style={{ cursor: 'pointer' }}
            >
              <Eye />
            </span>
            <Tooltip id='download-tooltip' />
            <Tooltip id='view-tooltip' />
          </>
        }
      />
    </>
  )
}
