const user_form_enUS = {
  PERSONAL_INFORMATION: {
    title: 'Personal Information',
    subtitle: "Fill in the user's personal information"
  },
  CONTACT: {
    title: 'Contact',
    subtitle: "Fill in the user's contact information"
  },
  ADDRESS: {
    title: 'Address',
    subtitle: "Fill in the user's address information"
  },
  DOCUMENTS: {
    title: 'Documents',
    subtitle: "Fill in the user's documents"
  },
  PROFILE: {
    title: 'Access Profiles',
    subtitle: 'Select the access profile for this user on the platform'
  },
  fields: {
    name: 'Full name',
    social_name: 'Nickname (optional)',
    birthdate: 'Date of birth',
    mother_unknown: 'Mother unknown',
    mother_name: 'Full name of mother',
    sex: 'Sex (optional)',
    gender: 'Gender (optional)',
    race: 'Race/Color (optional)',
    nationality: 'Nationality',
    birth_state: 'Birth state',
    birth_city: 'Birth city',
    naturalization_date: 'Naturalization date',
    birth_country: 'Birth country',
    passport_country: 'Passport issuing country',
    passport_number: 'Passport number',
    user_from_client: 'User from a client',
    email: 'Email',
    phone: 'Phone number',
    cell: 'Cell number',
    phone_type: 'Phone type',
    address: 'Street',
    address_number: 'Number',
    complement: 'Complement',
    state: 'State',
    city: 'City',
    district: 'District',
    document: 'CPF',
    cns: 'CNS',
    profile: 'Profile'
  },
  selects: {
    nationality: {
      BR: 'Brazilian',
      OTHER: 'Other'
    },
    sexes: {
      masculine: 'Male',
      feminine: 'Female'
    },
    genders: {
      cisgender_woman: 'Cisgender Woman',
      transgender_woman: 'Transgender Woman',
      cisgender_man: 'Cisgender Man',
      transgender_man: 'Transgender Man',
      non_binary: 'Non-binary',
      other: 'Other'
    },
    races: {
      white: 'White',
      black: 'Black',
      brown: 'Brown',
      yellow: 'Yellow',
      indigenous: 'Indigenous',
      no_information: 'No Information'
    },
    phone_types: {
      cell: 'Cell',
      phone: 'Landline'
    }
  },
  actions: {
    cancel: 'Cancel',
    continue: 'Continue',
    finish: 'Finish registration'
  }
}

export default user_form_enUS
