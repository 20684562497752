import React, { useState } from 'react'
import { Button } from 'semente-js'
import { ImageContentProps } from './types'

interface DragPosition {
  x: number
  y: number
  previousXOffset: number
  previousYOffset: number
}

export const ImageContent: React.FC<ImageContentProps> = ({ fileUrl, document, handleError }) => {
  const [draggingState, setDraggingState] = useState<'mouseDown' | 'dragging' | 'none'>('none')
  const [dragStartingPosition, setDragStartingPosition] = useState<DragPosition>({
    x: 0,
    y: 0,
    previousXOffset: 0,
    previousYOffset: 0
  })
  const [zoomStyle, setZoomStyle] = useState({
    transformOrigin: '',
    cursor: 'zoom-in ',
    scale: '1',
    top: '0px',
    left: '0px'
  })

  const resetDragging = () => {
    setZoomStyle(prev => {
      return { ...prev, top: '0px', left: '0px' }
    })

    setDragStartingPosition(prev => {
      return { ...prev, previousXOffset: 0, previousYOffset: 0 }
    })
  }

  const setZoom = (
    zoom: number,
    options?: {
      origin?: string
      top?: string
      left?: string
    }
  ) => {
    const { origin, top, left } = options || {}
    const cursor = zoom > 1 ? 'zoom-out' : 'zoom-in'

    setZoomStyle(prev => {
      return {
        transformOrigin: origin || prev.transformOrigin,
        cursor,
        scale: String(zoom),
        top: top || prev.top,
        left: left || prev.left
      }
    })
  }

  const handleImagePosition = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const imageElement = e.target as HTMLImageElement
    const { left, top, width, height } = imageElement.getBoundingClientRect()
    const x = ((e.clientX - left) / width) * 100
    const y = ((e.clientY - top) / height) * 100

    const getAxisPosition = (
      axis: number,
      { whenLow, whenHigh }: { whenLow: string; whenHigh: string }
    ) => {
      if (axis >= 33 && axis < 66) return 'center'
      return axis > 50 ? whenHigh : whenLow
    }

    const xOrigin = getAxisPosition(x, {
      whenHigh: 'right',
      whenLow: 'left'
    })

    const yOrigin = getAxisPosition(y, {
      whenHigh: 'bottom',
      whenLow: 'top'
    })

    const transformOrigin = `${yOrigin} ${xOrigin}`

    setZoom(2, {
      origin: transformOrigin
    })
  }

  return (
    <>
      <img
        src={fileUrl}
        alt={document.file_name}
        draggable={false}
        onMouseUp={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          if (draggingState === 'dragging') {
            setDraggingState('none')
            setDragStartingPosition(prev => {
              const getPreviousOffset = (value: string) => Number(value.replace('px', ''))
              return {
                ...prev,
                previousXOffset: getPreviousOffset(zoomStyle.left),
                previousYOffset: getPreviousOffset(zoomStyle.top)
              }
            })
            return
          }
          setDraggingState('none')
          if (zoomStyle.scale === '1') {
            handleImagePosition(e)
            return
          }
          setZoom(1)
        }}
        onMouseDown={() => {
          setDraggingState('mouseDown')
        }}
        onMouseMove={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          if (draggingState === 'mouseDown') {
            setDraggingState('dragging')

            setDragStartingPosition(prev => ({
              ...prev,
              x: e.clientX,
              y: e.clientY
            }))
          }

          if (draggingState === 'dragging') {
            const xOffset =
              dragStartingPosition.previousXOffset + e.clientX - dragStartingPosition.x
            const yOffset =
              dragStartingPosition.previousYOffset + e.clientY - dragStartingPosition.y

            setZoomStyle(prev => {
              return {
                ...prev,
                top: `${yOffset}px`,
                left: `${xOffset}px`
              }
            })
          }
        }}
        className={`relative m-auto max-h-full max-w-[80%] object-contain transition-transform duration-300`}
        style={{
          ...zoomStyle,
          ...(draggingState === 'dragging' && {
            cursor: 'grabbing'
          })
        }}
        onError={handleError}
      />

      <div className={'absolute right-4 mt-4 flex flex-col gap-4'}>
        <Button
          layout='circle'
          className={'bg-gray-400'}
          iconName={'magnifying-glass-plus'}
          onClick={() => {
            const previousScale = Number(zoomStyle.scale)
            const newZoom = previousScale * 2
            setZoom(newZoom, {
              origin: 'center center'
            })
          }}
        />
        <Button
          layout='circle'
          className={'bg-gray-400'}
          iconName={'magnifying-glass-minus'}
          onClick={() => {
            const previousScale = Number(zoomStyle.scale)
            const newZoom = previousScale / 2
            setZoom(newZoom, {
              origin: 'center center'
            })
            resetDragging()
          }}
        />
      </div>
    </>
  )
}
