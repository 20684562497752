const unit_type_enUS = {
  title: 'Unit Types',
  columns: {
    name: 'Unit Type Name'
  },
  fields: {
    search: 'Search by type name...'
  },
  actions: {
    add: 'New Unit Type',
    update: 'Edit'
  }
}

export default unit_type_enUS
