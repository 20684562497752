import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import CouncilService from '../services/council.service'
import * as Yup from 'yup'
import { Council } from '../types'
import { useFormik } from 'formik'
import { useToastContext } from '../contexts/toast'

export const useListCouncil = (page: number, pageSize: number, filter: string) => {
  return useQuery({
    queryKey: ['councilList', { page, pageSize, filter }],
    queryFn: () => CouncilService.listCouncil({ page, pageSize, filter }),
    placeholderData: prev => prev
  })
}

export const useListAllCouncilsWithSpecialties = () => {
  return useQuery({
    queryKey: ['councilListAllWithSpecialties'],
    queryFn: () => CouncilService.listAllWithSpecialtiesCouncil(),
    placeholderData: prev => prev
  })
}

export const useCouncilFormik = (
  council: Partial<Council> | null | undefined,
  closeModal: () => void,
  isExempt: boolean
) => {
  const { toast } = useToastContext()

  const initialValues = {
    name: council?.name || '',
    document_name: council?.document_name || '',
    is_active: council ? council?.is_active : true
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('Preencha o nome'),
    document_name: isExempt
      ? Yup.string()
      : Yup.string().required('Preencha o documento de registro profissional'),
    is_active: Yup.string().required('Status do conselho deve ser informado')
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async values => {
      if (council) {
        await CouncilService.updateCouncil(council?.id as string, values)
        toast.success('Conselho atualizado')
      } else {
        await CouncilService.createCouncil(values)
        toast.success('Conselho criado')
      }
      closeModal()
    }
  })

  return {
    formik
  }
}

export const useDeleteCouncil = (
  council: Council | null | undefined,
  closeModal: () => void,
  setMode: (value: 'edit' | 'delete') => void
) => {
  const { toast } = useToastContext()
  const queryClient = useQueryClient()

  const formik = useFormik({
    initialValues: { name: '' },
    validationSchema: Yup.object({
      name: Yup.string().required('É necessário confirmar o nome para excluir')
    }),
    onSubmit: values => {
      if (values.name === council?.name) {
        deleteMutation.mutate()
      } else {
        formik.setFieldError('name', 'Nome do conselho incorreto')
        formik.setSubmitting(false)
      }
    }
  })

  const deleteMutation = useMutation({
    mutationFn: () => CouncilService.deleteCouncil(council?.id as string),
    onSuccess: () => {
      toast.success('Conselho excluído')
      queryClient.invalidateQueries({ queryKey: ['councilList'] })
      closeModal()
      setMode('edit')
    },
    onError: error => {
      toast.error(error.message || 'Algo deu errado')
    }
  })

  return formik
}
