import Checkbox from '../../../../components/checkbox'
import DatePicker from '../../../../components/datepicker'
import Radio from '../../../../components/radio'
import InputField from '../../../../components/input'
import TextareaField from '../../../../components/text-area'
import { QuestionOption } from '../../../../types'
import { interpolateColor } from '../../../../helpers/interpolateColor'

type Props = {
  question: QuestionOption
  values: { [key: string]: string[] | string | null | undefined }
  handleValue: (newValues: { [key: string]: string[] | string | null | undefined }) => void
}

const AnyQuestion: React.FC<Props> = ({ question, handleValue, values }) => {
  const handleYesNoChange = (value: ' ' | 'nao' | undefined) => {
    handleValue({
      ...values,
      [question.id!]: value
    })
  }

  const handleInputChange = (value: string) => {
    handleValue({
      ...values,
      [`${question.id!}_input`]: value
    })
  }

  if (question.type === 'checkbox') {
    const currentValues = (values[question.id!] as string[]) || []

    const handleCheckboxChange = (option: string) => {
      if (currentValues.includes(option)) {
        handleValue({
          ...values,
          [question.id!]: currentValues.filter(value => value !== option)
        })
      } else {
        handleValue({
          ...values,
          [question.id!]: [...currentValues, option]
        })
      }
    }

    return (
      <div className='mt-2 flex flex-wrap items-center gap-2'>
        {question.yes_no && (
          <>
            <div className='mt-2 flex items-center gap-2 space-x-2'>
              <Radio
                size='md'
                state='enabled'
                selected={values[question.id!] === ' '}
                onChange={() => handleYesNoChange(' ')}
              />
              Sim
            </div>
            <div className='mt-2 flex items-center gap-2 space-x-2'>
              <Radio
                size='md'
                state='enabled'
                selected={values[question.id!] === 'nao'}
                onChange={() => handleYesNoChange('nao')}
              />
              Não
            </div>
          </>
        )}
        {question.options?.map((option, index) => (
          <div key={index} className='mt-2 flex items-center space-x-2'>
            <Checkbox
              isChecked={currentValues.includes(option)}
              onClick={() => handleCheckboxChange(option)}
            />
            <span className='mx-2 text-label-md font-bold'>{option}</span>
          </div>
        ))}
      </div>
    )
  }

  if (question.type === 'date') {
    return (
      <div className='mt-2 w-[300px]'>
        <DatePicker
          name={question.id!}
          placeholder='Selecione uma data'
          value={values[question.id!] ? new Date(values[question.id!] as string) : null}
          onChange={date => handleValue({ ...values, [question.id!]: date?.toJSON() })}
        />
      </div>
    )
  }

  if (question.type === 'scale') {
    const min = question.min || 1
    const max = question.max || 10
    const colors = ['#3E7952', '#F5A300', '#FF8333', '#B81435']

    return (
      <div className='mt-2'>
        <div className='flex items-center gap-2'>
          {Array.from({ length: max - min + 1 }, (_, i) => min + i).map(value => (
            <div key={value} className='flex flex-col items-center px-2'>
              <Radio
                size='md'
                selected={values[question.id!] === String(value)}
                state='enabled'
                onChange={() => handleValue({ ...values, [question.id!]: String(value) })}
                borderColor={interpolateColor(value, min, max, colors)}
              />
              <span className='mt-1 text-label-md font-bold'>{value}</span>
            </div>
          ))}
        </div>
      </div>
    )
  }

  if (question.type === 'radio') {
    return (
      <div className='mt-2 flex'>
        {question.options?.map((option, index) => (
          <div key={index} className='flex items-center gap-2 px-2'>
            <Radio
              size='md'
              state='enabled'
              selected={values[question.id!] === option}
              onChange={() => handleValue({ ...values, [question.id!]: option })}
            />
            <span className='text-label-md font-bold'>{option}</span>
          </div>
        ))}
      </div>
    )
  }

  if (question.type === 'textarea' || question.type === 'text') {
    return (
      <div className='flex flex-col gap-4'>
        {question.yes_no && (
          <div className='flex gap-4'>
            <div className='mt-2 flex items-center gap-2 space-x-2'>
              <Radio
                size='md'
                state='enabled'
                selected={values[question.id!] === ' '}
                onChange={() => handleYesNoChange(' ')}
              />
              Sim
            </div>
            <div className='mt-2 flex items-center gap-2 space-x-2'>
              <Radio
                size='md'
                state='enabled'
                selected={values[question.id!] === 'nao'}
                onChange={() => handleYesNoChange('nao')}
              />
              Não
            </div>
          </div>
        )}
        {values[question.id!] === ' ' &&
          (question.type === 'textarea' ? (
            <TextareaField
              placeholder='Descreva'
              value={
                values[`${question.id!}_input`] ? (values[`${question.id!}_input`] as string) : ''
              }
              onChange={e => handleInputChange(e.target.value)}
            />
          ) : (
            <InputField
              value={
                values[`${question.id!}_input`] ? (values[`${question.id!}_input`] as string) : ''
              }
              onChange={e => handleInputChange(e.target.value)}
            />
          ))}
        {!question.yes_no &&
          (question.type === 'textarea' ? (
            <TextareaField
              placeholder='Descreva'
              value={values[question.id!] as string}
              onChange={e => handleValue({ ...values, [question.id!]: e.target.value })}
            />
          ) : (
            <InputField
              value={values[question.id!] as string}
              onChange={e => handleValue({ ...values, [question.id!]: e.target.value })}
            />
          ))}
      </div>
    )
  }

  return (
    <div className='flex flex-col gap-4'>
      {question.yes_no && (
        <div className='flex gap-4'>
          <div className='mt-2 flex items-center gap-2 space-x-2'>
            <Radio
              size='md'
              state='enabled'
              selected={values[question.id!] === ' '}
              onChange={() => handleYesNoChange(' ')}
            />
            Sim
          </div>
          <div className='mt-2 flex items-center gap-2 space-x-2'>
            <Radio
              size='md'
              state='enabled'
              selected={values[question.id!] === 'nao'}
              onChange={() => handleYesNoChange('nao')}
            />
            Não
          </div>
        </div>
      )}
      {values[question.id!] === ' ' && (
        <InputField
          value={values[`${question.id!}_input`] ? (values[`${question.id!}_input`] as string) : ''}
          onChange={e => handleInputChange(e.target.value)}
        />
      )}
    </div>
  )
}

export default AnyQuestion
