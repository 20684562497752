import Content from '../../components/content'

const NotificationsPage: React.FC = () => {
  return (
    <Content title='Notificações'>
      <></>
    </Content>
  )
}

export default NotificationsPage
