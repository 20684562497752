import { Link, useNavigate, useLocation } from 'react-router-dom'
import { ROUTES } from '../../routes/path'
import { useAuth } from '../../contexts/auth'
import { useEffect, useState } from 'react'
import LoginForm from './login-form'
import PasswordForm from '../forgotPassword/password-form'
import StorageService from '../../services/storage.service'
import { User } from '../../types'

const LoginPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user, client, logo, logout } = useAuth()
  const [showUpdatePassword, setShowUpdatePassword] = useState(false)

  const from = location.state?.from

  useEffect(() => {
    if (user && !user.force_update_password) {
      navigate(ROUTES.home)
    } else {
      setShowUpdatePassword(!!user?.force_update_password)
    }
  }, [user, navigate])

  const handleLoginSuccess = (loggedUser?: User) => {
    if (loggedUser && !loggedUser.force_update_password) {
      navigate(from || ROUTES.home)
    } else {
      setShowUpdatePassword(!!loggedUser?.force_update_password)
    }
  }

  return (
    <div
      className='flex min-h-screen flex-col items-center justify-center'
      style={{ background: client?.primary_color || '#007BBD' }}
    >
      <div className='mx-10 w-full max-w-[500px] rounded-3xl bg-white p-4 pb-14'>
        {logo ? (
          <img src={logo} alt='L2D' className='mx-auto mb-14 mt-8 h-14' />
        ) : (
          <div className='mx-auto mb-14 mt-8 h-14' />
        )}

        {showUpdatePassword ? (
          <>
            <span className='mb-10 block w-full text-center text-label-lg text-primaryBrand-primary'>
              {'Atualize sua senha para finalizar o cadastro'}
            </span>

            <PasswordForm
              onCancel={logout}
              onSuccess={() => {
                navigate(ROUTES.home)
              }}
              token={StorageService.getToken() || ''}
            />
          </>
        ) : (
          <>
            <LoginForm onSuccess={handleLoginSuccess} />
            <div className='mt-8 flex justify-center'>
              Esqueceu sua senha?
              <Link
                to={ROUTES.forgotPassword}
                className='ml-2 text-primaryBrand-primary underline transition-all hover:opacity-70'
              >
                Clique aqui
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default LoginPage
