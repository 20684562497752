import { useEffect, useState } from 'react'
import {
  useGetConsultation,
  useStartConsultation,
  useFinishConsultation
} from '../../../hooks/useConsultation'
import { ConfirmationExitCallModal, ConfirmationFinishCallModal } from './DialogsConfirmationCall'
import { ConsultationDetailsSkeleton } from './skeleton/ConsultationDetailsSkeleton'
import { CARE_TYPE, Consultation, CONSULTATION_STATUS } from '../../../types'
import { Check, DoorOpen, PencilSimple } from '@phosphor-icons/react'
import ProtectedComponent from '../../../components/protected'
import { useNavigate, useParams } from 'react-router-dom'
import { useToastContext } from '../../../contexts/toast'
import Connection from '../../../components/connection'
import ConsultationSummary from './ConsultationSummary'
import { ChatConnection } from '../chat/ChatConnection'
import PermissionEnum from '../../../types/permissions'
import Content from '../../../components/content'
import Button from '../../../components/button'
import { useTranslation } from 'react-i18next'
import { ROUTES } from '../../../routes/path'
import PatientDetails from './PatientDetails'
import CameraPreview from './CameraPreview'
import ConsultationTabs from './tabs'

const ConsultationDetailsPage: React.FC = () => {
  const { t } = useTranslation('consultation_details')
  const { id, care_type } = useParams<{ id: string; care_type: CARE_TYPE }>()
  const navigate = useNavigate()

  const { toast } = useToastContext()
  const [isStarted, setIsStarted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [openCameraPreview, setCameraPreview] = useState(false)
  const [consultation, setConsultation] = useState<Consultation>()
  const [openConfirmationFinishModal, setOpenConfirmationFinishModal] = useState(false)
  const [openConfirmationExitModal, setOpenConfirmationExitModal] = useState(false)

  const { data, refetch } = useGetConsultation(id)
  const { mutate: startConsultation } = useStartConsultation()
  const { mutate: finishConsultation } = useFinishConsultation()

  useEffect(() => {
    setConsultation(data)
  }, [data])

  const handleBack = () => {
    if (openCameraPreview) setCameraPreview(false)
    else {
      navigate(ROUTES.consultation.list.replace(':care_type', care_type!))
    }
  }

  const handleEdit = () => {
    navigate(ROUTES.consultation.edit.replace(':id', id!).replace(':care_type', care_type!))
  }

  const onFinishCall = () => {
    setIsLoading(true)
    setIsStarted(false)
    setCameraPreview(false)

    finishConsultation(id!, {
      onSuccess: data => {
        setConsultation(data)
        setIsLoading(false)
      },
      onError: () => {
        setIsLoading(false)
      }
    })
  }

  const onFinishConnection = async () => {
    setConsultation(undefined)
    await refetch()
  }

  const handleStartConsultation = () => {
    if (consultation?.status === CONSULTATION_STATUS.FINISHED) return

    if (
      consultation?.status === CONSULTATION_STATUS.SCHEDULED ||
      consultation?.status === CONSULTATION_STATUS.WAITING
    ) {
      setIsLoading(true)

      startConsultation(id!, {
        onSuccess: data => {
          if (data.care_type !== CARE_TYPE.CHAT) {
            setCameraPreview(true)
          } else {
            setIsStarted(true)
          }

          setIsLoading(false)
          setConsultation(data)
        },
        onError: () => {
          setIsLoading(false)

          toast.error('Não foi possível iniciar a chamada')
        }
      })
    }

    if (consultation?.status === CONSULTATION_STATUS.IN_ROOM) {
      if (consultation.care_type === CARE_TYPE.CHAT) {
        setIsStarted(true)
      } else {
        setIsStarted(false)
        setCameraPreview(true)
      }
    }
  }

  const handleExitCall = () => {
    setCameraPreview(false)
    setIsStarted(false)
  }

  const leftContent = () => {
    return (
      <div className='flex items-center gap-4'>
        <Button
          size='sm'
          variant='primary'
          onClick={() => setOpenConfirmationExitModal(true)}
          label={t('actions.exit_call')}
          iconLeft={<DoorOpen size={16} />}
        />
        <Button
          size='sm'
          variant='primary'
          state='error'
          onClick={() => setOpenConfirmationFinishModal(true)}
          label={t('actions.finish_call')}
          iconLeft={<Check size={16} />}
        />
      </div>
    )
  }

  const rightContent = () => {
    return (
      <div className='flex items-center gap-2'>
        {consultation?.status === CONSULTATION_STATUS.SCHEDULED && (
          <ProtectedComponent
            permissions={[
              PermissionEnum.service_create_to_other_users,
              PermissionEnum.service_requester
            ]}
          >
            <Button
              size='sm'
              variant='secondary'
              onClick={handleEdit}
              label={t('actions.edit')}
              iconLeft={<PencilSimple size={16} />}
            />
          </ProtectedComponent>
        )}

        {consultation?.status === CONSULTATION_STATUS.IN_ROOM &&
          !isStarted &&
          !openCameraPreview && (
            <ProtectedComponent
              permissions={[
                PermissionEnum.service_create_to_other_users,
                PermissionEnum.service_requester,
                PermissionEnum.service_specialist
              ]}
            >
              <Button
                size='sm'
                iconLeft={<Check size={16} />}
                label={t('actions.complete_consultation')}
                onClick={() => setOpenConfirmationFinishModal(true)}
              />
            </ProtectedComponent>
          )}
      </div>
    )
  }

  return (
    <>
      <Content
        onBack={!isStarted ? handleBack : undefined}
        className={isStarted ? 'mr-[400px]' : ''}
        leftContent={isStarted && leftContent()}
        rightContent={rightContent()}
      >
        <div className='mx-[-24px] mt-9 flex max-h-[calc(100vh-230px)] flex-col gap-9 overflow-y-scroll px-6 sm:col-span-2'>
          {isLoading || !consultation ? (
            <ConsultationDetailsSkeleton />
          ) : (
            <>
              <PatientDetails
                onStart={handleStartConsultation}
                consultation={consultation}
                isStarted={isStarted}
              />

              <ConsultationSummary consultation={consultation} />

              {id && (
                <ConsultationTabs
                  id={id}
                  care_type={care_type}
                  cpf={consultation.patient?.cpf as string}
                  specialist_id={consultation.specialist_id}
                  patient_id={consultation.patient?.id as string}
                  is_finished={consultation.status === CONSULTATION_STATUS.FINISHED}
                />
              )}
            </>
          )}
        </div>

        {openCameraPreview && (
          <CameraPreview
            onStart={() => {
              setIsStarted(true)
              setCameraPreview(false)
            }}
          />
        )}
      </Content>

      <ConfirmationFinishCallModal
        onFinish={onFinishCall}
        open={openConfirmationFinishModal}
        onClose={() => setOpenConfirmationFinishModal(false)}
      />

      <ConfirmationExitCallModal
        onExit={handleExitCall}
        open={openConfirmationExitModal}
        onClose={() => setOpenConfirmationExitModal(false)}
      />

      {(care_type === CARE_TYPE.ELECTIVE_CARE || care_type === CARE_TYPE.EMERGENCY_CARE) &&
        isStarted &&
        id && (
          <div className='absolute bottom-[16px] right-0 top-[-16px] flex w-[400px] flex-col'>
            <Connection id={id} onFinish={onFinishConnection} />
          </div>
        )}

      {care_type === CARE_TYPE.CHAT && isStarted && id && (
        <div className='absolute bottom-0 right-0 top-0 flex w-[400px] flex-col'>
          <ChatConnection id={id} />
        </div>
      )}
    </>
  )
}

export default ConsultationDetailsPage
