enum UnitPermissionsEnum {
  /** WRITE PERMISSION */
  write_permission = 'write_permission',
  /** END WRITE PERMISSION */

  chat_consultation = 'chat_consultation',
  elective_care_consultation = 'elective_care_consultation',
  emergency_care_consultation = 'emergency_care_consultation',

  teleconsultation_type = 'teleconsultation_type',
  teleinterconsultation_type = 'teleinterconsultation_type',

  /** INVITE PATIENT */
  invite_patient = 'invite_patient',
  /** END INVITE PATIENT */

  /** INVITE PROFESSIONAL */
  invite_professional = 'invite_professional'
  /** END INVITE PROFESSIONAL */
}

export default UnitPermissionsEnum
