import { useCallback, useEffect, useRef, useState } from 'react'
import SessionService from '../../services/session.service'
import { OpenVidu, Publisher, Session, Subscriber } from 'openvidu-browser'
import { UserVideo } from '../user-video'
import { Button } from 'semente-js'
import { useToastContext } from '../../contexts/toast'
import { Chalkboard } from '@phosphor-icons/react'
import { Connection as OpenViduConnection } from 'openvidu-browser/lib/OpenVidu/Connection'

type ChildrenProps = {
  handleShareScreen: () => void
  shareScreen: boolean
  loadingShareScreen: boolean
  loading: boolean
  camera: boolean
  handleCamera: () => void
  microphone: boolean
  handleMicrophone: () => void
  subscribers: Subscriber[]
}

type Props = {
  id: string
  //TODO change for only one variable, for example authenticated | patient | professional
  isPatientConnection?: boolean
  isProfessionalConnection?: boolean
  children?: (props: ChildrenProps) => React.ReactNode
  onFinish: VoidFunction
}

const getConnectionData = (connection: OpenViduConnection) => {
  return JSON.parse(connection.data)
}

const Connection: React.FC<Props> = ({
  id,
  children,
  onFinish,
  isPatientConnection,
  isProfessionalConnection
}) => {
  const [openvidu, setOpenvidu] = useState<OpenVidu>()
  const [loading, setLoading] = useState(false)
  const [loadingShareScreen, setLoadingShareScreen] = useState(false)
  const [session, setSession] = useState<Session>()
  const [shareScreenSession, setShareScreenSession] = useState<Session | null>()
  const [subscribers, setSubscribers] = useState<Subscriber[]>([])
  const [publisher, setPublisher] = useState<Publisher>()
  const [camera, setCamera] = useState(true)
  const [microphone, setMicrophone] = useState(true)
  const [shareScreen, setShareScreen] = useState(false)
  const screenShareRef = useRef<HTMLDivElement | null>(null)
  const { toast } = useToastContext()
  const hasStartedConnectionRef = useRef(false)

  const getSessionService = () => {
    if (isPatientConnection) return SessionService.createPatientConnection
    if (isProfessionalConnection) return SessionService.createProfessionalConnection
    return SessionService.createConnection
  }

  const sessionService = getSessionService()

  useEffect(() => {
    return () => {
      if (session) {
        session?.forceDisconnect(session?.connection)
      }
    }
  }, [session])

  useEffect(() => {
    return () => {
      if (shareScreenSession) {
        shareScreenSession?.forceDisconnect(shareScreenSession?.connection)
      }
    }
  }, [shareScreenSession])

  const handleStopShareScreen = useCallback(
    async (params?: { forced?: boolean }) => {
      const { forced } = params || {}
      try {
        if (forced) {
          await shareScreenSession?.forceDisconnect(shareScreenSession?.connection)
        } else {
          shareScreenSession?.disconnect()
        }

        setLoadingShareScreen(false)
        setShareScreen(false)
        setShareScreenSession(null)
      } catch (err) {
        console.error(err)
      }
    },
    [shareScreenSession]
  )

  const handleShareScreen = async () => {
    if (!openvidu) return
    setLoadingShareScreen(true)

    if (shareScreen) {
      return handleStopShareScreen()
    }

    const newSession = openvidu.initSession()
    setShareScreenSession(newSession)

    sessionService(id, true)
      .then(async ({ token }) => {
        const newPublisher = await openvidu.initPublisherAsync('screen-share', {
          videoSource: 'screen',
          audioSource: false,
          mirror: false,
          resolution: '640x480'
        })

        const track = newPublisher.stream.getMediaStream().getVideoTracks()[0]

        await newSession.connect(token)

        track.addEventListener('ended', () => {
          handleStopShareScreen({ forced: true }).then(r => r)
        })

        track.addEventListener('inactive', () => {
          handleStopShareScreen().then(r => r)
        })

        newPublisher.once('accessDenied', () => {
          handleStopShareScreen().then(r => r)
        })

        newSession.publish(newPublisher).then(() => {
          setShareScreen(true)
          setLoadingShareScreen(false)
        })
      })
      .catch(error => {
        console.error(error)
        setLoadingShareScreen(false)
      })
  }

  const connect = useCallback(async () => {
    if (!id || openvidu) return

    const newOpenvidu = new OpenVidu()
    const newSession = newOpenvidu.initSession()
    setOpenvidu(newOpenvidu)
    setSession(newSession)

    newSession.on('streamCreated', event => {
      const eventData = getConnectionData(event.stream.connection)
      const sessionData = getConnectionData(newSession.connection)

      if (eventData?.id === sessionData?.id) {
        return
      }

      const message = eventData?.screenShare
        ? `${eventData.name} está compartilhando a tela`
        : `${eventData.name} entrou no atendimento`

      const newSub = newSession.subscribe(event.stream, undefined)
      toast.custom(message, {
        variant: 'brand',
        icon: <Chalkboard size={24} color='#fff' />
      })

      setSubscribers(old => [...old, newSub])
    })

    newSession.on('connectionDestroyed', event => {
      const eventData = getConnectionData(event.connection)
      const sessionData = getConnectionData(newSession.connection)

      if (eventData?.id === sessionData?.id) {
        return
      }

      const message = eventData?.screenShare
        ? `${eventData.name} parou de compartilhar a tela`
        : `${eventData.name} saiu do atendimento`

      toast.custom(message, {
        variant: 'brand',
        icon: <Chalkboard size={24} color='#fff' />
      })
      setSubscribers(old =>
        old.filter(sub => sub.stream.connection.connectionId !== event.connection.connectionId)
      )
    })

    newSession.on('sessionDisconnected', () => {
      onFinish()
    })

    newSession.on('exception', exception => {
      console.warn(exception)
    })

    const { token } = await sessionService(id)

    await newSession.connect(token)

    const newPublisher = await newOpenvidu.initPublisherAsync('publisher', {
      audioSource: undefined,
      videoSource: undefined,
      publishAudio: true,
      publishVideo: true,
      frameRate: 30,
      resolution: '640x480',
      insertMode: 'APPEND',
      mirror: true
    })

    newPublisher.once('accessDenied', () => {
      handleStopShareScreen().then(r => r)
    })

    newSession.publish(newPublisher).then(() => {
      setPublisher(newPublisher)
    })
  }, [id, openvidu, sessionService, toast, onFinish, handleStopShareScreen])

  useEffect(() => {
    if (!hasStartedConnectionRef.current && !session) {
      hasStartedConnectionRef.current = true
      setLoading(true)
      connect().then(() => {
        setLoading(false)
      })
    }
  }, [connect, session])

  const handleCamera = () => {
    if (publisher) {
      publisher.publishVideo(!camera).then(r => r)
      setCamera(!camera)
    }
  }

  const handleMicrophone = () => {
    if (publisher) {
      publisher.publishAudio(!microphone)
      setMicrophone(!microphone)
    }
  }

  if (children) {
    return (
      <>
        {children({
          handleShareScreen,
          shareScreen,
          loadingShareScreen,
          loading,
          camera,
          handleCamera,
          microphone,
          handleMicrophone,
          subscribers
        })}
      </>
    )
  }

  return (
    <>
      <div className='mb-6 mr-6 mt-[104px]  flex-1 flex-col gap-5 rounded-xl bg-white p-5'>
        <div className='relative mb-5 overflow-hidden rounded-xl'>
          <div id='screen-share' ref={screenShareRef} className='' />
          <div className='absolute bottom-1 left-0 right-0 flex justify-center gap-2'>
            <Button
              layout='circle'
              className={shareScreen ? 'bg-blue-400' : 'bg-gray-400'}
              iconName={'monitor-arrow-up'}
              onClick={handleShareScreen}
              isLoading={loadingShareScreen}
            />
          </div>
        </div>

        <div className='relative mb-5 overflow-hidden rounded-xl'>
          {loading && <>Carregando...</>}

          <div id='publisher' className='' />

          {!loading && (
            <>
              <div className='absolute bottom-1 left-0 right-0 flex justify-center gap-2'>
                <Button
                  layout='circle'
                  className={camera ? 'bg-gray-400' : 'bg-red-400'}
                  iconName={camera ? 'camera' : 'camera-slash'}
                  onClick={handleCamera}
                />
                <Button
                  layout='circle'
                  className={shareScreen ? 'bg-blue-400' : 'bg-gray-400'}
                  iconName={'monitor-arrow-up'}
                  onClick={handleShareScreen}
                  isLoading={loadingShareScreen}
                />
                <Button
                  layout='circle'
                  className={microphone ? 'bg-gray-400' : 'bg-red-400'}
                  iconName={microphone ? 'microphone' : 'microphone-slash'}
                  onClick={handleMicrophone}
                />
              </div>
            </>
          )}
        </div>

        {subscribers.map(sub => (
          <UserVideo key={sub.id} subscribe={sub} />
        ))}
      </div>
    </>
  )
}

export default Connection
