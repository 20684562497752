import { useTranslation } from 'react-i18next'
import Button from '../../../components/button'
import ContentFooter from '../../../components/content/footer'
import { useUserAddressFormik } from '../../../hooks/forms/useUserForm'
import ContentForm from '../../../components/content/form'
import InputField from '../../../components/input'
import CepField from '../../../components/input/cep-field'
import { CepAddress } from '../../../services/location.service'
import CityDropdown from '../../../components/dropdown/city-dropdown'
import StateDropdown from '../../../components/dropdown/state-dropdown'
import { User } from '../../../types'
import ButtonSkeleton from '../../../components/button/skeleton'
import InputSkeleton from '../../../components/input/skeleton'

type Props = {
  isUpdate: boolean
  isLoading: boolean
  user: Partial<User>
  onUpdate: (values: Partial<User>) => void
  onSave: (values: Partial<User>) => void
  onCancel: () => void
}

const UserAddressContact: React.FC<Props> = ({
  onSave,
  onCancel,
  isUpdate,
  onUpdate,
  user,
  isLoading
}) => {
  const { t } = useTranslation('user_form')
  const formik = useUserAddressFormik(isUpdate ? onUpdate : onSave, user)

  const handleAddressFetched = async (address: CepAddress) => {
    if (address.logradouro !== formik.values.address) {
      await formik.setFieldValue('address', address.logradouro)
    }
    if (address.bairro !== formik.values.district) {
      await formik.setFieldValue('district', address.bairro)
    }

    if (address.uf !== formik.values.state) {
      await formik.setFieldValue('state', address.uf)
    }

    if (address.localidade !== formik.values.city) {
      await formik.setFieldValue('city', address.localidade)
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      {isLoading ? (
        <Skeleton />
      ) : (
        <ContentForm hasSubtitle hasBackButton>
          <CepField
            label='CEP (opcional)'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.cep || ''}
            onAddressFetched={handleAddressFetched}
            state={formik.touched.cep && formik.errors.cep ? 'error' : 'default'}
            error={formik.touched.cep && formik.errors.cep ? formik.errors.cep : undefined}
            hint={formik.touched.cep && formik.errors.cep ? formik.errors.cep : undefined}
            cepUnknown={!!user.id && !user.cep}
            handleCepUnknown={async value => {
              await formik.setFieldValue('cepUnknown', value)
              await formik.setFieldValue('cep', '')
            }}
          />

          <div />

          <StateDropdown
            label={t('fields.state')}
            onBlur={formik.handleBlur}
            value={formik.values.state || ''}
            error={Boolean(formik.touched.state && formik.errors.state)}
            hint={formik.touched.state && formik.errors.state ? formik.errors.state : undefined}
            onChange={option => {
              formik.setFieldValue('state', option ? option.value : '')
              formik.setFieldValue('city', '')
            }}
          />

          <CityDropdown
            label={t('fields.city')}
            onBlur={formik.handleBlur}
            uf={formik.values.state || ''}
            value={formik.values.city || ''}
            error={Boolean(formik.touched.city && formik.errors.city)}
            onChange={option => formik.setFieldValue('city', option ? option.value : '')}
            hint={formik.touched.city && formik.errors.city ? formik.errors.city : undefined}
          />

          <div className='col-span-2 flex gap-4'>
            <InputField
              id='address'
              name='address'
              className='flex-1'
              onBlur={formik.handleBlur}
              label={t('fields.address')}
              onChange={formik.handleChange}
              value={formik.values.address || ''}
              hint={
                formik.touched.address && formik.errors.address ? formik.errors.address : undefined
              }
            />
            <InputField
              maxLength={11}
              id='address_number'
              className='max-w-40'
              name='address_number'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              label={t('fields.address_number')}
              value={formik.values.address_number || ''}
              hint={
                formik.touched.address_number && formik.errors.address_number
                  ? formik.errors.address_number
                  : undefined
              }
            />
          </div>

          <InputField
            id='complement'
            name='complement'
            onBlur={formik.handleBlur}
            label={t('fields.complement')}
            onChange={formik.handleChange}
            value={formik.values.complement || ''}
          />

          <InputField
            id='district'
            name='district'
            onBlur={formik.handleBlur}
            label={t('fields.district')}
            onChange={formik.handleChange}
            value={formik.values.district || ''}
          />
        </ContentForm>
      )}
      <ContentFooter>
        {isLoading ? (
          <ButtonSkeleton className='ml-0 mr-auto' />
        ) : (
          <Button
            size='md'
            type='button'
            onClick={onCancel}
            variant='secondary'
            label={t('actions.cancel')}
            isLoading={formik.isSubmitting}
          />
        )}
        {isLoading ? (
          <ButtonSkeleton />
        ) : (
          <Button
            size='md'
            type='submit'
            className='ml-auto'
            label={t(isUpdate ? 'actions.save' : 'actions.continue')}
            isLoading={formik.isSubmitting}
            state={!formik.isValid ? 'disable' : 'enable'}
          />
        )}
      </ContentFooter>
    </form>
  )
}

const Skeleton = () => {
  return (
    <ContentForm hasSubtitle hasBackButton>
      <InputSkeleton showLabel /> <div />
      <InputSkeleton showLabel />
      <InputSkeleton showLabel />
      <InputSkeleton showLabel />
      <InputSkeleton showLabel />
      <InputSkeleton showLabel />
      <InputSkeleton showLabel />
    </ContentForm>
  )
}

export default UserAddressContact
