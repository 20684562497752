import React, { createContext, ReactNode, useContext, useState } from 'react'

interface CalendarContextProps {
  selectedDates: Date[] | null
  setSelectedDates: React.Dispatch<React.SetStateAction<Date[]>>
}

const CalendarContext = createContext<CalendarContextProps | undefined>(undefined)

export const CalendarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedDates, setSelectedDates] = useState<Date[]>([])

  return (
    <CalendarContext.Provider value={{ selectedDates, setSelectedDates }}>
      {children}
    </CalendarContext.Provider>
  )
}

export const useCalendar = () => {
  const context = useContext(CalendarContext)
  if (!context) {
    throw new Error('useCalendar must be used within a FileProvider')
  }
  return context
}
