import { useMemo } from 'react'

type Props = {
  label: string
  isMultiple?: boolean
  isSelected?: boolean
  size?: 'xl' | 'lg' | 'md' | 'sm'
  className?: string
  onClick?: () => void
}

const Chip: React.FC<Props> = ({ onClick, isMultiple, label, size, isSelected, className }) => {
  const sizeLayout = useMemo(() => {
    switch (size) {
      case 'xl':
        return 'px-3 py-[10px] text-body-lg'
      case 'lg':
        return 'px-[10px] py-2 text-body-md'
      case 'sm':
        return 'pt-[6px] px-2 text-body-xs'

      default:
        return 'px-2 py-1 text-body-sm'
    }
  }, [size])

  const stateLayout = useMemo(() => {
    if (isSelected) {
      if (isMultiple) {
        return 'border-primaryBrand-primary bg-primaryBrand-primary'
      }
      return 'border-primaryBrand-primary bg-primaryBrand-light'
    }

    return 'border-neutralBackground-secondary bg-neutralBackground-secondary'
  }, [isMultiple, isSelected])

  return (
    <div
      onClick={onClick}
      className={`cursor-pointer rounded-xl border text-center text-neutralContent-primary transition-all hover:bg-primaryBrand-light ${sizeLayout} ${stateLayout} ${className}`}
    >
      {label}
    </div>
  )
}

export default Chip
