function Skeleton() {
  return (
    <div className='mt-9'>
      <div className='mb-9 flex flex-col flex-wrap gap-3 '>
        <div className='h-4 w-[90px] animate-pulse bg-gray-300' />
        <div className='flex flex-wrap gap-2'>
          <div className='h-[38px] w-[125px] animate-pulse rounded-full bg-gray-300' />
        </div>
      </div>
      <div className='mb-9 flex flex-col flex-wrap gap-3 '>
        <div className='h-4 w-[90px] animate-pulse bg-gray-300' />
        <div className='flex flex-wrap gap-2'>
          <div className='h-[38px] w-[125px] animate-pulse rounded-full bg-gray-300' />
          <div className='h-[38px] w-[125px] animate-pulse rounded-full bg-gray-300' />
          <div className='h-[38px] w-[125px] animate-pulse rounded-full bg-gray-300' />
        </div>
      </div>
      <div className='mb-9 flex flex-col flex-wrap gap-3 '>
        <div className='h-4 w-[90px] animate-pulse bg-gray-300' />
        <div className='flex flex-wrap gap-2'>
          <div className='h-[38px] w-[125px] animate-pulse rounded-full bg-gray-300' />
          <div className='h-[38px] w-[125px] animate-pulse rounded-full bg-gray-300' />
          <div className='h-[38px] w-[125px] animate-pulse rounded-full bg-gray-300' />
          <div className='h-[38px] w-[125px] animate-pulse rounded-full bg-gray-300' />
        </div>
      </div>
      <div className='mb-9 flex flex-col flex-wrap gap-3 '>
        <div className='h-4 w-[90px] animate-pulse bg-gray-300' />
        <div className='flex flex-wrap gap-2'>
          <div className='h-[38px] w-[125px] animate-pulse rounded-full bg-gray-300' />
        </div>
      </div>
      <div className='mb-9 flex flex-col flex-wrap gap-3 '>
        <div className='h-4 w-[90px] animate-pulse bg-gray-300' />
        <div className='flex flex-wrap gap-2'>
          <div className='h-[38px] w-[125px] animate-pulse rounded-full bg-gray-300' />
        </div>
      </div>
    </div>
  )
}

export default Skeleton
