const unit_list_enUS = {
  title: 'Manage units',
  search_placeholder: 'Search by unit name, CNES or CNPJ',
  columns: {
    name: 'Name',
    type: 'Unit type',
    cnpj: 'CNPJ',
    status: 'Status',
    actions: ''
  },
  actions: {
    details: 'Details',
    register_unit: 'Register unit'
  },
  tags: {
    active: 'Active',
    inactive: 'Inactive'
  }
}

export default unit_list_enUS
