import React from 'react'

type PriceInputProps = {
  value: string
  onChangeText: (text: string) => void
  currency?: string
  unit?: string
}

const PriceInput: React.FC<PriceInputProps> = ({
  value,
  onChangeText,
  currency = 'R$',
  unit = '/hora'
}) => {
  return (
    <div className='inline-flex items-center rounded-lg border border-gray-300 bg-white px-4 py-2 text-sm text-gray-900 shadow-sm'>
      {/* Currency */}
      <span className='mr-1 font-bold'>{currency}</span>

      {/* Input */}
      <input
        type='text'
        value={value}
        onChange={e => onChangeText(e.target.value)}
        className='w-10 border-none bg-transparent text-center text-sm font-bold text-gray-900 focus:outline-none'
        placeholder='0,00'
      />

      {/* Unit */}
      <span className='ml-2 text-gray-500'>{unit}</span>
    </div>
  )
}

export default PriceInput
