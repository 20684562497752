import React, { useState, useEffect } from 'react'
import { PencilSimple, Plus } from '@phosphor-icons/react'
import Button from '../../../components/button'
import Content from '../../../components/content'
import Table from '../../../components/table'
import SearchField from '../../../components/search'

import { Council } from '../../../types'
import { useListCouncil } from '../../../hooks/useCouncil'
import Tag from '../../../components/tag'
import CouncilFormModal from './council-form-modal'
import InputSkeleton from '../../../components/input/skeleton'

const CouncilPage: React.FC = () => {
  const [modalData, setModalData] = useState<{
    isOpen: boolean
    council?: Council
  }>({
    isOpen: false,
    council: undefined
  })
  const [filter, setFilter] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)

  const { data, isLoading, isFetching, refetch } = useListCouncil(currentPage, 10, filter)

  useEffect(() => {
    if (data) {
      setTotalPages(Math.ceil(data.totalItems / 10))
    }
  }, [data])

  const handleFormModal = (council?: Council) => setModalData({ isOpen: true, council })

  const onCloseModal = () => {
    refetch()
    setModalData({ isOpen: false, council: undefined })
  }

  const columns = [
    { header: 'Conselho', accessor: 'name' },
    { header: 'Documento', accessor: 'document_name' },
    { header: 'Status', accessor: 'is_active' },
    { header: '', accessor: 'actions', className: 'sm:max-w-[120px]' }
  ]

  const tableData =
    data?.data?.map((council: Council) => ({
      name: council.name,
      document_name: <span className='text-body-sm'>{council.document_name}</span>,
      is_active: (
        <Tag
          size='sm'
          variant='secondary'
          label={council.is_active ? 'Ativo' : 'Inativo'}
          color={council.is_active ? 'green' : 'neutral'}
        />
      ),
      actions: (
        <Button
          size='sm'
          label='Editar'
          className='ml-auto'
          variant='secondary'
          iconLeft={<PencilSimple size={12} />}
          onClick={() => handleFormModal(council)}
        />
      )
    })) ?? []

  return (
    <Content
      title='Conselhos'
      rightContent={
        <Button
          size='sm'
          label='Novo conselho'
          onClick={() => handleFormModal()}
          iconLeft={<Plus size={12} />}
        />
      }
    >
      <div className='flex h-[calc(100%-70px)] flex-col'>
        <div className='mb-4 w-64'>
          {isLoading ? (
            <InputSkeleton isRounded />
          ) : (
            <SearchField
              value={filter}
              maxLength={100}
              placeholder='Procure pelo nome...'
              onChange={({ target }) => setFilter(target.value)}
            />
          )}
        </div>

        <Table
          data={tableData}
          columns={columns}
          isLoading={isFetching}
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      </div>

      <CouncilFormModal
        council={modalData.council}
        isModalOpen={modalData.isOpen}
        closeModal={onCloseModal}
      />
    </Content>
  )
}

export default CouncilPage
