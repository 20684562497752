import React, { useEffect, useState } from 'react'
import { Props } from './types'
import Modal from '../../../../../../components/modal'
import { X } from '@phosphor-icons/react'
import Button from '../../../../../../components/button'
import Accordion from '../../../../../../components/accordion'
import Checkbox from '../../../../../../components/checkbox'

const SelectionModal: React.FC<Props> = ({ title, value, items, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [values, setValues] = useState(value)

  useEffect(() => {
    setValues(value)
  }, [value])

  return (
    <>
      <div className='flex w-full items-center rounded-xl border-[1px] border-solid border-neutralBorder-default p-4'>
        <div className='flex w-full flex-col'>
          <span className='text-base font-bold text-neutralContent-primary'>{title}</span>
          {value.length >= 1 && (
            <div className='flex flex-wrap gap-2'>
              {value.map((item, index) => (
                <div
                  key={index}
                  className='flex w-fit gap-1 rounded-sm bg-highlightBlue-light px-1 text-highlightBlue-pure'
                >
                  <span className='text-xs font-bold'>{item}</span>

                  <button type='button' onClick={() => onChange(value.filter(val => val !== item))}>
                    <X />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        <button
          type='button'
          onClick={() => setIsOpen(true)}
          className='rounded-full border-[1px] border-solid border-highlightBlue-pure bg-white px-3 py-1 text-highlightBlue-pure hover:bg-highlightBlue-light'
        >
          Selecione
        </button>
      </div>

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        className='min-w-[480px]'
        title={title}
      >
        <div className='flex h-full w-full flex-col justify-between'>
          <div className='flex max-h-[400px] w-full flex-col gap-2 overflow-auto pr-1'>
            {Object.keys(items).map(key => (
              <Accordion key={key} title={key}>
                {items[key].map(item => (
                  <div key={item} className='flex w-full items-center justify-between py-2'>
                    <span className='text-sm font-bold'>{item}</span>
                    <Checkbox
                      isChecked={values.includes(item)}
                      onClick={() => {
                        if (values.includes(item)) {
                          setValues(p => p.filter(val => val !== item))
                          return
                        }

                        setValues(p => [...p, item])
                      }}
                    />
                  </div>
                ))}
              </Accordion>
            ))}
          </div>

          <Button
            label='Selecionar'
            className='mt-4'
            onClick={() => {
              setIsOpen(false)
              onChange(values)
            }}
          />
        </div>
      </Modal>
    </>
  )
}

export default SelectionModal
