import React, { useEffect } from 'react'
import { Props } from './types'
import InputField from '../../../../../../components/input'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { PlusCircle } from '@phosphor-icons/react'

const validationSchema = Yup.object({
  name: Yup.string().required('Preencha o nome do medicamento'),
  dosage: Yup.string().required('Preencha a dosagem'),
  dosage_instructions: Yup.string().required('Preencha a posologia')
})

const MedicinesForm: React.FC<Props> = ({ value, handleAddMore, onChange }) => {
  const { t } = useTranslation('patient_form')
  const formik = useFormik({
    onSubmit: () => handleAddMore && handleAddMore(),
    initialValues: value,
    validationSchema,
    enableReinitialize: true
  })

  useEffect(() => {
    onChange(formik.values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values])

  return (
    <div>
      <div className='flex w-full flex-col gap-6 rounded-lg border-[1px] border-solid border-neutralBorder-default p-6'>
        <div className='flex w-full gap-9'>
          <InputField
            id='name'
            name='name'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            label={t('fields.dosage_name') || 'Medicamento'}
            placeholder={t('placeholders.dosage_name') || 'Selecione'}
            value={formik.values.name?.toString() || ''}
            hint={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
            state={formik.touched.name && formik.errors.name ? 'error' : 'default'}
          />

          <InputField
            id='dosage'
            name='dosage'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            label={t('fields.dosage') || 'Dosagem'}
            placeholder={t('placeholders.dosage') || 'Especifique'}
            value={formik.values?.dosage?.toString() || ''}
            hint={formik.touched.dosage && formik.errors.dosage ? formik.errors.dosage : undefined}
            state={formik.touched.dosage && formik.errors.dosage ? 'error' : 'default'}
          />
        </div>

        <InputField
          id='dosage_instructions'
          name='dosage_instructions'
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          label={t('fields.dosage_instructions') || 'Posologia'}
          placeholder={t('placeholders.dosage_instructions') || 'Especifique'}
          value={formik.values?.dosage_instructions?.toString() || ''}
          hint={
            formik.touched.dosage_instructions && formik.errors.dosage_instructions
              ? formik.errors.dosage_instructions
              : undefined
          }
          state={
            formik.touched.dosage_instructions && formik.errors.dosage_instructions
              ? 'error'
              : 'default'
          }
        />
      </div>

      {handleAddMore && (
        <button
          className='mt-2 flex w-fit items-center gap-2'
          type='button'
          onClick={async () => {
            const isInvalid = Object.keys(await formik.validateForm()).length >= 1
            if (!isInvalid) {
              return formik.handleSubmit()
            }
            formik.setFieldTouched('name')
            formik.setFieldTouched('dosage_instructions')
            formik.setFieldTouched('dosage')
          }}
        >
          <PlusCircle className='text-highlightBlue-pure' />
          <span className='text-highlightBlue-pure'>Adicionar outro medicamento</span>
        </button>
      )}
    </div>
  )
}

export default React.memo(MedicinesForm)
