import { format, isPast } from 'date-fns'
import Modal from '../../../components/modal'
import Tag from '../../../components/tag'
import {
  CARE_TYPE,
  Consultation,
  CONSULTATION_STATUS,
  FOLLOW_UP_DISPLAY_MODE,
  Patient,
  User
} from '../../../types'
import { getTimeDiff } from '../../../helpers/getTimeDiff'
import { CalendarBlank, Chats, Hospital, IdentificationCard, Webcam } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'
import { PersonInfo } from '../ProfessionaInfo'
import Button from '../../../components/button'
import { useFinishConsultation } from '../../../hooks/useConsultation'
import { useState } from 'react'
import { useToastContext } from '../../../contexts/toast'
import PersonDetailModal from './person-detail-modal'

interface Props {
  consultation: Consultation
  isOpen: boolean
  onClose: () => void
}
const ConsultationDetailModal: React.FC<Props> = ({ consultation, isOpen, onClose }) => {
  const { t } = useTranslation('consultation_follow_up')
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenPersonModal, setIsOpenPersonModal] = useState<{
    isOpen: boolean
    user: User | Patient | null
    specialty?: string | null
    document?: string | null
  }>({
    isOpen: false,
    user: null,
    specialty: undefined,
    document: undefined
  })
  const { toast } = useToastContext()

  const { mutate: finishConsultation } = useFinishConsultation()

  if (!consultation) {
    return null
  }

  const onFinishConsultation = () => {
    setIsLoading(true)
    finishConsultation(consultation.id!, {
      onSuccess: () => {
        toast.success(t('modal_consultation_detail.toast.success'))

        setIsLoading(false)
        onClose()
      },
      onError: () => {
        setIsLoading(false)
        toast.error(t('modal_consultation_detail.toast.error'))
      }
    })
  }

  const consultationDate = consultation.start_date || consultation.created_at
  const formattedDate = consultationDate
    ? format(new Date(consultationDate), 'dd/MM/yyyy, HH:mm')
    : t('date_not_available')

  return (
    <Modal
      isOpen={isOpen}
      title={
        <span className='text-heading-xl text-neutralContent-primary'>
          {t('modal_consultation_detail.consultation_title', { code: consultation.code })}
        </span>
      }
      onClose={onClose}
      className='w-96'
    >
      <div
        className={
          consultation.status === CONSULTATION_STATUS.IN_ROOM
            ? 'mb-[70px] flex h-[400px] w-full flex-col overflow-x-hidden overflow-y-scroll'
            : ''
        }
      >
        <div className='inline-flex'>
          {consultation.status === CONSULTATION_STATUS.IN_ROOM ? (
            <Tag label={t('in_progress')} color='green' size='xs' />
          ) : isPast(new Date(consultation.start_date || consultation.created_at!)) ? (
            <Tag
              label={
                getTimeDiff(
                  new Date(consultation.start_date || consultation.created_at!),
                  new Date()
                ) + t('in_waiting')
              }
              color='red'
              size='xs'
            />
          ) : (
            <Tag
              label={
                t('start_in_waiting') +
                getTimeDiff(
                  new Date(consultation.start_date || consultation.created_at!),
                  new Date()
                )
              }
              color='blue'
              size='xs'
            />
          )}
        </div>
        <div className='mt-9'>
          <span className='text-heading-xs text-neutralContent-primary'>
            {t('modal_consultation_detail.detail')}
          </span>

          <div className='mt-4 flex flex-col gap-4 '>
            <div className='flex items-center gap-2'>
              {consultation.care_type === CARE_TYPE.CHAT ? (
                <Chats
                  size={24}
                  className={
                    consultation.care_type === CARE_TYPE.CHAT
                      ? 'text-highlightRed-pure'
                      : 'text-neutralContent-primary'
                  }
                />
              ) : (
                <Webcam
                  size={24}
                  className={
                    consultation.care_type === CARE_TYPE.EMERGENCY_CARE
                      ? 'text-highlightRed-pure'
                      : 'text-neutralContent-primary'
                  }
                />
              )}

              <span
                className={`text-body-md ${consultation.care_type === CARE_TYPE.ELECTIVE_CARE ? 'text-neutralContent-primary' : 'text-highlightRed-pure'}`}
              >
                {t(`types.${consultation.care_type}`)}
              </span>
            </div>
            <div className='ml-8 h-[1px] w-full bg-gray-200' />

            <div className='flex items-center gap-2'>
              <IdentificationCard size={24} />

              <span className={'text-body-md text-neutralContent-primary'}>
                {consultation.specialty?.name || ''}
              </span>
            </div>
            <div className='ml-8 h-[1px] w-full bg-gray-200' />

            <div className='flex items-center gap-2 '>
              <CalendarBlank size={24} />

              {consultation.status === CONSULTATION_STATUS.IN_ROOM ? (
                <span className='text-body-sm text-neutralContent-primary'>
                  {consultation.status === CONSULTATION_STATUS.IN_ROOM
                    ? t('start_in') + getTimeDiff(new Date(consultation.started_at!), new Date())
                    : format(
                        consultation.start_date || consultation.created_at || '',
                        'dd/MM/yyyy HH:mm'
                      )}
                </span>
              ) : (
                <span className={'text-body-md text-neutralContent-primary'}>{formattedDate}</span>
              )}
            </div>
            <div className='ml-8 h-[1px] w-full bg-gray-200' />

            <div className='flex items-center gap-2'>
              <Hospital size={24} />

              <span className={'text-body-md text-neutralContent-primary'}>
                {consultation?.unit?.name || ''}
              </span>
            </div>
          </div>

          <div className='mt-9 '>
            <span className='text-heading-xs text-neutralContent-primary'>
              {t('modal_consultation_detail.participants')}
            </span>
          </div>

          <div className='mt-4 flex flex-col gap-2'>
            {consultation.requester && (
              <PersonInfo
                type='requester'
                displayMode={FOLLOW_UP_DISPLAY_MODE.CARD}
                name={consultation.requester.name || ''}
                showOnline={false}
                onClick={() => {
                  setIsOpenPersonModal({
                    isOpen: true,
                    user: consultation.requester!,
                    specialty: consultation.specialty?.name || null,
                    document: consultation.specialist?.council_registration_number
                  })
                }}
              />
            )}
            {consultation.specialist && (
              <PersonInfo
                type='specialist'
                displayMode={FOLLOW_UP_DISPLAY_MODE.CARD}
                name={consultation.specialist.name || ''}
                showOnline={false}
                onClick={() => {
                  setIsOpenPersonModal({
                    isOpen: true,
                    user: consultation.specialist!,
                    specialty: consultation.specialty?.name,
                    document: consultation.specialist?.council_registration_number
                  })
                }}
              />
            )}
            {consultation.patient && (
              <PersonInfo
                type='patient'
                displayMode={FOLLOW_UP_DISPLAY_MODE.CARD}
                name={consultation.patient?.name || ''}
                showOnline={false}
                onClick={() => {
                  setIsOpenPersonModal({ isOpen: true, user: consultation.patient! })
                }}
              />
            )}
          </div>
        </div>
      </div>

      {consultation.status === CONSULTATION_STATUS.IN_ROOM && (
        <div className='absolute bottom-0 left-0 right-0 z-50 self-center bg-white px-6 py-4 shadow-footer'>
          <Button
            label={t('actions.finish_consultation')}
            width={'350'}
            className='ml-[-8px] flex justify-center self-center '
            isLoading={isLoading}
            onClick={onFinishConsultation}
          />
        </div>
      )}

      <PersonDetailModal
        isOpen={isOpenPersonModal.isOpen}
        user={isOpenPersonModal.user!}
        specialty={isOpenPersonModal.specialty || undefined}
        document={isOpenPersonModal.document || undefined}
        onClose={() =>
          setIsOpenPersonModal({ isOpen: false, user: null, specialty: null, document: null })
        }
      />
    </Modal>
  )
}

export default ConsultationDetailModal
