import { useTranslation } from 'react-i18next'
import Avatar from '../../../components/avatar'
import { FOLLOW_UP_DISPLAY_MODE } from '../../../types'

interface Props {
  online?: boolean
  name: string
  type: 'patient' | 'requester' | 'specialist'
  displayMode?: FOLLOW_UP_DISPLAY_MODE
  showOnline?: boolean
  onClick?: () => void
}

export const PersonInfo: React.FC<Props> = ({
  online,
  name,
  type,
  displayMode = FOLLOW_UP_DISPLAY_MODE.CARD,
  showOnline = false,
  onClick
}) => {
  const { t } = useTranslation('consultation_follow_up')

  const onClickHandler = () => {
    onClick?.()
  }

  return (
    <div
      className={`flex flex-row items-center gap-2 rounded-xl px-2 py-2 ${online ? 'border-2 border-highlightGreen-pure bg-white' : 'border-2 border-neutralBackground-secondary bg-neutralBackground-secondary'} ${!!onClick ? 'cursor-pointer' : '*:'}`}
      onClick={onClickHandler}
    >
      <div>
        <Avatar name={name} size='sm' color='neutral' />
      </div>

      <div
        className={`flex w-full gap-1 ${displayMode === FOLLOW_UP_DISPLAY_MODE.CARD ? 'flex-row items-center justify-between' : 'flex-col '}`}
      >
        <div className='flex flex-col'>
          <span className='text-label-md'>{name}</span>
          <span className='text-body-sm text-neutralContent-secondary'>{t(`person.${type}`)}</span>
        </div>
        {!!showOnline && (
          <span
            className={` ${online ? 'text-label-md text-highlightGreen-pure' : 'text-body-sm text-neutralContent-tertiary'}`}
          >
            {online ? 'Entrou' : 'Não entrou'}
          </span>
        )}
      </div>
    </div>
  )
}
