import Avatar from '../../../components/avatar'
import Content from '../../../components/content'
import { useAuth } from '../../../contexts/auth'

const MyAccountPage = () => {
  const { user } = useAuth()

  return (
    <Content title='Minha conta'>
      <div className='flex flex-col gap-4'>
        <Avatar user={user} size='xxxl' className='mb-6' />
        <div className='flex flex-col text-body-lg'>
          <label className='text-label-sm'>Nome</label>
          {user?.name}
        </div>
        <div className='flex flex-col text-body-lg'>
          <label className='text-label-sm'>Email</label>
          {user?.email}
        </div>
        <div className='flex flex-col text-body-lg'>
          <label className='text-label-sm'>Perfil</label>
          {user?.profile?.name}
        </div>
      </div>
    </Content>
  )
}

export default MyAccountPage
