import { User } from '../../types'
import UserMenu from '../user-menu'
import LanguageMenu from '../language-menu'

const TopBar: React.FC<{
  isOpen: boolean
  user: User | null
  logo?: string
  handleMenu: (value: boolean) => void
  onLogout: () => void
}> = ({ isOpen, user, logo, handleMenu, onLogout }) => {
  if (!user)
    return (
      <div
        className={`z-50 flex h-16 items-center border-b border-neutralBorder-default bg-white px-9 py-4`}
      >
        <img src={logo} alt='L2D' className='h-7' />
      </div>
    )

  return (
    <div
      className={`z-50 flex h-16 items-center border-b border-neutralBorder-default bg-white px-9 py-4`}
    >
      <img src={logo} alt='L2D' className='h-7' />

      <div className='hidden flex-1 flex-row items-center justify-end md:flex'>
        <LanguageMenu />
        <UserMenu user={user} onLogout={onLogout} />
      </div>
      <div className='ml-auto md:hidden'>
        <MobileMenuIcon isOpen={isOpen} handleMenu={handleMenu} />
      </div>
    </div>
  )
}

const MobileMenuIcon: React.FC<{ isOpen: boolean; handleMenu: (value: boolean) => void }> = ({
  isOpen,
  handleMenu
}) => {
  return (
    <div
      className={`relative flex h-10 w-12 cursor-pointer flex-col items-center justify-between rounded-md p-2 hover:bg-primaryBrand-light ${isOpen ? 'open' : ''}`}
      onClick={() => handleMenu(!isOpen)}
    >
      <span
        className={`block h-1 w-full transform rounded-full bg-primaryBrand-dark transition duration-500 ease-in-out ${isOpen ? 'translate-y-2.5 rotate-45' : ''}`}
      />
      <span
        className={`block h-1 w-full rounded-full bg-primaryBrand-dark transition-opacity duration-500 ease-in-out ${isOpen ? 'opacity-0' : 'opacity-100'}`}
      />
      <span
        className={`block h-1 w-full transform rounded-full bg-primaryBrand-dark transition duration-500 ease-in-out ${isOpen ? '-translate-y-2.5 -rotate-45' : ''}`}
      />
    </div>
  )
}

export default TopBar
