import { DropdownOption } from '../components/dropdown'

export const generateHourOptions = (min?: string): DropdownOption[] => {
  const options: DropdownOption[] = []
  const start = min ? Number(min.split(':')[0]) : 7

  for (let i = start; i <= 23; i++) {
    const hour = i.toString().padStart(2, '0')
    options.push({ value: `${hour}:00`, label: `${hour}:00` })
  }

  return options
}
