import { ReactNode } from 'react'

const AccordionSkeleton: React.FC<{ children: ReactNode | string }> = ({ children }) => {
  return (
    <div className='mb-4'>
      <div className='mb-4 flex  items-center rounded-lg border border-gray-200 p-2'>
        <div className='mr-2 h-4 w-4 animate-pulse   rounded bg-gray-200' />
        <div className='h-5 w-60 animate-pulse rounded bg-gray-200' />
      </div>
      <div className='pl-8'>{children}</div>
    </div>
  )
}

export default AccordionSkeleton
