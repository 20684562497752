import { useEffect, useState, useMemo } from 'react'
import { Client, Unit } from '../../../types'
import Modal from '../../../components/modal'
import ClientListFilter from './client-list-filter'
import UnitsListFilter from './units-list-filter'
import SearchField from '../../../components/search'
import Avatar from '../../../components/avatar'

export type UnitItem = { unit_id?: string; unit?: Unit }
export type ClientItem = { client_id?: string; client?: Client; units?: UnitItem[] }

type Props = {
  isOpen: boolean
  clients: ClientItem[]
  onClose: () => void
  onSelect?: (clients: ClientItem[]) => void
  onBack?: () => void
  selectedClientsUnits: ClientItem[]
  filter: string
  setFilter: (value: string) => void
}

const ClientUnitsFilterModal: React.FC<Props> = ({
  isOpen,
  clients,
  onClose,
  onSelect: onSelectMultiple,
  onBack,
  selectedClientsUnits,
  filter,
  setFilter
}) => {
  const [selectedClient, setSelectedClient] = useState<Client | null>(null)
  const [selectedClientsUnitsState, setSelectedClientsUnitsState] = useState<ClientItem[]>([])
  const [showUnities, setShowUnities] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setShowUnities(false)
      setSelectedClient(null)
      setSelectedClientsUnitsState(selectedClientsUnits)
    }
  }, [isOpen, selectedClientsUnits])

  const handleClientSelect = (clients: Client[]) => {
    const client = clients[0]
    setSelectedClient(client)
    setShowUnities(true)
  }

  const handleUnitSelect = (units: Unit[]) => {
    setSelectedClientsUnitsState(prev => {
      const otherClients = prev.filter(item => item.client_id !== selectedClient?.id)
      const unitsForClient = units.map(unit => ({ unit_id: unit.id, unit }))
      const clientItem = {
        client_id: selectedClient?.id,
        client: selectedClient!,
        units: unitsForClient
      }
      return [...otherClients, clientItem]
    })
  }

  const handleModalClose = () => {
    if (onSelectMultiple) {
      onSelectMultiple(selectedClientsUnitsState)
    }
    onClose()
  }

  const handleModalBack = () => {
    if (showUnities) {
      setShowUnities(false)
      setSelectedClient(null)
    } else {
      onBack && onBack()
    }
  }

  const handleSearch = (value: string) => {
    setFilter(value)
  }

  const titleModal = () => {
    if (showUnities && selectedClient) {
      return (
        <div className='flex items-center gap-2'>
          <Avatar client={selectedClient} size='sm' />
          <span className='text-label-md text-neutralContent-primary'>{selectedClient.name}</span>
        </div>
      )
    }
    return 'Clientes'
  }

  const selectedUnitsForCurrentClient = useMemo(() => {
    const clientItem = selectedClientsUnitsState.find(item => item.client_id === selectedClient?.id)
    return clientItem?.units?.map(u => u.unit).filter(Boolean) || []
  }, [selectedClientsUnitsState, selectedClient])

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleModalClose}
      className='max-h-[65vh] w-full max-w-[95vw] overflow-y-scroll sm:w-[500px]'
      title={titleModal()}
      onBack={handleModalBack}
    >
      {!showUnities && (
        <SearchField
          placeholder={'Procurar por cliente...'}
          onChange={({ target }) => handleSearch(target.value)}
          value={filter}
          maxLength={100}
        />
      )}
      <div className='h-full overflow-y-auto px-4'>
        <div>
          {showUnities ? (
            <UnitsListFilter
              multiple
              selectedClient={selectedClient!}
              selectedUnits={selectedUnitsForCurrentClient.filter(
                (unit): unit is Unit => unit !== undefined
              )}
              handleSelectedUnits={handleUnitSelect}
            />
          ) : (
            <ClientListFilter
              multiple={false}
              clients={clients.map(item => item.client!).filter(Boolean)}
              selectedClients={[]}
              handleSelectedClients={handleClientSelect}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ClientUnitsFilterModal
