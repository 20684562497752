import { Globe } from '@phosphor-icons/react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

const LANGUAGES = ['ptBR', 'enUS']
const acronym = {
  ptBR: 'PT',
  enUS: 'EN'
}

const LanguageMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { t, i18n } = useTranslation('languages')
  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language)
    setIsOpen(false)
  }

  return (
    <div ref={dropdownRef} className='relative'>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className='mr-4 flex cursor-pointer flex-row items-center rounded-lg px-2 py-4 transition-all hover:bg-primaryBrand-light'
      >
        <Globe size={20} />
        <div className='ml-2 flex flex-col'>
          <span className='text-label-sm'> {acronym[i18n.language as 'enUS' | 'ptBR']}</span>
        </div>
      </div>
      <div
        className={`absolute  right-0 mt-2 flex w-36 origin-top-right transform flex-col gap-2 rounded-md bg-white px-2 py-4 shadow-lg ring-1 ring-black ring-opacity-5 transition duration-200 ease-out focus:outline-none ${isOpen ? 'scale-100 opacity-100' : 'pointer-events-none scale-95 opacity-0'}`}
      >
        {LANGUAGES.map(language => (
          <div
            key={language}
            onClick={() => changeLanguage(language)}
            className={`flex cursor-pointer items-center justify-end overflow-hidden rounded-xl px-3 py-[10px] text-right text-label-sm transition-all ${i18n.language === language ? 'bg-primaryBrand-primary text-white' : 'text-neutralContent-primary hover:bg-primaryBrand-light'}`}
          >
            {t(`${language}`)}
          </div>
        ))}
      </div>
    </div>
  )
}

export default LanguageMenu
