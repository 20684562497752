import { useEffect, useMemo, useState } from 'react'
import ChatService from '../services/chat.service'
import ConsultationService from '../services/consultation.service'
import { ConsultationMessage } from '../types'

export const useChatConnection = (consultation_id: string) => {
  const [message, setMessage] = useState('')
  const [isLoading, setLoading] = useState(true)
  const [messages, setMessages] = useState<ConsultationMessage[]>([])

  const chat = useMemo<ChatService>(() => new ChatService(), [])

  useEffect(() => {
    ConsultationService.getMessagesByConsultationId(consultation_id)
      .then(setMessages)
      .finally(() => setLoading(false))
  }, [consultation_id])

  useEffect(() => {
    chat.connectChat(consultation_id)

    chat.socket?.on('message', (message: ConsultationMessage) => {
      setMessages(old => [...old, message])
    })

    return () => {
      chat.socket?.removeAllListeners()
    }
  }, [chat, consultation_id])

  const handleSendMessage = async () => {
    if (message.trim()) {
      await ConsultationService.saveMessage(consultation_id, message)
      setMessage('')
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSendMessage()
    }
  }

  return {
    message,
    messages,
    isLoading,
    setMessage,
    handleSendMessage,
    handleKeyPress
  }
}
