export function validateCNPJ(cnpj: string) {
  cnpj = cnpj.replace(/\D/g, '')

  if (cnpj.length !== 14) {
    return false
  }

  if (/^(\d)\1+$/.test(cnpj)) {
    return false
  }

  let length = cnpj.length - 2
  let numbers = cnpj.substring(0, length)
  const digits = cnpj.substring(length)
  let sum = 0
  let position = length - 7

  for (let i = length; i >= 1; i--) {
    sum += parseInt(numbers.charAt(length - i), 10) * position--
    if (position < 2) position = 9
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
  if (result !== parseInt(digits.charAt(0), 10)) {
    return false
  }

  length += 1
  numbers = cnpj.substring(0, length)
  sum = 0
  position = length - 7

  for (let i = length; i >= 1; i--) {
    sum += parseInt(numbers.charAt(length - i), 10) * position--
    if (position < 2) position = 9
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
  return result === parseInt(digits.charAt(1), 10)
}
