/* eslint-disable @typescript-eslint/no-explicit-any */
import { useActivationStatus } from '../../../hooks/useClient'
import ContentFooter from '../../../components/content/footer'
import ContentForm from '../../../components/content/form'
import { useParams } from 'react-router-dom'
import InputField from '../../../components/input'
import Button from '../../../components/button'
import ClientFormSkeleton from './skeleton'
import ClientPersonType from './client-person-type'
import CepField from '../../../components/input/cep-field'
import StateDropdown from '../../../components/dropdown/state-dropdown'
import CityDropdown from '../../../components/dropdown/city-dropdown'
import { Client } from '../../../types'
import DatePicker from '../../../components/datepicker'
import { EyeSlash } from '@phosphor-icons/react'
import { useState } from 'react'
import InactivateClient from './client-inactivate-modal'
import { useTranslation } from 'react-i18next'
import { CepAddress } from '../../../services/location.service'
import { useClientGeneralInformationFormik } from '../../../hooks/forms/useClientForm'

type Props = {
  onSuccess: (values: Partial<Client>) => void
  onCancel: () => void
  initialValues?: Partial<Client>
  isEdit?: boolean
  isLoading?: boolean
}

const ClientGeneralFormPage: React.FC<Props> = ({
  onSuccess,
  initialValues,
  isLoading,
  isEdit = false
}: Props) => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation('client_form')
  const [modalInactivate, setMOdalInactivate] = useState(false)
  const { mutate: activationStatus, isPending } = useActivationStatus(id as string)

  const clientFormik = useClientGeneralInformationFormik(values => {
    onSuccess(values)
  }, initialValues)

  const handleAddressFetched = async (address: CepAddress) => {
    if (address.logradouro !== clientFormik.values.address) {
      await clientFormik.setFieldValue('address', address.logradouro)
    }
    if (address.bairro !== clientFormik.values.district) {
      await clientFormik.setFieldValue('district', address.bairro)
    }

    if (address.uf !== clientFormik.values.state) {
      await clientFormik.setFieldValue('state', address.uf)
    }

    if (address.localidade !== clientFormik.values.city) {
      await clientFormik.setFieldValue('city', address.localidade)
    }
  }

  const handleActivation = () => {
    if (!clientFormik.values.is_active) activationStatus()
    else setMOdalInactivate(true)
  }

  return (
    <div>
      <form onSubmit={clientFormik.handleSubmit} className='h-full'>
        <ContentForm hasSubtitle hasBackButton>
          {isLoading ? (
            <ClientFormSkeleton />
          ) : (
            <>
              <div className='col-span-2'>
                <ClientPersonType
                  id='type'
                  value={clientFormik.values.type ?? ''}
                  onChangeValue={clientFormik.setFieldValue}
                />
              </div>

              <DatePicker
                name='contract_start'
                label={t('fields.personal.contract_start')}
                placeholder={t('actions.select_start')}
                onChange={val => clientFormik.setFieldValue('contract_start', val)}
                onBlur={clientFormik.handleBlur}
                value={
                  clientFormik.values.contract_start
                    ? new Date(clientFormik.values.contract_start)
                    : null
                }
                disabled={clientFormik.isSubmitting}
                error={clientFormik.errors.contract_start}
                touched={clientFormik.touched.contract_start}
              />

              <DatePicker
                name='contract_end'
                label={t('fields.personal.contract_end')}
                placeholder={t('actions.select_end')}
                onChange={val => clientFormik.setFieldValue('contract_end', val)}
                onBlur={clientFormik.handleBlur}
                value={
                  clientFormik.values.contract_end
                    ? new Date(clientFormik.values.contract_end)
                    : null
                }
                disabled={clientFormik.isSubmitting}
                error={clientFormik.errors.contract_end}
                touched={clientFormik.touched.contract_end}
              />

              <InputField
                id='name'
                name='name'
                type='text'
                label={t('fields.personal.name')}
                placeholder={t('fields.personal.name')}
                onBlur={clientFormik.handleBlur}
                value={clientFormik.values.name ?? ''}
                onChange={clientFormik.handleChange}
                state={clientFormik.touched.name && clientFormik.errors.name ? 'error' : 'default'}
                hint={
                  clientFormik.touched.name && clientFormik.errors.name
                    ? clientFormik.errors.name
                    : undefined
                }
              />

              {clientFormik.values.type === 'PF' ? (
                <InputField
                  id='cpf'
                  name='cpf'
                  type='cpf'
                  label={t('fields.personal.cpf')}
                  onBlur={clientFormik.handleBlur}
                  onChange={clientFormik.handleChange}
                  value={clientFormik.values.cpf ?? ''}
                  placeholder={t('fields.personal.cpf')}
                  state={clientFormik.touched.cpf && clientFormik.errors.cpf ? 'error' : 'default'}
                  hint={
                    clientFormik.touched.cpf && clientFormik.errors.cpf
                      ? clientFormik.errors.cpf
                      : undefined
                  }
                />
              ) : (
                <InputField
                  id='cnpj'
                  name='cnpj'
                  type='cnpj'
                  onBlur={clientFormik.handleBlur}
                  label={t('fields.personal.cnpj')}
                  onChange={clientFormik.handleChange}
                  value={clientFormik.values.cnpj ?? ''}
                  placeholder={t('fields.personal.cnpj')}
                  state={
                    clientFormik.touched.cnpj && clientFormik.errors.cnpj ? 'error' : 'default'
                  }
                  hint={
                    clientFormik.touched.cnpj && clientFormik.errors.cnpj
                      ? clientFormik.errors.cnpj
                      : undefined
                  }
                />
              )}
              <InputField
                id='email'
                name='email'
                type='email'
                label={t('fields.personal.email')}
                placeholder={t('fields.personal.email')}
                onBlur={clientFormik.handleBlur}
                value={clientFormik.values.email ?? ''}
                onChange={clientFormik.handleChange}
                state={
                  clientFormik.touched.email && clientFormik.errors.email ? 'error' : 'default'
                }
                hint={
                  clientFormik.touched.email && clientFormik.errors.email
                    ? clientFormik.errors.email
                    : undefined
                }
              />

              <InputField
                id='phone'
                name='phone'
                type='phone'
                label={t('fields.personal.phone')}
                placeholder={t('fields.personal.phone')}
                onBlur={clientFormik.handleBlur}
                onChange={clientFormik.handleChange}
                value={clientFormik.values.phone || ''}
                state={
                  clientFormik.touched.phone && clientFormik.errors.phone ? 'error' : 'default'
                }
                hint={
                  clientFormik.touched.phone && clientFormik.errors.phone
                    ? clientFormik.errors.phone
                    : undefined
                }
              />

              <div className='col-span-2'>
                <h1 className='text-heading-sm text-neutralContent-primary'>
                  {t('GENERAL_INFORMATION.address')}
                </h1>
              </div>
              <div className='col-span-2 grid grid-cols-3 gap-3'>
                <CepField
                  className='w-full'
                  onBlur={clientFormik.handleBlur}
                  onChange={clientFormik.handleChange}
                  value={clientFormik.values.cep || ''}
                  onAddressFetched={handleAddressFetched}
                  cepUnknown={clientFormik.values.cepUnknown}
                  handleCepUnknown={async value => {
                    await clientFormik.setFieldValue('cepUnknown', value)
                    await clientFormik.setFieldValue('cep', '')
                  }}
                  state={clientFormik.touched.cep && clientFormik.errors.cep ? 'error' : 'default'}
                  error={
                    clientFormik.touched.cep && clientFormik.errors.cep
                      ? clientFormik.errors.cep
                      : undefined
                  }
                  hint={
                    clientFormik.touched.cep && clientFormik.errors.cep
                      ? clientFormik.errors.cep
                      : undefined
                  }
                />
              </div>
              <InputField
                id='address'
                name='address'
                label={t('fields.personal.address')}
                placeholder={t('fields.personal.address')}
                onBlur={clientFormik.handleBlur}
                value={clientFormik.values.address || ''}
                onChange={clientFormik.handleChange}
                state={'default'}
                hint={
                  clientFormik.touched.address && clientFormik.errors.address
                    ? clientFormik.errors.address
                    : undefined
                }
              />
              <InputField
                label={t('fields.personal.address_number')}
                placeholder={t('fields.personal.address_number')}
                maxLength={11}
                id='address_number'
                name='address_number'
                onBlur={clientFormik.handleBlur}
                onChange={clientFormik.handleChange}
                value={clientFormik.values.address_number || ''}
                state={'default'}
                hint={
                  clientFormik.touched.address_number && clientFormik.errors.address_number
                    ? clientFormik.errors.address_number
                    : undefined
                }
              />

              <InputField
                id='district'
                name='district'
                label={t('fields.personal.district')}
                placeholder={t('fields.personal.district')}
                onBlur={clientFormik.handleBlur}
                onChange={clientFormik.handleChange}
                value={clientFormik.values.district || ''}
                state={'default'}
              />

              <InputField
                id='complement'
                name='complement'
                onBlur={clientFormik.handleBlur}
                label={t('fields.personal.complement')}
                placeholder={t('fields.personal.complement')}
                onChange={clientFormik.handleChange}
                value={clientFormik.values.complement || ''}
              />

              <StateDropdown
                label={t('fields.personal.state')}
                placeholder={t('actions.select')}
                value={clientFormik.values.state || ''}
                onChange={async option => {
                  await clientFormik.setFieldValue('state', option ? option.value : '')
                  await clientFormik.setFieldValue('city', '')
                }}
                onBlur={clientFormik.handleBlur}
                error={Boolean(clientFormik.touched.state && clientFormik.errors.state)}
                hint={
                  clientFormik.touched.state && clientFormik.errors.state
                    ? clientFormik.errors.state
                    : undefined
                }
              />
              <CityDropdown
                label={t('fields.personal.city')}
                placeholder={t('actions.select')}
                uf={clientFormik.values.state || ''}
                value={clientFormik.values.city || ''}
                onChange={async option =>
                  await clientFormik.setFieldValue('city', option ? option.value : '')
                }
                onBlur={clientFormik.handleBlur}
                error={Boolean(clientFormik.touched.city && clientFormik.errors.city)}
                hint={
                  clientFormik.touched.city && clientFormik.errors.city
                    ? clientFormik.errors.city
                    : undefined
                }
              />

              <ContentFooter className={`${!!isEdit ? 'justify-between' : 'justify-end'} gap-2`}>
                {!!isEdit && (
                  <Button
                    type='button'
                    state={'enable'}
                    size='md'
                    iconLeft={<EyeSlash size={18} />}
                    label={
                      initialValues?.is_active ? t('actions.inactivate') : t('actions.activate')
                    }
                    onClick={handleActivation}
                    variant='secondary'
                    isLoading={isPending}
                  />
                )}
                <Button
                  state={!clientFormik.isValid && !isEdit ? 'disable' : 'enable'}
                  type='submit'
                  size='md'
                  label={isEdit ? t('actions.save') : t('actions.next')}
                  isLoading={clientFormik.isSubmitting}
                />
              </ContentFooter>
            </>
          )}
        </ContentForm>
      </form>
      <InactivateClient
        isModalOpen={modalInactivate}
        clientId={id as string}
        closeModal={() => setMOdalInactivate(false)}
      />
    </div>
  )
}

export default ClientGeneralFormPage
