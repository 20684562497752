/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import AnamnesisService from '../../../../services/anamnesis.service'
import AnamnesisQuestionsSpecialty from '../../anamnesis/specialty'
import AnamnesisQuestionsDefault from '../../anamnesis/default'
import BlankCard from '../../../../components/blank-card'
import Tag from '../../../../components/tag'
import debounce from 'lodash.debounce'
import { useGetAnamnesisTemplateByConsultation } from '../../../../hooks/useAnamnesis'

export const AnamneseTemplate = ({ id }: { id: string }) => {
  const { t } = useTranslation('consultation_details')

  const [selectedType, setSelectedType] = useState<'default' | 'specialty'>('default')
  const [defaultValues, setDefaultValues] = useState<any>({})
  const [specialtyValues, setSpecialtyValues] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const { data: template } = useGetAnamnesisTemplateByConsultation(id)

  const handleSave = async (updatedValues: any, type: 'default' | 'specialty') => {
    setLoading(true)
    try {
      await AnamnesisService.save({
        consultation_id: id,
        default_answers: type === 'default' ? updatedValues : defaultValues,
        specialty_answers: type === 'specialty' ? updatedValues : specialtyValues
      })
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const debounceSave = useCallback(
    debounce((updatedValues: any, type: 'default' | 'specialty') => {
      handleSave(updatedValues, type)
    }, 500),
    [defaultValues, specialtyValues, id]
  )

  useEffect(() => {
    if (selectedType === 'default') {
      debounceSave(defaultValues, 'default')
    }
  }, [defaultValues, debounceSave, selectedType])

  useEffect(() => {
    if (selectedType === 'specialty') {
      debounceSave(specialtyValues, 'specialty')
    }
  }, [specialtyValues, debounceSave, selectedType])

  const handlePress = (type: 'default' | 'specialty') => {
    setSelectedType(type)
  }

  const renderContent = () => {
    if (!!template && selectedType === 'specialty') {
      return (
        <AnamnesisQuestionsSpecialty
          id={id}
          template={template}
          setSpecialtyValues={setSpecialtyValues}
          specialtyValues={specialtyValues}
        />
      )
    } else {
      return (
        <AnamnesisQuestionsDefault
          id={id}
          defaultValues={defaultValues}
          setDefaultValues={setDefaultValues}
        />
      )
    }
  }

  return (
    <>
      <div className='col-span-2 mb-3 flex items-center gap-2'>
        <Tag
          label={t('anamnese_tab.default')}
          color={selectedType === 'default' ? 'blue' : 'neutral'}
          size='md'
          variant='secondary'
          onClick={() => handlePress('default')}
        />
        {!!template && (
          <Tag
            label={t('anamnese_tab.specialty')}
            color={selectedType === 'specialty' ? 'blue' : 'neutral'}
            size='md'
            variant='secondary'
            onClick={() => handlePress('specialty')}
          />
        )}
      </div>
      <BlankCard>{renderContent()}</BlankCard>
    </>
  )
}
