const patient_list_enUS = {
  title: 'Patients',
  search_placeholder: 'Search',
  columns: {
    name: 'Name',
    document: 'Document'
  },
  actions: {
    new: 'New patient'
  }
}

export default patient_list_enUS
