const client_list_enUS = {
  title: 'Clients',
  search_placeholder: 'Search by name...',
  columns: {
    name: 'Name',
    contract_period: 'Contract Period',
    status: 'Status',
    units_qty: 'Nº. of Units',
    actions: ''
  },
  actions: {
    details: 'Details',
    new_client: 'New client'
  },
  tags: {
    active: 'Active',
    inactive: 'Inactive'
  },
  contract_period: {
    no_date: 'no contract period',
    from_to: 'to'
  }
}

export default client_list_enUS
