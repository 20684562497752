import { Anamnesis, AnamnesisSectionDefault } from '../types'
import api from './api.service'

class AnamnesisService {
  static async getQuestionsDefault() {
    const response = await api.get<AnamnesisSectionDefault[]>('/anamnesis/form/default')
    return response.data
  }
  static async getByConsultationId(consultation_id: string) {
    const response = await api.get<Anamnesis>('/anamnesis/' + consultation_id)
    return response.data
  }
  static async save(anamnesis: Anamnesis) {
    const response = await api.post<Anamnesis>('/anamnesis', anamnesis)
    return response.data
  }
}

export default AnamnesisService
