import { useMemo } from 'react'
import Accordion from '../../../components/accordion'
import Checkbox from '../../../components/checkbox'
import PermissionEnum from '../../../types/permissions'
import { useTranslation } from 'react-i18next'

type Props = {
  title: string
  isDisable?: boolean
  currentPermissions: PermissionEnum[]
  permissionsList: PermissionEnum[]
  onChange: (values: PermissionEnum[]) => void
}

const PermissionsAccordion: React.FC<Props> = ({
  title,
  onChange,
  isDisable,
  permissionsList,
  currentPermissions = []
}) => {
  const { t } = useTranslation('profiles')

  const selectedFromList = useMemo(() => {
    return currentPermissions.filter(item => item && permissionsList.includes(item))
  }, [currentPermissions, permissionsList])

  const isAllChecked = useMemo(() => {
    return selectedFromList.length === permissionsList.length
  }, [selectedFromList, permissionsList])

  const isIndeterminate = useMemo(() => {
    return currentPermissions.some(item => item && permissionsList.includes(item))
  }, [currentPermissions, permissionsList])

  const isPermissionChecked = (permission: PermissionEnum) => {
    return currentPermissions.some(item => item === permission)
  }

  const handleChangeAll = () => {
    if (isDisable) return

    if (isAllChecked) {
      onChange(currentPermissions.filter(item => item && !permissionsList.includes(item)))
    } else {
      const unselected = permissionsList.filter(
        permission => !currentPermissions.some(item => item === permission)
      )

      onChange([...currentPermissions, ...unselected])
    }
  }

  const togglePermission = (permission: PermissionEnum) => {
    if (isDisable) return

    const isChecked = isPermissionChecked(permission)

    if (isChecked) {
      onChange(currentPermissions.filter(item => item !== permission))
    } else {
      onChange([...currentPermissions, permission])
    }
  }

  const getSubTitle = () => {
    return `${selectedFromList.length}/${permissionsList.length} Permissões`
  }

  const listToShow = isDisable
    ? selectedFromList.map(item => item as PermissionEnum)
    : permissionsList

  if (permissionsList.length === 0) return <></>

  return isDisable && listToShow.length === 0 ? (
    <></>
  ) : (
    <Accordion
      title={
        <div className='flex'>
          {!isDisable && (
            <Checkbox
              size='sm'
              isChecked={isAllChecked}
              onClick={handleChangeAll}
              state={isDisable ? 'disable' : 'enable'}
              isIndeterminate={!isAllChecked && isIndeterminate}
            />
          )}
          <div className='ml-2'>{t(`permission_groups.${title}`)}</div>
        </div>
      }
      subtitle={getSubTitle()}
    >
      <div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
        {listToShow.map(permission => (
          <div className='flex' key={permission}>
            {!isDisable && (
              <Checkbox
                size='sm'
                id={permission}
                isChecked={isPermissionChecked(permission)}
                onClick={() => togglePermission(permission)}
              />
            )}
            <label htmlFor={permission} className='ml-2'>
              {t(`permissions.${permission}`)}
            </label>
          </div>
        ))}
      </div>
    </Accordion>
  )
}

export default PermissionsAccordion
