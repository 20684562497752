import { useQuery } from '@tanstack/react-query'
import LocationService from '../services/location.service'

export const useGetAddressByCep = (cep: string) => {
  return useQuery({
    queryKey: ['address', cep],
    queryFn: () => LocationService.getAddressByCep(cep),
    enabled: cep.length === 8
  })
}

export const useGetStates = () => {
  return useQuery({
    queryKey: ['states'],
    queryFn: () => LocationService.getStates()
  })
}

export const useGetState = (uf?: string) => {
  return useQuery({
    queryKey: ['state', uf],
    queryFn: () => LocationService.getState(uf),
    enabled: !!uf
  })
}

export const useGetCitiesByState = (uf?: string) => {
  return useQuery({
    queryKey: ['cities', uf],
    queryFn: () => (uf ? LocationService.getCitiesByState(uf) : Promise.resolve(undefined)),
    enabled: !!uf
  })
}

export const useGetCity = (city_id?: string) => {
  return useQuery({
    queryKey: ['cities', city_id],
    queryFn: () => city_id && LocationService.getCity(city_id),
    enabled: !!city_id
  })
}
