export const calculateAge = (birthDate: Date | string | null | undefined): string | number => {
  if (!birthDate) {
    return '-'
  }

  const parsedDate = birthDate instanceof Date ? birthDate : new Date(birthDate)

  if (isNaN(parsedDate.getTime())) {
    return '-'
  }

  const today = new Date()
  let age = today.getFullYear() - parsedDate.getUTCFullYear()

  const monthDifference = today.getMonth() - parsedDate.getUTCMonth()
  const dayDifference = today.getDate() - parsedDate.getUTCDate()

  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--
  }

  return age
}
