const unit_detail_enUS = {
  fields: {
    name: 'Name',
    cnpj: 'CNPJ',
    cnes: 'National Health Establishment Register (CNES)',
    technical_manager: 'Technical Manager',
    email: 'Email',
    phone: 'Phone',
    cep: 'Postal Code',
    address: 'Address',
    address_number: 'Number',
    district: 'District',
    city: 'City',
    state: 'State',
    specialties: 'Specialties',
    edit_info: 'Edit Information',
    inactivate: 'Inactivate',
    activate: 'Activate'
  },
  modal: {
    title: 'Inactivate Unit',
    message_1:
      'Are you sure you want to inactivate this unit? All accesses linked to this client will be suspended.',
    message_2: 'This action is reversible and you can activate it again.',
    cancel: 'Cancel',
    confirm: 'Inactivate Unit'
  }
}

export default unit_detail_enUS
