import { io, Socket } from 'socket.io-client'
import StorageService from './storage.service'

class ChatService {
  socket?: Socket
  consultation_id?: string

  constructor() {
    const token = StorageService.getToken()

    this.socket = io(process.env.REACT_APP_API_URL!, {
      auth: {
        token: token
      }
    })
  }

  connectChat(consultation_id: string) {
    this.consultation_id = consultation_id
    this.socket?.emit('joinChat', this.consultation_id)
  }

  sendMessage(message: string) {
    this.socket?.emit('message', this.consultation_id, message)
  }
}

export default ChatService
