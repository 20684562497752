import { useState, useEffect } from 'react'
import SelectableModal from '../../../../components/modal/selectable-modal'
import { Specialty } from '../../../../types'
import { useGetClient } from '../../../../hooks/useClient'

interface Props {
  isOpen: boolean
  selectedValues: Specialty[]
  onClose: () => void
  onSuccess: (selectedValues: Specialty[]) => void
  client_id: string
}

const SpecialtyModal = ({ isOpen, selectedValues, onClose, onSuccess, client_id }: Props) => {
  const [specialties, setSpecialties] = useState<Specialty[]>([])
  const [selectedSpecialties, setSelectedSpecialties] = useState<Specialty[]>(selectedValues)
  const { data: client, isFetching } = useGetClient(client_id)

  useEffect(() => {
    if (isFetching || !client?.specialties) return

    const uniqueSpecialties = Array.from(
      new Map(
        client.specialties.map(item => [
          item.specialty?.id,
          { id: item.specialty_id, name: item.specialty?.name }
        ])
      ).values()
    )
    setSpecialties(uniqueSpecialties)
  }, [client?.specialties, isFetching])

  useEffect(() => {
    setSelectedSpecialties(selectedValues)
  }, [selectedValues, isOpen])

  const handleSuccess = (selectedValues: Specialty[]) => {
    setSelectedSpecialties(selectedValues)
    onSuccess(selectedValues)
  }

  const renderField = (item: Specialty) => (
    <div>
      <span className='text-label-md text-neutralContent-primary'>{item.name}</span>
    </div>
  )

  return (
    <SelectableModal
      title='Selecionar especialidades'
      isOpen={isOpen}
      search
      searchFieldLabel='Procure por uma especialidade'
      data={specialties}
      selectedValues={selectedSpecialties}
      renderField={renderField}
      onClose={onClose}
      onSuccess={handleSuccess}
      onLoadMore={() => {}}
    />
  )
}

export default SpecialtyModal
