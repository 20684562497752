const user_list_ptBR = {
  header: {
    name: 'Nome',
    email: 'Email',
    health_professional: 'Profissional e Saúde',
    status: 'Status'
  },
  status: {
    active: 'Ativo',
    inactive: 'Inativo'
  }
}

export default user_list_ptBR
