import { StylesConfig } from 'react-select'
import { DropdownOption } from '.'

export const getDropdownStyles = (
  disabled = false,
  error = false
): StylesConfig<DropdownOption, false> => ({
  control: (provided, state) => ({
    ...provided,
    border: disabled
      ? 'none'
      : state.isFocused
        ? '2px solid #007BBD'
        : error
          ? '2px solid #B81435'
          : '1px solid #DADEE7',
    boxShadow: state.isFocused && !disabled ? '0 0 0 1px #007BBD' : 'none',
    '&:hover': {
      border:
        state.isFocused && !disabled
          ? '2px solid #007BBD'
          : error
            ? '2px solid #B81435'
            : '1px solid #DADEE7'
    },
    borderRadius: '0.75rem',
    padding: '0.4rem',
    minHeight: '48px',
    backgroundColor: disabled ? '#F3F4F7' : 'white',
    cursor: disabled ? 'not-allowed' : 'default'
  }),
  placeholder: provided => ({
    ...provided,
    color: disabled ? '#A4AAB7' : '#7887A5'
  }),
  singleValue: provided => ({
    ...provided,
    color: disabled ? '#6B7280' : '#0F121A'
  }),
  menu: provided => ({
    ...provided,
    zIndex: 9999
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: disabled ? '#9CA3AF' : '#6b7280',
    '&:hover': {
      color: disabled ? '#9CA3AF' : '#374151'
    }
  })
})
