import React, { useState } from 'react'
import { getTimeDiff } from '../../helpers/getTimeDiff'
import { ConsultationInfo } from './ConsultationInfo'
import { Chats, Webcam } from '@phosphor-icons/react'
import FollowUpSkeleton from './follow-up-skeleton'
import { useTranslation } from 'react-i18next'
import { PersonInfo } from './ProfessionaInfo'
import Tag from '../../components/tag'
import { isPast } from 'date-fns'
import { CARE_TYPE, Consultation, CONSULTATION_STATUS, FOLLOW_UP_DISPLAY_MODE } from '../../types'
import ConsultationDetailModal from './Modals/consultation-detail-modal'
import ConsultationCountToast from './ConsultationCountToast'

interface FollowUpDisplayProps {
  isLoading?: boolean
  data: Consultation[]
  displayMode: FOLLOW_UP_DISPLAY_MODE
}

const FollowUpList: React.FC<FollowUpDisplayProps> = ({ data, displayMode, isLoading }) => {
  const { t } = useTranslation('consultation_follow_up')
  const [openConsultationDetailModal, setOpenConsultationDetailModal] = useState({
    isOpen: false,
    consultation: {}
  })

  if (isLoading) return <FollowUpSkeleton displayMode={displayMode} />

  const handleOpenConsultationDetailModal = (consultation: Consultation) => {
    setOpenConsultationDetailModal({ isOpen: true, consultation })
  }

  return (
    <div
      className={`grid w-full gap-4 ${displayMode === FOLLOW_UP_DISPLAY_MODE.LIST ? 'grid-cols-1' : 'sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3'}`}
    >
      {data?.map(consultation => (
        <div
          className='cursor-pointer gap-4'
          key={consultation.id}
          onClick={() => handleOpenConsultationDetailModal(consultation)}
        >
          <div className='flex h-full flex-col gap-2 rounded-3xl border border-neutralBorder-default p-4'>
            <div className='flex items-center justify-between'>
              <div className='flex flex-wrap items-center gap-1'>
                <div className='flex items-center gap-1'>
                  <div className='inline-flex'>
                    <Tag
                      label={
                        consultation.care_type === CARE_TYPE.CHAT ? (
                          <Chats size={12} />
                        ) : (
                          <Webcam size={12} />
                        )
                      }
                      variant='secondary'
                      color={consultation.care_type === CARE_TYPE.ELECTIVE_CARE ? 'blue' : 'red'}
                    />
                  </div>
                  <div>
                    <span
                      className={`text-label-md ${consultation.care_type === CARE_TYPE.ELECTIVE_CARE ? 'text-primaryBrand-primary' : 'text-highlightRed-pure'}`}
                    >
                      {t(`types.${consultation.care_type}`)}
                    </span>
                  </div>
                </div>
                <div>
                  <Tag
                    size={'xs'}
                    color='gray'
                    variant='tertiary'
                    label={'#' + consultation.code}
                  />
                </div>
              </div>
              {consultation.status === CONSULTATION_STATUS.IN_ROOM ? (
                <Tag label='Em andamento' color='green' size='xs' />
              ) : isPast(new Date(consultation.start_date || consultation.created_at!)) ? (
                <Tag
                  label={
                    getTimeDiff(
                      new Date(consultation.start_date || consultation.created_at!),
                      new Date()
                    ) + ' em espera'
                  }
                  color='red'
                  size='xs'
                />
              ) : (
                <Tag
                  label={
                    'Iniciará em ' +
                    getTimeDiff(
                      new Date(consultation.start_date || consultation.created_at!),
                      new Date()
                    )
                  }
                  color='blue'
                  size='xs'
                />
              )}
            </div>

            <div
              className={`grid grid-cols-1 gap-2 ${displayMode === FOLLOW_UP_DISPLAY_MODE.LIST ? 'md:grid-cols-4' : ''}`}
            >
              <ConsultationInfo consultation={consultation} />

              {!consultation.requester && <div />}
              {!consultation.specialist && <div />}
              {!consultation.patient && <div />}

              {consultation.requester && (
                <PersonInfo
                  type='requester'
                  displayMode={displayMode}
                  online={!!consultation.requester_entered_at}
                  name={consultation.requester.name || ''}
                />
              )}
              {consultation.specialist && (
                <PersonInfo
                  type='specialist'
                  displayMode={displayMode}
                  online={!!consultation.specialist_entered_at}
                  name={consultation.specialist.name || ''}
                />
              )}
              {consultation.patient && (
                <PersonInfo
                  type='patient'
                  displayMode={displayMode}
                  online={!!consultation.patient_entered_at}
                  name={consultation.patient?.name || ''}
                />
              )}
            </div>
          </div>
        </div>
      ))}

      <ConsultationDetailModal
        isOpen={openConsultationDetailModal.isOpen}
        onClose={() => setOpenConsultationDetailModal({ isOpen: false, consultation: {} })}
        consultation={openConsultationDetailModal.consultation}
      />
      <ConsultationCountToast />
    </div>
  )
}

export default FollowUpList
