import { CaretDoubleLeft } from '@phosphor-icons/react'
import Button from '../button'
import MenuItemList from './menu-item-list'
import { isMobile } from '../../helpers/device'
import { User } from '../../types'

const MainMenu: React.FC<{
  isOpen: boolean
  user: User
  handleMenu: (value: boolean) => void
}> = ({ isOpen, user, handleMenu }) => {
  const onNavigate = () => {
    if (isMobile()) {
      handleMenu(false)
    }
  }

  return (
    <div
      className={`fixed left-0 right-0 top-[64px] z-10 flex w-full flex-col overflow-hidden bg-white transition-all md:relative md:top-0 md:h-[calc(100dvh-65px)] md:overflow-visible md:py-4  ${isOpen ? 'h-screen pt-8 md:w-full-menu' : 'h-0 pt-0 md:w-short-menu'}`}
    >
      <div className='absolute bottom-0 h-[1px] w-full bg-neutralBorder-default' />

      <div className='absolute right-[-15px] top-[calc(50vh-15px)] hidden md:block'>
        <Button
          size='xs'
          variant='tertiary'
          className={`h-[32px] w-[32px] py-2 transition-all ${isOpen ? '' : 'rotate-180'} border border-neutralBorder-default`}
          onClick={() => handleMenu(!isOpen)}
          iconLeft={<CaretDoubleLeft size={14} className='text-black' />}
        />
      </div>

      <div className='flex flex-1 flex-col'>
        <MenuItemList onNavigate={onNavigate} isOpen={isOpen} userProfile={user?.profile} />
      </div>
    </div>
  )
}

export default MainMenu
