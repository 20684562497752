import { useState } from 'react'
import { useDeleteSpecialty, useSpecialtyFormik } from '../../../hooks/forms/useSpecialtyForm'
import CouncilSelect from '../../../components/dropdown/council-dropdown'
import SpecialtyService from '../../../services/specialty.service'
import { useToastContext } from '../../../contexts/toast'
import InputField from '../../../components/input'
import Switch from '../../../components/switch'
import Button from '../../../components/button'
import Modal from '../../../components/modal'
import { Specialty } from '../../../types'

interface Props {
  isModalOpen: boolean
  closeModal: () => void
  specialty?: Specialty
}

const SpecialtyFormModal = ({ isModalOpen, closeModal, specialty }: Props) => {
  const [mode, setMode] = useState('edit')
  const { toast } = useToastContext()
  const deleteFormik = useDeleteSpecialty(specialty, closeModal, setMode)

  const onSubmit = async (values: Partial<Specialty>) => {
    if (specialty) {
      await SpecialtyService.updateSpecialty(specialty?.id as string, values)
      toast.success('Especialidade atualizada')
    } else {
      await SpecialtyService.createSpecialty(values)
      toast.success('Especialidade criada')
    }
    closeModal()
  }

  const formik = useSpecialtyFormik(specialty, onSubmit)

  const handleDeleteMode = () => {
    setMode('delete')
    deleteFormik.resetForm()
  }

  const handleEditMode = () => {
    setMode('edit')
  }

  const handleClose = () => {
    formik.resetForm()
    deleteFormik.resetForm()
    setMode('edit')
    closeModal()
  }

  return (
    <Modal
      title={mode === 'edit' ? 'Editar especialidade' : 'Excluir especialidade'}
      isOpen={isModalOpen}
      onClose={handleClose}
    >
      <form
        onSubmit={mode === 'edit' ? formik.handleSubmit : deleteFormik.handleSubmit}
        className='w-full sm:w-[480px]'
      >
        {mode === 'delete' ? (
          <>
            <div className='mb-1'>
              <p className='text-body-md text-neutralContent-tertiary'>
                Tem certeza que deseja excluir essa especialidade? Essa ação é permanente e não
                poderá ser desfeita.
              </p>
              <br />
              <p className='text-body-md text-neutralContent-tertiary'>
                Para confirmar, digite o nome da especialidade.
              </p>
            </div>

            <div className='my-4 flex justify-center'>
              <InputField
                label=''
                id='name'
                name='name'
                onBlur={deleteFormik.handleBlur}
                value={deleteFormik.values.name}
                placeholder='Nome da especialidade'
                state={deleteFormik.touched.name && deleteFormik.errors.name ? 'error' : 'default'}
                onChange={deleteFormik.handleChange}
                hint={
                  deleteFormik.touched.name && deleteFormik.errors.name
                    ? deleteFormik.errors.name
                    : ''
                }
              />
            </div>
          </>
        ) : (
          <>
            <div className='my-4 flex justify-center'>
              <InputField
                id='name'
                name='name'
                label='Nome'
                onBlur={formik.handleBlur}
                value={formik.values.name || ''}
                onChange={formik.handleChange}
                placeholder='Conselho Regional de Medicina'
                hint={(formik.touched.name && formik.errors.name) || ''}
                state={formik.touched.name && formik.errors.name ? 'error' : 'default'}
              />
            </div>
            <div className='my-4 flex justify-center'>
              <CouncilSelect
                label='Conselho'
                name='council_id'
                onBlur={formik.handleBlur}
                placeholder='Selecione o conselho'
                onChange={option => formik.setFieldValue('council_id', option?.id || '')}
                value={formik.values.council_id || specialty?.council_id || ''}
                error={Boolean(formik.touched.council_id && formik.errors.council_id)}
                hint={
                  formik.touched.council_id && formik.errors.council_id
                    ? formik.errors.council_id
                    : ''
                }
              />
            </div>
            <div className='my-4 flex justify-center'>
              <InputField
                placeholder='RQE'
                label='Documento de qualificação (opcional)'
                hint={
                  formik.touched.document_name && formik.errors.document_name
                    ? formik.errors.document_name
                    : ''
                }
                onChange={formik.handleChange}
                value={formik.values.document_name || ''}
                name='document_name'
                state={
                  formik.touched.document_name && formik.errors.document_name ? 'error' : 'default'
                }
                onBlur={formik.handleBlur}
              />
            </div>

            {specialty && (
              <div className='mb-10 ml-2 items-center justify-center'>
                <div className='flex items-center justify-between'>
                  <div className='flex flex-col'>
                    <span className='text-body-md'>Status</span>
                    <span className='text-body-sm'>
                      {formik.values.is_active ? 'Ativo' : 'Inativo'}
                    </span>
                  </div>

                  <Switch
                    isChecked={Boolean(formik.values.is_active)}
                    onChange={() => formik.setFieldValue('is_active', !formik.values.is_active)}
                  />
                </div>
              </div>
            )}
          </>
        )}

        <div className='mt-10 flex w-full justify-between'>
          {mode === 'delete' ? (
            <div className='flex w-full items-center justify-end'>
              <Button
                size='sm'
                type='button'
                label='Cancelar'
                variant='tertiary'
                onClick={handleEditMode}
              />
              <Button
                size='sm'
                type='submit'
                state='error'
                label='Excluir'
                variant='primary'
                isLoading={deleteFormik.isSubmitting}
              />
            </div>
          ) : (
            <div className='flex w-full items-center justify-between'>
              {specialty && (
                <div>
                  <Button
                    size='sm'
                    type='button'
                    state='error'
                    variant='secondary'
                    label='Excluir especialidade'
                    onClick={handleDeleteMode}
                  />
                </div>
              )}
              <div className='ml-auto flex'>
                <Button
                  size='sm'
                  type='button'
                  label='Cancelar'
                  variant='tertiary'
                  onClick={handleClose}
                />
                <Button
                  size='sm'
                  type='submit'
                  label='Salvar'
                  variant='primary'
                  isLoading={formik.isSubmitting}
                />
              </div>
            </div>
          )}
        </div>
      </form>
    </Modal>
  )
}

export default SpecialtyFormModal
