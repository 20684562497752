const anamense_list_enUs = {
  title: 'Templates de Anamnese',
  subtitle: 'Crie e gerencie templates de fichas de anamnese',

  fields: {
    search: 'Procure por uma especialidade'
  },
  columns: {
    specialty: 'Especialidade',
    client: 'Cliente',
    unit: 'Unidade',
    status: 'Status'
  },
  actions: {
    new: 'Novo template'
  }
}

export default anamense_list_enUs
