import ButtonSkeleton from '../../../components/button/skeleton'
import ContentFooter from '../../../components/content/footer'
import InputSkeleton from '../../../components/input/skeleton'

const ClientFormSkeleton: React.FC = () => {
  return (
    <div className='grid grid-cols-1 gap-4 gap-y-8 md:grid-cols-2 lg:grid-cols-3'>
      <InputSkeleton showLabel />
      <InputSkeleton showLabel />
      <InputSkeleton showLabel />
      <InputSkeleton showLabel />
      <InputSkeleton showLabel />
      <InputSkeleton showLabel />
      <InputSkeleton showLabel />
      <InputSkeleton showLabel />

      <ContentFooter className='justify-end'>
        <ButtonSkeleton className='w-[80px]' />
      </ContentFooter>
    </div>
  )
}

export default ClientFormSkeleton
