import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Content from '../../../components/content'
import Steps, { StepsLabels } from './steps'
import { useTranslation } from 'react-i18next'
import ContentFooter from '../../../components/content/footer'
import Button from '../../../components/button'
import ContentForm from '../../../components/content/form'
import { Patient } from '../../../types'
import PatientService from '../../../services/patient.service'
import { ArrowLeft, ArrowRight, FloppyDisk, SpinnerGap } from '@phosphor-icons/react'
import Stepper from '../../../components/stepper'

const PatientFormPage: React.FC = () => {
  const { id } = useParams()
  const { t } = useTranslation('patient_form')
  const navigate = useNavigate()
  const [step, setStep] = useState(0)
  const formRef = useRef<HTMLButtonElement>(null)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const isEdit = id !== 'new'
  const [patient, setPatient] = useState<Partial<Patient>>({})
  const [isLoading, setIsLoading] = useState(isEdit)

  useEffect(() => {
    if (isEdit) {
      PatientService.getByDocument(id as string).then(data => {
        setPatient(data as Patient)
        setIsLoading(false)
      })
    }
  }, [isEdit, id])

  const handleBack = () => {
    navigate(-1)
  }

  const handleNextStep = async (val: Partial<Patient>) => {
    if (Steps.length === step + 1) {
      if (isEdit) {
        await PatientService.update({ ...patient, ...val })
        handleBack()
        return
      }
      await PatientService.create({ ...patient, ...val })
      handleBack()
      return
    }
    setPatient(previous => ({ ...previous, ...val }))
    setStep(p => p + 1)
  }

  const StepComponent = Steps[step]

  return (
    <Content title={t(isEdit ? 'title_update' : 'title_create')} subtitle={''} onBack={handleBack}>
      {isLoading ? (
        <div className='mt-10 flex h-full w-full items-center justify-center'>
          <SpinnerGap size={20} className='animate-spin' />
        </div>
      ) : (
        <>
          <ContentForm className='!flex-col md:flex' hasBackButton>
            <StepComponent
              value={patient}
              handleSubmit={handleNextStep}
              handleStateSubmit={setButtonDisabled}
              ref={formRef}
            />
          </ContentForm>
          <ContentFooter className={`justify-between gap-2`}>
            <Stepper
              title={t(StepsLabels[step])}
              currentStep={step + 1}
              totalSteps={Steps.length}
            />

            <div className='flex items-center gap-4'>
              {step >= 1 && (
                <button className='flex items-center gap-2' onClick={() => setStep(p => p - 1)}>
                  <ArrowLeft />
                  {t('actions.back')}
                </button>
              )}
              <Button
                state={buttonDisabled ? 'disable' : 'enable'}
                size='md'
                label={t(
                  Steps.length !== step + 1
                    ? 'actions.next'
                    : !isEdit
                      ? 'actions.end_create'
                      : 'actions.end_update'
                )}
                isLoading={false}
                onClick={() => {
                  if (formRef.current) formRef.current.click()
                }}
                iconRight={Steps.length !== step + 1 ? <ArrowRight /> : <FloppyDisk />}
              />
            </div>
          </ContentFooter>
        </>
      )}
    </Content>
  )
}

export default PatientFormPage
