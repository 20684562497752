/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import Table from '../../../../../components/table'
import { useGetLastConsultationsByPatient } from '../../../../../hooks/useConsultation'
import { PaginationParams } from '../../../../../types'
import { formatOnlyDate, formatOnlyHour } from '../../../../../helpers/formatDate'
import Tag from '../../../../../components/tag'
import { useGetClinicalInformation } from '../../../../../hooks/usePatient'
import { format } from 'date-fns'

const pageSize = 10

export const History: React.FC<{
  patient_id: string
  cpf: string
}> = ({ patient_id, cpf }) => {
  const { t } = useTranslation('consultation_details')
  const [params, setParams] = useState<PaginationParams>({ page: 0, pageSize, filter: '' })
  const [totalPages, setTotalPages] = useState(0)

  const { data: list, isFetching } = useGetLastConsultationsByPatient(patient_id || '', params)
  const { data: listMedications, isFetching: isFetchingMedications } =
    useGetClinicalInformation(cpf)

  useEffect(() => {
    if (list?.data?.totalItems) {
      setTotalPages(Math.ceil(list?.data?.totalItems / pageSize))
    }
  }, [list])

  const handlePage = (newPage: number) => {
    setParams(oldState => ({ ...oldState, page: newPage }))
  }

  const handleFilter = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setParams({ filter: value, page: 0, pageSize })
  }

  const columns = [
    {
      header: t('historic.columns.code'),
      accessor: 'id',
      className: 'flex justify-center sm:max-w-[200px] max-w-[150px]'
    },
    {
      header: t('historic.columns.date'),
      accessor: 'date',
      className: ' flex justify-center '
    },
    {
      header: t('historic.columns.hour'),
      accessor: 'hour',
      className: 'flex justify-center '
    },
    {
      header: t('historic.columns.specialty'),
      accessor: 'specialty',
      className: ' flex justify-center '
    },
    {
      header: t('historic.columns.care_type'),
      accessor: 'care_type',
      className: ' flex justify-center '
    },
    {
      header: t('historic.columns.unit'),
      accessor: 'units',
      className: 'flex justify-center '
    }
  ]

  const columnsMedications = [
    {
      header: t('historic.columns.name'),
      accessor: 'name',
      className: 'flex justify-center'
    },
    {
      header: t('historic.columns.dosage'),
      accessor: 'dosage',
      className: 'flex justify-center'
    },
    {
      header: t('historic.columns.dosage_desc'),
      accessor: 'dosage_desc',
      className: 'flex justify-center'
    },
    {
      header: t('historic.columns.treatment'),
      accessor: 'treatment',
      className: 'flex justify-center'
    },
    {
      header: t('historic.columns.duration'),
      accessor: 'duration',
      className: 'flex justify-center'
    }
  ]

  const tableData =
    list?.data.data?.map(
      (consultation: {
        code: string
        created_at: string
        hour: string
        specialty: { name: string }
        care_type: string
        unit: { name: string }
      }) => ({
        id: (
          <Tag
            label={
              consultation?.code.length > 10
                ? consultation?.code.slice(0, 10).concat('...')
                : consultation?.code
            }
            className='max-w-[150px] overflow-hidden truncate whitespace-nowrap'
            variant='secondary'
            color='neutral'
            size='sm'
          />
        ),
        date: `${formatOnlyDate(consultation.created_at)}`,
        hour: `${formatOnlyHour(consultation.created_at)}`,
        specialty: consultation.specialty.name,
        care_type: (
          <Tag
            label={t(`types.${consultation.care_type}`)}
            variant='secondary'
            color='blue'
            size='sm'
          />
        ),
        units: consultation.unit.name
      })
    ) ?? []

  const tableDataMedications =
    listMedications?.medicines?.map(medication => ({
      name: medication.name ?? 'Não informado',
      dosage: medication.dosage ?? 'Não informado',
      dosage_desc: medication.dosage_instructions ?? 'Não informado',
      treatment: format(medication.created_at!, 'dd/MM/yyyy'),
      duration: medication.dosage_instructions ?? 'Não informado'
    })) ?? []

  return (
    <div className='flex flex-col gap-5'>
      <div className='mb-6 mt-9'>
        <span className='mb-4 flex text-label-lg text-neutralContent-primary'>
          {t('historic.older_consultations')}
        </span>

        <Table
          data={tableData}
          columns={columns}
          isLoading={isFetching}
          totalPages={totalPages}
          currentPage={params.page}
          onPageChange={handlePage}
          sort={['date', 'hour']}
          className='max-h-[calc(100dvh-600px)] md:max-h-[calc(100dvh-520px)]'
        />
      </div>
      <div>
        <span className='mb-4 flex text-label-lg text-neutralContent-primary'>
          {t('historic.medications_in_use')}
        </span>

        <Table
          data={tableDataMedications}
          columns={columnsMedications}
          isLoading={isFetchingMedications}
          className='max-h-[calc(100dvh-600px)] md:max-h-[calc(100dvh-520px)]'
          emptyDataLabel='Nenhuma medicação em uso encontrada'
        />
      </div>
    </div>
  )
}
