import { Hospital } from '@phosphor-icons/react'
import { Unit } from '../../../../../types'
import Button from '../../../../../components/button'

export const SelectedUnits: React.FC<{
  units: Unit[]
  onRemove: (unitId: string) => void
}> = ({ units, onRemove }) => {
  return (
    <div className='rounded-lg border p-2'>
      {units.map(unit => (
        <div className='flex items-center justify-between border-b last:border-b-0' key={unit.id}>
          <div className='flex items-center gap-2'>
            <Hospital size={20} className='text-neutralContent-primary' />
            <span className='text-label-sm text-neutralContent-primary'>{unit.name}</span>
          </div>
          <Button
            label='Remover'
            onClick={() => onRemove(unit.id!)}
            variant='tertiary'
            state='error'
            size='md'
          />
        </div>
      ))}
    </div>
  )
}
