import { useTranslation } from 'react-i18next'
import BlankCard from '../../../../../components/blank-card'
import { Stethoscope } from '@phosphor-icons/react'
import Button from '../../../../../components/button'

export const SelectCard: React.FC<{
  onChange: () => void
  label: string
  isDisabled?: boolean
}> = ({ onChange, label, isDisabled = false }) => {
  const { t } = useTranslation('agendav2_list')

  return (
    <BlankCard className='flex h-14 items-center justify-between p-2'>
      <div className='flex items-center gap-2'>
        <Stethoscope size={20} className='text-primaryBrand-primary' />
        <span className='text-label-md text-primaryBrand-primary'>{label}</span>
      </div>
      <Button
        label={t('actions.select')}
        onClick={onChange}
        variant='secondary'
        size='sm'
        state={isDisabled ? 'disable' : 'enable'}
      />
    </BlankCard>
  )
}
