import React, { useEffect, useRef, useState } from 'react'
import { Specialty } from '../../../../../types'
import { useListSpecialty } from '../../../../../hooks/useSpecialty'
import Modal from '../../../../../components/modal'
import SearchField from '../../../../../components/search'

const TOTAL_PAGE = 10

interface Props {
  isOpen: boolean
  client_id?: string
  onClose: () => void
  selectedValue: Specialty | null
  onSuccess: (selectedValue: Specialty) => void
}

const SpecialtyModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSuccess,
  client_id,
  selectedValue
}) => {
  const [specialties, setSpecialties] = useState<Specialty[]>([])
  const [filter, setFilter] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [totalSpecialties, setTotalSpecialties] = useState(0)

  const { data: dataSpecialty } = useListSpecialty(currentPage, TOTAL_PAGE, filter, client_id)
  const scrollRef = useRef<HTMLDivElement>(null)
  const isFetchingRef = useRef(false)

  useEffect(() => {
    if (dataSpecialty?.data) {
      setTotalSpecialties(dataSpecialty.totalItems)
      setSpecialties(old =>
        dataSpecialty.page === 0 ? dataSpecialty.data : [...old, ...dataSpecialty.data]
      )
      isFetchingRef.current = false
    }
  }, [dataSpecialty])

  const onLoadMore = () => {
    if (specialties.length >= totalSpecialties) return
    setCurrentPage(prevPage => prevPage + 1)
  }

  const throttledLoadMore = () => {
    if (isFetchingRef.current) return
    isFetchingRef.current = true
    onLoadMore()
  }

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement
    if (target.scrollTop + target.clientHeight >= target.scrollHeight - 10) {
      throttledLoadMore()
    }
  }

  const handleSelectSpecialty = (specialty: Specialty) => {
    onSuccess(specialty)
    onClose()
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event
    setFilter(target.value)
    setCurrentPage(0)
    setSpecialties([])
  }

  return (
    <Modal isOpen={isOpen} title='Selecione a especialidade' onClose={onClose}>
      <div className='relative'>
        <SearchField
          placeholder='Procure por uma especialidade'
          onChange={handleSearch}
          value={filter}
          maxLength={100}
        />
      </div>
      <div
        ref={scrollRef}
        className='mt-4 h-[400px] w-[400px] overflow-y-auto'
        onScroll={handleScroll}
      >
        {specialties.map(specialty => (
          <div
            key={specialty.id}
            className={`cursor-pointer border-b py-2 ${
              selectedValue?.id === specialty.id ? 'bg-blue-100' : ''
            }`}
            onClick={() => handleSelectSpecialty(specialty)}
          >
            <span className='text-label-md text-neutralContent-primary'>{specialty.name}</span>
            <span className='block text-body-sm text-neutralContent-secondary'>
              {specialty.council?.name}
            </span>
          </div>
        ))}
      </div>
    </Modal>
  )
}

export default SpecialtyModal
