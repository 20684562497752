import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { PaginationParams, User } from '../types'
import ClientService, { ListUnitParams } from '../services/client.service'
import { useToastContext } from '../contexts/toast'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../routes/path'

export const useListClients = (
  params: PaginationParams,
  localRequest?: { getFromUser: boolean; user: User | null }
) => {
  return useQuery({
    queryKey: ['listClients', params],
    queryFn: () =>
      localRequest?.getFromUser
        ? Promise.resolve({
            data: localRequest.user?.clients?.map(item => item.client!) || [],
            totalItems: localRequest.user?.clients?.length || 0,
            page: 0,
            pageSize: localRequest.user?.clients?.length || 0,
            filter: ''
          })
        : ClientService.list(params),
    placeholderData: prev => prev
  })
}

export const useListUnitsClient = (client_id?: string, params?: ListUnitParams, user?: User) => {
  return useQuery({
    queryKey: ['listUnitsClient', { client_id, params }],
    queryFn: () =>
      !client_id
        ? null
        : params?.getFromUser
          ? Promise.resolve({
              data:
                user?.clients
                  ?.find(client => client.client_id === client_id)
                  ?.units?.map(item => item.unit!) || [],
              totalItems: user?.clients?.length || 0,
              page: 0,
              pageSize: user?.clients?.length || 0,
              filter: ''
            })
          : ClientService.listUnits(client_id, params),
    placeholderData: prev => prev
  })
}

export const useGetClient = (id?: string) => {
  return useQuery({
    queryKey: ['getClient', { id }],
    queryFn: () =>
      id && id !== 'new' && id !== ':client_id' ? ClientService.getById(id) : undefined,
    placeholderData: prev => prev,
    enabled: !!id
  })
}

export const useGetCount = (id: string) => {
  return useQuery({
    queryKey: ['countUnits', { id }],
    queryFn: () => ClientService.countUnits(id),
    placeholderData: prev => prev,
    enabled: !!id
  })
}

export const useGetUnitClient = (id: string, client_id: string) => {
  return useQuery({
    queryKey: ['getUnitClient', { id, client_id }],
    queryFn: () =>
      id !== 'new' ? ClientService.getUnitById(id, client_id) : Promise.resolve(undefined),
    placeholderData: prev => prev,
    enabled: !!id
  })
}

export const useActivationStatus = (id: string) => {
  const queryClient = useQueryClient()
  const { toast } = useToastContext()
  const navigate = useNavigate()

  return useMutation({
    mutationFn: () => ClientService.activation(id as string),
    onSuccess: data => {
      if (data.data.is_active) {
        toast.success('Cliente ativado')
      } else {
        toast.success('Cliente desativado')
      }
      queryClient.invalidateQueries({ queryKey: ['listClients'] })
      navigate(ROUTES.client.list)
    },
    onError: error => {
      toast.error(error.message || 'Algo deu errado')
    }
  })
}

export const useUnitActivationStatus = (id: string, client_id: string) => {
  const queryClient = useQueryClient()
  const { toast } = useToastContext()
  const navigate = useNavigate()

  return useMutation({
    mutationFn: () => ClientService.activationUnit(id as string),
    onSuccess: data => {
      if (data.data.is_active) {
        toast.success('Unidade ativada')
      } else {
        toast.success('Unidade desativada')
      }
      queryClient.invalidateQueries({ queryKey: ['listUnitsClient'] })
      navigate(ROUTES.client.unit.list.replace(':client_id', client_id))
    },
    onError: error => {
      toast.error(error.message || 'Algo deu errado')
    }
  })
}
