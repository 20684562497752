import React, { useEffect, useState } from 'react'
import { Specialty, Unit, User } from '../../../../../types'
import Modal from '../../../../../components/modal'
import SearchField from '../../../../../components/search'
import { useListSpecialists } from '../../../../../hooks/useUser'

interface Props {
  isOpen: boolean
  units?: Unit[]
  onClose: () => void
  selectedValue: User | null
  onSuccess: (selectedValue: User) => void
  specialty: Specialty
}

const SpecialistModal: React.FC<Props> = ({
  units,
  isOpen,
  onClose,
  onSuccess,
  selectedValue,
  specialty
}) => {
  const [filter, setFilter] = useState('')
  const [specialists, setSpecialists] = useState<User[]>([])

  const { data: specialistsData } = useListSpecialists({
    specialties: specialty.id ? [specialty.id] : [],
    unit_ids: units?.map(unit => unit.id!),
    name: filter
  })

  useEffect(() => {
    if (specialistsData) {
      setSpecialists(specialistsData)
    }
  }, [specialistsData])

  const handleSelectSpecialist = (specialist: User) => {
    onSuccess(specialist)
    onClose()
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value)
  }

  return (
    <Modal isOpen={isOpen} title='Selecione o especialista' onClose={onClose}>
      <div className='relative'>
        <SearchField
          placeholder='Procure por um especialista'
          onChange={handleSearch}
          value={filter}
          maxLength={100}
        />
      </div>
      <div className='mt-4 h-[400px] w-[400px] overflow-y-auto'>
        {specialists.map(specialist => (
          <div
            key={specialist.id}
            className={`cursor-pointer border-b py-2 ${
              selectedValue?.id === specialist.id ? 'bg-blue-100' : ''
            }`}
            onClick={() => handleSelectSpecialist(specialist)}
          >
            <span className='text-label-md text-neutralContent-primary'>{specialist.name}</span>
            <span className='block text-body-sm text-neutralContent-secondary'>
              {specialist.email}
            </span>
          </div>
        ))}
      </div>
    </Modal>
  )
}

export default SpecialistModal
