import React from 'react'
import { Props } from '../types'
import InputField from '../../../../../../../components/input'
import DatePickerWithCalendar from '../../../../../../../components/datepicker'
import { useTranslation } from 'react-i18next'

const PassportForm: React.FC<Props> = ({ formik }) => {
  const { t } = useTranslation('patient_form')

  return (
    <div className='flex w-full flex-col gap-8'>
      <div className='flex w-full gap-6'>
        <InputField
          id='document_number'
          name='document_number'
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          label={t('fields.passport_number')}
          placeholder={t('fields.passport_number_placeholder')}
          value={formik.values.document_number || ''}
          hint={
            (formik.touched.document_number && formik.errors.document_number
              ? formik.errors.document_number
              : undefined) as string
          }
        />

        <InputField
          id='document_agency'
          name='document_agency'
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          label={t('fields.passport_country')}
          placeholder={t('fields.passport_country_placeholder')}
          value={formik.values.document_agency || ''}
          hint={
            (formik.touched.document_agency && formik.errors.document_agency
              ? formik.errors.document_agency
              : undefined) as string
          }
          state={
            formik.touched.document_agency && formik.errors.document_agency ? 'error' : 'default'
          }
        />

        <DatePickerWithCalendar
          name='document_date'
          label={t('fields.naturalization_date')}
          placeholder={t('fields.select_date')}
          onChange={val => formik.setFieldValue('document_date', val)}
          onBlur={formik.handleBlur}
          value={!!formik.values.document_date ? new Date(formik.values.document_date) : null}
          disabled={formik.isSubmitting}
          error={formik.errors.document_date as string}
          touched={formik.touched.document_date as boolean}
        />
      </div>

      <DatePickerWithCalendar
        name='document_validity'
        label={t('fields.validity')}
        placeholder={t('fields.select_date')}
        onChange={val => formik.setFieldValue('document_validity', val)}
        onBlur={formik.handleBlur}
        value={!!formik.values.document_validity ? new Date(formik.values.document_validity) : null}
        disabled={formik.isSubmitting}
        error={formik.errors.document_validity as string}
        touched={formik.touched.document_validity as boolean}
      />
    </div>
  )
}

export default PassportForm
