import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAuth } from '../../contexts/auth'
import { useToastContext } from '../../contexts/toast'
import InputField from '../../components/input'
import { ArrowRight } from '@phosphor-icons/react/dist/ssr'
import { useState } from 'react'
import { SpinnerGap } from '@phosphor-icons/react'
import { User } from '../../types'

interface LoginFormValues {
  email: string
  password: string
}

const initialValues: LoginFormValues = {
  email: '',
  password: ''
}

const validationSchema = Yup.object({
  email: Yup.string().email('Email inválido').required('O email é obrigatório'),
  password: Yup.string().required('A senha é obrigatória')
})

interface LoginFormProps {
  onSuccess?: (user?: User) => void
}

const LoginForm = ({ onSuccess = () => {} }: LoginFormProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const { toast } = useToastContext()
  const { login } = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: LoginFormValues) => {
      setIsLoading(true)
      try {
        const user = await login(values)
        onSuccess(user)
      } catch (err) {
        console.log({ err })
        toast.error((err as Error).message || 'Algo deu errado')
      }
      setIsLoading(false)
    }
  })

  return (
    <form onSubmit={formik.handleSubmit} className='mx-auto flex max-w-[350px] flex-col gap-8'>
      <div className='flex flex-col'>
        <InputField
          id='email'
          name='email'
          type='email'
          maxLength={100}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          onChange={formik.handleChange}
          placeholder='Digite seu e-mail'
          hint={formik.touched.email && formik.errors.email ? formik.errors.email : undefined}
        />
      </div>

      <div className='flex flex-col'>
        <InputField
          id='password'
          name='password'
          type='password'
          maxLength={100}
          onBlur={formik.handleBlur}
          placeholder='Digite sua senha'
          onChange={formik.handleChange}
          value={formik.values.password}
          hint={
            formik.touched.password && formik.errors.password ? formik.errors.password : undefined
          }
        />
      </div>

      <button
        type='submit'
        className='relative w-full rounded-full bg-primaryBrand-primary p-4 text-white transition-all hover:bg-primaryBrand-dark active:bg-primaryBrand-primary'
      >
        {isLoading ? (
          <div className={` bottom-0 left-0 right-0 top-0 flex items-center justify-center `}>
            <SpinnerGap size={24} className='animate-spin' />
          </div>
        ) : (
          <>
            Entrar <ArrowRight color='white' size={24} className='absolute right-4 top-4 ml-auto' />
          </>
        )}
      </button>
    </form>
  )
}

export default LoginForm
