import { useEffect, useState, FocusEvent } from 'react'
import InputSkeleton from '../input/skeleton'
import { Council } from '../../types'
import Dropdown from '.'
import { useListCouncil } from '../../hooks/useCouncil'

type Option = { value: string; label: string }

type Props = {
  label: string
  name: string
  hint?: string
  value: string
  error?: boolean
  disabled?: boolean
  placeholder?: string
  onChange: (op?: Council) => void
  onBlur?: (e: FocusEvent<unknown, Element>) => void
}

const CouncilSelect: React.FC<Props> = ({ onChange, ...props }) => {
  const [hasMore, setHasMore] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const [options, setOptions] = useState<Option[]>([])

  const { data, isFetching } = useListCouncil(currentPage, 20, '')

  useEffect(() => {
    if (data && data.data) {
      setOptions(current => [
        ...(data.page >= 1 ? current : []),
        ...data.data.map((council: Council) => ({
          value: council.id || '',
          label: council.name || ''
        }))
      ])
      setHasMore(data.totalItems > (data.page + 1) * data.pageSize)
    }
  }, [data])

  const loadMoreOptions = () => {
    if (hasMore) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handleSelect = (option: { value: string; label: string } | null) => {
    if (!option) onChange(undefined)

    onChange(data?.data.find(profile => profile.id === option?.value))
  }

  if (isFetching) return <InputSkeleton showLabel className='flex-1' />

  return (
    <Dropdown
      {...props}
      options={options}
      onChangeValue={handleSelect}
      loadMoreOptions={loadMoreOptions}
    />
  )
}

export default CouncilSelect
