/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react'
import { useGetStates } from '../../hooks/useLocation'
import Dropdown, { DropdownOption } from '.'
import InputSkeleton from '../input/skeleton'

interface StateDropdownProps {
  hint?: string
  label?: string
  error?: boolean
  disabled?: boolean
  placeholder?: string
  value: string
  onBlur: (event: React.FocusEvent<any>) => void
  onChange: (option: { value: string; label: string } | null) => void
}

const StateDropdown: React.FC<StateDropdownProps> = ({
  hint,
  value,
  label,
  error,
  onBlur,
  onChange,
  disabled = false,
  placeholder = 'Selecione'
}) => {
  const { data: states } = useGetStates()

  const options: DropdownOption[] = useMemo(
    () =>
      states
        ? states.map(
            (state: { sigla: string; nome: string }): DropdownOption => ({
              value: state.sigla,
              label: state.nome
            })
          )
        : [],
    [states]
  )

  if (!states) return <InputSkeleton />

  return (
    <Dropdown
      hint={hint}
      name='state'
      value={value}
      error={error}
      onBlur={onBlur}
      options={options}
      disabled={disabled}
      onChangeValue={onChange}
      label={label || 'Estado'}
      placeholder={placeholder}
    />
  )
}

export default StateDropdown
