import { useMutation, useQueryClient } from '@tanstack/react-query'
import SpecialtyService from '../../services/specialty.service'
import { useToastContext } from 'semente-js'
import { Specialty } from '../../types'
import { useFormik } from 'formik'
import * as Yup from 'yup'

export const useSpecialtyFormik = (
  specialty: Partial<Specialty> | undefined,
  onSubmit: (values: Partial<Specialty>) => void
) => {
  const initialValues = {
    name: specialty?.name || '',
    council_id: specialty?.council_id || '',
    document_name: specialty?.document_name || '',
    is_active: specialty ? specialty?.is_active : true
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('Preencha o nome'),
    council_id: Yup.string().required('Selecione o conselho'),
    is_active: Yup.string().required('Status do conselho deve ser informado')
  })

  return useFormik<Specialty>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit
  })
}

export const useDeleteSpecialty = (
  specialty: Specialty | null | undefined,
  closeModal: () => void,
  setMode: (value: 'edit' | 'delete') => void
) => {
  const { toast } = useToastContext()
  const queryClient = useQueryClient()

  const formik = useFormik({
    initialValues: { name: '' },
    validationSchema: Yup.object({
      name: Yup.string().required('É necessário confirmar o nome para excluir')
    }),
    onSubmit: values => {
      if (values.name === specialty?.name) {
        deleteMutation.mutate()
      } else {
        formik.setFieldError('name', 'Nome da especialidade incorreto')
        formik.setSubmitting(false)
      }
    }
  })

  const deleteMutation = useMutation({
    mutationFn: () => SpecialtyService.deleteSpecialty(specialty?.id as string),
    onSuccess: () => {
      toast.success('Especialidade excluída com sucesso')
      queryClient.invalidateQueries({ queryKey: ['specialtyList'] })
      closeModal()
      setMode('edit')
    },
    onError: error => {
      toast.error(error.message || 'Algo deu errado')
    }
  })

  return formik
}
