import { useEffect, useState, FocusEvent, useMemo } from 'react'
import { useListProfiles } from '../../hooks/useProfile'
import InputSkeleton from '../input/skeleton'
import { Client, Profile } from '../../types'
import Dropdown from '.'
import { useAuth } from '../../contexts/auth'
import PermissionEnum from '../../types/permissions'

type Option = { value: string; label: string }

type Props = {
  label: string
  name: string
  hint?: string
  value: string
  error?: boolean
  disabled?: boolean
  placeholder?: string
  profileOptions?: Profile[]
  clients?: Client[]
  onChange: (profile?: Profile) => void
  onBlur?: (e: FocusEvent<unknown, Element>) => void
}

const ProfileSelect: React.FC<Props> = ({ onChange, profileOptions, clients, ...props }) => {
  const [hasMore, setHasMore] = useState(true)
  const { hasAccess, user } = useAuth()
  const [currentPage, setCurrentPage] = useState(0)
  const [options, setOptions] = useState<Option[]>([])
  const [customOptions, setCustomOptions] = useState<Option[]>()

  const { data: profiles, isFetching } = useListProfiles(currentPage, 20, '')

  useEffect(() => {
    if (hasAccess(PermissionEnum.register_profile)) {
      if (profiles && profiles.data) {
        setOptions(current => [
          ...(profiles.page >= 1 ? current : []),
          ...profiles.data.map((profile: Profile) => ({
            value: profile.id || '',
            label: profile.name || ''
          }))
        ])
        setHasMore(profiles.totalItems > (profiles.page + 1) * profiles.pageSize)
      }
    } else {
      console.log({
        clients,
        user_clients: user?.clients?.filter(client =>
          clients?.some(item => item.id === client.client_id)
        )
      })
      const profiles = user?.clients
        ?.filter(client => clients?.some(item => item.id === client.client_id))
        ?.reduce(
          (acc, item) => [
            ...acc,
            ...(item.client?.profiles?.map(client_profile => client_profile.profile!) || [])
          ],
          [] as Profile[]
        )

      setOptions(profiles?.map(item => ({ value: item.id!, label: item.name! })) || [])
    }
  }, [clients, hasAccess, profiles, user?.clients])

  useEffect(() => {
    if (profileOptions) {
      setCustomOptions(
        profileOptions.map(profile => ({ label: profile.name || '', value: profile.id || '' }))
      )
    } else {
      setCustomOptions(undefined)
    }
  }, [profileOptions])

  const loadMoreOptions = () => {
    if (hasMore) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handleSelect = (option: { value: string; label: string } | null) => {
    if (!option) onChange(undefined)

    let profile: Profile | undefined

    if (profileOptions) {
      profile = profileOptions.find(profile => profile.id === option?.value)
    } else {
      profile = profiles?.data.find(profile => profile.id === option?.value)
    }

    onChange(profile)
  }

  const currentOptions = useMemo(() => customOptions || options, [customOptions, options])

  if (isFetching) return <InputSkeleton showLabel className='flex-1' />

  return (
    <Dropdown
      {...props}
      onChangeValue={handleSelect}
      options={currentOptions}
      loadMoreOptions={loadMoreOptions}
    />
  )
}

export default ProfileSelect
