import { useMemo } from 'react'

const ContentForm: React.FC<{
  children: React.ReactNode
  className?: string
  hasSubtitle?: boolean
  hasBackButton?: boolean
  hasNotTitle?: boolean
}> = ({ children, className, hasSubtitle, hasBackButton, hasNotTitle }) => {
  const hight = useMemo(() => {
    if (hasNotTitle) return 'h-[calc(100dvh-350px)] sm:h-[calc(100dvh-340px)]'
    if (hasSubtitle && hasBackButton) return 'h-[calc(100dvh-350px)] sm:h-[calc(100dvh-400px)]'
    if (hasBackButton || hasSubtitle) return 'h-[calc(100dvh-300px)] sm:h-[calc(100dvh-364px)]'
    return 'h-[calc(100dvh-320px)]'
  }, [hasSubtitle, hasBackButton, hasNotTitle])

  return (
    <div
      className={`${className} mx-[-16px] grid grid-cols-1 content-start gap-4 gap-y-8 overflow-y-auto overflow-x-hidden px-4 pb-20 md:grid-cols-2  ${hight}`}
    >
      {children}
    </div>
  )
}

export default ContentForm
