import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  className?: string
  onClick?: () => void
}
const BlankCard: React.FC<Props> = ({ children, className, onClick }: Props) => {
  return (
    <div
      onClick={() => onClick?.()}
      className={`rounded-xl border border-neutralBorder-default p-4 ${className} ${!!onClick ? 'cursor-pointer' : ''}`}
    >
      {children}
    </div>
  )
}

export default BlankCard
