import { useQuery } from '@tanstack/react-query'
import countriesJSON from '../helpers/countries-ptBR.json'

export const useListCountries = () => {
  return useQuery({
    queryKey: ['listCountries'],
    queryFn: async () => {
      return countriesJSON.map((country: { nome_pais: string; sigla: string }) => ({
        value: country.nome_pais,
        label: country.nome_pais
      }))
    },
    placeholderData: []
  })
}
