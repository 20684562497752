const consultation_list_enUS = {
  title: 'Consultations',
  columns: {
    code: 'Code',
    date: 'Date',
    patient: 'Patient',
    specialist: 'Specialist',
    consultation: 'Consultation',
    consultation_type: {
      elective: 'Elective',
      urgency: 'Urgency'
    },
    status: 'Status',
    status_type: {
      SCHEDULED: 'Scheduled'
    },
    unit: 'Unit'
  },
  actions: {
    elective: 'Elective Consultation',
    emergency: 'Emergency Care',
    transfer: 'Transfer Consultations'
  }
}

export default consultation_list_enUS
