import { useAuth } from '../../contexts/auth'
import React from 'react'
import { Navigate, RouteProps, Outlet } from 'react-router-dom'
import MainMenu from '../main-menu'
import TopBar from '../top-bar'
import { useMenu } from '../../contexts/menu'

const ProtectedLayout: React.FC<RouteProps> = () => {
  const { user, logout, logo } = useAuth()
  const { isOpen, setIsOpen } = useMenu()

  const onLogout = () => {
    logout()
    setIsOpen(false)
  }

  if (!user) {
    return <Navigate to='/login' state={{ from: location.pathname }} replace />
  }

  return (
    <div className='flex flex-col'>
      <TopBar isOpen={isOpen} handleMenu={setIsOpen} logo={logo} user={user} onLogout={onLogout} />
      <div className='flex flex-row'>
        <MainMenu isOpen={isOpen} handleMenu={setIsOpen} user={user} />
        <div className='flex-1'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default ProtectedLayout
