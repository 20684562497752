import React from 'react'
import TopBar from '../../../components/top-bar'

import BirthdateValidator from './birthdate-validator'
import VideoConsultation from './video-consultation'

const ConsultationJoinPage: React.FC = () => {
  const [isBirthdateValidated, setIsBirthdateValidated] = React.useState(false)

  return (
    <div className='flex min-h-[100vh] w-[100vw] flex-col'>
      <TopBar isOpen={false} handleMenu={() => {}} user={null} onLogout={() => {}} />

      <div className='m-6 grid h-full flex-1 items-baseline rounded-2xl bg-white'>
        {!isBirthdateValidated && (
          <BirthdateValidator
            onSuccess={() => {
              setIsBirthdateValidated(true)
            }}
          />
        )}

        {isBirthdateValidated && <VideoConsultation />}
      </div>
    </div>
  )
}

export default ConsultationJoinPage
