const unit_detail_ptBR = {
  fields: {
    name: 'Nome',
    cnpj: 'CNPJ',
    cnes: 'Cadastro Nacional de Estabelecimentos de Saúde (CNES)',
    technical_manager: 'Responsável Técnico',
    permissions: 'Permissões',
    email: 'E-mail',
    phone: 'Telefone',
    cep: 'CEP',
    address: 'Logradouro',
    address_number: 'Número',
    district: 'Bairro',
    city: 'Cidade',
    state: 'Estado',
    specialties: 'Especialidades',
    edit_info: 'Editar informações',
    inactivate: 'Inativar',
    activate: 'Ativar'
  },
  modal: {
    title: 'Inativar unidade',
    message_1:
      'Tem certeza que deseja inativar essa unidade? Todos os acessos vinculados a este cliente serão suspensos.',
    message_2: 'Essa ação é reversível e você poderá ativá-lo novamente.',
    cancel: 'Cancelar',
    confirm: 'Inativar unidade'
  }
}

export default unit_detail_ptBR
