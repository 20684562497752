/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useClientProfileFormik } from '../../../hooks/forms/useClientForm'
import SpecialtyModal from '../../../components/modal/specialty-modal'
import { removeDuplicates } from '../../../helpers/removeDuplicates'
import ProfilesModal from '../../../components/modal/profiles-modal'
import ContentFooter from '../../../components/content/footer'
import StepperNumber from '../../../components/stepper-number'
import ContentForm from '../../../components/content/form'
import TagSelector from '../../../components/tag-selector'
import ColorPicker from '../../../components/color-picker'
import BlankCard from '../../../components/blank-card'
import { UploadSimple } from '@phosphor-icons/react'
import Checkbox from '../../../components/checkbox'
import InputField from '../../../components/input'
import Avatar from '../../../components/avatar'
import Button from '../../../components/button'
import { useTranslation } from 'react-i18next'
import { Client } from '../../../types'
import { FormikProps } from 'formik'
import { useToastContext } from '../../../contexts/toast'

type Props = {
  onSuccess: (values: Partial<Client>) => void
  onCancel: () => void
  initialValues?: Partial<Client>
  isEdit?: boolean
  loading?: boolean
}

const ClientProfileForm: React.FC<Props> = ({
  onSuccess,
  initialValues,
  isEdit = false,
  loading
}) => {
  const { t } = useTranslation('client_form')
  const clientFormik = useClientProfileFormik(onSuccess, initialValues)
  const { toast } = useToastContext()
  const [isModalSpecialtyOpen, setIsModalSpecialtyOpen] = useState(false)
  const [isModalProfilesOpen, setIsModalProfilesOpen] = useState(false)
  const [uploadStatus, setUploadStatus] = useState<'idle' | 'loading' | 'completed' | 'error'>(
    'idle'
  )
  const [imageName, setImageName] = useState<string>('')
  const [displaySpecialties, setDisplaySpecialties] = useState<any[]>(
    clientFormik.values.specialties || []
  )
  const [displayProfiles, setDisplayProfiles] = useState<any[]>(clientFormik.values.profiles || [])

  useEffect(() => {
    if (initialValues) {
      setDisplaySpecialties(
        removeDuplicates(
          initialValues.specialties?.map(s => ({ id: s.specialty_id, name: s.specialty?.name })) ||
            [],
          'id'
        )
      )
      setDisplayProfiles(
        removeDuplicates(
          initialValues.profiles?.map(p => ({ id: p.profile_id, name: p.profile?.name })) || [],
          'id'
        )
      )
    }
  }, [initialValues])

  const handleSuccessSpecialties = (selectedValues: any[]) => {
    const uniqueSelectedValues = removeDuplicates(selectedValues, 'id')
    setDisplaySpecialties(uniqueSelectedValues)
    clientFormik.setFieldValue(
      'specialties',
      uniqueSelectedValues.map(value => ({ specialty_id: value.id }))
    )
    setIsModalSpecialtyOpen(false)
  }

  const handleSuccessProfiles = (selectedValues: any[]) => {
    const uniqueSelectedValues = removeDuplicates(selectedValues, 'id')
    setDisplayProfiles(uniqueSelectedValues)
    clientFormik.setFieldValue(
      'profiles',
      uniqueSelectedValues.map(value => ({ profile_id: value.id }))
    )
    setIsModalProfilesOpen(false)
  }

  const handleRemoveItem = async (id: string, type: 'specialty' | 'profile') => {
    if (type === 'specialty') {
      setDisplaySpecialties(displaySpecialties.filter(s => s.id !== id))
      await clientFormik.setFieldValue(
        'specialties',
        clientFormik.values.specialties?.filter(s => s.specialty_id !== id)
      )
    } else {
      setDisplayProfiles(displayProfiles.filter(p => p.id !== id))
      await clientFormik.setFieldValue(
        'profiles',
        clientFormik.values.profiles?.filter(p => p.profile_id !== id)
      )
    }
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp']
      if (!validImageTypes.includes(file.type)) {
        setUploadStatus('error')
        toast.error('Por favor, selecione um arquivo de imagem válido (JPEG, PNG, ou WEBP).')
        return
      }

      setImageName(file.name)
      try {
        await clientFormik.setFieldValue('image', URL.createObjectURL(file))
        setUploadStatus('completed')
      } catch {
        setUploadStatus('error')
      }
    }
  }

  return (
    <form onSubmit={clientFormik.handleSubmit}>
      <ContentForm hasSubtitle hasBackButton>
        <UnitLimitField clientFormik={clientFormik as FormikProps<any>} t={t} />

        <TagSelectorField
          label={t('fields.profile.specialties')}
          items={displaySpecialties}
          onSelect={() => setIsModalSpecialtyOpen(true)}
          onRemove={item => handleRemoveItem(item.id, 'specialty')}
          buttonLabel={t('actions.select_specialties')}
        />

        <CheckboxField
          isChecked={clientFormik.values.allow_profile_access_to_client!}
          onChange={async val =>
            await clientFormik.setFieldValue('allow_profile_access_to_client', val)
          }
          label={t('fields.profile.allow_profile_access_to_client')}
        />

        {clientFormik.values.allow_profile_access_to_client && (
          <TagSelectorField
            label={t('fields.profile.allowed_profiles')}
            items={displayProfiles}
            onSelect={() => setIsModalProfilesOpen(true)}
            onRemove={item => handleRemoveItem(item.id, 'profile')}
            buttonLabel={t('actions.select_profiles')}
          />
        )}

        <BlankCard className='col-span-2 flex flex-col gap-2'>
          <span className='font-semibold'>{'Dominio (DNS)'}</span>
          <InputField
            id='domain'
            name='domain'
            onChange={clientFormik.handleChange}
            value={clientFormik.values.domain}
            placeholder='www.dominio.com.br'
          />
        </BlankCard>

        <BlankCard className='col-span-2'>
          <AvatarUploadField
            client={initialValues}
            imageName={imageName}
            imageUrl={clientFormik.values.image}
            uploadStatus={uploadStatus}
            onFileChange={handleFileChange}
            t={t}
          />
          <ColorPickerField
            value={clientFormik.values.primary_color!}
            onChange={async value => await clientFormik.setFieldValue('primary_color', value)}
            t={t}
          />
        </BlankCard>

        <SpecialtyModal
          isOpen={isModalSpecialtyOpen}
          selectedValues={displaySpecialties}
          onClose={() => setIsModalSpecialtyOpen(false)}
          onSuccess={handleSuccessSpecialties}
        />
        <ProfilesModal
          isOpen={isModalProfilesOpen}
          selectedValues={displayProfiles}
          onClose={() => setIsModalProfilesOpen(false)}
          onSuccess={handleSuccessProfiles}
        />
        <ContentFooter className='justify-end gap-2'>
          <Button
            state={!clientFormik.isValid ? 'disable' : 'enable'}
            type='submit'
            size='md'
            label={isEdit ? t('actions.save') : t('actions.create_client')}
            isLoading={loading && (loading || clientFormik.isSubmitting)}
          />
        </ContentFooter>
      </ContentForm>
    </form>
  )
}

const UnitLimitField: React.FC<{
  clientFormik: FormikProps<Partial<Client>>
  t: (key: string) => string
}> = ({ clientFormik, t }) => (
  <div className='col-span-2 flex items-center justify-between'>
    <div>
      <label className='text-heading-md'>{t('fields.profile.units_limit')}</label>
      <p className='text-sm'>{t('fields.profile.units_limit_hint')}</p>
    </div>
    <div>
      <StepperNumber
        hint={
          clientFormik.touched.units_limit && clientFormik.errors.units_limit
            ? clientFormik.errors.units_limit
            : ''
        }
        name='units_limit'
        value={clientFormik.values.units_limit ?? 0}
        onChange={clientFormik.handleChange}
        onBlur={clientFormik.handleBlur}
        min={1}
        max={100}
        step={1}
        state={'default'}
      />
    </div>
  </div>
)

const CheckboxField: React.FC<{
  isChecked: boolean
  onChange: (val: boolean) => void
  label: string
}> = ({ isChecked, onChange, label }) => (
  <div className='mt-2 flex items-center gap-2'>
    <Checkbox size='md' isChecked={isChecked} onClick={onChange} />
    <span className='text-label-lg font-bold text-neutralContent-primary'>{label}</span>
  </div>
)

const TagSelectorField: React.FC<{
  label: string
  items: { id: string; name: string }[]
  onSelect: () => void
  onRemove: (item: { id: string; name: string }) => void
  buttonLabel: string
}> = ({ label, items, onSelect, onRemove, buttonLabel }) => (
  <div className='col-span-2'>
    <TagSelector
      label={label}
      selectedItems={items}
      onSelect={onSelect}
      onRemove={onRemove}
      buttonLabel={buttonLabel}
    />
  </div>
)

const AvatarUploadField: React.FC<{
  client?: Partial<Client>
  imageName: string
  imageUrl?: string
  uploadStatus: 'idle' | 'loading' | 'completed' | 'error'
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  t: (key: string) => string
}> = ({ imageName, imageUrl, client, uploadStatus, onFileChange, t }) => (
  <div className='mb-8 flex items-center justify-between'>
    <div className='flex items-center'>
      <Avatar className='mr-2' imageUrl={imageUrl} client={client} size={'sm'} />
      <div className='flex flex-col'>
        <span className='text-label-md text-neutralContent-primary'>
          {imageName || t('fields.profile.no_image_selected')}
        </span>
        <span className='text-body-sm text-neutralContent-secondary'>
          {uploadStatus === 'loading' && t('fields.profile.uploading')}
          {uploadStatus === 'completed' && t('fields.profile.completed')}
          {uploadStatus === 'error' && t('fields.profile.error')}
        </span>
      </div>
    </div>

    <input
      type='file'
      id='avatar-upload'
      style={{ display: 'none' }}
      onChange={onFileChange}
      accept='image/*'
    />
    <Button
      type='button'
      label={imageName ? t('actions.change_file') : t('actions.select_file')}
      onClick={() => document.getElementById('avatar-upload')?.click()}
      variant='secondary'
      size={'sm'}
      iconLeft={<UploadSimple size={18} />}
    />
  </div>
)

const ColorPickerField: React.FC<{
  value: string
  onChange: (value: string) => void
  t: (key: string) => string
}> = ({ value, onChange, t }) => (
  <div className='flex items-center justify-between'>
    <div className='flex flex-col'>
      <span className='text-label-md text-neutralContent-primary'>
        {t('fields.profile.primary_color')}
      </span>
      <span className='text-body-sm text-neutralContent-secondary'>
        {t('fields.profile.primary_color_hint')}
      </span>
    </div>
    <ColorPicker id='color_client' value={value} onChange={onChange} label='' />
  </div>
)

export default ClientProfileForm
