/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef, useEffect } from 'react'
import InputField from '../../../../../components/input'
import { Props } from '../types'
import { usePatientContactFormik } from '../../../../../hooks/forms/usePatientForm'
import { useTranslation } from 'react-i18next'
import StateDropdown from '../../../../../components/dropdown/state-dropdown'
import CityDropdown from '../../../../../components/dropdown/city-dropdown'
import { SpinnerGap } from '@phosphor-icons/react'
import CepField from '../../../../../components/input/cep-field'
import LocationService from '../../../../../services/location.service'
import { useGetStates } from '../../../../../hooks/useLocation'

const PatientContact = forwardRef<HTMLButtonElement, Props>(
  ({ handleSubmit, handleStateSubmit, value }, ref) => {
    const { t } = useTranslation('patient_form')
    const formik = usePatientContactFormik(handleSubmit, value)

    const { data: states } = useGetStates()

    const handleAddressFetched = async (address: any) => {
      if (address.logradouro !== formik.values.address) {
        await formik.setFieldValue('address', address.logradouro)
      }

      if (address.bairro !== formik.values.address_neighborhood) {
        await formik.setFieldValue('address_neighborhood', address.bairro)
      }

      const selectedState = states?.find((state: { sigla: string }) => state.sigla === address.uf)
      console.log(selectedState, selectedState.id)
      if (selectedState.id !== formik.values.address_state) {
        await formik.setFieldValue('address_state', selectedState.sigla)
      }

      const citiesData = await LocationService.getCitiesByState(selectedState.id)

      const selectedCity = citiesData?.find(
        (city: { nome: string }) => city.nome === address.localidade
      )
      console.log(selectedCity)

      if (selectedCity?.id.toString() !== formik.values.address_city) {
        await formik.setFieldValue('address_city', selectedCity?.nome)
      }
    }

    useEffect(() => {
      handleStateSubmit(!formik.isValid)
    }, [formik.isValid, handleStateSubmit])

    return formik.isSubmitting ? (
      <div className='flex h-full w-full items-center justify-center'>
        <SpinnerGap size={20} className='animate-spin' />
      </div>
    ) : (
      <form onSubmit={formik.handleSubmit}>
        <div className='flex w-full flex-col gap-8'>
          <InputField
            id='email'
            name='email'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            label={t('fields.email')}
            placeholder={t('placeholders.email')}
            value={formik.values.email || ''}
            hint={formik.touched.email && formik.errors.email ? formik.errors.email : undefined}
            state={formik.touched.email && formik.errors.email ? 'error' : 'default'}
          />

          <div className='flex gap-6'>
            <InputField
              type='phone'
              id='primary_phone'
              name='primary_phone'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              label={t('fields.phone')}
              placeholder={t('placeholders.phone')}
              className='max-w-[328px]'
              value={formik.values.primary_phone || ''}
              hint={
                formik.touched.primary_phone && formik.errors.primary_phone
                  ? formik.errors.primary_phone
                  : undefined
              }
              state={
                formik.touched.primary_phone && formik.errors.primary_phone ? 'error' : 'default'
              }
            />
            <InputField
              type='phone'
              id='secondary_phone'
              name='secondary_phone'
              className='max-w-[328px]'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              label={t('fields.secondary_phone')}
              placeholder={t('placeholders.secondary_phone')}
              value={formik.values.secondary_phone || ''}
              hint={
                formik.touched.secondary_phone && formik.errors.secondary_phone
                  ? formik.errors.secondary_phone
                  : undefined
              }
              state={
                formik.touched.secondary_phone && formik.errors.secondary_phone
                  ? 'error'
                  : 'default'
              }
            />
          </div>

          <div className='col-span-2 grid grid-cols-2'>
            <CepField
              id='address_code'
              name='address_code'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.address_code || ''}
              onAddressFetched={handleAddressFetched}
              handleCepUnknown={async value => {
                await formik.setFieldValue('cepUnknown', value)
                await formik.setFieldValue('address_code', '')
              }}
              cepUnknown={!!formik.values.id && !formik.values.address_code}
              state={
                formik.touched.address_code && formik.errors.address_code ? 'error' : 'default'
              }
              error={
                formik.touched.address_code && formik.errors.address_code
                  ? formik.errors.address_code
                  : undefined
              }
              hint={
                formik.touched.address_code && formik.errors.address_code
                  ? formik.errors.address_code
                  : undefined
              }
            />
          </div>

          {/* <InputField
            id='address_code'
            name='address_code'
            type='cep'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            label={t('fields.zip')}
            placeholder={t('placeholders.zip')}
            value={formik.values.address_code || ''}
            hint={
              formik.touched.address_code && formik.errors.address_code
                ? formik.errors.address_code
                : undefined
            }
            state={formik.touched.address_code && formik.errors.address_code ? 'error' : 'default'}
          /> */}

          <InputField
            id='address'
            name='address'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            label={t('fields.street')}
            placeholder={t('placeholders.street')}
            value={formik.values.address || ''}
            hint={
              formik.touched.address && formik.errors.address ? formik.errors.address : undefined
            }
            state={formik.touched.address && formik.errors.address ? 'error' : 'default'}
          />

          <div className='flex w-full justify-between gap-4'>
            <InputField
              id='address_number'
              name='address_number'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              label={t('fields.number')}
              placeholder={t('placeholders.number')}
              value={formik.values.address_number || ''}
              hint={
                formik.touched.address_number && formik.errors.address_number
                  ? formik.errors.address_number
                  : undefined
              }
              state={
                formik.touched.address_number && formik.errors.address_number ? 'error' : 'default'
              }
            />
            <InputField
              id='address_complement'
              name='address_complement'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              label={t('fields.complement')}
              placeholder={t('placeholders.complement')}
              value={formik.values.address_complement || ''}
              hint={
                formik.touched.address_complement && formik.errors.address_complement
                  ? formik.errors.address_complement
                  : undefined
              }
              state={
                formik.touched.address_complement && formik.errors.address_complement
                  ? 'error'
                  : 'default'
              }
            />
            <InputField
              id='address_neighborhood'
              name='address_neighborhood'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              label={t('fields.neighborhood')}
              placeholder={t('placeholders.neighborhood')}
              value={formik.values.address_neighborhood || ''}
              hint={
                formik.touched.address_neighborhood && formik.errors.address_neighborhood
                  ? formik.errors.address_neighborhood
                  : undefined
              }
              state={
                formik.touched.address_neighborhood && formik.errors.address_neighborhood
                  ? 'error'
                  : 'default'
              }
            />
          </div>

          <div className='mb-12 grid w-full grid-cols-3 justify-between gap-4'>
            <StateDropdown
              label={t('fields.state')}
              onBlur={formik.handleBlur}
              value={formik.values.address_state || ''}
              hint={formik.submitCount > 0 ? formik.errors.address_state : ''}
              error={formik.submitCount > 0 && !!formik.errors.address_state}
              onChange={option => formik.setFieldValue('address_state', option?.value)}
            />

            <CityDropdown
              label={t('fields.city')}
              onBlur={formik.handleBlur}
              value={formik.values.address_city || ''}
              uf={formik.values.address_state!}
              error={formik.submitCount > 0 && !!formik.errors.address_city}
              hint={formik.submitCount > 0 ? formik.errors.address_city : ''}
              onChange={option => formik.setFieldValue('address_city', option?.value)}
            />
          </div>
        </div>

        <button ref={ref} className='hidden' type='submit' />
      </form>
    )
  }
)

PatientContact.displayName = 'PatientContact'

export default PatientContact
