const patient_list_ptBR = {
  title: 'Pacientes',
  search_placeholder: 'Busque por nome',
  columns: {
    name: 'Nome',
    document: 'CPF'
  },
  actions: {
    new: 'Novo paciente'
  }
}

export default patient_list_ptBR
