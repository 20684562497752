import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  CaretLeft,
  CaretRight,
  ClockCountdown,
  ClockUser,
  CornersOut,
  FunnelSimple
} from '@phosphor-icons/react'
import {
  CARE_TYPE,
  Consultation,
  CONSULTATION_STATUS,
  FOLLOW_UP_DISPLAY_MODE,
  FOLLOW_UP_STATUS,
  Specialty
} from '../../types'
import { ListConsultationProps } from '../../hooks/useConsultation'
import { filtersToPendingStatus, iconsDisplay } from './constants'
import SegmentedButton from '../../components/segmented-button'
import { useToastContext } from '../../contexts/toast'
import useFullScreen from '../../hooks/useFullscreen'
import Content from '../../components/content'
import Button from '../../components/button'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import FollowUpList from './follow-up-list'
import ConsultationService, { ConsultationListResponse } from '../../services/consultation.service'
import Filters from './Filters'
import Tag from '../../components/tag'
import { ClientItem } from './Filters/client-units-filter-modal'
import FollowUpService from '../../services/follow-up.service'

const defaultParams: ListConsultationProps = {
  filter: '',
  page: 0,
  pageSize: 12
}

const FollowUpPage: React.FC = () => {
  const { t } = useTranslation('consultation_follow_up')
  const { status } = useParams<{ status: FOLLOW_UP_STATUS }>()
  const { toast } = useToastContext()

  const [isLoading, setIsLoading] = useState(true)
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
  const [response, setResponse] = useState<ConsultationListResponse>()
  const [selectedIconDisplay, setSelectedIconDisplay] = useState<string>(iconsDisplay[0].value)

  const [selectedCareTypes, setSelectedCareTypes] = useState<CARE_TYPE[]>([])
  const [selectedSpecialties, setSelectedSpecialties] = useState<Specialty[]>([])
  const [selectedUnits, setSelectedUnits] = useState<{ id: string; name: string }[]>([])

  const [selectedClientsUnits, setSelectedClientsUnits] = useState<ClientItem[]>([])

  const { toggleFullScreen } = useFullScreen()

  const totalPages = useMemo(
    () =>
      response?.totalItems && response?.totalItems > 0
        ? Math.ceil(Number(response?.totalItems / (response?.pageSize || 1)))
        : 1,
    [response]
  )

  const followUp = useMemo(() => new FollowUpService(), [])

  const listConsultations = useCallback(
    async (params: ListConsultationProps) => {
      setIsLoading(true)
      try {
        setResponse(await ConsultationService.list(params))
      } catch (_) {
        toast.error('Não foi possível carregar as consultas')
      }
      setIsLoading(false)
    },
    [toast]
  )

  useEffect(() => {
    if (!followUp.socket?.hasListeners('entered'))
      followUp.socket?.on('entered', (consultation: Consultation) => {
        setResponse(
          response
            ? {
                ...response,
                data: response.data.map(item =>
                  item.id === consultation.id ? { ...item, ...consultation } : item
                )
              }
            : undefined
        )
      })

    return () => {
      followUp.socket?.removeAllListeners()
    }
  }, [response, followUp])

  const clearAllFilters = useCallback(() => {
    {
      setSelectedCareTypes([])
      setSelectedSpecialties([])
      setSelectedUnits([])
      setSelectedClientsUnits([])

      if (status === FOLLOW_UP_STATUS.PENDING)
        listConsultations({ ...defaultParams, status: CONSULTATION_STATUS.NOT_FINISHED })
      if (status === FOLLOW_UP_STATUS.IN_PROGRESS)
        listConsultations({ ...defaultParams, status: CONSULTATION_STATUS.IN_ROOM })
    }
  }, [listConsultations, status])

  useEffect(() => {
    clearAllFilters()
  }, [clearAllFilters])

  const handleChangePage = async (page: number) => {
    if (response) {
      const { data, ...params } = response
      await listConsultations({ ...params, page })
    }
  }

  const applyFilter = async (filter: Partial<ListConsultationProps>) => {
    if (response) {
      const { data, ...params } = response
      await listConsultations({ ...params, ...filter, page: 0 })
    }
  }

  const handleUnitsFilter = async (
    units: { id: string; name: string }[],
    updatedSelectedClientsUnits: ClientItem[]
  ) => {
    const unitIds = units.map(u => u.id)
    setSelectedUnits(units)
    setSelectedClientsUnits(updatedSelectedClientsUnits)
    await applyFilter({ unit_ids: unitIds })
  }

  const handleSpecialtiesFilter = async (specialties: Specialty[]) => {
    const specialtyIds = specialties.map(s => s.id)
    setSelectedSpecialties(specialties)
    await applyFilter({
      specialty_ids: specialtyIds.filter((id): id is string => id !== undefined)
    })
  }

  const handleCareTypesFilter = async (care_types: CARE_TYPE[]) => {
    setSelectedCareTypes(care_types)
    await applyFilter({ care_types })
  }

  return (
    <Content
      title={t(`title_${status}`)}
      rightContent={
        <Button
          size={'sm'}
          variant='secondary'
          className='hidden md:flex'
          iconLeft={<CornersOut />}
          onClick={toggleFullScreen}
          label={t('actions.full_screen')}
        />
      }
    >
      <div className='flex flex-col gap-2 sm:flex-row sm:items-center sm:justify-between'>
        <div className='flex h-10 items-center  gap-2'>
          {response && status === FOLLOW_UP_STATUS.PENDING && (
            <div className='inline-flex'>
              <SegmentedButton
                isLoading={isLoading}
                labels={filtersToPendingStatus.map(filter => filter.label)}
                onChange={index => applyFilter({ status: filtersToPendingStatus[index].value })}
                selectedIndex={filtersToPendingStatus.findIndex(
                  filter => filter.value === response?.status
                )}
              />
            </div>
          )}

          <span className='flex gap-2 text-label-sm text-neutralContent-tertiary'>
            {status === FOLLOW_UP_STATUS.PENDING ? <ClockCountdown /> : <ClockUser />}
            {isLoading ? (
              <div className='h-4 w-4 animate-pulse bg-slate-100' />
            ) : (
              response?.totalItems
            )}
            {' Atendimentos'}
          </span>
        </div>

        <div className='flex flex-col gap-2 sm:flex-row sm:items-center'>
          <div className='ml-auto inline-flex gap-2 sm:flex sm:flex-wrap'>
            <Button
              size='sm'
              label='Filtros'
              variant='secondary'
              iconLeft={<FunnelSimple />}
              onClick={() => setDropdownOpen(!dropdownOpen)}
            />

            <Filters
              isOpen={dropdownOpen}
              handleUnitsFilter={handleUnitsFilter}
              selectedCareTypes={selectedCareTypes}
              selectedSpecialties={selectedSpecialties}
              selectedClientsUnits={selectedClientsUnits}
              handleCareTypesFilter={handleCareTypesFilter}
              toggleDropdown={() => setDropdownOpen(false)}
              handleSpecialtiesFilter={handleSpecialtiesFilter}
              setSelectedClientsUnits={setSelectedClientsUnits}
            />

            <div className='hidden md:flex'>
              <SegmentedButton
                icons={iconsDisplay.map(filter => filter.icon)}
                onChange={index => setSelectedIconDisplay(iconsDisplay[index].value)}
                selectedIndex={iconsDisplay.findIndex(
                  filter => filter.value === selectedIconDisplay
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='mt-4 h-[calc(100vh-340px)] overflow-y-scroll'>
        <div className='my-2 flex flex-wrap gap-2 '>
          {selectedCareTypes.map(careType => (
            <Tag
              size={'sm'}
              color='neutral'
              variant='tertiary'
              key={`careType-${careType}`}
              label={
                <span className='text-label-sm text-neutralContent-primary'>
                  {t(`types.${careType}`)}
                </span>
              }
              onClose={() => {
                const updatedCareTypes = selectedCareTypes.filter(ct => ct !== careType)
                setSelectedCareTypes(updatedCareTypes)
                applyFilter({ care_types: updatedCareTypes })
              }}
            />
          ))}
          {selectedSpecialties.map(specialty => (
            <Tag
              key={`specialty-${specialty.id}`}
              variant='tertiary'
              color='neutral'
              label={
                <span className='text-label-sm text-neutralContent-primary'>{specialty.name}</span>
              }
              onClose={() => {
                const updatedSpecialties = selectedSpecialties.filter(s => s.id !== specialty.id)
                setSelectedSpecialties(updatedSpecialties)
                applyFilter({
                  specialty_ids: updatedSpecialties
                    .map(s => s.id)
                    .filter((id): id is string => id !== undefined)
                })
              }}
              size={'sm'}
            />
          ))}

          {selectedUnits.map(unit => (
            <Tag
              key={`unit-${unit.id}`}
              variant='tertiary'
              color='neutral'
              label={<span className='text-label-sm text-neutralContent-primary'>{unit.name}</span>}
              onClose={() => {
                const updatedUnits = selectedUnits.filter(u => u.id !== unit.id)
                setSelectedUnits(updatedUnits)
                applyFilter({ unit_ids: updatedUnits.map(u => u.id) })

                const updatedSelectedClientsUnits = selectedClientsUnits
                  .map(clientItem => ({
                    ...clientItem,
                    units: clientItem.units?.filter(u => u.unit_id !== unit.id)
                  }))
                  .filter(clientItem => clientItem.units && clientItem.units.length > 0)

                setSelectedClientsUnits(updatedSelectedClientsUnits)
              }}
              size={'sm'}
            />
          ))}

          {(selectedCareTypes.length > 0 ||
            selectedSpecialties.length > 0 ||
            selectedUnits.length > 0) && (
            <Tag
              size={'sm'}
              color='blue'
              variant='tertiary'
              onClick={clearAllFilters}
              className='cursor-pointer'
              label={t('actions.clear_filters')}
            />
          )}
        </div>

        <FollowUpList
          isLoading={isLoading}
          data={response?.data || []}
          displayMode={selectedIconDisplay as FOLLOW_UP_DISPLAY_MODE}
        />
      </div>

      <div className='flex items-center justify-center border-t border-neutralBorder-default px-4 py-2'>
        <Button
          size='sm'
          variant='secondary'
          iconLeft={<CaretLeft />}
          disabled={!response?.page || isLoading}
          state={!response?.page || isLoading ? 'disable' : 'enable'}
          onClick={() => handleChangePage(response?.page ? response.page - 1 : 0)}
        />

        <span className='mx-2 text-label-sm font-medium text-neutralContent-tertiary'>
          Página {(response?.page || 0) + 1} de {totalPages}
        </span>

        <Button
          size='sm'
          variant='secondary'
          iconLeft={<CaretRight />}
          onClick={() =>
            (response?.page || 0) + 1 < totalPages || isLoading
              ? handleChangePage(response?.page ? response.page + 1 : 1)
              : undefined
          }
          disabled={(response?.page || 0) + 1 >= totalPages || isLoading}
          state={(response?.page || 0) + 1 < totalPages || isLoading ? 'enable' : 'disable'}
        />
      </div>
    </Content>
  )
}

export default FollowUpPage
