interface CPFValidator {
  (cpf: string): boolean
}

const removeNonDigits = (cpf: string): string => {
  return cpf.replace(/\D/g, '')
}

const allDigitsSame = (cpf: string): boolean => {
  return cpf.split('').every(digit => digit === cpf[0])
}

const calculateCheckDigit = (cpf: string, factor: number): number => {
  let total = 0
  for (let i = 0; i < factor - 1; i++) {
    total += parseInt(cpf[i]) * (factor - i)
  }
  const remainder = total % 11
  return remainder < 2 ? 0 : 11 - remainder
}

export const validateCPF: CPFValidator = (cpf: string): boolean => {
  if (!cpf || typeof cpf !== 'string') {
    return false
  }

  cpf = removeNonDigits(cpf)

  if (cpf.length !== 11 || allDigitsSame(cpf)) {
    return false
  }

  const digit1 = calculateCheckDigit(cpf, 10)
  const digit2 = calculateCheckDigit(cpf.substring(0, 9) + digit1, 11)

  return cpf[9] === digit1.toString() && cpf[10] === digit2.toString()
}
