import MonthSelectCalendar from '../../../components/custom-week-calendar/month-select-calendar'
import CustomWeekCalendar from '../../../components/custom-week-calendar'
import PublishScale from '../scale_day/scale-list/modals/publish-scale'
import { useListScalesByClient } from '../../../hooks/useScale'
import { ArrowSquareOut, Plus } from '@phosphor-icons/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetClient } from '../../../hooks/useClient'
import BlankCard from '../../../components/blank-card'
import Content from '../../../components/content'
import FullScaleModal from './modals/full-scale'
import Avatar from '../../../components/avatar'
import Button from '../../../components/button'
import { useTranslation } from 'react-i18next'
import { ROUTES } from '../../../routes/path'
import { useMemo, useState } from 'react'
import { Scale } from '../../../types'
import {
  addDays,
  addMonths,
  endOfWeek,
  startOfWeek,
  startOfMonth,
  isWithinInterval,
  format
} from 'date-fns'

const CalendarPage: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('agendav2_list')
  const { client_id } = useParams<{ client_id: string }>()
  const { data: clientData } = useGetClient(client_id || '')

  const [openPublishModal, setOpenPublishModal] = useState({
    isOpen: false,
    scale: {} as Scale
  })

  const [lastDayOfTheWeek, setLastDayOfTheWeek] = useState(
    endOfWeek(new Date(), { weekStartsOn: 1 })
  )

  const [isOpenFullScaleModal, setIsOpenFullScaleModal] = useState(false)

  const {
    data: scales,
    isFetching,
    refetch: reloadScales
  } = useListScalesByClient({
    client_id,
    end_date: format(lastDayOfTheWeek, 'yyyy-MM-dd'),
    start_date: format(addDays(lastDayOfTheWeek, -7), 'yyyy-MM-dd')
  })

  const isCurrentWeek = useMemo(() => {
    return isWithinInterval(new Date(), {
      start: startOfWeek(lastDayOfTheWeek, { weekStartsOn: 1 }),
      end: lastDayOfTheWeek
    })
  }, [lastDayOfTheWeek])

  const handleBack = () => {
    navigate(ROUTES.agenda.list)
  }

  const goToPreviousMonth = () => {
    setLastDayOfTheWeek(
      endOfWeek(startOfMonth(addMonths(lastDayOfTheWeek, -1)), { weekStartsOn: 1 })
    )
  }

  const goToNextMonth = () => {
    setLastDayOfTheWeek(
      endOfWeek(startOfMonth(addMonths(lastDayOfTheWeek, 1)), { weekStartsOn: 1 })
    )
  }

  const goToCurrentWeek = (e?: React.MouseEvent) => {
    e?.preventDefault()
    setLastDayOfTheWeek(endOfWeek(new Date(), { weekStartsOn: 1 }))
  }

  const goToDayList = (date: Date) => {
    navigate(
      `${ROUTES.agenda.scale_day.replace(':client_id', client_id!)}?date=${date.toISOString()}`
    )
  }

  const handleNewScale = () => {
    navigate(`${ROUTES.agenda.new_scale.replace(':client_id', client_id!)}`)
  }

  const rightHeaderContent = () => (
    <div className='flex items-center gap-2'>
      <Button
        size='sm'
        variant='secondary'
        iconRight={<ArrowSquareOut />}
        label={t('actions.access_full_scale')}
        onClick={() => setIsOpenFullScaleModal(true)}
      />
      <Button
        size='sm'
        iconLeft={<Plus />}
        label={t('actions.new_scale')}
        onClick={() => handleNewScale()}
      />
    </div>
  )

  const handleEditScale = (scale: Scale) => {
    navigate(
      ROUTES.agenda.scale_form.replace(':client_id', client_id!).replace(':scale_id', scale?.id)
    )
  }

  return (
    <Content onBack={handleBack} rightContent={rightHeaderContent()}>
      <div className='p-4'>
        <div className='mb-12 flex items-center justify-between'>
          <div className='flex items-center gap-6'>
            <MonthSelectCalendar
              date={lastDayOfTheWeek}
              onClickNextMonth={goToNextMonth}
              onClickPreviousMonth={goToPreviousMonth}
            />

            <Button
              label='Hoje'
              state={!isCurrentWeek ? 'enable' : 'disable'}
              onClick={!isCurrentWeek ? goToCurrentWeek : undefined}
            />
          </div>

          <BlankCard className='h-14'>
            <div className='mt-[-6px] flex items-center justify-center gap-3'>
              <Avatar name={clientData?.name} size='sm' />
              <span className='text-label-md text-neutralContent-primary'>
                {clientData?.name || ''}
              </span>
            </div>
          </BlankCard>
        </div>

        <CustomWeekCalendar
          data={scales || []}
          isLoading={isFetching}
          handleClickDay={goToDayList}
          lastDayOfTheWeek={lastDayOfTheWeek}
          onChangeLastDayOfTheWeek={setLastDayOfTheWeek}
          handleClickCard={scale => setOpenPublishModal({ scale, isOpen: true })}
        />
      </div>

      {clientData && (
        <FullScaleModal
          isOpen={isOpenFullScaleModal}
          onClose={() => setIsOpenFullScaleModal(false)}
          clientData={clientData}
        />
      )}

      <PublishScale
        scale={openPublishModal.scale}
        isOpen={openPublishModal.isOpen}
        redirectToEdit={(scale: Scale) => handleEditScale(scale)}
        onClose={() => {
          setOpenPublishModal({
            isOpen: false,
            scale: {} as Scale
          })
          reloadScales()
        }}
      />
    </Content>
  )
}

export default CalendarPage
