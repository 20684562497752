import { useDeleteScale, usePublishScale } from '../../../../../hooks/useScale'
import { scaleNumberToTime } from '../../../../../helpers/scaleTime'
import ConfirmPublishScaleModal from './confirm-publish-scale'
import BlankCard from '../../../../../components/blank-card'
import { truncateText } from '../../helper/truncateText'
import Avatar from '../../../../../components/avatar'
import Button from '../../../../../components/button'
import Modal from '../../../../../components/modal'
import React, { useEffect, useState } from 'react'
import Tag from '../../../../../components/tag'
import { useTranslation } from 'react-i18next'
import { Scale } from '../../../../../types'
import { format, parseISO } from 'date-fns'
import {
  CalendarDots,
  CaretRight,
  Clock,
  Hospital,
  PencilSimple,
  Plus,
  ShareFat
} from '@phosphor-icons/react'

interface Props {
  isOpen: boolean
  onClose: () => void
  scale: Scale | null
  redirectToEdit: (scale: Scale) => void
}

const PublishScale: React.FC<Props> = ({ isOpen, onClose, scale, redirectToEdit }) => {
  const { t } = useTranslation('agendav2_list')

  const [showAllUnits, setShowAllUnits] = useState(false)
  const [confirmPublishModalOpen, setConfirmPublishModalOpen] = useState(false)

  const date = scale?.date ? parseISO(scale.date.toString()) : null
  const isValidDate = date instanceof Date && !isNaN(date.getTime())

  const units = scale?.units || []

  const unitsToShow = showAllUnits ? units : units.slice(0, 3)
  const remainingUnitsCount = units.length - unitsToShow.length

  const { mutate: publish, isPending, isSuccess } = usePublishScale(onClose)

  const {
    mutate: deleteScale,
    isPending: isPendingDelete,
    isSuccess: isSuccessDelete
  } = useDeleteScale(scale?.id as string, onClose)

  const handlePublish = () => {
    if (!scale) return

    publish({ id: scale.id })
  }

  const handleDelete = () => {
    deleteScale()
  }

  useEffect(() => {
    if (isSuccess || isSuccessDelete) {
      onClose()
      setConfirmPublishModalOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isSuccessDelete])

  return (
    <Modal isOpen={isOpen} title='' onClose={onClose} className='w-[550px]'>
      {scale ? (
        <div className='mx-[-16px] max-h-[90dvh] min-h-[500px] min-w-[400px] overflow-y-auto px-4 pb-20'>
          <div className='flex justify-between'>
            <div className='flex items-center gap-3'>
              <span className='text-heading-md text-neutralContent-primary'>
                {scale.specialty?.name}
              </span>
              <Tag
                size={'xs'}
                variant='secondary'
                label={t(`status.${scale.status}`)}
                color={scale.status === 'DRAFT' ? 'neutral' : 'green'}
              />
            </div>

            {scale.status === 'DRAFT' && (
              <div>
                <Button
                  size='xs'
                  variant='secondary'
                  label='Publicar escala'
                  iconLeft={<ShareFat />}
                  onClick={() => setConfirmPublishModalOpen(true)}
                />
              </div>
            )}
          </div>

          {/* DATA */}
          <div className='inline-flex py-3'>
            <Tag
              size={'xs'}
              color='blue'
              variant='secondary'
              leftIcon={<CalendarDots size={12} />}
              label={isValidDate ? format(date, 'dd/MM/yyyy') : 'Data inválida'}
            />
          </div>

          {/* DURAÇÃO */}
          <div className='py-3'>
            <BlankCard className='flex items-center justify-between'>
              <div className='flex items-center gap-4'>
                <Clock size={20} />
                <span className='text-label-sm text-neutralContent-primary'>
                  Duração dos atendimentos
                </span>
              </div>
              <span className='text-body-sm text-neutralContent-tertiary'>
                {scale.duration} minutos
              </span>
            </BlankCard>
          </div>

          {/* PROFISSIONAIS */}
          <div className='rounded-sm border p-4'>
            <span className='text-heading-xs'>Profissional</span>
            {scale.specialists?.map(specialist => (
              <div key={specialist.id?.toString()}>
                <div className='flex items-center gap-2'>
                  <BlankCard className='mt-2 flex h-9 flex-1 items-center gap-4'>
                    <Avatar name={specialist.specialist.name} size='xs' />
                    <span className='text-label-md text-neutralContent-primary'>
                      {truncateText(specialist.specialist.name as string, 30)}
                    </span>
                  </BlankCard>
                  <BlankCard className='mt-2 flex h-9 items-center gap-4'>
                    <span>R$ {specialist.price} /hora</span>
                  </BlankCard>
                </div>

                {/* INTERVALOS DE TEMPO DA ESCALA */}
                <div className='flex flex-wrap gap-3 py-3'>
                  {scale.time_intervals?.map((interval, idx) => (
                    <div key={idx.toString()} className='flex items-center gap-4'>
                      <BlankCard className='flex h-9 items-center'>
                        <span className='text-label-sm'>
                          {scaleNumberToTime(interval.start_time)}
                        </span>
                      </BlankCard>
                      <span className='text-label-md text-neutralContent-secondary'>às</span>
                      <BlankCard className='flex h-9 items-center'>
                        <span className='text-label-sm'>
                          {scaleNumberToTime(interval.end_time)}
                        </span>
                      </BlankCard>
                      {idx < scale.time_intervals.length - 1 && (
                        <span className='text-label-md text-neutralContent-secondary'> e das </span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          {/* UNIDADES */}
          <div className='py-4'>
            <div className='flex items-center justify-between'>
              <span className='text-heading-xs'>Unidades</span>
              <span className='text-label-md text-neutralContent-tertiary'>
                {units.length} Unidades
              </span>
            </div>

            <div className='mt-2 rounded-lg border'>
              {unitsToShow.map(unit => (
                <div
                  key={unit.id.toString()}
                  className='flex items-center gap-2 border-b p-3 last:border-b-0'
                >
                  <Hospital size={20} />
                  <span>{unit.unit?.name}</span>
                </div>
              ))}
              {remainingUnitsCount > 0 && (
                <div className='flex items-center gap-2 p-3'>
                  <button
                    className='w-full text-label-sm text-neutralContent-primary'
                    onClick={() => setShowAllUnits(true)}
                  >
                    <div className='flex items-center justify-between'>
                      <span className='flex items-center gap-2'>
                        <Plus size={20} /> Ver mais {remainingUnitsCount} unidades
                      </span>
                      <CaretRight size={16} />
                    </div>
                  </button>
                </div>
              )}
              {showAllUnits && units.length > 3 && (
                <div className='flex items-center gap-2 p-3'>
                  <button
                    className='text-label-sm text-neutralContent-primary'
                    onClick={() => setShowAllUnits(false)}
                  >
                    Ver menos unidades
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}

      <div>
        <div className='absolute bottom-0 left-0 right-0 z-20 flex justify-between bg-white px-6 py-4 shadow-footer'>
          <div>
            <Button
              label={t('actions.delete')}
              size='sm'
              state='error'
              onClick={handleDelete}
              isLoading={isPendingDelete}
            />
          </div>
          <div className='flex items-center'>
            <Button
              label={t('actions.close')}
              size='sm'
              variant='tertiary'
              onClick={() => onClose()}
            />
            <Button
              label={t('actions.update')}
              size='sm'
              onClick={() => redirectToEdit(scale!)}
              iconLeft={<PencilSimple size={16} />}
            />
          </div>
        </div>
      </div>

      <ConfirmPublishScaleModal
        open={confirmPublishModalOpen}
        onClose={() => setConfirmPublishModalOpen(false)}
        isPending={isPending}
        onPublish={handlePublish}
      />
    </Modal>
  )
}

export default PublishScale
