import React, { useState } from 'react'

type TabContent = {
  label: string
  content: React.ReactNode
  disabled?: boolean
}

type TabProps = {
  tabs: TabContent[]
}

const Tab: React.FC<TabProps> = ({ tabs }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <div>
      <div className='flex border-b'>
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => setActiveIndex(index)}
            className={`border-b-2 px-4 py-2 text-label-lg font-bold ${
              index === activeIndex
                ? 'border-primaryBrand-primary text-primaryBrand-primary'
                : 'border-neutralBorder-default text-neutralContent-tertiary'
            } ${tab.disabled ? 'cursor-not-allowed text-neutralContent-disabled' : 'hover:text-primaryBrand-primary'}`}
            disabled={tab.disabled}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className='p-4'>{tabs[activeIndex].content}</div>
    </div>
  )
}

export default Tab
