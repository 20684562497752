const unit_type_ptBR = {
  title: 'Tipos de Unidade',
  columns: {
    name: 'Nome do tipo de unidade'
  },
  fields: {
    search: 'Procure pelo nome do tipo...'
  },
  actions: {
    add: 'Novo tipo de unidade',
    update: 'Editar'
  }
}

export default unit_type_ptBR
