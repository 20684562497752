import React, { useState, useEffect } from 'react'
import { PencilSimple, Plus } from '@phosphor-icons/react'
import Button from '../../../components/button'
import Content from '../../../components/content'
import Table from '../../../components/table'
import SearchField from '../../../components/search'
import UnitTypeFormModal from './unit-type-form-modal'
import { UnitType } from '../../../types'
import { useUnitTypes } from '../../../hooks/useUnitType'
import InputSkeleton from '../../../components/input/skeleton'
import { useTranslation } from 'react-i18next'

const UnitTypePage: React.FC = () => {
  const { t } = useTranslation('unit_type')

  const [modalData, setModalData] = useState<{ isOpen: boolean; unit?: UnitType }>({
    isOpen: false,
    unit: undefined
  })
  const [filter, setFilter] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)

  const { data: unitTypes, isLoading, isFetching, refetch } = useUnitTypes(currentPage, 10, filter)

  useEffect(() => {
    if (unitTypes) {
      setTotalPages(Math.ceil(unitTypes.totalItems / 10))
    }
  }, [unitTypes])

  const handleOpenFormModal = (unit?: UnitType) => setModalData({ isOpen: true, unit })

  const handleCloseModal = () => {
    refetch()
    setModalData({ isOpen: false, unit: undefined })
  }

  const columns = [
    { header: t('columns.name'), accessor: 'name' },
    { header: '', accessor: 'actions', className: 'sm:max-w-[120px]' }
  ]

  const tableData =
    unitTypes?.data?.map(unitType => ({
      name: unitType.name,
      actions: (
        <Button
          size='sm'
          label='Editar'
          variant='secondary'
          className='ml-auto'
          iconLeft={<PencilSimple size={12} />}
          onClick={() => handleOpenFormModal(unitType)}
        />
      )
    })) ?? []

  return (
    <Content
      title={t('title')}
      rightContent={
        <Button
          size='sm'
          label={t('actions.add')}
          iconLeft={<Plus size={12} />}
          onClick={() => handleOpenFormModal()}
        />
      }
    >
      <div className='flex h-[calc(100%-70px)] flex-col'>
        <div className='mb-4 w-64'>
          {isLoading ? (
            <InputSkeleton isRounded />
          ) : (
            <SearchField
              value={filter}
              maxLength={100}
              placeholder={t('fields.search')}
              onChange={({ target }) => setFilter(target.value)}
            />
          )}
        </div>
        <Table
          data={tableData}
          columns={columns}
          isLoading={isFetching}
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      </div>
      <UnitTypeFormModal
        unit={modalData.unit}
        isModalOpen={modalData.isOpen}
        closeModal={handleCloseModal}
      />
    </Content>
  )
}

export default UnitTypePage
