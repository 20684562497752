import { CalendarDots, Plus } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'
import Button from '../../../../components/button'

interface Props {
  onNewScale: () => void
}

const EmptyScale: React.FC<Props> = ({ onNewScale }) => {
  const { t } = useTranslation('agendav2_list')

  return (
    <div className='flex flex-col items-center justify-center gap-4 '>
      <CalendarDots size={64} className='text-neutralContent-quartenary' />
      <span className='text-heading-xs text-neutralContent-primary'>{t('empty_scale')}</span>
      <Button label={t('actions.new_scale')} iconLeft={<Plus />} size='sm' onClick={onNewScale} />
    </div>
  )
}

export default EmptyScale
