import { useMemo } from 'react'
import { MAIN_MENU_ITEMS } from './menus'
import { Profile } from '../../../types'
import MenuItem from '../menu-item'
import MenuDivider from '../menu-divider'
import { ROUTES } from '../../../routes/path'
import { useAuth } from '../../../contexts/auth'
import UnitPermissionsEnum from '../../../types/unit-permissions'

type Props = {
  isOpen?: boolean
  onNavigate: () => void
  userProfile?: Profile
}

const MenuItemList: React.FC<Props> = ({ isOpen, onNavigate, userProfile }) => {
  const { hasUnitPermission } = useAuth()

  const MENU_ITEMS = useMemo(() => {
    const permissions = userProfile?.permissions || []
    return MAIN_MENU_ITEMS.filter(
      item =>
        (!item?.permission || permissions.includes(item.permission)) &&
        (!item.permissions || item.permissions.some(permission => permissions.includes(permission)))
    )
      .filter(item => {
        if (item.route === ROUTES.consultation.list_elective)
          return hasUnitPermission(UnitPermissionsEnum.elective_care_consultation)
        if (item.route === ROUTES.consultation.list_emergency)
          return hasUnitPermission(UnitPermissionsEnum.emergency_care_consultation)
        if (item.route === ROUTES.consultation.list_chat)
          return hasUnitPermission(UnitPermissionsEnum.chat_consultation)

        return true
      })
      .filter(
        (item, index, list) =>
          !item.divider || (!(index + 1 === list.length) && !list[index + 1].divider)
      )
  }, [hasUnitPermission, userProfile?.permissions])

  return (
    <div className='flex max-h-[calc(100vh-96px)] flex-col overflow-y-auto px-2'>
      {MENU_ITEMS.map((item, index) =>
        item.divider ? (
          <MenuDivider item={item} isOpen={isOpen} key={'divider_' + index} />
        ) : (
          <MenuItem key={item.route} item={item} onNavigate={onNavigate} />
        )
      )}
    </div>
  )
}

export default MenuItemList
