/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useRef, useCallback } from 'react'
import SearchField from '../search'
import Checkbox from '../checkbox'
import Modal from '.'
import Button from '../button'
import { Loading } from 'semente-js'

interface Props {
  title: string
  search?: boolean
  searchFieldLabel?: string
  isOpen?: boolean
  data: any[]
  selectedValues: any[]
  renderField: (item: any) => JSX.Element
  onClose: () => void
  onSuccess: (selectedValues: any[]) => void
  onLoadMore: () => void
  onSearch?: (searchValue: string) => void
  singleSelection?: boolean
  loading?: boolean
  type?: 'rounded' | 'default'
  onBack?: () => void
}

const SelectableModal = ({
  title,
  isOpen = false,
  search,
  searchFieldLabel,
  data,
  selectedValues,
  renderField,
  onClose,
  onSuccess,
  onLoadMore,
  onSearch,
  singleSelection = false,
  loading = false,
  type = 'rounded',
  onBack
}: Props) => {
  const [filter, setFilter] = useState('')
  const [selectedItems, setSelectedItems] = useState<any[]>(selectedValues)
  const scrollRef = useRef<HTMLDivElement>(null)
  const [isFetching, setIsFetching] = useState(false)

  const handleSearch = (value: string) => {
    setFilter(value)
    if (onSearch) {
      onSearch(value)
    }
  }

  const handleSelect = (item: any) => {
    if (singleSelection) {
      setSelectedItems([item])
    } else {
      setSelectedItems(prevSelectedItems => {
        if (prevSelectedItems.some(selectedItem => selectedItem.id === item.id)) {
          return prevSelectedItems.filter(i => i.id !== item.id)
        } else {
          return [...prevSelectedItems, item]
        }
      })
    }
  }

  const filteredData = filter
    ? data.filter(item => item.name.toString().toLowerCase().includes(filter.toLowerCase()))
    : data

  const throttledLoadMore = useCallback(() => {
    if (isFetching) return
    setIsFetching(true)
    onLoadMore()
  }, [isFetching, onLoadMore])

  const handleScroll = useCallback(() => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        throttledLoadMore()
      }
    }
  }, [throttledLoadMore])

  useEffect(() => {
    const currentRef = scrollRef.current
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll)
      return () => {
        currentRef.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll])

  useEffect(() => {
    if (isOpen) {
      setSelectedItems(selectedValues)
    }
  }, [isOpen, selectedValues])

  useEffect(() => {
    if (isFetching) {
      setIsFetching(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleClose = () => {
    handleSearch('')
    setIsFetching(false)
    onClose()
  }

  const handleSave = () => {
    if (type === 'rounded') handleClose()
    onSuccess(selectedItems)
  }

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onClose={() => {
        handleClose()
        if (type === 'default') onSuccess(selectedItems)
      }}
      onBack={() => {
        if (type === 'default') onSuccess(selectedItems)
        onBack?.()
      }}
    >
      <>
        {search && (
          <SearchField
            placeholder={searchFieldLabel}
            onChange={({ target }) => handleSearch(target.value)}
            value={filter}
            maxLength={100}
          />
        )}
        <div ref={scrollRef} className='custom-scrollbar mt-4 h-96 w-96 overflow-y-auto'>
          {filteredData.map(item => (
            <div key={item.id} className='my-4 flex items-center justify-between'>
              <div>{renderField(item)}</div>
              <Checkbox
                isChecked={selectedItems.some(selectedItem => selectedItem.id === item.id)}
                layout={type}
                onClick={() => {
                  handleSelect(item)
                }}
              />
            </div>
          ))}

          {loading && (
            <div className='mx-auto flex items-center justify-center'>
              <Loading />
            </div>
          )}
        </div>

        {type === 'rounded' && (
          <div className='flex justify-end'>
            <Button label='Salvar' size='sm' onClick={handleSave} />
          </div>
        )}
      </>
    </Modal>
  )
}

export default SelectableModal
