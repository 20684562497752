import { ArrowLeft, ArrowRight } from '@phosphor-icons/react'
import { scaleNumberToTime } from '../../helpers/scaleTime'
import { addDays, isSameDay, parse, startOfWeek } from 'date-fns'
import { Scale } from '../../types'
import { useMemo } from 'react'
import Card from '../card'

type Props = {
  lastDayOfTheWeek: Date
  data: Scale[]
  isLoading?: boolean
  handleClickDay: (day: Date) => void
  handleClickCard: (scale: Scale) => void
  onChangeLastDayOfTheWeek: (lastDayOfTheWeek: Date) => void
}

const CustomWeekCalendar: React.FC<Props> = ({
  data,
  isLoading,
  lastDayOfTheWeek,
  handleClickDay,
  handleClickCard,
  onChangeLastDayOfTheWeek
}) => {
  const weekDays = useMemo(() => {
    const weekDays = []
    const start = startOfWeek(lastDayOfTheWeek)

    for (let i = 6; i >= 0; i--) {
      weekDays.push(addDays(start, -i))
    }

    return weekDays
  }, [lastDayOfTheWeek])

  const nextWeek = () => {
    onChangeLastDayOfTheWeek(addDays(lastDayOfTheWeek, 7))
  }

  const previousWeek = () => {
    onChangeLastDayOfTheWeek(addDays(lastDayOfTheWeek, -7))
  }

  return (
    <div className='grid h-full min-h-[400px] grid-cols-7 grid-rows-[80px,1fr] rounded-lg border'>
      {weekDays.map((date, index) => (
        <div key={`weekday_${index}`} className='relative mb-2 flex h-fit border-b-[1px]'>
          {index === 0 && (
            <button
              onClick={previousWeek}
              className='absolute left-1 top-5 rounded-full p-2 text-primaryBrand-primary transition-all hover:bg-primaryBrand-light'
            >
              <ArrowLeft size={18} />
            </button>
          )}

          <div className='flex-1 items-center justify-center py-1'>
            <div
              onClick={() => handleClickDay(date)}
              className={`m-auto flex h-16 w-16 cursor-pointer flex-col items-center justify-center rounded-full border-[1px] border-white text-center transition-all hover:border-primaryBrand-primary ${
                isSameDay(date, new Date()) ? 'bg-primaryBrand-light' : ''
              }`}
            >
              <div className='text-label-xs text-neutralContent-tertiary'>
                {date
                  .toLocaleDateString('pt-BR', { weekday: 'short' })
                  .toUpperCase()
                  .replace('.', '')}
              </div>
              <div className='text-heading-md text-primaryBrand-primary'>{date.getDate()}</div>
            </div>
          </div>

          {index >= 6 && (
            <button
              onClick={nextWeek}
              className='absolute right-1 top-5 rounded-full p-2 text-primaryBrand-primary transition-all hover:bg-primaryBrand-light'
            >
              <ArrowRight size={18} />
            </button>
          )}
        </div>
      ))}

      {weekDays.map((date, index) => (
        <div key={`day_${index}`} className='mx-2'>
          {isLoading ? (
            <CardLoading />
          ) : (
            <DayColumn onClickCard={handleClickCard} date={date} scales={data} />
          )}
        </div>
      ))}
    </div>
  )
}

const DayColumn: React.FC<{ scales: Scale[]; date: Date; onClickCard: (scale: Scale) => void }> = ({
  scales,
  date,
  onClickCard
}) => {
  return (
    <>
      {scales.reduce((acc, scale) => {
        if (isSameDay(parse(scale.date, 'yyyy-MM-dd', new Date()), date)) {
          return [
            ...acc,
            <Card
              key={scale.id}
              onClick={() => onClickCard(scale)}
              isActive={scale.status === 'PUBLISHED'}
              className='mb-2 cursor-pointer transition-all hover:opacity-65'
            >
              <div className='flex flex-col'>
                <div className=''>{scale.specialty?.name}</div>
                {scale.time_intervals.map(interval => (
                  <div className='ml-2 text-body-xs' key={interval.id}>
                    {scaleNumberToTime(interval.start_time)}-{scaleNumberToTime(interval.end_time)}
                  </div>
                ))}
              </div>
            </Card>
          ]
        }
        return acc
      }, [] as React.ReactNode[])}
    </>
  )
}

const CardLoading: React.FC = () => {
  const numberOfItems = Math.floor(Math.random() * 5) + 1
  return (
    <div className='flex flex-col gap-2'>
      {new Array(numberOfItems).fill('').map((_, index) => (
        <div
          key={`${numberOfItems}_${index}`}
          className='h-14 animate-pulse rounded-lg bg-gray-300 p-2'
        />
      ))}
    </div>
  )
}

export default CustomWeekCalendar
