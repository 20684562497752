const client_form_ptBR = {
  GENERAL_INFORMATION: {
    title_create: 'Criar novo cliente',
    title_update: 'Editar dados de cadastro',
    subtitle: 'Dados de Cadastro',
    address: 'Endereço'
  },
  PROFILE: {
    title_create: 'Criar novo cliente',
    title_update: 'Editar personalização e permissões',
    subtitle: 'Personalização e Permissões'
  },
  fields: {
    personal: {
      contract_start: 'Vigência do contrato',
      contract_end: ' ‎',
      name: 'Nome',
      cpf: 'CPF',
      cnpj: 'CNPJ',
      email: 'Email',
      phone: 'Telefone',
      cep: 'CEP',
      address: 'Logradouro',
      address_number: 'Número',
      district: 'Bairro',
      complement: 'Complemento',
      state: 'Estado',
      city: 'Cidade'
    },
    profile: {
      units_limit: 'Número de unidades',
      units_limit_hint: 'Selecione quantas unidades o cliente poderá cadastrar',
      specialties: 'Especialidades',
      allow_profile_access_to_client: 'Permitir cadastro de usuários pelo cliente',
      allowed_profiles: 'Perfis de acesso permitidos para cadastro pelo cliente',
      primary_color: 'Cor primária do cliente',
      primary_color_hint: 'Selecione manualmente ou insira o código hexadecimal',
      no_image_selected: 'Nenhuma imagem selecionada',
      uploading: 'Carregando...',
      completed: 'Concluída',
      error: 'Erro ao carregar'
    }
  },
  actions: {
    select: 'Selecione',
    select_specialties: 'Selecionar especialidades',
    select_profiles: 'Selecionar perfis de acesso',
    change_file: 'Trocar arquivo',
    select_file: 'Selecionar arquivo',
    activate: 'Ativar',
    inactivate: 'Inativar',
    save: 'Salvar',
    next: 'Próximo',
    create_client: 'Criar cliente',
    select_start: 'Selecione o início',
    select_end: 'Selecione o fim'
  }
}

export default client_form_ptBR
