const consultation_details_enUS = {
  title: 'Consultation Details',
  subtitle: 'View and manage consultations',
  video_conference: 'Video Conference',
  information: 'Information',
  documents: 'Documents',
  code: 'Code',
  consultation: 'Consultation',
  date: 'Date and Time',
  specialty: 'Specialty',
  specialist: 'Specialist',

  consultation_type: {
    elective: 'Elective',
    urgency: 'Urgency'
  },

  actions: {
    edit: 'Edit',
    complete_consultation: 'Complete Consultation',
    see_complete_sheet: 'View Complete Sheet',
    start_call: 'Start Video Conference',
    anamnesis: 'Anamnesis',
    report: 'Report',
    attach_file: 'Attach File'
  }
}

export default consultation_details_enUS
