import { useTranslation } from 'react-i18next'
import Button from '../../../components/button'
import ContentFooter from '../../../components/content/footer'
import { useUserProfessionalInformationFormik } from '../../../hooks/forms/useUserForm'
import ContentForm from '../../../components/content/form'
import { Specialty, User } from '../../../types'
import { AddressBookTabs } from '@phosphor-icons/react'
import Switch from '../../../components/switch'
import SpecialtyModal from '../../../components/modal/specialty-modal'
import { ChangeEvent, useMemo, useState } from 'react'
import InputField from '../../../components/input'
import ButtonSkeleton from '../../../components/button/skeleton'
import Dropdown, { DropdownOption } from '../../../components/dropdown'
import { UFs } from '../../follow-up/constants'

type Props = {
  isUpdate: boolean
  isLoading: boolean
  user: Partial<User>
  onUpdate: (values: Partial<User>) => void
  onSave: (values: Partial<User>) => void
  onCancel: () => void
}

const UserFormProfessionalInformation: React.FC<Props> = ({
  onSave,
  onCancel,
  isLoading,
  isUpdate,
  onUpdate,
  user
}) => {
  const { t } = useTranslation('user_form')
  const formik = useUserProfessionalInformationFormik(isUpdate ? onUpdate : onSave, user)

  const [showSpecialtiesModal, setShowSpecialtiesModal] = useState(false)

  const handleOpenModal = () => {
    setShowSpecialtiesModal(true)
  }
  const handleCloseModal = () => {
    setShowSpecialtiesModal(false)
  }

  const selectedValues = useMemo(() => {
    return formik.values.specialties?.map(({ specialty }) => specialty!) || []
  }, [formik.values.specialties])

  const handleSelectSpecialties = (specialties: Specialty[]) => {
    formik.setFieldValue(
      'specialties',
      specialties.map(specialty => ({ specialty }))
    )

    specialties.map(specialty => {
      if (!formik.values.councils?.some(({ council }) => council?.id === specialty.council_id))
        formik.setFieldValue('councils', [
          ...formik.values.councils!,
          { council: specialty.council }
        ])
    })

    handleCloseModal()
  }

  const handleRemoveSpecialty = (specialtyIndex: number) => {
    const deletedItem = formik.values.specialties![specialtyIndex]
    const newList = formik.values.specialties?.filter((_, index) => index !== specialtyIndex)

    formik.setFieldValue('specialties', newList)

    if (
      !newList?.some(
        ({ specialty }) => specialty?.council?.id === deletedItem.specialty?.council?.id
      )
    ) {
      formik.setFieldValue(
        'councils',
        formik.values.councils?.filter(
          ({ council }) => council?.id !== deletedItem.specialty?.council?.id
        )
      )
    }
  }

  const handleChangeCouncilDocument = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    e.stopPropagation()
    formik.setFieldValue(
      'councils',
      formik.values.councils?.map((item, i) =>
        i === index ? { ...item, document: e.target.value } : item
      )
    )
  }

  const handleChangeCouncilUf = (option: DropdownOption | null, index: number) => {
    formik.setFieldValue(
      'councils',
      formik.values.councils?.map((item, i) =>
        i === index ? { ...item, uf: option?.value } : item
      )
    )
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <ContentForm hasSubtitle hasBackButton>
        {isLoading ? (
          <Skeleton />
        ) : (
          <div className='flex items-center gap-4 rounded-xl border border-neutralBorder-default p-4 text-label-md text-neutralContent-primary sm:col-span-2'>
            <AddressBookTabs size={24} color='#0F121A' />
            {t('fields.is_health_professional')}
            <Switch
              onChange={() =>
                formik.setFieldValue(
                  'is_health_professional',
                  !formik.values.is_health_professional
                )
              }
              className='ml-auto'
              isChecked={!!formik.values.is_health_professional}
            />
          </div>
        )}

        {!isLoading && formik.values.is_health_professional && (
          <div className='flex w-full flex-col sm:col-span-2'>
            <div className='text-heading-xs text-neutralContent-primary'>
              {t('fields.specialties')}
            </div>

            <div className='mt-2 flex items-center py-4 text-label-md text-neutralContent-primary'>
              {t('fields.select_specialties')}
              <Button
                size='sm'
                type='button'
                onClick={handleOpenModal}
                variant='secondary'
                className='ml-auto'
                label={t('actions.select')}
              />
            </div>

            <div className='rounded-xl border px-4'>
              {formik.values.specialties?.map(({ specialty }, index) => (
                <div key={specialty?.id} className='flex w-full items-center border-b py-4'>
                  <div className='flex flex-col text-label-md text-neutralContent-primary'>
                    {specialty?.name}
                    <div className='text-body-sm text-neutralContent-secondary'>
                      {specialty?.council?.name} ({specialty?.council?.document_name})
                    </div>
                  </div>
                  <Button
                    size='sm'
                    type='button'
                    className='ml-auto'
                    variant='secondary'
                    label={t('actions.remove')}
                    onClick={() => handleRemoveSpecialty(index)}
                  />
                </div>
              ))}
            </div>

            <div className='mt-9 text-heading-xs text-neutralContent-primary'>
              {t('fields.records.title')}
            </div>
            <div className='mb-9 mt-1 text-body-md text-neutralContent-secondary'>
              {t('fields.records.subtitle')}
            </div>

            {formik.values.councils?.map(({ council, document, uf }, index) => (
              <div key={council?.id} className='mb-4 rounded-xl border px-4'>
                <div className='flex w-full border-b py-4'>
                  <div className='flex flex-1 flex-col text-label-md text-neutralContent-primary'>
                    {council?.name} ({council?.document_name})
                    <div className='text-body-sm text-neutralContent-secondary'>
                      {t('fields.records.description')}
                    </div>
                  </div>
                  <div className='sm:w-[200px]'>
                    <InputField
                      label={t('fields.records.register_number')}
                      value={document || ''}
                      onChange={e => handleChangeCouncilDocument(e, index)}
                      state={formik.submitCount > 0 && !document ? 'error' : 'default'}
                    />
                  </div>
                  <div className='ml-2 w-[140px]'>
                    <Dropdown
                      label={t('fields.records.uf')}
                      name='uf'
                      value={uf || ''}
                      options={UFs}
                      placeholder='UF'
                      onChangeValue={option => handleChangeCouncilUf(option, index)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </ContentForm>

      <SpecialtyModal
        isOpen={showSpecialtiesModal}
        onClose={handleCloseModal}
        onSuccess={handleSelectSpecialties}
        selectedValues={selectedValues}
      />

      <ContentFooter>
        {isLoading ? (
          <ButtonSkeleton className='ml-0 mr-auto' />
        ) : (
          <Button
            size='md'
            type='button'
            onClick={onCancel}
            variant='secondary'
            label={t('actions.cancel')}
            isLoading={formik.isSubmitting}
          />
        )}
        {isLoading ? (
          <ButtonSkeleton />
        ) : (
          <Button
            size='md'
            type='submit'
            className='ml-auto'
            label={t(isUpdate ? 'actions.save' : 'actions.continue')}
            isLoading={formik.isSubmitting}
            state={!formik.isValid ? 'disable' : 'enable'}
          />
        )}
      </ContentFooter>
    </form>
  )
}

const Skeleton = () => {
  return (
    <div className='flex items-center gap-4 rounded-xl border border-neutralBorder-default p-4 text-label-md text-neutralContent-primary sm:col-span-2'>
      <AddressBookTabs size={24} color='#0F121A' />
      <div className='h-5 w-60 animate-pulse rounded bg-gray-200' />
      <div className='ml-auto h-5 w-14 animate-pulse rounded bg-gray-200' />
    </div>
  )
}

export default UserFormProfessionalInformation
