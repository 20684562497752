import React from 'react'
import { Props } from '../types'
import InputField from '../../../../../../../components/input'
import DatePickerWithCalendar from '../../../../../../../components/datepicker'
import { useTranslation } from 'react-i18next'

const GeneralRegisterForm: React.FC<Props> = ({ formik }) => {
  const { t } = useTranslation('patient_form')

  return (
    <div className='flex w-full flex-col'>
      <div className='flex w-full gap-6'>
        <InputField
          id='document_number'
          name='document_number'
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          label={t('fields.document_number')}
          placeholder={t('fields.document_number_placeholder')}
          value={formik.values.document_number || ''}
          hint={
            (formik.touched.document_number && formik.errors.document_number
              ? formik.errors.document_number
              : undefined) as string
          }
          state={
            formik.touched.document_number && formik.errors.document_number ? 'error' : 'default'
          }
        />

        <InputField
          id='document_agency'
          name='document_agency'
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          label={t('fields.issue_agency')}
          placeholder={t('fields.issue_agency_placeholder')}
          value={formik.values.document_agency || ''}
          hint={
            (formik.touched.document_agency && formik.errors.document_agency
              ? formik.errors.document_agency
              : undefined) as string
          }
          state={
            formik.touched.document_agency && formik.errors.document_agency ? 'error' : 'default'
          }
        />

        <DatePickerWithCalendar
          name='document_date'
          label={t('fields.send_date')}
          placeholder={t('fields.select_date')}
          onChange={val => formik.setFieldValue('document_date', val)}
          onBlur={formik.handleBlur}
          value={!!formik.values.document_date ? new Date(formik.values.document_date) : null}
          disabled={formik.isSubmitting}
          error={formik.errors.document_date as string}
          touched={formik.touched.document_date as boolean}
        />
      </div>
    </div>
  )
}

export default GeneralRegisterForm
