import React, { createContext, ReactNode, useContext, useState } from 'react'

interface FileContextProps {
  selectedFiles: File[] | null
  setSelectedFiles: (files: File[] | null) => void
}

const FileContext = createContext<FileContextProps | undefined>(undefined)

export const FileProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null)

  return (
    <FileContext.Provider value={{ selectedFiles, setSelectedFiles }}>
      {children}
    </FileContext.Provider>
  )
}

export const useFile = () => {
  const context = useContext(FileContext)
  if (!context) {
    throw new Error('useFile must be used within a FileProvider')
  }
  return context
}
