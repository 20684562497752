/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import CidsSelect from '../../../../../components/dropdown/cids-dropdown'
import { CID } from '../../../../../types'
import { useGetConsultation } from '../../../../../hooks/useConsultation'
import { useUpdateDiagnosis } from '../../../../../hooks/useCids'

export const Diagnosis: React.FC<{
  consultationId: string
  specialist_id?: string
}> = ({ consultationId, specialist_id }) => {
  const { data: consultation } = useGetConsultation(consultationId)
  const updateDiagnosis = useUpdateDiagnosis(consultationId)

  const [initialDiagnosis, setInitialDiagnosis] = useState<CID | undefined>()
  const [finalDiagnosis, setFinalDiagnosis] = useState<CID | undefined>()

  useEffect(() => {
    if (consultation) {
      setInitialDiagnosis((consultation.initial_diagnosis as unknown as CID) || undefined)
      setFinalDiagnosis((consultation.final_diagnosis as unknown as CID) || undefined)
    }
  }, [consultation])

  useEffect(() => {
    if (initialDiagnosis?.id) {
      updateDiagnosis.mutate({
        initial_diagnosis_id: initialDiagnosis.id,
        final_diagnosis_id: finalDiagnosis?.id || ''
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDiagnosis])

  useEffect(() => {
    if (finalDiagnosis?.id) {
      updateDiagnosis.mutate({
        initial_diagnosis_id: initialDiagnosis?.id || '',
        final_diagnosis_id: finalDiagnosis.id
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalDiagnosis])

  return (
    <div className='flex h-[calc(100dvh-350px)] w-full flex-wrap gap-4'>
      <CidsSelect
        name='initial_diagnosis'
        label='Hipótese Diagnóstica Inicial (CID10)'
        placeholder='Procure ou selecione um item'
        onBlur={() => {}}
        onChange={setInitialDiagnosis}
        value={initialDiagnosis?.id || ''}
      />

      <CidsSelect
        disabled={!specialist_id}
        name='final_diagnosis'
        label='Diagnóstico Final (CID10)'
        placeholder='Procure ou selecione um item'
        onBlur={() => {}}
        onChange={setFinalDiagnosis}
        value={finalDiagnosis?.id || ''}
      />
    </div>
  )
}
