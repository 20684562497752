import React, { useEffect, useMemo, useState } from 'react'
import SearchField from '../../../../../components/search'
import Checkbox from '../../../../../components/checkbox'
import Button from '../../../../../components/button'
import Avatar from '../../../../../components/avatar'
import { Client, Unit } from '../../../../../types'
import Modal from '../../../../../components/modal'
import { Hospital } from '@phosphor-icons/react'
import { useAuth } from '../../../../../contexts/auth'

interface Props {
  client?: Client
  isOpen: boolean
  onClose: () => void
  specialty_id?: string
  selectedValues: Unit[]
  onSuccess: (selectedValues: Unit[]) => void
}

const UnitsModal: React.FC<Props> = ({
  client,
  isOpen,
  onClose,
  onSuccess,
  specialty_id,
  selectedValues
}) => {
  const { user, reloadCurrentUser } = useAuth()
  const [filter, setFilter] = useState('')
  const [selectedUnits, setSelectedUnits] = useState<Unit[]>(selectedValues || [])

  useEffect(() => {
    reloadCurrentUser()
  }, [reloadCurrentUser])

  const current_client = useMemo(
    () => user?.clients?.find(item => item.client_id === client?.id),
    [client, user]
  )

  const units = useMemo(
    () =>
      current_client?.units
        ?.map(item => item.unit!)
        .filter(unit =>
          unit.specialties?.some(specialty => specialty.specialty_id === specialty_id)
        ) || [],
    [current_client, specialty_id]
  )

  useEffect(() => {
    setSelectedUnits(selectedValues || [])
  }, [selectedValues])

  const handleToggleUnit = (unit: Unit) => {
    setSelectedUnits(prevSelectedUnits => {
      if (prevSelectedUnits.some(u => u?.id === unit?.id)) {
        return prevSelectedUnits.filter(u => u?.id !== unit?.id)
      } else {
        return [...prevSelectedUnits, unit]
      }
    })
  }

  const allUnitsSelected =
    units.length > 0 && units.every(unit => selectedUnits.some(u => u?.id === unit?.id))

  const handleToggleSelectAll = () => {
    if (allUnitsSelected) {
      setSelectedUnits([])
    } else {
      setSelectedUnits(Array.from(new Set([...selectedUnits, ...units])))
    }
  }

  const handleSelectUnits = () => {
    onSuccess(selectedUnits)
    onClose()
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event
    setFilter(target.value)
  }

  const formatAddress = (unit: Unit) => {
    if (unit.address && unit.address_number && unit.district && unit.city && unit.state) {
      return `${unit.address}, ${unit.address_number}, ${unit.district}, ${unit.city}, ${unit.state}`
    }

    return 'Endereço não cadastrado/incompleto'
  }

  return (
    <Modal isOpen={isOpen} title='Selecione as unidades' onClose={onClose}>
      <div className='relative'>
        <SearchField
          placeholder='Procure por uma unidade'
          onChange={handleSearch}
          value={filter}
          maxLength={100}
        />
      </div>

      {/* INFORMAÇÕES DO CLIENTE */}
      <div className='mt-8 flex items-center gap-2'>
        <Avatar name={client?.name} size='sm' />
        <div className='flex w-full items-center justify-between'>
          <div className='flex flex-col gap-2'>
            <span className='text-label-md text-neutralContent-primary'>{client?.name}</span>
            <span className='text-body-sm text-neutralContent-secondary'>Cliente</span>
          </div>
          <div>
            <span className='text-body-sm text-neutralContent-tertiary'>
              {units.length} Unidades
            </span>
          </div>
        </div>
      </div>

      {/* LISTA DE UNIDADES */}
      <div className='mt-4 max-h-[400px] w-[400px] overflow-y-auto'>
        <div className='rounded-md border px-4'>
          <div className='flex justify-between py-2'>
            <span className='text-label-md text-neutralContent-primary'>Selecionar todas</span>
            <Checkbox isChecked={allUnitsSelected} onClick={handleToggleSelectAll} />
          </div>
          {units.map(unit => (
            <div key={unit?.id} className={`cursor-pointer gap-2  py-3 `}>
              <div className='flex items-center justify-between'>
                <div className='flex items-center gap-2'>
                  <Hospital size={24} className='text-neutralContent-primary' />
                  <div className='flex flex-col'>
                    <span className='text-label-md text-neutralContent-primary'>{unit?.name}</span>
                    <span className='text-body-sm text-neutralContent-secondary'>
                      {formatAddress(unit)}
                    </span>
                  </div>
                </div>
                <div>
                  <Checkbox
                    isChecked={selectedUnits.some(u => u?.id === unit?.id)}
                    onClick={() => handleToggleUnit(unit)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* BOTÃO SELECIONAR */}
      <div className='mt-4'>
        <Button label='Selecionar' className='w-full' onClick={handleSelectUnits} />
      </div>
    </Modal>
  )
}

export default UnitsModal
