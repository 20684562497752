/* eslint-disable @typescript-eslint/ban-ts-comment */
import Modal from '../../../components/modal'
import InputField from '../../../components/input'
import Button from '../../../components/button'
import { Consultation } from '../../../types'
import { useDeleteConsultation } from '../../../hooks/useConsultation'

interface Props {
  isModalOpen: boolean
  closeModal: () => void
  consultation: Consultation | null | undefined
}

const DeleteConsultationModal = ({ isModalOpen, closeModal, consultation }: Props) => {
  const deleteFormik = useDeleteConsultation(consultation, closeModal)

  const handleCancelDelete = () => {
    closeModal()
  }

  return (
    <Modal title='Excluir perfil de acesso' isOpen={isModalOpen} onClose={handleCancelDelete}>
      <div className='w-[400px]'>
        <div className='mb-1'>
          <p className='text-body-md text-neutralContent-tertiary'>
            Tem certeza que deseja excluir esse atendimento?
          </p>
          <br />
          <p className='text-body-md text-neutralContent-tertiary'>
            Para excluir, digite o nome do Paciente abaixo:
          </p>
        </div>
        <div className='my-4 flex w-[400px] justify-center'>
          <InputField
            name='patient.name'
            onBlur={deleteFormik.handleBlur}
            value={deleteFormik.values.patient?.name || ''}
            onChange={deleteFormik.handleChange}
            placeholder='Nome do paciente'
            //@ts-expect-error
            state={deleteFormik.errors.patient?.name ? 'error' : 'default'}
          />
        </div>
        <div className='mt-4 flex w-[400px] justify-between'>
          <Button
            type='button'
            label='Cancelar'
            size='sm'
            variant='tertiary'
            onClick={handleCancelDelete}
          />
          <Button
            size='sm'
            label='Excluir'
            variant='primary'
            type='submit'
            isLoading={deleteFormik.isSubmitting}
            onClick={() => deleteFormik.handleSubmit()}
            state={
              deleteFormik.values.patient?.name !== consultation?.patient?.name
                ? 'disable'
                : 'error'
            }
          />
        </div>
      </div>
    </Modal>
  )
}

export default DeleteConsultationModal
