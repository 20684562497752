import ConsultationService from '../services/consultation.service'
import { useToastContext } from '../contexts/toast'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { CARE_TYPE, CONSULTATION_STATUS, CONSULTATION_TYPE, PaginationParams } from '../types'
import { Consultation } from '../types'
import { ROUTES } from '../routes/path'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useState } from 'react'

export type ListConsultationProps = Partial<PaginationParams> & {
  care_type?: CARE_TYPE
  unit_id?: string
  client_id?: string

  unit_ids?: string[]
  care_types?: CARE_TYPE[]
  specialty_ids?: string[]
  status?: CONSULTATION_STATUS
}

export const useListConsultations = (params: ListConsultationProps) => {
  return useQuery({
    queryKey: ['listClients', params],
    queryFn: () => ConsultationService.list(params),
    placeholderData: prev => prev
  })
}

export const useGetConsultation = (id?: string) => {
  return useQuery({
    queryKey: ['getConsultation', { id }],
    queryFn: () => (id ? ConsultationService.getById(id) : undefined),
    placeholderData: prev => prev
  })
}

export const useGetConsultationBySpecialist = (id: string, params: PaginationParams) => {
  return useQuery({
    queryKey: ['getConsultationBySpecialist', id, params],
    queryFn: () => (id ? ConsultationService.getConsultationBySpecialist(id, params) : null),
    placeholderData: prev => prev
  })
}

export const useTransferConsultationFormik = (specialist_id: string | null) => {
  const { toast } = useToastContext()
  const navigate = useNavigate()

  const validationSchema = Yup.object({
    new_specialist_id: Yup.string().required(
      'Selecione o especialista que receberá os novos atendimentos'
    ),
    consultations: Yup.array()
      .min(1, 'Selecione pelo menos uma consulta')
      .of(Yup.string().required())
  })

  const initialValues: {
    new_specialist_id: string
    consultations: string[]
  } = {
    new_specialist_id: '',
    consultations: []
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: { new_specialist_id: string; consultations: string[] }) => {
      if (!specialist_id) {
        toast.error('Especialista "De" não foi selecionado.')
        return
      }
      try {
        await ConsultationService.transfer(
          specialist_id,
          values.new_specialist_id,
          values.consultations
        )
        toast.success('Atendimento(s) transferido(s) com sucesso')
        navigate(ROUTES.consultation.list_elective)
      } catch (err) {
        toast.error((err as Error).message || '')
      }
    }
  })

  return formik
}

export const useConsultationFormik = (values: Partial<Consultation>) => {
  const { toast } = useToastContext()
  const navigate = useNavigate()

  const validationSchema = Yup.object({
    client_id: Yup.string().required('O cliente é obrigatório.'),
    unit_id: Yup.string().required('A unidade é obrigatório.'),
    specialty_id: Yup.string().required('A unidade é obrigatório.'),
    without_patient: Yup.boolean().required(),
    patient_id: Yup.string().when('without_patient', {
      is: false,
      then: schema => schema.required('O paciente é obrigatório.'),
      otherwise: schema => schema.optional()
    }),
    type: Yup.string()
      .oneOf(
        [CONSULTATION_TYPE.TELECONSULTATION, CONSULTATION_TYPE.TELEINTERCONSULTATION],
        'Selecione um tipo válido'
      )
      .required('O tipo é obrigatório'),
    care_type: Yup.string()
      .oneOf(
        [CARE_TYPE.CHAT, CARE_TYPE.ELECTIVE_CARE, CARE_TYPE.EMERGENCY_CARE],
        'Invalid care type'
      )
      .required('O care_type é obrigatório'),
    requester_id: Yup.string().when('type', {
      is: CONSULTATION_TYPE.TELEINTERCONSULTATION,
      then: schema =>
        schema.required('O Solicitante é obrigatório para consultas do tipo TELEINTERCONSULTA.'),
      otherwise: schema => schema.notRequired().nullable()
    }),
    scale_id: Yup.string().when('care_type', {
      is: CARE_TYPE.ELECTIVE_CARE,
      then: schema => schema.required('A escala é obrigatória para consultas eletivas.'),
      otherwise: schema => schema.optional()
    }),
    start_date: Yup.string().when('care_type', {
      is: CARE_TYPE.ELECTIVE_CARE,
      then: schema => schema.required('O start_date é obrigatório para consultas eletivas.'),
      otherwise: schema => schema.optional()
    }),
    end_date: Yup.string().when('care_type', {
      is: CARE_TYPE.ELECTIVE_CARE,
      then: schema => schema.required('O end_date é obrigatório para consultas eletivas.'),
      otherwise: schema => schema.optional()
    })
  })

  const initialValues: Consultation & { without_patient?: boolean } = {
    type: undefined,
    unit_id: '',
    end_date: '',
    start_date: '',
    patient_id: '',
    specialty_id: '',
    requester_id: '',
    without_patient: false,
    client_id: values.unit?.client_id || '',
    ...values
  }

  const formik = useFormik<Consultation & { without_patient?: boolean }>({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      try {
        if (values.id) {
          await ConsultationService.update(values.id, values)
          toast.success('Agendamento atualizado com sucesso.')
        } else {
          await ConsultationService.create(values)
          toast.success('Agendamento realizado com sucesso.')
        }
        navigate(ROUTES.consultation.list.replace(':care_type', values.care_type || ''))
      } catch (err) {
        toast.error((err as Error).message || '')
      }
    }
  })

  return formik
}

export const useDeleteConsultation = (
  consultation: Consultation | null | undefined,
  closeModal: () => void
) => {
  const { toast } = useToastContext()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      patient: {
        name: ''
      }
    },
    validationSchema: Yup.object({
      patient: Yup.object({
        name: Yup.string().required('É necessário confirmar o nome para excluir')
      })
    }),
    onSubmit: async (values: Partial<Consultation>) => {
      try {
        if (values?.patient?.name === consultation?.patient?.name) {
          await ConsultationService.delete(consultation?.id as string)
          toast.success('Atendimento excluído')
          navigate(ROUTES.consultation.list_elective)
          closeModal()
        } else {
          toast.error('O nome do paciente não corresponde')
          formik.resetForm()
        }
      } catch (error) {
        toast.error((error as Error)?.message || 'Algo deu errado')
      }
    }
  })

  return formik
}

export const useFinishConsultation = () => {
  return useMutation({
    mutationFn: (id: string) => ConsultationService.finishConsultation(id)
  })
}

export const useStartConsultation = () => {
  return useMutation({
    mutationFn: (id: string) => ConsultationService.startConsultation(id)
  })
}

export const useInviteProfessional = (consultation_id: string, closeModal: () => void) => {
  const { toast } = useToastContext()

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      birthdate: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('É necessário informar o nome do profissional'),
      email: Yup.string().email('Email inválido').required('E-mail é obrigatório'),
      birthdate: Yup.date().required('Preencha a data de nascimento')
    }),
    onSubmit: async values => {
      try {
        await ConsultationService.sendInviteToProfessional(consultation_id, values)
        closeModal()
        toast.success('Convite enviado com sucesso!')
      } catch (error) {
        formik.setSubmitting(false)
        toast.error((error as Error)?.message || 'Algo deu errado')
      }
    }
  })

  return formik
}
export const useInvitePatient = (consultation_id: string, closeModal: () => void) => {
  const { toast } = useToastContext()
  const [isLoading, setIsLoading] = useState(false)

  const sendInvite = async () => {
    setIsLoading(true)
    try {
      await ConsultationService.sendInviteToPatient(consultation_id)
      closeModal()
      toast.success('Convite enviado com sucesso!')
    } catch (error) {
      toast.error((error as Error)?.message || 'Algo deu errado')
    } finally {
      setIsLoading(false)
    }
  }

  return { sendInvite, isLoading }
}

export const useSendNpsFeedback = (onClose: () => void) => {
  const { toast } = useToastContext()

  const mutation = useMutation({
    mutationFn: (data: { score: number; description?: string }) =>
      ConsultationService.sendNpsFeedback(data.score, data.description),
    onSuccess: () => {
      toast.success('Feedback enviado com sucesso!')
      onClose()
    },
    onError: error => {
      toast.error((error as Error)?.message || 'Algo deu errado')
    }
  })

  return { mutate: mutation.mutate, isLoading: mutation.isPending }
}

export const useGetLastConsultationsByPatient = (id: string, params: PaginationParams) => {
  return useQuery({
    queryKey: ['getLastConsultationsByPatient', id, params],
    queryFn: () => (id ? ConsultationService.getLastConsultationsByPatient(id, params) : null),
    placeholderData: prev => prev
  })
}
