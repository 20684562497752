import { EventInput } from '@fullcalendar/core'
import { EventImpl } from '@fullcalendar/core/internal'
import { useCallback, useEffect, useState } from 'react'
import { Scale } from '../../../types'
import { addMinutes, areIntervalsOverlapping, isAfter, parse } from 'date-fns'
import { v4 as uuid } from 'uuid'
import WeekCalendar from '../../../components/calendar/week-calendar'
import { Loading } from 'semente-js'
import { scaleNumberToDate } from '../../../helpers/scaleTime'
import ScaleService from '../../../services/scale.service'

const SelectConsultationTime: React.FC<{
  unit_id: string
  specialty_id: string
  selectedEvent?: EventImpl
  onSelectEvent: (event: EventImpl) => void
}> = ({ specialty_id, unit_id, onSelectEvent, selectedEvent }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [events, setEvents] = useState<EventInput[]>([])

  const splitSimpleEvents = useCallback(
    (scale: Scale, index = 0, startDate?: Date): EventInput[] => {
      if (
        !scale.duration ||
        !scale.time_intervals[index] ||
        !scale.time_intervals[index]?.start_time
      )
        return []

      const start =
        startDate ||
        scaleNumberToDate(
          scale.time_intervals[index].start_time,
          parse(scale.date, 'yyyy-MM-dd', new Date())
        )

      const end = addMinutes(start, scale.duration)

      // END SCALE TIME
      if (isAfter(new Date(), start)) return [...splitSimpleEvents(scale, index, end)]

      if (
        isAfter(
          end,
          scaleNumberToDate(
            scale.time_intervals[index].end_time,
            parse(scale.date, 'yyyy-MM-dd', new Date())
          )
        )
      ) {
        // THERE ISN'T NEXT SCALE
        if (!scale.time_intervals[index + 1]) return []

        // START WITH START SCALE START
        return [
          ...splitSimpleEvents(
            scale,
            index + 1,
            scaleNumberToDate(
              scale.time_intervals[index + 1].start_time,
              parse(scale.date, 'yyyy-MM-dd', new Date())
            )
          )
        ]
      }

      if (
        scale?.consultations &&
        scale?.consultations.some(({ end_date, start_date }) => {
          return areIntervalsOverlapping(
            { start, end },
            { start: new Date(start_date!), end: new Date(end_date!) }
          )
        })
      ) {
        // IT TIME IS IN USE
        return [...splitSimpleEvents(scale, index, end)]
      }

      return [
        {
          id: uuid(),
          end,
          start,
          title: scale.specialty?.name || '',
          extendedProps: {
            scale_id: scale.id
          }
        },
        ...splitSimpleEvents(scale, index, end)
      ]
    },
    []
  )

  useEffect(() => {
    ScaleService.listScalesFromUnitAndSpecialty(unit_id, specialty_id)
      .then(scales => {
        setEvents([
          ...(scales?.reduce((scales: EventInput[], scale: Scale) => {
            const newEvents = splitSimpleEvents(scale)
            return [...scales, ...newEvents]
          }, []) || [])
        ])
      })
      .finally(() => setIsLoading(false))
  }, [unit_id, specialty_id, splitSimpleEvents])

  if (isLoading)
    return (
      <div className='flex w-full justify-center'>
        <Loading />
      </div>
    )

  return (
    <WeekCalendar
      events={events}
      allowEventSelect
      eventSelected={selectedEvent}
      onEventClick={({ event }) => onSelectEvent(event)}
    />
  )
}

export default SelectConsultationTime
