import { useState, useEffect } from 'react'
import { useListSpecialty } from '../../../hooks/useSpecialty'
import { Specialty } from '../../../types'
import SelectableModal from '../../../components/modal/selectable-modal'

interface Props {
  isOpen: boolean
  selectedValues: Specialty[]
  onClose: () => void
  onSuccess: (selectedValues: Specialty[]) => void
  onBack: () => void
}

const TOTAL_PAGE = 10

const SpecialtiesFilterModal = ({ isOpen, selectedValues, onClose, onSuccess, onBack }: Props) => {
  const [specialties, setSpecialties] = useState<Specialty[]>([])
  const [filter, setFilter] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [totalSpecialties, setTotalSpecialties] = useState(0)
  const { data: dataSpecialty, isFetching } = useListSpecialty(currentPage, TOTAL_PAGE, filter)

  useEffect(() => {
    if (dataSpecialty?.data && dataSpecialty.data.length > 0) {
      setTotalSpecialties(dataSpecialty.totalItems)
      setSpecialties(old =>
        dataSpecialty.page === 0 ? dataSpecialty.data : [...old, ...dataSpecialty.data]
      )
    }
  }, [dataSpecialty])

  const handleLoadMore = () => {
    if (specialties.length >= totalSpecialties) return
    setCurrentPage(prevPage => prevPage + 1)
  }

  const handleSearch = (searchValue: string) => {
    setFilter(searchValue)
    setCurrentPage(0)
  }

  const renderField = (item: Specialty) => (
    <div>
      <span className='text-label-md text-neutralContent-primary'>{item.name}</span>
      <span className='block text-body-sm text-neutralContent-secondary'>{item.council?.name}</span>
    </div>
  )

  const handleSuccess = (selectedList: Specialty[]) => {
    const uniqueSpecialties = selectedList.filter(
      (value, index, self) => index === self.findIndex(v => v.id === value.id)
    )
    onSuccess(uniqueSpecialties)
  }

  return (
    <SelectableModal
      search
      isOpen={isOpen}
      onClose={onClose}
      data={specialties}
      loading={isFetching}
      onSearch={handleSearch}
      onSuccess={handleSuccess}
      renderField={renderField}
      onLoadMore={handleLoadMore}
      selectedValues={selectedValues}
      type='default'
      title='Especialidades'
      searchFieldLabel='Procurar por especialidades'
      onBack={onBack}
    />
  )
}

export default SpecialtiesFilterModal
