/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import ProfileService from '../services/profile.service'
import { useToastContext } from '../contexts/toast'
import * as Yup from 'yup'
import { Profile } from '../types'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../routes/path'
import { useEffect } from 'react'

export const useListProfiles = (page: number, pageSize: number, filter: string) => {
  return useQuery({
    queryKey: ['profileList', { page, pageSize, filter }],
    queryFn: () => ProfileService.list({ page, pageSize, filter }),
    retry: false,
    placeholderData: prev => prev
  })
}

export const useProfileForm = (id?: string) => {
  const { toast } = useToastContext()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const validationSchema = Yup.object({
    name: Yup.string().required('Preencha o nome')
  })

  const initialValues: Partial<Profile & { is_client: boolean }> = {
    name: '',
    permissions: []
  }

  const saveMutation = useMutation({
    mutationFn: async (values: Partial<Profile>) => {
      if (id && id !== 'new') await ProfileService.update(id, values)
      else await ProfileService.create(values)
    },
    onSuccess: () => {
      toast.success(id ? 'Perfil atualizado com sucesso.' : 'Perfil criado com sucesso.')
      queryClient.invalidateQueries({ queryKey: ['getProfile', 'profileList'] })
      navigate(ROUTES.profile.list)
      formik.resetForm()
    },
    onError: error => {
      toast.error(error.message || 'Algo deu errado')
    }
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values: Partial<Profile & { is_client: boolean }>) => {
      if (values.permissions && values.permissions.length > 0) saveMutation.mutate(values)
      else toast.error('Selecione uma permissão')
    }
  })

  const {
    data,
    isSuccess,
    isLoading: isLoadingProfile
  } = useQuery({
    queryKey: ['getProfile', { id }],
    queryFn: () =>
      id && id !== 'new' ? ProfileService.getById(id) : Promise.resolve(initialValues),
    enabled: Boolean(id),
    placeholderData: prev => prev
  })

  useEffect(() => {
    if (isSuccess && data && id) {
      formik.setValues({
        id: data.id,
        name: data.name,
        permissions: data.permissions
      })
    }
  }, [isSuccess, data, id])

  return {
    formik,
    isLoading: isLoadingProfile || saveMutation.isPending
  }
}

export const useDeleteProfile = (profile: Profile | null | undefined, closeModal: () => void) => {
  const { toast } = useToastContext()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: { name: '' },
    validationSchema: Yup.object({
      name: Yup.string().required('É necessário confirmar o nome para excluir')
    }),
    onSubmit: async (values: Profile) => {
      try {
        if (values.name === profile?.name) {
          await ProfileService.delete(profile?.id as string)
          toast.success('Perfil excluído')
          navigate(ROUTES.profile.list)
          closeModal()
        } else {
          toast.error('O nome não corresponde')
          formik.resetForm()
        }
      } catch (error) {
        toast.error((error as Error)?.message || 'Algo deu errado')
      }
    }
  })

  return formik
}
