import { ClientDomainProvider } from './contexts/auth'
import { router } from './routes'
import 'semente-js/styles.css'
import './styles/global.css'
import './styles/index.css'
import { RouterProvider } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ToastContextProvider } from './contexts/toast'
import { ChatProvider } from './contexts/chat'
import { FileProvider } from './contexts/file'
import { MenuProvider } from './contexts/menu'
import { CalendarProvider } from './contexts/calendar'

function App() {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContextProvider>
        <ClientDomainProvider>
          <ChatProvider>
            <FileProvider>
              <MenuProvider>
                <CalendarProvider>
                  <RouterProvider router={router} />
                </CalendarProvider>
              </MenuProvider>
            </FileProvider>
          </ChatProvider>
        </ClientDomainProvider>
      </ToastContextProvider>
    </QueryClientProvider>
  )
}

export default App
