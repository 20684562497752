/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect } from 'react'
import {
  useGetAnamnesisByConsultation,
  useGetQuestionsDefault
} from '../../../../hooks/useAnamnesis'
import AnyQuestion from './any-questions'

const AnamnesisQuestionsDefault: React.FC<{
  id: string
  defaultValues: any
  setDefaultValues: (values: any) => void
}> = ({ id, defaultValues, setDefaultValues }) => {
  const { data: answers, isFetching: isLoadingAnswers } = useGetAnamnesisByConsultation(id)
  const { data: questions, isLoading: isLoadingQuestions } = useGetQuestionsDefault()

  useEffect(() => {
    if (answers?.default_answers && Object.keys(defaultValues).length === 0) {
      setDefaultValues(answers.default_answers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers?.default_answers, setDefaultValues])

  return (
    <>
      {isLoadingAnswers || isLoadingQuestions ? (
        <span>Carregando</span>
      ) : (
        <>
          {questions?.map((question, index) => (
            <div key={index} className='col-span-2 py-2'>
              <span className='text-label-lg font-semibold'>{question.title}</span>
              {question.questions.map((question, index) => (
                <div
                  key={index}
                  className='border-b pb-10 pt-10 first:pt-0 last:border-b-0 last:pb-0'
                >
                  <span className='mb-6 block text-label-md font-semibold'>{question.title}</span>
                  <AnyQuestion
                    question={question}
                    values={defaultValues}
                    handleValue={setDefaultValues}
                  />
                </div>
              ))}
            </div>
          ))}
        </>
      )}
    </>
  )
}

export default AnamnesisQuestionsDefault
