import React from 'react'

const ConsultationDetailSkeleton: React.FC = () => {
  return (
    <div className='grid-col-2 grid w-full gap-4 py-6 sm:grid-cols-3 md:grid-cols-5'>
      {Array.from({ length: 8 }).map((_, index) => (
        <div key={index} className='flex items-center gap-2'>
          <div className='h-4 w-20 animate-pulse rounded bg-neutralBorder-default' />
          <div className='h-6 w-16 animate-pulse rounded bg-neutralBorder-default' />
        </div>
      ))}
    </div>
  )
}

export default ConsultationDetailSkeleton
