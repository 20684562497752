import { useEffect, useState, FocusEvent } from 'react'
import InputSkeleton from '../input/skeleton'
import Dropdown from '.'
import { useListRequester } from '../../hooks/useUser'
import { CARE_TYPE, User } from '../../types'

type Props = {
  label: string
  name: string
  hint?: string
  value: string
  error?: boolean
  unit_id?: string
  disabled?: boolean
  placeholder?: string
  care_type: CARE_TYPE
  without_custom_agenda?: boolean
  labelClassName?: string
  onChange: (unit?: User) => void
  onBlur: (e: FocusEvent<unknown, Element>) => void
}

const RequesterSelect: React.FC<Props> = ({ unit_id, onChange, care_type, ...props }) => {
  const [options, setOptions] = useState<{ value: string; label: string }[]>([])
  const { data: specialists, isFetching } = useListRequester(care_type, unit_id)

  useEffect(() => {
    if (specialists) {
      setOptions(
        specialists.map((user: User) => ({
          value: user.id || '',
          label: user.name || ''
        }))
      )
    }
  }, [specialists])

  const handleSelect = (option: { value: string; label: string } | null) => {
    if (!option) onChange(undefined)

    const unit = specialists?.find(item => item.id === option?.value)
    onChange(unit)
  }

  if (isFetching) return <InputSkeleton showLabel className='flex-1' />

  return <Dropdown options={options} onChangeValue={handleSelect} {...props} />
}

export default RequesterSelect
