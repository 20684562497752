const unit_form_ptBR = {
  fields: {
    name: 'Nome',
    unit_type: 'Tipo de Unidade',
    cnpj: 'CNPJ',
    cnes: 'Cadastro Nacional do Estabelecimento de Saúde (CNES)',
    email: 'Email',
    phone: 'Telefone',
    technical_manager: 'Responsável Técnico',
    address: 'Logradouro',
    address_number: 'Número',
    district: 'Bairro',
    cep: 'CEP',
    state: 'Estado',
    city: 'Cidade',
    specialties: 'Especialidades',
    select_specialties: 'Selecionar especialidades',
    permissions: 'Funcionalidade de atendimentos',
    permission_type_care: 'Tipos de atendimentos',
    permission_type: 'Modalidade de atendimentos',
    create_unit: 'Criar unidade',
    edit_unit: 'Editar unidade',
    unit_details: 'Detalhes da unidade',
    unit_address: 'Endereço da Unidade',
    technical_manager_name: 'Nome',
    technical_manager_council_doc: 'Número do conselho',
    technical_manager_doc: 'CPF'
  },
  actions: {
    save: 'Salvar',
    create: 'Criar unidade',
    cancel: 'Cancelar'
  }
}

export default unit_form_ptBR
