import ProfileSelect from '../../../components/dropdown/profile-dropdown'
import ButtonSkeleton from '../../../components/button/skeleton'
import ContentFooter from '../../../components/content/footer'
import ContentForm from '../../../components/content/form'
import Button from '../../../components/button'
import { useTranslation } from 'react-i18next'
import { User } from '../../../types'
import { useMemo } from 'react'

type Props = {
  isUpdate: boolean
  isLoading: boolean
  isSaving: boolean
  user: Partial<User>
  onFinish: () => void
  onCancel: () => void
  onChange: (values: Partial<User>) => void
}

const UserProfileForm: React.FC<Props> = ({
  onFinish,
  onCancel,
  onChange,
  user,
  isSaving,
  isUpdate,
  isLoading
}) => {
  const { t } = useTranslation('user_form')

  const isValid = useMemo(() => {
    return !!user?.profile?.id
  }, [user])

  return (
    <>
      <ContentForm hasSubtitle hasBackButton className='gap-0'>
        <ProfileSelect
          name='profile'
          label={t('fields.profile')}
          value={user?.profile?.id || ''}
          clients={user.clients?.map(item => item.client!)}
          onChange={profile => onChange({ profile, profile_id: profile?.id })}
        />
      </ContentForm>

      <ContentFooter>
        {isLoading ? (
          <ButtonSkeleton className='ml-0 mr-auto' />
        ) : (
          <Button
            size='md'
            type='button'
            onClick={onCancel}
            variant='secondary'
            isLoading={isSaving}
            label={t('actions.cancel')}
          />
        )}
        {isLoading ? (
          <ButtonSkeleton />
        ) : (
          <Button
            size='md'
            type='button'
            onClick={onFinish}
            className='ml-auto'
            isLoading={isSaving}
            state={isValid ? 'enable' : 'disable'}
            label={t(isUpdate ? 'actions.save' : 'actions.finish')}
          />
        )}
      </ContentFooter>
    </>
  )
}

export default UserProfileForm
