import Modal from '../../../components/modal'
import Button from '../../../components/button'
import { useActivationStatus } from '../../../hooks/useClient'

interface Props {
  isModalOpen: boolean
  closeModal: () => void
  clientId: string
}

const InactivateClient = ({ isModalOpen, closeModal, clientId }: Props) => {
  const { mutate: inactivate, isPending } = useActivationStatus(clientId as string)

  const handleInactivate = () => {
    inactivate()
  }

  return (
    <Modal
      title={'Inativar cliente'}
      isOpen={isModalOpen}
      onClose={() => {
        closeModal()
      }}
    >
      <div className='mb-1 w-96'>
        <p className='block text-body-md text-neutralContent-tertiary'>
          Tem certeza que deseja inativar esse cliente? Todos os acessos vinculados a este cliente
          serão suspensos.
        </p>
        <br />
        <p className='text-body-md text-neutralContent-tertiary'>
          Essa ação é reversível e você poderá ativá-lo novamente.
        </p>
      </div>

      <div className='mt-4 flex w-[400px]  justify-between'>
        <div className='flex w-full items-center justify-end'>
          <Button
            type='button'
            label='Cancelar'
            size='sm'
            variant='tertiary'
            onClick={closeModal}
          />
          <Button
            onClick={handleInactivate}
            label='Inativar cliente'
            size='sm'
            variant='primary'
            state='error'
            isLoading={isPending}
          />
        </div>
      </div>
    </Modal>
  )
}

export default InactivateClient
