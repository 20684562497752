import { useState, useCallback } from 'react'
import { useToastContext } from '../contexts/toast'
import { useMenu } from '../contexts/menu'

const useFullScreen = () => {
  const { toast } = useToastContext()
  const [isFullScreen, setIsFullScreen] = useState(false)
  const { setIsOpen: setIsOpenMenu } = useMenu()

  const toggleFullScreen = useCallback(() => {
    if (!document.fullscreenElement) {
      document.documentElement
        .requestFullscreen()
        .then(() => {
          setIsFullScreen(true)
        })
        .catch(err => {
          toast.error(`Erro ao tentar entrar no modo de tela cheia: ${err.message}`)
        })
      setIsOpenMenu(false)
    } else {
      document
        .exitFullscreen()
        .then(() => {
          setIsFullScreen(false)
        })
        .catch(err => {
          toast.error(`Erro ao tentar sair do modo de tela cheia: ${err.message}`)
        })
      setIsOpenMenu(true)
    }
  }, [setIsOpenMenu, toast])

  return { isFullScreen, toggleFullScreen }
}

export default useFullScreen
