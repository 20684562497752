import { X } from '@phosphor-icons/react'
import React, { ReactNode } from 'react'

interface Props {
  color?: 'neutral' | 'red' | 'blue' | 'yellow' | 'green'
  onClose?: () => void
  className?: string
  title: string
  message?: string
  leftIcon?: ReactNode
  content?: ReactNode
}

const Banner: React.FC<Props> = ({
  title,
  message,
  leftIcon,
  content,
  color = 'neutral',
  onClose,
  className
}) => {
  const colorClasses = {
    neutral: 'bg-neutralBackground-secondary text-neutralContent-primary ',
    red: 'bg-highlightRed-light border border-highlightRed-pure text-neutralContent-primary',
    blue: 'bg-highlightBlue-light text-neutralContent-primary',
    yellow: 'bg-highlightYellow-light text-neutralContent-primary',
    green: 'bg-highlightGreen-light  text-neutralContent-primary'
  }

  return (
    <div
      className={`${colorClasses[color]} ${className} flex items-center justify-between gap-4 rounded-xl p-4`}
    >
      <div className='flex items-center gap-2'>
        <div>{leftIcon}</div>
        <div className='flex flex-col'>
          <span className='text-label-md'>{title}</span>
          <span className='text-body-md'>{message}</span>
        </div>
      </div>
      {content}
      {onClose && (
        <button
          onClick={onClose}
          className={`ml-2 ${colorClasses[color]} focus:outline-none`}
          aria-label='Close'
        >
          <X size={20} />
        </button>
      )}
    </div>
  )
}

export default Banner
