import { CID, PaginationParams, PaginationResponse } from '../types'
import api from './api.service'

class CidsService {
  static async list(params: PaginationParams) {
    const response = await api.get<PaginationResponse<CID>>('/cids', {
      params
    })
    return response.data
  }

  static update(
    id: string,
    data: {
      initial_diagnosis_id: string
      final_diagnosis_id: string
    }
  ) {
    return api.patch<void>(`/consultation/${id}/diagnosis`, data)
  }
}

export default CidsService
