/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import Modal from '../../../components/modal'
import InputField from '../../../components/input'
import Button from '../../../components/button'
import { CARE_TYPE, Consultation } from '../../../types'
import { useConsultationFormik } from '../../../hooks/useConsultation'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

interface Props {
  isModalOpen: boolean
  closeModal: () => void
  consultation: Consultation | null | undefined
  care_type: CARE_TYPE | undefined
}

const UpdateConsultationModal = ({ isModalOpen, closeModal, consultation, care_type }: Props) => {
  const { t } = useTranslation('consultation_form')

  const updateFormik = useConsultationFormik({
    ...consultation,
    patient: consultation?.patient || { name: '' },
    care_type
  })
  useEffect(() => {
    if (isModalOpen && consultation) {
      updateFormik.resetForm({
        values: {
          ...consultation,
          patient: {
            ...consultation.patient,
            name: ''
          }
        }
      })
    }
  }, [isModalOpen, consultation])

  const handleCancelUpdate = () => {
    closeModal()
  }

  return (
    <Modal
      title={t('modal_confirmation_update_consultation.title')}
      isOpen={isModalOpen}
      onClose={handleCancelUpdate}
    >
      <div className='w-[400px]'>
        <div className='mb-1'>
          <p className='text-body-md text-neutralContent-tertiary'>
            {t('modal_confirmation_update_consultation.decription1')}
          </p>
          <br />
          <p className='text-body-md text-neutralContent-tertiary'>
            {t('modal_confirmation_update_consultation.decription2')}
          </p>
        </div>
        <div className='my-4 flex w-[400px] justify-center'>
          <InputField
            name='patient.name'
            onBlur={updateFormik.handleBlur}
            value={updateFormik.values.patient?.name || ''}
            onChange={updateFormik.handleChange}
            placeholder={t('modal_confirmation_update_consultation.input_placeholder')}
            //@ts-expect-error
            state={updateFormik.errors.patient?.name ? 'error' : 'default'}
          />
        </div>
        <div className='mt-4 flex w-[400px] justify-between'>
          <Button
            type='button'
            label={t('actions.cancel')}
            size='sm'
            variant='tertiary'
            onClick={handleCancelUpdate}
          />
          <Button
            size='sm'
            label={t('actions.confirm')}
            variant='primary'
            type='submit'
            isLoading={updateFormik.isSubmitting}
            onClick={() => updateFormik.handleSubmit()}
            state={
              updateFormik.values.patient?.name === consultation?.patient?.name
                ? 'enable'
                : 'disable'
            }
          />
        </div>
      </div>
    </Modal>
  )
}

export default UpdateConsultationModal
