import { format } from 'date-fns'
import { MessageType } from '../../../../types'

export const LogItem: React.FC<{
  user: string
  message: string
  hour: string
  type: MessageType.LOG_ENTERED | MessageType.LOG_EXITED
}> = ({ user, message, hour, type }) => {
  const badgeColor =
    type === MessageType.LOG_ENTERED ? 'text-highlightGreen-pure' : 'text-highlightRed-pure'
  return (
    <div className='my-4 flex items-center justify-between'>
      <div className='flex items-center gap-1'>
        <span className={badgeColor}>● </span>
        <span className='text-label-xs font-bold'>{user}</span>
        <span className='text-body-xs text-neutralContent-tertiary'>{message}</span>
      </div>
      <span className='text-body-xs text-neutralContent-disabled'>
        {format(new Date(hour), 'HH:mm')}
      </span>
    </div>
  )
}
