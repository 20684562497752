/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Button from '../button'
import Tag from '../tag'
import BlankCard from '../blank-card'

interface TagSelectorProps {
  label: string
  selectedItems: any[]
  onSelect: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onRemove: (item: any) => void
  maxSelection?: number
  buttonLabel?: string
}

const TagSelector: React.FC<TagSelectorProps> = ({
  label,
  buttonLabel = 'Selecione',
  selectedItems = [],
  onSelect,
  onRemove,
  maxSelection
}) => {
  const uniqueSelectedItems = selectedItems.filter(
    (item, index, self) => self.findIndex(i => i.id === item.id) === index
  )

  return (
    <BlankCard>
      <div className='mb-2 flex items-center justify-between'>
        <span className='font-semibold'>{label}</span>
        <span className='text-body-sm text-neutralContent-tertiary'>
          {selectedItems?.length}{' '}
          {maxSelection ? `de ${maxSelection} selecionados` : 'selecionados'}
        </span>
      </div>

      <Button
        label={buttonLabel}
        size={'sm'}
        variant='secondary'
        // @ts-ignore
        onClick={onSelect}
        type='button'
      />
      <div className='mt-3 flex flex-wrap gap-2'>
        {uniqueSelectedItems.map(item => (
          <Tag
            size='sm'
            key={item.id}
            variant='secondary'
            color='blue'
            label={item.name ?? item.id}
            onClose={() => onRemove(item)}
          />
        ))}
      </div>
    </BlankCard>
  )
}

export default TagSelector
