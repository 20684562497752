import { Link } from 'react-router-dom'
import { ROUTES } from '../../routes/path'
import React from 'react'
import { useUpdatePasswordFormik } from '../../hooks/forms/useUserForm'
import InputField from '../../components/input'
import { SpinnerGap } from '@phosphor-icons/react'

type Props = { token: string; onSuccess: () => void; onCancel?: VoidFunction }

const PasswordForm: React.FC<Props> = ({ token, onSuccess, onCancel }) => {
  const formik = useUpdatePasswordFormik(token, onSuccess)

  return (
    <form onSubmit={formik.handleSubmit} className='mx-auto flex max-w-[350px] flex-col gap-8'>
      <div className=''>
        <InputField
          placeholder='Nova senha'
          id='password'
          name='password'
          type='password'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          maxLength={100}
        />
        {formik.touched.password && formik.errors.password ? (
          <div className='text-red-500'>{formik.errors.password}</div>
        ) : null}
      </div>

      <div className=''>
        <InputField
          placeholder='Confirmar nova senha'
          id='confirmPassword'
          name='confirmPassword'
          type='password'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.confirmPassword}
          maxLength={100}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
          <div className='text-red-500'>{formik.errors.confirmPassword}</div>
        ) : null}
      </div>

      <div className='flex gap-2'>
        <Link
          to={ROUTES.login}
          onClick={onCancel}
          className='m-0 rounded-lg bg-gray-100 p-4 px-4 transition-all hover:bg-gray-200'
        >
          Cancelar
        </Link>
        <button
          type='submit'
          className='w-full rounded-lg bg-primaryBrand-primary p-4 text-white transition-all hover:bg-primaryBrand-dark'
        >
          {formik.isSubmitting ? (
            <div className={` bottom-0 left-0 right-0 top-0 flex items-center justify-center `}>
              <SpinnerGap size={24} className='animate-spin' />
            </div>
          ) : (
            <>Atualizar senha</>
          )}
        </button>
      </div>
    </form>
  )
}

export default PasswordForm
