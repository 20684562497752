const agenda_form_ptBR = {
  choose_agenda_type: 'Qual o tipo de agenda você deseja criar?',
  fixed: {
    title: 'Agenda fixa',
    description: `Escolha os dias da semana e os horários fixos em que o profissional estará disponível para atendimento.<br /><br />Ideal para profissionais que mantêm uma rotina de atendimento semanal regular`
  },
  custom: {
    title: 'Agenda personalizada',
    description:
      'Escolha dias e horários específicos, sem seguir uma rotina fixa, em que o profissional estará disponível para atendimento.<br /><br />Ideal para profissionais com horários de atendimento variáveis, que mudam de semana para semana'
  },
  event_form: {
    from: 'Das',
    until: 'Até',
    unit: 'Unidade',
    select: 'Selecione',
    duration: 'Duração do Atendimento',
    empty: '‎'
  },
  fixed_form: {
    title: 'Criar agenda fixa',
    subtitle:
      'Escolha os dias da semana e os horários fixos em que o profissional estará disponível para atendimento',
    accordion_tag: '{{start}} às {{end}} | {{unit}}',
    fields: {
      professional: 'Profissional',
      period_label: 'Período de atendimento',
      empty: '‎',
      period_start: 'Data de início',
      period_end: 'Data de término',
      title_availability: 'Defina a disponibilidade de atendimento',
      subtitle_availability:
        'Os dias e horários serão liberados para agendamento conforme a configuração definida',
      add_period: 'Adicionar período'
    }
  },
  custom_form: {
    title: 'Criar agenda personalizada',
    subtitle:
      'Escolha dias e horários específicos, sem seguir uma rotina fixa, em que o profissional estará disponível para atendimento',
    modal: {
      title: 'Período disponível em {{date}}',
      alert:
        'O profissional estará disponível para receber atendimentos desta unidade somente no período definido.'
    }
  },
  actions: {
    select_an_option: 'Selecione uma opção',
    create_agenda: 'Criar agenda',
    save: 'Salvar',
    cancel: 'Cancelar',
    select: 'Selecione'
  },
  days_of_week: {
    1: 'Segunda-feira',
    2: 'Terça-feira',
    3: 'Quarta-feira',
    4: 'Quinta-feira',
    5: 'Sexta-feira',
    6: 'Sábado',
    0: 'Domingo'
  }
}

export default agenda_form_ptBR
