import React from 'react'
import { Check, Minus } from '@phosphor-icons/react'

type CheckboxProps = {
  isChecked: boolean
  isIndeterminate?: boolean
  state?: 'enable' | 'disable'
  layout?: 'default' | 'rounded'
  size?: 'md' | 'sm'
  onClick: (value: boolean) => void
  id?: string
  className?: string
}

const Checkbox: React.FC<CheckboxProps> = ({
  isChecked,
  isIndeterminate = false,
  state = 'enable',
  layout = 'default',
  size = 'md',
  onClick,
  className,
  id
}) => {
  const getSizeStyles = () => {
    return size === 'sm' ? 'w-4 h-4 ' : 'w-5 h-5 '
  }

  const getCommonStyles = () => {
    return layout === 'rounded' ? 'rounded-500 ' : 'rounded-[4px] '
  }

  //cursor-not-allowed
  const getStateStyles = () => {
    if (state === 'disable') {
      if (layout === 'rounded') {
        if (!isChecked && !isIndeterminate) {
          return 'cursor-not-allowed border-2 border-solid border-gray-400 p-2 '
        } else if (!isChecked && isIndeterminate) {
          return 'cursor-not-allowed border-2 border-solid border-primaryBrand-primary bg-white text-primaryBrand-primary '
        } else if (isChecked) {
          return 'cursor-not-allowed border-2 border-solid border-primaryBrand-primary bg-white text-primaryBrand-primary '
        }
      } else {
        if (!isChecked && !isIndeterminate) {
          return 'cursor-not-allowed border-2 border-solid border-gray-400 p-2 '
        } else if (!isChecked && isIndeterminate) {
          return 'cursor-not-allowed border-2 border-solid border-primaryBrand-primary bg-primaryBrand-primary text-neutralContent-primaryInverse '
        } else if (isChecked) {
          return 'cursor-not-allowed border-2 border-solid border-primaryBrand-primary bg-primaryBrand-primary text-neutralContent-primaryInverse '
        }
      }
    }
    if (state === 'enable') {
      if (!isChecked && !isIndeterminate) {
        return 'border-2 border-solid border-gray-400 p-2 '
      } else if (!isChecked && isIndeterminate) {
        return 'border-2 border-solid border-primaryBrand-primary bg-primaryBrand-primary text-neutralContent-primaryInverse '
      } else if (isChecked) {
        return 'border-2 border-solid border-primaryBrand-primary bg-primaryBrand-primary text-neutralContent-primaryInverse '
      }
    }
  }

  const sizeLayout = getSizeStyles()
  const commonLayout = getCommonStyles()
  const stateLayout = getStateStyles()

  return (
    <div
      role='checkbox'
      id={id}
      aria-checked={isIndeterminate ? 'mixed' : isChecked}
      className={`flex cursor-pointer items-center justify-center ${sizeLayout} ${commonLayout} ${stateLayout} ${className}`}
      onClick={() => state === 'enable' && onClick(!isChecked)}
    >
      {isIndeterminate && <Minus size={size === 'sm' ? 12 : 16} data-testid='minus-icon' />}
      {isChecked && !isIndeterminate && (
        <Check size={size === 'sm' ? 12 : 16} data-testid='check-icon' />
      )}
    </div>
  )
}

export default Checkbox
