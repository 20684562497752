import React from 'react'

interface StepperProps {
  title: string
  subtitle?: string
  currentStep: number
  totalSteps: number
}

const Stepper: React.FC<StepperProps> = ({ title, subtitle, currentStep, totalSteps }) => {
  const percentage = (currentStep / totalSteps) * 100
  const circleStyle = {
    background: `conic-gradient(#007BBD ${percentage}%, #E5F6FF ${percentage}%)`
  }

  return (
    <div className='flex items-center space-x-4'>
      <div className='relative flex-shrink-0'>
        <div className='flex h-12 w-12 items-center justify-center rounded-full'>
          <div className='absolute h-12 w-12 rounded-full' style={circleStyle} />
          <div className='relative flex h-11 w-11 items-center justify-center rounded-full border-4 border-transparent bg-white'>
            <div className='h-9 w-9 rounded-full bg-primaryBrand-dark'>
              <div className='flex h-full w-full items-center justify-center rounded-full bg-blue-500 text-base font-bold text-white'>
                {currentStep}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='text-lg font-bold'>{title}</div>
        <div className='text-gray-500'>{subtitle || `Passo ${currentStep} de ${totalSteps}`}</div>
      </div>
    </div>
  )
}

export default Stepper
