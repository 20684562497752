const patient_detail_enUS = {
  last_consultations: 'Last Consultations',
  birth_date: 'Date of Birth',
  weight: 'Weight',
  height: 'Height',
  gender: 'Gender',
  mother_name: "Mother's Name",
  race: 'Race/Color',
  nationality: 'Nationality',
  naturalness: 'Place of Birth',
  cpf: 'CPF',
  cns_number: 'CNS Number',
  address_complete: 'Full Address',

  principal_email: 'Primary Email',
  principal_phone: 'Primary Phone',
  alternative_phone: 'Alternative Phone',
  rg: 'ID Number (RG)',
  rg_issuer: 'Issuing Authority',
  issuer_date: 'Issue Date',

  basic_identification: 'Basic Identification',
  demographic_information: 'Demographic Information',
  contact_information: 'Contact Information',
  documentation: 'Documentation',
  address: 'Address',

  medications_in_use: 'Medications in Use',
  allergies: 'Allergies',
  cronic_diseases: 'Chronic Conditions',
  surgical_history: 'Surgical History',

  medical_certificates: 'Medical Certificates',
  prescriptions: 'Prescriptions',
  referrals: 'Referrals',
  test_requests: 'Test Requests',
  medical_reports: 'Medical Reports',

  no_attach: 'No Files Attached',
  no_info: 'No Information',
  no_consultations: 'No consultations found for this patient',
  not_informed: 'Not Informed',

  tabs: {
    resume: 'Summary',
    personal_data: 'Personal Data',
    clinical_information: 'Clinical Information',
    consultations: 'Consultations',
    document: 'Documents'
  },
  types: {
    chat: 'Chat',
    elective_care: 'Elective Care',
    emergency_care: 'Emergency Care'
  },
  actions: {
    update: 'Edit',
    see_more: 'See More'
  }
}

export default patient_detail_enUS
