import { X } from '@phosphor-icons/react'
import { BellRinging } from '@phosphor-icons/react/dist/ssr'
import { useEffect, useMemo, useState } from 'react'
import FollowUpService from '../../../services/follow-up.service'

const ConsultationCountToast: React.FC = () => {
  const [count, setCount] = useState(0)
  const followUp = useMemo(() => new FollowUpService(), [])

  useEffect(() => {
    if (!followUp.socket?.hasListeners('new'))
      followUp.socket?.on('new', () => {
        setCount(old => old + 1)
      })
    return () => {
      followUp.socket?.removeAllListeners()
    }
  }, [followUp])

  return (
    <div
      className={`fixed right-4 flex gap-4 rounded-2xl border-[1px] border-neutralBorder-default bg-white p-4 shadow-md transition-all ${count > 0 ? 'bottom-4' : 'bottom-[-100px]'}`}
    >
      <div className='h-fit rounded-lg border-[1px] border-neutralBorder-default p-2'>
        <BellRinging size={24} />
      </div>
      <div>
        <div className='relative flex'>
          <span className='flex-1 text-label-md'>Novos atendimentos</span>
          <button className='absolute right-0 top-0' type='button' onClick={() => setCount(0)}>
            <X size={16} />
          </button>
        </div>
        <div className='mt-2 text-body-md'>{`${count} atendimentos foram criados recentemente`}</div>
      </div>
    </div>
  )
}

export default ConsultationCountToast
