import Button from '../../../../components/button'
import Modal from '../../../../components/modal'
import { useUnitActivationStatus } from '../../../../hooks/useClient'
import { useTranslation } from 'react-i18next'

interface Props {
  isModalOpen: boolean
  closeModal: () => void
  id: string
  client_id: string
}

const InactivateUnit = ({ isModalOpen, closeModal, id, client_id }: Props) => {
  const { t } = useTranslation('unit_detail')
  const { mutate: inactivate, isPending } = useUnitActivationStatus(
    id as string,
    client_id as string
  )

  const handleInactivate = () => {
    inactivate()
  }

  return (
    <Modal title={t('modal.title')} isOpen={isModalOpen} onClose={closeModal}>
      <div className='mb-1 w-96'>
        <p className='block text-body-md text-neutralContent-tertiary'>{t('modal.message_1')}</p>
        <br />
        <p className='text-body-md text-neutralContent-tertiary'>{t('modal.message_2')}</p>
      </div>

      <div className='mt-4 flex w-[400px] justify-between'>
        <div className='flex w-full items-center justify-end'>
          <Button
            type='button'
            label={t('modal.cancel')}
            size='sm'
            variant='tertiary'
            onClick={closeModal}
          />
          <Button
            onClick={handleInactivate}
            label={t('modal.confirm')}
            size='sm'
            variant='primary'
            state='error'
            isLoading={isPending}
          />
        </div>
      </div>
    </Modal>
  )
}

export default InactivateUnit
