import { UserClient } from '../../../types'
import { Hospital } from '@phosphor-icons/react'
import Button from '../../../components/button'
import { useTranslation } from 'react-i18next'
import Avatar from '../../../components/avatar'
import Modal from '../../../components/modal'
import { useState } from 'react'

const ClientUnitsList: React.FC<{
  userClients: UserClient[]
}> = ({ userClients }) => {
  const [userClientToShow, setUserClientToShow] = useState<UserClient>()
  const { t } = useTranslation('user_form')

  return (
    <div className='rounded-xl border border-neutralBorder-default px-4'>
      {userClients.map(userClient => (
        <div className='flex w-full flex-col items-center' key={userClient.client?.id}>
          <div className='flex w-full items-center'>
            <Avatar client={userClient.client} className='mr-4' size='sm' />
            <div className='flex flex-1 items-center border-b'>
              <div className='flex flex-1 flex-col  py-4'>
                <div className='text-label-md text-neutralContent-primary'>
                  {userClient.client?.name}
                </div>
                <div className='text-body-sm text-neutralContent-tertiary'>
                  {t('fields.units_selected', { value: userClient.units!.length })}
                </div>
              </div>
              <Button
                size='sm'
                type='button'
                variant='secondary'
                label={t('actions.see_units')}
                onClick={() => setUserClientToShow(userClient)}
              />
            </div>
          </div>
          <Modal
            title={userClientToShow?.client?.name || ''}
            isOpen={!!userClientToShow}
            onClose={() => setUserClientToShow(undefined)}
          >
            <div className='flex  w-full min-w-[400px] flex-col rounded-xl border border-neutralBorder-default px-4'>
              {userClientToShow?.units?.map(({ unit }) => (
                <div key={unit?.id} className='mb-auto flex w-full items-center gap-4'>
                  <Hospital size={24} color='#0F121A' className='my-4' />
                  <div className='mr-auto  text-label-md text-neutralContent-primary'>
                    {unit?.name}
                  </div>
                </div>
              ))}
            </div>
          </Modal>
        </div>
      ))}
    </div>
  )
}

export default ClientUnitsList
