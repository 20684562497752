import { Hospital } from '@phosphor-icons/react'
import { Client, Unit } from '../../../types'
import { useListUnitsClient } from '../../../hooks/useClient'
import Checkbox from '../../../components/checkbox'
import Radio from '../../../components/radio'
import SearchField from '../../../components/search'
import { useState } from 'react'

type Props = {
  multiple?: boolean
  selectedClient: Client
  selectedUnits: Unit[]
  handleSelectedUnits: (units: Unit[]) => void
}

const UnitsListFilter: React.FC<Props> = ({
  multiple,
  selectedClient,
  selectedUnits,
  handleSelectedUnits
}) => {
  const [unitFilter, setUnitFilter] = useState('')

  return (
    <div className='flex flex-col gap-2'>
      <div className='mt-2'>
        <SearchField
          placeholder={'Procurar por unidade...'}
          onChange={({ target }) => setUnitFilter(target.value)}
          value={unitFilter}
          maxLength={100}
        />
      </div>
      <UnitsClientList
        client={selectedClient}
        multiple={multiple}
        handleSelectedUnits={handleSelectedUnits}
        selectedUnits={selectedUnits}
        unitFilter={unitFilter}
      />
    </div>
  )
}

const UnitsClientList: React.FC<{
  multiple?: boolean
  client: Client
  selectedUnits: Unit[]
  handleSelectedUnits: (units: Unit[]) => void
  unitFilter: string
}> = ({ client, multiple, handleSelectedUnits, selectedUnits, unitFilter }) => {
  const { data: unitsData, isFetching } = useListUnitsClient(client.id!)

  const units = unitsData?.data?.filter(unit =>
    unit.name?.toLowerCase().includes(unitFilter.toLowerCase())
  )

  const handleSelect = (toSelect: boolean, unit: Unit) => {
    let newSelectedUnits: Unit[]
    if (toSelect) {
      newSelectedUnits = multiple ? [...selectedUnits, unit] : [unit]
    } else {
      newSelectedUnits = selectedUnits.filter(item => item.id !== unit.id)
    }
    handleSelectedUnits(newSelectedUnits)
  }

  return (
    <div className='flex w-full flex-col items-center' key={client.id}>
      {isFetching ? (
        <Skeleton />
      ) : units && units.length > 0 ? (
        units.map((unit: Unit) => (
          <div key={unit.id} className='flex w-full items-center gap-4'>
            <Hospital size={24} color='#0F121A' className='my-4' />
            <div className='mr-auto text-label-md text-neutralContent-primary'>{unit.name}</div>
            {multiple ? (
              <Checkbox
                isChecked={selectedUnits.some(item => item.id === unit.id)}
                onClick={toSelect => handleSelect(toSelect, unit)}
              />
            ) : (
              <Radio
                size='md'
                state='enabled'
                selected={selectedUnits.some(item => item.id === unit.id)}
                onChange={() =>
                  handleSelect(!selectedUnits.some(item => item.id === unit.id), unit)
                }
              />
            )}
          </div>
        ))
      ) : (
        <div className='text-center text-neutralContent-secondary'>Nenhuma unidade encontrada.</div>
      )}
    </div>
  )
}

const Skeleton: React.FC = () => {
  return (
    <div className='flex w-full flex-col'>
      {Array.from({ length: 3 }, (_, index_row) => (
        <div key={index_row} className='flex w-full items-center py-4'>
          <div className='mr-4 h-9 w-9 animate-pulse rounded-xl bg-gray-200' />
          <div className='mr-auto h-5 w-36 animate-pulse bg-gray-200' />
          <div className='h-5 w-5 animate-pulse rounded-md bg-gray-200' />
        </div>
      ))}
    </div>
  )
}

export default UnitsListFilter
