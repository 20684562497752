/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState } from 'react'
import { useUserPersonalInformationFormik } from '../../../hooks/forms/useUserForm'
import StateDropdown from '../../../components/dropdown/state-dropdown'
import CityDropdown from '../../../components/dropdown/city-dropdown'
import SelectUnitsModal from '../../../components/select-units-modal'
import ButtonSkeleton from '../../../components/button/skeleton'
import InputSkeleton from '../../../components/input/skeleton'
import ContentFooter from '../../../components/content/footer'
import ContentForm from '../../../components/content/form'
import { AddressBookTabs, UploadSimple } from '@phosphor-icons/react'
import DatePicker from '../../../components/datepicker'
import Checkbox from '../../../components/checkbox'
import Dropdown, { DropdownOption } from '../../../components/dropdown'
import InputField from '../../../components/input'
import ClientUnitsList from './client-units-list'
import Button from '../../../components/button'
import { User, UserType } from '../../../types'
import { useTranslation } from 'react-i18next'
import Radio from '../../../components/radio'
import Chip from '../../../components/chip'
import CountriesDropdown from '../../../components/dropdown/countries-dropdown'
import BlankCard from '../../../components/blank-card'
import Avatar from '../../../components/avatar'
import { useToastContext } from '../../../contexts/toast'

type Props = {
  isUpdate: boolean
  isLoading: boolean
  user: Partial<User>
  onUpdate: (values: Partial<User>) => void
  onSave: (values: Partial<User>) => void
  onCancel: () => void
}

export const genders = [
  'cisgender_woman',
  'transgender_woman',
  'cisgender_man',
  'transgender_man',
  'non_binary',
  'other'
]

export const sexes = ['masculine', 'feminine', 'other']

const races = ['white', 'black', 'brown', 'yellow', 'indigenous', 'no_information']

const UserFormPersonalInformation: React.FC<Props> = ({
  onSave,
  onCancel,
  onUpdate,
  user,
  isUpdate,
  isLoading
}) => {
  const { t } = useTranslation('user_form')

  const [isOtherGender, setIsOtherGender] = useState(
    !!user.gender && !genders.includes(user.gender || '')
  )

  const { toast } = useToastContext()

  const [imageName, setImageName] = useState<string>('')
  const [uploadStatus, setUploadStatus] = useState<'idle' | 'loading' | 'completed' | 'error'>(
    'idle'
  )

  const [isOtherSex, setIsOtherSex] = useState(!!user.sex && !sexes.includes(user.sex || ''))

  const [showUnitsModal, setShowUnitsModal] = useState(false)

  const formik = useUserPersonalInformationFormik(isUpdate ? onUpdate : onSave, user)

  const onChangeNationality = (nationality: string) => {
    if (nationality === 'BR') {
      formik.setFieldValue('nationality', 'BR')
      formik.setFieldValue('naturalization_date', '')
      formik.setFieldValue('passport_country', '')
      formik.setFieldValue('passport_number', '')
    } else {
      formik.setFieldValue('nationality', '')
      formik.setFieldValue('birth_state', '')
      formik.setFieldValue('birth_city', '')
    }
  }

  const handleGenderChange = (option: DropdownOption | null) => {
    if (option?.value === 'other') {
      formik.setFieldValue('gender', '')
      setIsOtherGender(genders.includes(option?.value || ''))
    } else {
      formik.setFieldValue('gender', option?.value || '')
      setIsOtherGender(false)
    }
  }

  const handleSexChange = (option: DropdownOption | null) => {
    if (option?.value === 'other') {
      formik.setFieldValue('sex', '')
      setIsOtherSex(genders.includes(option?.value || ''))
    } else {
      formik.setFieldValue('sex', option?.value || '')
      setIsOtherSex(false)
    }
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp']
      if (!validImageTypes.includes(file.type)) {
        setUploadStatus('error')
        toast.error('Por favor, selecione um arquivo de imagem válido (JPEG, PNG, ou WEBP).')
        return
      }

      setImageName(file.name)
      try {
        await formik.setFieldValue('image', URL.createObjectURL(file))
        setUploadStatus('completed')
      } catch {
        setUploadStatus('error')
      }
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      {isLoading ? (
        <Skeleton />
      ) : (
        <ContentForm hasSubtitle hasBackButton>
          <InputField
            id='name'
            name='name'
            type='text'
            label={t('fields.name')}
            onBlur={formik.handleBlur}
            value={formik.values.name!}
            onChange={formik.handleChange}
            state={formik.touched.name && formik.errors.name ? 'error' : 'default'}
            hint={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
          />

          <InputField
            type='text'
            id='social_name'
            name='social_name'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            label={t('fields.social_name')}
            value={formik.values.social_name!}
            state={formik.touched.social_name && formik.errors.social_name ? 'error' : 'default'}
            hint={
              formik.touched.social_name && formik.errors.social_name
                ? formik.errors.social_name
                : undefined
            }
          />

          <DatePicker
            name='birthdate'
            label={t('fields.birthdate')}
            placeholder='Selecione uma data'
            onChange={val => formik.setFieldValue('birthdate', val)}
            onBlur={formik.handleBlur}
            value={!!formik.values.birthdate ? new Date(formik.values.birthdate) : null}
            disabled={formik.isSubmitting}
            error={formik.errors.birthdate}
            touched={formik.touched.birthdate}
            limit={{ maxDate: new Date(), minDate: new Date(1900, 0, 1) }}
          />

          <div className=''>
            <InputField
              type='text'
              id='mother_name'
              name='mother_name'
              onBlur={formik.handleBlur}
              label={t('fields.mother_name')}
              value={formik.values.mother_name!}
              onChange={formik.handleChange}
              state={
                formik.values.mother_unknown
                  ? 'disabled'
                  : formik.touched.mother_name && formik.errors.mother_name
                    ? 'error'
                    : 'default'
              }
              hint={
                formik.touched.mother_name && formik.errors.mother_name
                  ? formik.errors.mother_name
                  : undefined
              }
            />
            <div className='mt-2 flex items-center gap-2'>
              <Checkbox
                size='sm'
                isChecked={formik.values.mother_unknown!}
                onClick={isChecked => {
                  if (isChecked) formik.setFieldValue('mother_name', '')
                  formik.setFieldValue('mother_unknown', isChecked)
                }}
              />
              {t('fields.mother_unknown')}
            </div>
          </div>
          <div className='flex grid-cols-2 items-center gap-2'>
            <Dropdown
              name='sex'
              label={t('fields.sex')}
              hint={formik.errors.sex ? formik.errors.sex?.toString() : undefined}
              value={isOtherSex ? 'other' : formik.values.sex || ''}
              onBlur={formik.handleBlur}
              error={Boolean(formik.touched.sex && formik.errors.sex)}
              onChangeValue={handleSexChange}
              options={sexes.map(value => ({
                value: value !== 'other' ? t(`selects.sexes.${value}`) : 'other',
                label: t(`selects.sexes.${value}`)
              }))}
            />

            {isOtherSex && (
              <InputField
                type='text'
                id='other_sex'
                name='other_sex'
                className='flex-1'
                onBlur={formik.handleBlur}
                onChange={e => formik.setFieldValue('sex', e.target.value)}
                label={t('fields.other_sex')}
                value={formik.values.sex || ''}
                state={formik.touched.sex && formik.errors.sex ? 'error' : 'default'}
                hint={formik.touched.sex && formik.errors.sex ? formik.errors.sex : undefined}
              />
            )}
          </div>

          <div className='flex grid-cols-2 items-center gap-2'>
            <Dropdown
              name='gender'
              className='flex-1'
              label={t('fields.gender')}
              onBlur={formik.handleBlur}
              onChangeValue={handleGenderChange}
              value={isOtherGender ? 'other' : formik.values.gender || ''}
              options={genders.map(value => ({
                value: value !== 'other' ? t(`selects.genders.${value}`) : 'other',
                label: t(`selects.genders.${value}`)
              }))}
            />

            {isOtherGender && (
              <InputField
                type='text'
                id='other_gender'
                name='other_gender'
                className='flex-1'
                onBlur={formik.handleBlur}
                onChange={e => formik.setFieldValue('gender', e.target.value)}
                label={t('fields.other_gender')}
                value={formik.values.gender || ''}
                state={formik.touched.gender && formik.errors.gender ? 'error' : 'default'}
                hint={
                  formik.touched.gender && formik.errors.gender ? formik.errors.gender : undefined
                }
              />
            )}
          </div>

          <Dropdown
            name='race'
            label={t('fields.race')}
            value={formik.values.race!}
            onBlur={formik.handleBlur}
            onChangeValue={option => formik.setFieldValue('race', option?.value || '')}
            options={races.map(value => ({ value, label: t(`selects.races.${value}`) }))}
          />

          <div>
            <label className='label-md text-neutralContent-primary'>
              {t('fields.nationality')}
            </label>
            <div className='flex w-full gap-2'>
              <Chip
                size='xl'
                className='flex-1'
                label={t('selects.nationality.BR')}
                isSelected={formik.values.nationality === 'BR'}
                onClick={() => onChangeNationality('BR')}
              />

              <Chip
                size='xl'
                className='flex-1'
                label={t('selects.nationality.OTHER')}
                isSelected={formik.values.nationality !== 'BR'}
                onClick={() => onChangeNationality('')}
              />
            </div>
          </div>

          {formik.values.nationality === 'BR' ? (
            <>
              <StateDropdown
                onBlur={formik.handleBlur}
                label={t('fields.birth_state')}
                value={formik.values.birth_state || ''}
                error={formik.submitCount > 0 && !!formik.errors.birth_state}
                hint={formik.submitCount > 0 ? formik.errors.birth_state : ''}
                onChange={option => formik.setFieldValue('birth_state', option?.value)}
              />

              <CityDropdown
                onBlur={formik.handleBlur}
                label={t('fields.birth_city')}
                uf={formik.values.birth_state!}
                value={formik.values.birth_city || ''}
                error={formik.submitCount > 0 && !!formik.errors.birth_city}
                hint={formik.submitCount > 0 ? formik.errors.birth_city : ''}
                onChange={option => formik.setFieldValue('birth_city', option?.value)}
              />
            </>
          ) : (
            <>
              <DatePicker
                name='naturalization_date'
                label={t('fields.naturalization_date')}
                placeholder='Selecione uma data'
                onChange={val => formik.setFieldValue('naturalization_date', val)}
                onBlur={formik.handleBlur}
                value={
                  !!formik.values.naturalization_date
                    ? new Date(formik.values.naturalization_date)
                    : null
                }
                disabled={formik.isSubmitting}
                error={formik.errors.naturalization_date}
                touched={formik.touched.naturalization_date}
              />

              <CountriesDropdown
                onBlur={formik.handleBlur}
                label={t('fields.birth_country')}
                value={formik.values.nationality || ''}
                error={formik.submitCount > 0 && !!formik.errors.nationality}
                hint={formik.submitCount > 0 ? formik.errors.nationality : ''}
                onChange={option => formik.setFieldValue('nationality', option?.value)}
              />

              {/* <InputField
                type='text'
                id='nationality'
                name='nationality'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                label={t('fields.birth_country')}
                value={formik.values.nationality!}
                state={
                  formik.touched.nationality && formik.errors.nationality ? 'error' : 'default'
                }
                hint={
                  formik.touched.nationality && formik.errors.nationality
                    ? formik.errors.nationality
                    : undefined
                }
              /> */}

              <CountriesDropdown
                onBlur={formik.handleBlur}
                label={t('fields.passport_country')}
                value={formik.values.passport_country || ''}
                error={formik.submitCount > 0 && !!formik.errors.passport_country}
                hint={formik.submitCount > 0 ? formik.errors.passport_country : ''}
                onChange={option => formik.setFieldValue('passport_country', option?.value)}
              />
              {/* 
              <InputField
                type='text'
                id='passport_country'
                name='passport_country'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                label={t('fields.passport_country')}
                value={formik.values.passport_country!}
                state={
                  formik.touched.passport_country && formik.errors.passport_country
                    ? 'error'
                    : 'default'
                }
                hint={
                  formik.touched.passport_country && formik.errors.passport_country
                    ? formik.errors.passport_country
                    : undefined
                }
              /> */}

              <InputField
                type='passport'
                id='passport_number'
                name='passport_number'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                label={t('fields.passport_number')}
                value={formik.values.passport_number!}
                state={
                  formik.touched.passport_number && formik.errors.passport_number
                    ? 'error'
                    : 'default'
                }
                hint={
                  formik.touched.passport_number && formik.errors.passport_number
                    ? formik.errors.passport_number
                    : undefined
                }
              />
            </>
          )}

          <div className='flex items-center gap-4 rounded-xl border border-neutralBorder-default p-4 text-label-md text-neutralContent-primary sm:col-span-2'>
            <AddressBookTabs size={24} color='#0F121A' />
            {t('fields.user_from_client')}

            <button
              type='button'
              className={`ml-auto flex items-center rounded-md border p-4 ${formik.values.type === UserType.INTERNAL ? 'border-primaryBrand-primary bg-primaryBrand-light' : ''}`}
              onClick={() => formik.setFieldValue('type', UserType.INTERNAL)}
            >
              <Radio
                size='md'
                state='enabled'
                className='mr-2'
                selected={formik.values.type === UserType.INTERNAL}
              />
              <span>Interno L2D</span>
            </button>

            <button
              type='button'
              className={`flex items-center rounded-md border p-4  ${formik.values.type === UserType.CLIENT ? 'border-primaryBrand-primary bg-primaryBrand-light' : ''}`}
              onClick={() => formik.setFieldValue('type', UserType.CLIENT)}
            >
              <Radio
                size='md'
                state='enabled'
                className='mr-2'
                selected={formik.values.type === UserType.CLIENT}
              />
              <span>Cliente</span>
            </button>
          </div>

          <div className='sm:col-span-2'>
            <div className='text-heading-xs text-neutralContent-primary'>{t('fields.clients')}</div>

            <div className='mt-2 flex items-center py-4 text-label-md text-neutralContent-primary'>
              {t('fields.select_clients')}
              <Button
                size='sm'
                type='button'
                onClick={() => setShowUnitsModal(true)}
                variant='secondary'
                className='ml-auto'
                label={t('actions.select')}
              />
            </div>

            {formik.values.clients!.length > 0 && (
              <ClientUnitsList userClients={formik.values.clients || []} />
            )}

            <SelectUnitsModal
              multiple
              isOpen={showUnitsModal}
              clients={formik.values.clients || []}
              onClose={() => setShowUnitsModal(false)}
              onSelectMultiple={clients => formik.setFieldValue('clients', clients)}
            />
          </div>

          <BlankCard className='col-span-2 h-[75px] items-center'>
            <AvatarUploadField
              imageName={imageName}
              imageUrl={formik.values.image}
              uploadStatus={uploadStatus}
              onFileChange={handleFileChange}
              t={t}
            />
          </BlankCard>
        </ContentForm>
      )}

      <ContentFooter>
        {isLoading ? (
          <ButtonSkeleton className='ml-0 mr-auto' />
        ) : (
          <Button
            size='md'
            type='button'
            onClick={onCancel}
            variant='secondary'
            label={t('actions.cancel')}
            isLoading={formik.isSubmitting}
          />
        )}
        {isLoading ? (
          <ButtonSkeleton />
        ) : (
          <Button
            size='md'
            type='submit'
            className='ml-auto'
            isLoading={formik.isSubmitting}
            state={!formik.isValid ? 'disable' : 'enable'}
            label={t(isUpdate ? 'actions.save' : 'actions.continue')}
          />
        )}
      </ContentFooter>
    </form>
  )
}

const AvatarUploadField: React.FC<{
  imageName: string
  imageUrl?: string
  uploadStatus: 'idle' | 'loading' | 'completed' | 'error'
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  t: (key: string) => string
}> = ({ imageName, imageUrl, uploadStatus, onFileChange, t }) => (
  <div className='mb-8 flex items-center justify-between'>
    <div className='flex items-center'>
      <Avatar className='mr-2' imageUrl={imageUrl} size={'sm'} />
      <div className='flex flex-col'>
        <span className='text-label-md text-neutralContent-primary'>
          {imageName || t('fields.no_image_selected')}
        </span>
        <span className='text-body-sm text-neutralContent-secondary'>
          {uploadStatus === 'loading' && t('fields.uploading')}
          {uploadStatus === 'completed' && t('fields.completed')}
          {uploadStatus === 'error' && t('fields.error')}
        </span>
      </div>
    </div>
    <input
      type='file'
      id='avatar-upload'
      style={{ display: 'none' }}
      onChange={onFileChange}
      accept='image/*'
    />
    <Button
      type='button'
      label={imageName ? t('actions.change_file') : t('actions.select_file')}
      onClick={() => document.getElementById('avatar-upload')?.click()}
      variant='secondary'
      size={'sm'}
      iconLeft={<UploadSimple size={18} />}
    />
  </div>
)

const Skeleton = () => {
  return (
    <ContentForm hasSubtitle hasBackButton>
      <InputSkeleton showLabel />
      <InputSkeleton showLabel />
      <InputSkeleton showLabel />
      <InputSkeleton showLabel />
      <InputSkeleton showLabel />
      <InputSkeleton showLabel />
      <InputSkeleton showLabel />
      <InputSkeleton showLabel />
    </ContentForm>
  )
}

export default UserFormPersonalInformation
