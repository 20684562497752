import React from 'react'
import Dropdown from '.'
import InputField from '../input'
import { useListCountries } from '../../hooks/useCountries'

interface CountriesDropdownProps {
  value: string
  hint?: string
  label?: string
  error?: boolean
  disabled?: boolean
  placeholder?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur: (event: React.FocusEvent<any>) => void
  onChange: (option: { value: string; label: string } | null) => void
}

const CountriesDropdown: React.FC<CountriesDropdownProps> = ({
  hint,
  value,
  label,
  error,
  onBlur,
  onChange,
  disabled = false,
  placeholder = 'Selecione'
}) => {
  const { data: countries } = useListCountries()

  if (!countries)
    return (
      <InputField onChange={() => {}} state='disabled' value='' label={label || 'Nacionalidade'} />
    )

  return (
    <Dropdown
      name='nationality'
      label={label || 'Nacionalidade'}
      placeholder={placeholder}
      onBlur={onBlur}
      value={value}
      onChangeValue={onChange}
      options={countries}
      disabled={disabled}
      error={error}
      hint={hint}
    />
  )
}

export default CountriesDropdown
