/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import Modal from '../../../components/modal'
import InputField from '../../../components/input'
import Button from '../../../components/button'
import Checkbox from '../../../components/checkbox'
import Switch from '../../../components/switch'
import { Council } from '../../../types'
import { useCouncilFormik, useDeleteCouncil } from '../../../hooks/useCouncil'

interface Props {
  council?: Council
  isModalOpen: boolean
  closeModal: () => void
}

const CouncilFormModal = ({ isModalOpen, closeModal, council }: Props) => {
  const [isExempt, setIsExempt] = useState(false)
  const [mode, setMode] = useState('edit')

  const { formik } = useCouncilFormik(council, closeModal, isExempt)
  const deleteFormik = useDeleteCouncil(council, closeModal, setMode)

  const handleDeleteMode = () => {
    setMode('delete')
    deleteFormik.resetForm()
  }

  const handleEditMode = () => {
    setMode('edit')
    formik.resetForm()
  }

  useEffect(() => {
    if (isModalOpen) {
      setIsExempt(!!council && !council.document_name)
    }
  }, [council, isModalOpen])

  useEffect(() => {
    if (isExempt) formik.setFieldValue('document_name', '')
  }, [isExempt])

  const handleClose = () => {
    formik.resetForm()
    deleteFormik.resetForm()
    closeModal()
    setMode('edit')
  }

  const getTitle = () => {
    if (!council) return 'Novo conselho'
    if (mode === 'delete') return 'Excluir conselho'

    return 'Editar conselho'
  }

  return (
    <Modal isOpen={isModalOpen} title={getTitle()} onClose={handleClose}>
      <form
        onSubmit={mode === 'edit' ? formik.handleSubmit : deleteFormik.handleSubmit}
        className='max-w-[480px]'
      >
        {mode === 'delete' ? (
          <>
            <div className='mb-1'>
              <p className='text-body-md text-neutralContent-tertiary'>
                Tem certeza que deseja excluir esse conselho? Essa ação é permanente e não poderá
                ser desfeita.
              </p>
              <br />
              <p className='text-body-md text-neutralContent-tertiary'>
                Para confirmar, digite o nome do conselho.
              </p>
            </div>

            <div className='my-4 flex justify-center'>
              <InputField
                label={''}
                name='name'
                placeholder='Nome do conselho'
                value={deleteFormik.values.name}
                onBlur={deleteFormik.handleBlur}
                onChange={deleteFormik.handleChange}
                state={deleteFormik.touched.name && deleteFormik.errors.name ? 'error' : 'default'}
                hint={
                  deleteFormik.touched.name && deleteFormik.errors.name
                    ? deleteFormik.errors.name
                    : ''
                }
              />
            </div>
          </>
        ) : (
          <>
            <div className='my-4 flex justify-center'>
              <InputField
                name='name'
                label={'Nome'}
                onBlur={formik.handleBlur}
                value={formik.values.name!}
                onChange={formik.handleChange}
                placeholder='Conselho Regional de Medicina'
                state={formik.touched.name && formik.errors.name ? 'error' : 'default'}
                hint={formik.touched.name && formik.errors.name ? formik.errors.name : ''}
              />
            </div>

            <div className='my-4 flex justify-center'>
              <InputField
                placeholder='CRM'
                name='document_name'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.document_name!}
                label='Documento de registro profissional'
                hint={
                  formik.touched.document_name && formik.errors.document_name
                    ? formik.errors.document_name
                    : ''
                }
                state={
                  isExempt
                    ? 'disabled'
                    : formik.touched.document_name && formik.errors.document_name
                      ? 'error'
                      : 'default'
                }
              />
            </div>

            <div className='my-4 ml-2 flex items-center justify-center'>
              <Checkbox isChecked={isExempt} onClick={() => setIsExempt(!isExempt)} />
              <span className='ml-4 text-body-sm'>
                Esse conselho não possui um documento de registro profissional obrigatório
              </span>
            </div>

            {!!council && (
              <div className='mb-10 ml-2 items-center justify-center'>
                <div className='flex items-center justify-between'>
                  <div className='flex flex-col'>
                    <span className='text-body-md'>Status</span>
                    <span className='text-body-sm'>
                      {formik.values.is_active ? 'Ativo' : 'Inativo'}
                    </span>
                  </div>

                  <Switch
                    isChecked={Boolean(formik.values.is_active)}
                    onChange={() => formik.setFieldValue('is_active', !formik.values.is_active)}
                  />
                </div>
              </div>
            )}
          </>
        )}

        <div className='mt-10 flex justify-between'>
          {mode === 'delete' ? (
            <div className='flex w-full items-center justify-end'>
              <Button
                size='sm'
                type='button'
                label='Cancelar'
                variant='tertiary'
                onClick={handleEditMode}
              />
              <Button
                size='sm'
                state='error'
                type='submit'
                label='Excluir'
                variant='primary'
                isLoading={deleteFormik.isSubmitting}
              />
            </div>
          ) : (
            <div className='flex w-full items-center justify-between'>
              {!!council && (
                <div>
                  <Button
                    type='button'
                    label='Excluir conselho'
                    size='sm'
                    variant='secondary'
                    state='error'
                    onClick={handleDeleteMode}
                  />
                </div>
              )}
              <div className='flex'>
                <Button
                  type='button'
                  label='Cancelar'
                  size='sm'
                  variant='tertiary'
                  onClick={handleClose}
                />
                <Button
                  size='sm'
                  type='submit'
                  label='Salvar'
                  variant='primary'
                  isLoading={formik.isSubmitting}
                />
              </div>
            </div>
          )}
        </div>
      </form>
    </Modal>
  )
}

export default CouncilFormModal
