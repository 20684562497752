/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@tanstack/react-query'
import UnitTypeService from '../services/unit-type.service'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useToastContext } from '../contexts/toast'
import { UnitType } from '../types'

export const useUnitTypes = (page: number, pageSize: number, filter: string) => {
  return useQuery({
    queryKey: ['unitTypes', { page, pageSize, filter }],
    queryFn: () => UnitTypeService.listTypes({ page, pageSize, filter }),
    placeholderData: prev => prev
  })
}

export const useUpdateUnitForm = (unit: UnitType | null | undefined, closeModal: () => void) => {
  const { toast } = useToastContext()

  const initialValues = {
    name: unit ? unit.name : ''
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('Preencha o nome')
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async values => {
      try {
        if (unit) {
          await UnitTypeService.updateUnitType(unit?.id as string, values)
          toast.success('Tipo de unidade atualizada')
        } else {
          await UnitTypeService.createUnitType(values)
          toast.success('Tipo de unidade criada')
        }
        closeModal()
      } catch (error) {
        if ((error as any).message === 'DUPLICATED') {
          formik.setFieldError('name', 'Já existe uma unidade cadastrada com este nome')
        } else {
          toast.error((error as any).message || 'Algo deu errado')
        }
        formik.setSubmitting(false)
      }
    }
  })

  return {
    formik
  }
}

export const useDeleteUnitForm = (
  unit: UnitType | null | undefined,
  closeModal: () => void,
  setMode: (value: 'edit' | 'delete') => void
) => {
  const { toast } = useToastContext()
  const queryClient = useQueryClient()

  const formik = useFormik({
    initialValues: { name: '' },
    validationSchema: Yup.object({
      name: Yup.string().required('Preencha o nome do tipo de unidade')
    }),
    onSubmit: values => {
      if (values.name === unit?.name) {
        deleteMutation.mutate()
      } else {
        formik.setFieldError('name', 'O nome não corresponde')
        formik.setSubmitting(false)
      }
    }
  })

  const deleteMutation = useMutation({
    mutationFn: () => UnitTypeService.deleteUnitType(unit?.id as string),
    onSuccess: () => {
      toast.success('Tipo de unidade excluído')
      queryClient.invalidateQueries({ queryKey: ['unitTypes'] })
      closeModal()
      setMode('edit')
    },
    onError: error => {
      formik.setSubmitting(false)
      toast.error(error.message || 'Algo deu errado')
    }
  })

  return formik
}
