import { useQuery } from '@tanstack/react-query'
import UserService from '../services/user.service'
import { CARE_TYPE, PaginationParams } from '../types'

export const useListRequester = (care_type: CARE_TYPE, unit_id?: string) => {
  return useQuery({
    queryKey: ['listRequesters', care_type, unit_id],
    queryFn: () =>
      !unit_id ? Promise.resolve([]) : UserService.listRequesters(care_type, unit_id),
    placeholderData: prev => prev
  })
}

export const useListSpecialists = (params: {
  name?: string
  unit_id?: string
  unit_ids?: string[]
  specialties?: string[]
  without_fix_agenda?: boolean
  without_custom_agenda?: boolean
}) => {
  return useQuery({
    queryKey: ['listSpecialists', params],
    queryFn: () => UserService.listSpecialists(params),
    placeholderData: prev => prev
  })
}

export const useListHealthProfessional = (
  params: PaginationParams & {
    unit_id?: string
    without_agenda?: boolean
    with_agenda?: boolean
  }
) => {
  return useQuery({
    queryKey: ['listHealthProfessional', params],
    queryFn: () => UserService.list({ ...params, is_health_professional: true }),
    placeholderData: prev => prev
  })
}

export const useListUsers = (params: PaginationParams, client_id?: string, unit_id?: string) => {
  return useQuery({
    queryKey: ['listUsers', params, client_id, unit_id],
    queryFn: () => UserService.list({ ...params, client_id, unit_id }),
    placeholderData: prev => prev
  })
}

export const useGetUser = (id?: string) => {
  return useQuery({
    queryKey: ['getUser', { id }],
    queryFn: () => (id ? UserService.getById(id) : null),
    placeholderData: prev => prev
  })
}
