import { MagnifyingGlass, XCircle } from '@phosphor-icons/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import debounce from '../../helpers/debounce'

interface SearchFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  value: string
  errorMessage?: string
  type?: 'cpf' | 'text'
}

const SearchField = ({
  placeholder,
  onChange,
  errorMessage,
  value,
  type = 'text',
  ...props
}: SearchFieldProps) => {
  const [inputValue, setInputValue] = useState<string>(value)
  const inputRef = useRef<HTMLInputElement>(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event)
    }, 300),
    [onChange]
  )

  useEffect(() => {
    setInputValue(type === 'cpf' ? formatCPF(cleanCPF(value)) : value)
  }, [value, type])

  const formatCPF = (cpf: string) => {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }

  const cleanCPF = (cpf: string) => {
    return cpf.replace(/\D/g, '')
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value

    if (type === 'cpf') {
      const cleanValue = cleanCPF(inputValue)
      setInputValue(formatCPF(cleanValue))

      const customEvent = {
        ...event,
        target: {
          ...event.target,
          value: cleanValue
        }
      }
      debouncedOnChange(customEvent as React.ChangeEvent<HTMLInputElement>)
    } else {
      setInputValue(inputValue)
      debouncedOnChange(event)
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const paste = event.clipboardData.getData('text')

    if (type === 'cpf') {
      const cleanValue = cleanCPF(paste)
      setInputValue(formatCPF(cleanValue))

      const customEvent = {
        ...event,
        target: {
          ...event.target,
          value: cleanValue
        }
      }
      debouncedOnChange(customEvent as unknown as React.ChangeEvent<HTMLInputElement>)
    }
  }

  const handleClear = () => {
    const event = {
      target: {
        value: ''
      }
    } as React.ChangeEvent<HTMLInputElement>

    handleInputChange(event)
  }

  return (
    <div className='relative flex flex-grow flex-col items-center'>
      <span className='absolute left-3 top-[14px]'>
        <MagnifyingGlass size={16} />
      </span>
      <input
        {...props}
        type='text'
        ref={inputRef}
        value={inputValue}
        placeholder={placeholder}
        onChange={handleInputChange}
        onPaste={handlePaste}
        className={`${props.className} w-full rounded-500 border  py-2 pl-10 pr-4 focus:outline-none focus:ring-2  ${errorMessage ? 'border-highlightRed-pure focus:border-[2px]' : 'border-neutralBorder-default focus:ring-neutralBorder-brand'}`}
        maxLength={type === 'cpf' ? 14 : undefined}
      />
      {errorMessage && (
        <div className='w-full p-2 text-left text-body-md text-highlightRed-pure'>
          {errorMessage}
        </div>
      )}
      {inputValue && (
        <span className='absolute right-3 top-[14px] cursor-pointer' onClick={handleClear}>
          <XCircle size={16} />
        </span>
      )}
    </div>
  )
}

export default SearchField
