/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import Content from '../../../components/content'
import { ROUTES } from '../../../routes/path'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Client } from '../../../types'
import { useToastContext } from '../../../contexts/toast'
import ClientGeneralFormPage from './client-general-form'
import ClientProfileForm from './client-profile-form'
import ClientService from '../../../services/client.service'
import { useGetClient } from '../../../hooks/useClient'
import FileService from '../../../services/file.service'

export enum CLIENT_FORM_STEPS {
  GENERAL_INFORMATION = 'GENERAL_INFORMATION',
  PROFILE = 'PROFILE'
}

const ClientFormPage: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams<{ id: string }>()
  const { id } = params || {}
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const stepQuery = queryParams.get('step')
  const { t } = useTranslation('client_form')
  const { toast } = useToastContext()
  const [loading, setLoading] = useState(false)

  const [client, setClient] = useState<Partial<Client>>({})
  const [step, setStep] = useState<CLIENT_FORM_STEPS>(
    stepQuery === 'PROFILE' ? CLIENT_FORM_STEPS.PROFILE : CLIENT_FORM_STEPS.GENERAL_INFORMATION
  )

  const { data: clientData, isFetching } = useGetClient(id || '')

  useEffect(() => {
    if (clientData) {
      setClient(clientData)
    }
  }, [clientData])

  const filterValues = (values: Partial<Client>) => {
    if (values.specialties) {
      values.specialties = values.specialties.map(specialty => ({
        specialty_id: specialty.specialty_id || specialty.id
      }))
    }
    if (values.profiles) {
      values.profiles = values.profiles.map(profile => ({
        profile_id: profile.profile_id || profile.id
      }))
    }
    return values
  }

  const goToNextStep = async (values: Partial<Client>) => {
    const filteredValues = filterValues({ ...client, ...values })
    let file: { id?: string } = {}

    try {
      if (values.image) {
        const formData = new FormData()
        const response = await fetch(values.image)
        formData.append('file', await response.blob())
        file = await FileService.upload(formData)
      }

      if (id !== 'new') {
        // @ts-expect-error
        await ClientService.update(id, { ...filteredValues, image_id: file?.id })
        toast.success('Cliente atualizado com sucesso!!')
        navigate(ROUTES.client.list)
      } else {
        switch (step) {
          case CLIENT_FORM_STEPS.GENERAL_INFORMATION:
            setStep(CLIENT_FORM_STEPS.PROFILE)
            setClient({ ...client, ...values })
            break
          default:
            try {
              setLoading(true)
              const newClient = { ...client, ...values, image_id: file?.id }

              await ClientService.create(newClient)

              toast.success('Cliente cadastrado com sucesso!!')
              setLoading(false)

              navigate(ROUTES.client.list)
            } catch (error) {
              //@ts-expect-error
              toast.error(`Erro ao cadastrar cliente: ${error.message}`)
            } finally {
              setLoading(false)
            }
            break
        }
      }
    } catch (error) {
      console.log(error)
      toast.error('Algo deu errado. Tente novamente.')
    }
  }

  const handleBack = () => {
    if (id === 'new') {
      navigate(ROUTES.client.list)
    }
    navigate(ROUTES.client.details.replace(':id', client?.id || ''))
  }

  return (
    <Content
      onBack={handleBack}
      title={t(`${step}.title_${id !== 'new' ? 'update' : 'create'}`)}
      subtitle={t(`${step}.subtitle`)}
    >
      {step === CLIENT_FORM_STEPS.GENERAL_INFORMATION && (
        <ClientGeneralFormPage
          onSuccess={goToNextStep}
          onCancel={handleBack}
          initialValues={client}
          isLoading={isFetching}
          isEdit={id !== 'new'}
        />
      )}
      {step === CLIENT_FORM_STEPS.PROFILE && (
        <ClientProfileForm
          onSuccess={goToNextStep}
          onCancel={handleBack}
          initialValues={client}
          isEdit={id !== 'new'}
          loading={loading}
        />
      )}
    </Content>
  )
}

export default ClientFormPage
