import { Subscriber } from 'openvidu-browser'
import { useEffect, useRef, useState } from 'react'
import { User } from '../../types'
import { Button } from 'semente-js'

type Props = {
  subscribe: Subscriber
  className?: string
}

export const UserVideo: React.FC<Props> = ({ subscribe, className }) => {
  const [user, setUser] = useState<User>()
  const videoRef = useRef(null)
  const [expanded, setExpanded] = useState<boolean>(false)

  useEffect(() => {
    if (subscribe) {
      setUser(JSON.parse(subscribe.stream.connection.data))
      if (videoRef.current) subscribe.addVideoElement(videoRef.current)
    }
  }, [subscribe])

  return (
    <div
      className={
        expanded
          ? 'fixed left-0 top-0 z-[999] h-full w-full bg-gray-800'
          : className || 'relative mb-5 h-[fit-content]'
      }
    >
      <div className='absolute bottom-2 left-2 rounded-lg bg-gray-300 px-2 py-1 text-body-sm'>
        {user?.name}
      </div>
      <div className='w-full overflow-hidden rounded-xl'>
        <video className={expanded ? 'm-auto h-[100vh] w-auto' : 'h-full w-full'} ref={videoRef} />
      </div>

      <div className='absolute bottom-1 left-0 right-2 flex justify-end gap-2'>
        <Button
          layout='circle'
          className={'bg-gray-400'}
          iconName={expanded ? 'arrows-in-simple' : 'arrows-out-simple'}
          size={expanded ? 'lg' : 'base'}
          onClick={() => {
            setExpanded(prev => !prev)
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLButtonElement>) => {
            if (!expanded) return

            if (event.key === 'Escape') {
              setExpanded(false)
            }
          }}
        />
      </div>
    </div>
  )
}
