/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Tab from '../../../../components/tabs'
import Resume from '../resume'
import { Patient } from '../../../../types'
import PersonalData from '../personal_data'
import ClinicalInformation from '../clinical_information'
import Consultations from '../consultations'
import Documents from '../documents'

const PatientTabs: React.FC<{
  id: string
  patientData: Patient | null | undefined
}> = ({ id, patientData }) => {
  const { t } = useTranslation('patient_detail')

  const tabs = useMemo(
    () => [
      {
        label: t('tabs.resume'),
        content: <Resume id={id} patientData={patientData} />,
        disabled: false
      },
      {
        label: t('tabs.personal_data'),
        content: <PersonalData patientData={patientData} />,
        disabled: false
      },
      {
        label: t('tabs.clinical_information'),
        content: <ClinicalInformation id={id} />,
        disabled: false
      },
      {
        label: t('tabs.consultations'),
        content: <Consultations id={id} />,
        disabled: false
      },
      {
        label: t('tabs.document'),
        content: <Documents id={id} />,
        disabled: false
      }
    ],
    [t, id]
  )
  return <Tab tabs={tabs} />
}

export default PatientTabs
