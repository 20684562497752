import React, { useState, useEffect } from 'react'
import SelectableModal from './selectable-modal'
import { useListProfiles } from '../../hooks/useProfile'
import { Profile } from '../../types'

interface Props {
  isOpen: boolean
  selectedValues: Partial<Profile>[]
  onClose: () => void
  onSuccess: (selectedValues: Partial<Profile>[]) => void
}

const ProfilesModal = ({ isOpen, selectedValues, onClose, onSuccess }: Props) => {
  const [profiles, setProfiles] = useState<Partial<Profile>[]>([])
  const [filter, setFilter] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [total, setTotal] = useState(0)

  const { data, isFetching } = useListProfiles(currentPage, 10, filter)

  useEffect(() => {
    if (!isFetching && data && data.data.length > 0) {
      setTotal(data.totalItems)
      setProfiles(prev => {
        const newData = currentPage === 1 ? data.data : [...prev, ...data.data]
        const combinedData = [...newData, ...selectedValues]
        const uniqueData = combinedData.filter(
          (item, index, self) => self.findIndex(i => i.id === item.id) === index
        )
        return uniqueData
      })
    }
  }, [data, isFetching, currentPage, selectedValues])

  useEffect(() => {
    if (isOpen) {
      setProfiles(prev => {
        const combinedProfiles = [...selectedValues, ...prev]
        const uniqueProfiles = combinedProfiles.filter(
          (item, index, self) => self.findIndex(i => i.id === item.id) === index
        )
        return uniqueProfiles
      })
    }
  }, [isOpen, selectedValues])

  const handleLoadMore = () => {
    if (profiles.length >= total) return
    setCurrentPage(prevPage => prevPage + 1)
  }

  const handleSearch = (searchValue: string) => {
    setFilter(searchValue)
    setCurrentPage(1)
  }

  const renderField = (item: Partial<Profile>) => (
    <div>
      <span className='text-label-md text-neutralContent-primary'>{item.name}</span>
      <span className='block text-body-sm text-neutralContent-secondary'>{item.type}</span>
    </div>
  )

  return (
    <SelectableModal
      title='Selecionar perfis'
      isOpen={isOpen}
      search
      searchFieldLabel='Procure por um perfil de acesso'
      data={profiles}
      selectedValues={selectedValues}
      renderField={renderField}
      onClose={onClose}
      onSuccess={onSuccess}
      onLoadMore={handleLoadMore}
      onSearch={handleSearch}
    />
  )
}

export default ProfilesModal
