const user_details_enUS = {
  sessions: {
    personal_info: {
      title: 'Personal Information',
      birthdate: 'Birthdate',
      mother_name: "Mother's Full Name",
      mother_unknown: 'Mother Unknown',
      sex: 'Sex',
      gender: 'Gender',
      race: 'Race/Color',
      nationality: 'Nationality',
      birth_state: 'State of Birth',
      birth_city: 'City of Birth',

      masculine: 'Masculine',
      feminine: 'Feminine',

      white: 'White',
      black: 'Black',
      brown: 'Brown',
      yellow: 'Yellow',
      indigenous: 'Indigenous',
      no_information: 'No Information',

      cisgender_woman: 'Cisgender Woman',
      transgender_woman: 'Transgender Woman',
      cisgender_man: 'Cisgender Man',
      transgender_man: 'Transgender Man',
      non_binary: 'Non-Binary',
      other: 'Other'
    },
    clients: {
      title: 'Clients'
    },
    documents: {
      title: 'Documents',
      document: 'CPF',
      cns: 'National Health Card Number - CNS'
    },
    contact: {
      title: 'Contact',
      email: 'Primary Email',
      cell: 'Cell Phone Number',
      phone: 'Phone Number'
    },
    address: {
      title: 'Address'
    },
    professional_info: {
      title: 'Professional Information',
      specialties: 'Medical Specialties',
      records: 'Records'
    },
    profile: {
      title: 'Access Profiles',
      no_profile_selected: 'No Profile Selected'
    }
  },
  actions: {
    edit: 'Edit'
  }
}

export default user_details_enUS
