import React, { useState } from 'react'

interface ColorPickerProps {
  id?: string
  value: string
  onChange: (value: string) => void
  label?: string
  disabled?: boolean
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  id,
  value,
  onChange,
  label,
  disabled = false
}) => {
  const [showPicker, setShowPicker] = useState(false)

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  const togglePicker = () => {
    if (!disabled) {
      setShowPicker(!showPicker)
    }
  }

  return (
    <div className='flex flex-col items-start'>
      {label && (
        <label htmlFor={id} className='mb-2 text-sm text-gray-600'>
          {label}
        </label>
      )}
      <div className='relative flex items-center'>
        <input
          id={id}
          type='text'
          value={value}
          onChange={handleColorChange}
          className={`h-12 w-28 rounded-l-lg border border-r-0 px-2 text-body-md text-neutralContent-quartenary ${disabled ? 'border-neutralBorder-default bg-gray-100 ' : 'border-neutralBorder-default '}`}
          disabled={disabled}
        />
        <div
          onClick={togglePicker}
          className={`flex h-12 w-10 cursor-pointer items-center justify-center rounded-r-lg  border-l-0 ${disabled ? 'bg-gray-300' : 'border border-neutralBorder-default bg-white'}`}
        >
          <div className='h-6 w-6 rounded-full' style={{ backgroundColor: value }} />
        </div>
        {showPicker && (
          <input
            type='color'
            value={value}
            onChange={handleColorChange}
            className='absolute left-0 top-0 h-full w-full cursor-pointer opacity-0'
          />
        )}
      </div>
    </div>
  )
}

export default ColorPicker
