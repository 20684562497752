const unit_list_ptBR = {
  title: 'Gerenciar unidades',
  search_placeholder: 'Procure por nome da unidade, CNES ou CNPJ',
  columns: {
    name: 'Nome',
    type: 'Tipo de unidade',
    cnpj: 'CNPJ',
    status: 'Status',
    actions: ''
  },
  actions: {
    details: 'Detalhes',
    register_unit: 'Cadastrar unidade'
  },
  tags: {
    active: 'Ativo',
    inactive: 'Inativo'
  }
}

export default unit_list_ptBR
