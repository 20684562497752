import { useState } from 'react'
import Modal from '../../../components/modal'
import InputField from '../../../components/input'
import Button from '../../../components/button'
import { UnitType } from '../../../types'
import { useUpdateUnitForm, useDeleteUnitForm } from '../../../hooks/useUnitType'

interface Props {
  isModalOpen: boolean
  closeModal: () => void
  unit?: UnitType
}

const UnitTypeFormModal = ({ isModalOpen, closeModal, unit }: Props) => {
  const [mode, setMode] = useState('edit')
  const { formik } = useUpdateUnitForm(unit, closeModal)
  const deleteFormik = useDeleteUnitForm(unit, closeModal, setMode)

  const handleDeleteMode = () => {
    setMode('delete')
    deleteFormik.resetForm()
  }

  const handleCancelDelete = () => {
    setMode('edit')
    formik.resetForm()
  }

  const getTitle = () => {
    if (!unit) return 'Novo tipo de unidade'
    if (mode === 'delete') return 'Excluir tipo de unidade'
    return 'Editar tipo de unidade'
  }

  const handleClose = () => {
    formik.resetForm()
    deleteFormik.resetForm()
    closeModal()
    setMode('edit')
  }

  return (
    <Modal title={getTitle()} isOpen={isModalOpen} onClose={handleClose}>
      <form
        onSubmit={mode === 'edit' ? formik.handleSubmit : deleteFormik.handleSubmit}
        className='w-screen max-w-[480px]'
      >
        {mode === 'delete' ? (
          <>
            <div className='mb-1'>
              <p className='text-body-md text-neutralContent-tertiary'>
                Tem certeza que deseja excluir esse tipo de unidade? Essa
                <p> ação é permanente e não poderá ser desfeita.</p>
              </p>
              <br />
              <p className='text-body-md text-neutralContent-tertiary'>
                Para confirmar, digite o nome do tipo de unidade.
              </p>
            </div>

            <InputField
              label=''
              name='name'
              className='mt-4'
              value={deleteFormik.values.name}
              onBlur={deleteFormik.handleBlur}
              onChange={deleteFormik.handleChange}
              placeholder='Nome do tipo de unidade'
              state={deleteFormik.touched.name && deleteFormik.errors.name ? 'error' : 'default'}
              hint={
                deleteFormik.touched.name && deleteFormik.errors.name
                  ? deleteFormik.errors.name
                  : ''
              }
            />
          </>
        ) : (
          <div className='flex justify-center'>
            <InputField
              name='name'
              label='Nome'
              value={formik.values.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder='Nome do tipo de unidade'
              hint={(formik.touched.name && formik.errors.name) || ''}
              state={formik.touched.name && formik.errors.name ? 'error' : 'default'}
            />
          </div>
        )}

        <div className='mt-10 flex justify-end'>
          {mode === 'delete' ? (
            <>
              <Button
                size='sm'
                type='button'
                label='Cancelar'
                variant='tertiary'
                onClick={handleCancelDelete}
              />
              <Button
                size='sm'
                type='submit'
                state='error'
                label='Excluir'
                variant='primary'
                isLoading={deleteFormik.isSubmitting}
              />
            </>
          ) : (
            <>
              {unit && (
                <Button
                  size='sm'
                  type='button'
                  state='error'
                  variant='secondary'
                  onClick={handleDeleteMode}
                  label='Excluir tipo de unidade'
                />
              )}
              <Button
                size='sm'
                type='button'
                label='Cancelar'
                variant='tertiary'
                onClick={handleClose}
              />
              <Button
                size='sm'
                type='submit'
                label='Salvar'
                variant='primary'
                isLoading={formik.isSubmitting}
              />
            </>
          )}
        </div>
      </form>
    </Modal>
  )
}

export default UnitTypeFormModal
