const debounce = (func: (event: React.ChangeEvent<HTMLInputElement>) => void, delay: number) => {
  let timer: NodeJS.Timeout

  return (event: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(event)
    }, delay)
  }
}

export default debounce
