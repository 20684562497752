import { Minus, Plus } from '@phosphor-icons/react'
import React from 'react'

interface StepperNumberProps {
  id?: string
  state?: 'default' | 'disabled'
  label?: string
  hint?: string
  min?: number
  max?: number
  step?: number
  name: string
  value: number
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  className?: string
}

const StepperNumber: React.FC<StepperNumberProps> = ({
  state = 'default',
  label,
  min = 1,
  max = 10,
  step = 1,
  name,
  value,
  onChange,
  className
}) => {
  const handleIncrement = () => {
    if (value < max) {
      onChange({
        target: { name, value: value + step }
      } as unknown as React.ChangeEvent<HTMLInputElement>)
    }
  }

  const handleDecrement = () => {
    if (value > min) {
      onChange({
        target: { name, value: value - step }
      } as unknown as React.ChangeEvent<HTMLInputElement>)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getBorderClasses = () => {
    switch (state) {
      case 'disabled':
        return 'border-neutralBackground-disabled bg-neutralBackground-disabled'
      default:
        return 'border border-neutralBorder-default focus-within:border-2 focus-within:border-primaryBrand-dark'
    }
  }

  const getTextClasses = () => {
    switch (state) {
      case 'disabled':
        return 'text-neutralContent-disabled'
      default:
        return 'text-neutralContent-primary'
    }
  }

  return (
    <div className={`flex w-full flex-col gap-2 ${className}`}>
      {!!label && <label className={`label-md ${getTextClasses()}`}>{label}</label>}
      <div className={`p-[12px 16px] flex h-12 items-center gap-3 `}>
        <button
          type='button'
          onClick={handleDecrement}
          className='flex h-8 w-8 items-center justify-center rounded-full border border-blue-500 text-neutralContent-primary '
          disabled={value <= min}
        >
          <span>
            <Minus size={16} />
          </span>
        </button>
        <div className='flex h-10 w-12 items-center justify-center rounded-lg bg-neutralBackground-secondary'>
          <span>{value}</span>
        </div>
        <button
          type='button'
          onClick={handleIncrement}
          className='flex h-8 w-8 items-center justify-center rounded-full border border-blue-500 text-neutralContent-primary'
          disabled={value >= max}
        >
          <span>
            <Plus size={16} />
          </span>
        </button>
      </div>
    </div>
  )
}

export default StepperNumber
