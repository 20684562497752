import React, { useState } from 'react'
import SelectMonthCalendar from '../../../components/calendar/select-month-calendar'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { formatMonthYear } from '../../../helpers/formatMonthYear'
import { useListScalesByClient } from '../../../hooks/useScale'
import PublishScale from './scale-list/modals/publish-scale'
import { ArrowLeft, Plus } from '@phosphor-icons/react'
import { useGetClient } from '../../../hooks/useClient'
import BlankCard from '../../../components/blank-card'
import Content from '../../../components/content'
import Button from '../../../components/button'
import Avatar from '../../../components/avatar'
import { useTranslation } from 'react-i18next'
import { ROUTES } from '../../../routes/path'
import { Scale } from '../../../types'
import ScaleList from './scale-list'
import { formatDate } from 'date-fns'

const ScaleDayPage: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('agendav2_list')
  const { client_id } = useParams<Record<string, string | undefined>>()
  const { data: clientData, isFetching } = useGetClient(client_id || '')

  const [searchParams, setParams] = useSearchParams()

  const date = searchParams.get('date')

  const {
    data: scales,
    isFetching: isFetchingScales,
    refetch: reloadScales
  } = useListScalesByClient({
    client_id,
    date: formatDate(date || new Date(), 'yyyy-MM-dd')
  })

  const [openPublishModal, setOpenPublishModal] = useState({
    isOpen: false,
    scale: {} as Scale
  })

  const toggleDateSelection = (day: Date) => {
    setParams({ date: day.toISOString() })
  }

  const handleRedirectToNewScale = () => {
    navigate(`${ROUTES.agenda.new_scale.replace(':client_id', client_id!)}?date=${date}`)
  }

  const handleClickScale = (scale: Scale) => {
    setOpenPublishModal({ isOpen: true, scale })
  }

  const handleEditScale = (scale: Scale) => {
    navigate(
      ROUTES.agenda.scale_form.replace(':client_id', client_id!).replace(':scale_id', scale?.id)
    )
  }

  const handleBack = () => {
    navigate(ROUTES.agenda.list)
  }

  if (!date)
    return (
      <Content title='Invalid date'>
        <></>
      </Content>
    )

  return (
    <Content title=''>
      <div className='grid h-[calc(100vh-180px)] grid-cols-[260px,1px,1fr] gap-6'>
        <div className='flex flex-col gap-6'>
          <Button
            size='sm'
            label='Voltar'
            variant='tertiary'
            className='w-fit'
            iconLeft={<ArrowLeft />}
            onClick={handleBack}
          />

          <SelectMonthCalendar
            selectedDate={new Date(date)}
            dateOfMonth={new Date(date || '')}
            onSelectDate={toggleDateSelection}
          />
        </div>

        <div className='h-full bg-gray-300' />

        <div className='w-full overflow-y-auto px-3'>
          <div className='mb-4 flex items-center justify-between'>
            <Button
              color=''
              size='sm'
              label='Voltar'
              variant='tertiary'
              iconLeft={<ArrowLeft />}
              onClick={handleBack}
            />

            <Button
              size='sm'
              iconLeft={<Plus />}
              label={t('actions.new_scale')}
              onClick={handleRedirectToNewScale}
            />
          </div>

          <div className='flex flex-col gap-5'>
            <div className='flex items-center justify-between'>
              <span className='text-center text-heading-md text-neutralContent-primary'>
                {formatMonthYear(new Date(date || ''), true)}
              </span>

              <BlankCard className='flex h-14 items-center justify-center'>
                {isFetching ? (
                  <div className='h-5 w-40 animate-pulse rounded-md bg-gray-300' />
                ) : (
                  <div className='flex items-center justify-center gap-3'>
                    <Avatar name={clientData?.name} size='sm' />
                    <span className='text-label-md text-neutralContent-primary'>
                      {clientData?.name || ''}
                    </span>
                  </div>
                )}
              </BlankCard>
            </div>

            <ScaleList
              scales={scales || []}
              isLoading={isFetchingScales}
              onAddNewScale={handleRedirectToNewScale}
              onClickScale={scale => handleClickScale(scale)}
            />
          </div>
        </div>
      </div>

      <PublishScale
        scale={openPublishModal.scale}
        isOpen={openPublishModal.isOpen}
        redirectToEdit={(scale: Scale) => handleEditScale(scale)}
        onClose={() => {
          setOpenPublishModal({
            isOpen: false,
            scale: {} as Scale
          })
          reloadScales()
        }}
      />
    </Content>
  )
}

export default ScaleDayPage
