import api from './api.service'

export type CepAddress = {
  cep?: string
  logradouro?: string
  complemento?: string
  unidade?: string
  bairro?: string
  localidade: string
  uf: string
  ibge: string
  gia: string
  ddd: string
  siafi: string
}

class LocationService {
  static async getAddressByCep(cep: string) {
    const response = await api.get<CepAddress>(`/address/${cep}`)
    return response.data
  }

  static async getStates() {
    const response = await api.get('/states')
    return response.data
  }

  static async getState(state_uf?: string) {
    const response = await api.get<{ nome: string; sigla: string; id: string }>(
      `/states/${state_uf}`
    )
    return response.data
  }

  static async getCitiesByState(uf: string) {
    const response = await api.get<{ nome: string; id: string }[]>(`/states/${uf}/cities`)
    return response.data
  }

  static async getCity(city_id: string) {
    const response = await api.get(`/cities/${city_id}`)
    return response.data
  }
}

export default LocationService
