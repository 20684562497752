import React from 'react'
import Connection from '../../../components/connection'
import { useParams } from 'react-router-dom'
import { CARE_TYPE } from '../../../types'
import { Button } from 'semente-js'
import { UserVideo } from '../../../components/user-video'

const VideoConsultation: React.FC = () => {
  const { id } = useParams<{ id: string; care_type: CARE_TYPE }>()

  return (
    <>
      <Connection id={id!} onFinish={() => {}} isPatientConnection>
        {({
          handleShareScreen,
          shareScreen,
          loadingShareScreen,
          loading,
          camera,
          handleCamera,
          microphone,
          handleMicrophone,
          subscribers
        }) => {
          const totalVideos = 1 + subscribers.length + (shareScreen ? 1 : 0)

          const maxSizeClassName =
            totalVideos <= 2 ? 'md:w-[calc(50%-30px)]' : 'md:w-auto md:h-[38vh]'

          const videoWrapperClassName = `relative mb-5 flex aspect-[4/3] items-center h-[fit-content] min-h-0 w-full h-full min-w-0 rounded-xl bg-gray-800 ${maxSizeClassName}`

          return (
            <div className='flex h-auto flex-col flex-wrap items-center justify-start gap-5 p-6 md:h-[90%] md:flex-row md:justify-evenly md:gap-0'>
              {subscribers.map(sub => (
                <UserVideo key={sub.id} subscribe={sub} className={videoWrapperClassName} />
              ))}
              <div className={shareScreen ? videoWrapperClassName : 'hidden'}>
                <div id='screen-share' className='flex' />
                <div className='absolute bottom-1 left-0 right-0 flex justify-center gap-2'>
                  <Button
                    layout='circle'
                    className={shareScreen ? 'bg-blue-400' : 'bg-gray-400'}
                    iconName={'monitor-arrow-up'}
                    onClick={handleShareScreen}
                    isLoading={loadingShareScreen}
                  />
                </div>
              </div>
              <div className={videoWrapperClassName}>
                <div id='publisher' className='flex h-full w-full'>
                  <style>
                    {`
                        #publisher > * {
                          width: 100%;
                        }
                      `}
                  </style>
                </div>

                {!loading && (
                  <>
                    <div className='absolute bottom-1 left-0 right-0 flex justify-center gap-2'>
                      <Button
                        layout='circle'
                        className={camera ? 'bg-gray-400' : 'bg-red-400'}
                        iconName={camera ? 'camera' : 'camera-slash'}
                        onClick={handleCamera}
                      />
                      <Button
                        layout='circle'
                        className={shareScreen ? 'bg-blue-400' : 'bg-gray-400'}
                        iconName={'monitor-arrow-up'}
                        onClick={handleShareScreen}
                        isLoading={loadingShareScreen}
                      />
                      <Button
                        layout='circle'
                        className={microphone ? 'bg-gray-400' : 'bg-red-400'}
                        iconName={microphone ? 'microphone' : 'microphone-slash'}
                        onClick={handleMicrophone}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          )
        }}
      </Connection>
    </>
  )
}

export default VideoConsultation
