export function interpolateColor(value: number, min: number, max: number, colors: string[]) {
  const range = max - min
  const step = range / (colors.length - 1)

  for (let i = 0; i < colors.length - 1; i++) {
    const startValue = min + i * step
    const endValue = min + (i + 1) * step

    if (value >= startValue && value <= endValue) {
      const ratio = (value - startValue) / (endValue - startValue)

      const rStart = parseInt(colors[i].substring(1, 3), 16)
      const gStart = parseInt(colors[i].substring(3, 5), 16)
      const bStart = parseInt(colors[i].substring(5, 7), 16)

      const rEnd = parseInt(colors[i + 1].substring(1, 3), 16)
      const gEnd = parseInt(colors[i + 1].substring(3, 5), 16)
      const bEnd = parseInt(colors[i + 1].substring(5, 7), 16)

      const r = Math.round(rStart + (rEnd - rStart) * ratio)
        .toString(16)
        .padStart(2, '0')
      const g = Math.round(gStart + (gEnd - gStart) * ratio)
        .toString(16)
        .padStart(2, '0')
      const b = Math.round(bStart + (bEnd - bStart) * ratio)
        .toString(16)
        .padStart(2, '0')

      return `#${r}${g}${b}`
    }
  }

  if (value < min) {
    return colors[0]
  } else {
    return colors[colors.length - 1]
  }
}
