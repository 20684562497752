import Details from './details'
import Contact from './contact'
import General from './general'
import Medical from './medical'

const Steps = [General, Contact, Details, Medical]

export const StepsLabels = [
  'stepper.general',
  'stepper.contact',
  'stepper.details',
  'stepper.medical'
]

export default Steps
