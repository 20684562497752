import React from 'react'

interface TextareaFieldProps {
  id?: string
  state?: 'default' | 'disabled' | 'error'
  height?: string
  label?: string
  placeholder?: string
  hint?: string
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  value: string
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>
  name?: string
  maxLength?: number
  className?: string
  onKeyPress?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void
}

const TextareaField: React.FC<TextareaFieldProps> = ({
  state = 'default',
  label,
  placeholder,
  hint,
  height,
  onChange,
  value,
  onBlur,
  name,
  maxLength,
  className,
  onKeyPress
}) => {
  const getBorderClasses = () => {
    switch (state) {
      case 'disabled':
        return 'border-neutralBackground-disabled bg-neutralBackground-disabled'
      case 'error':
        return 'border-2 border-highlightRed-pure'
      default:
        return 'border border-neutralBorder-default focus-within:border-2 focus-within:border-primaryBrand-dark'
    }
  }

  const getTextClasses = () => {
    switch (state) {
      case 'disabled':
        return 'text-neutralContent-disabled'
      default:
        return 'text-neutralContent-primary'
    }
  }

  const getPlaceholderClasses = () => {
    switch (state) {
      case 'disabled':
        return 'text-neutralContent-disabled'
      default:
        return 'text-neutralContent-quartenary'
    }
  }

  return (
    <div className={`flex w-full flex-col gap-2 ${className}`}>
      <label
        htmlFor={name}
        className={`label-md block font-bold  ${state === 'disabled' ? 'text-neutralContent-disabled' : 'text-neutralContent-primary'}`}
      >
        {label}
      </label>
      <div className={`p-[12px 16px] flex h-32 rounded-[12px]`}>
        <textarea
          aria-disabled={state === 'disabled'}
          placeholder={placeholder}
          className={`body-md flex-grow rounded-lg bg-transparent ${getBorderClasses()} ${value ? getTextClasses() : getPlaceholderClasses()} ${
            state !== 'disabled' && 'focus:outline-none'
          } p-3 ${height && `h-[${height}]`}`}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          disabled={state === 'disabled'}
          name={name}
          maxLength={maxLength}
          rows={5}
          onKeyDown={onKeyPress}
        />
      </div>
      {state === 'error' && hint && (
        <div className='body-sm flex items-center text-highlightRed-pure'>
          <span className='ml-2'>{hint}</span>
        </div>
      )}
    </div>
  )
}

export default TextareaField
