const anamense_form_ptBR = {
  title: 'Criar novo template',

  fields: {
    client: 'Cliente',
    unit: 'Unidade',
    specialty: 'Especialidade',
    add_question: 'Adicionar questão'
  },
  select_an_option: 'Selecione',
  banner_warn: {
    title: 'Este formulário é apenas uma pré-visualização',
    message:
      'Crie o template para que ele esteja visível para os clientes, unidades e especialidades indicadas.'
  },
  actions: {
    save: 'Criar template',
    add: 'Adicionar questão',
    select_an_option: 'Selecione uma opção',
    cancel: 'Cancelar',
    add_question: 'Adicionar',
    preview_template: 'Pré-visualizar template'
  }
}

export default anamense_form_ptBR
