const profile_enUS = {
  permission_groups: {
    registrations: 'Registrations',
    listings: 'Listings',
    management: 'Management',
    services: 'Services',
    reports: 'Reports',
    documents: 'Documents'
  },
  permissions: {
    register_profile: 'Create/edit profiles',
    register_anamnesis_template: 'Create/edit anamnesis template',
    register_unit_type: 'Create/edit unit types',
    register_council: 'Create/edit council',
    register_specialty: 'Create/edit specialty',
    register_client: 'Create/edit clients',
    register_patient: 'Register/edit patient',
    register_unit: 'Create/edit units',
    register_user: 'Create/edit users',

    list_clients: 'Access client list',
    list_units: 'Access unit list',
    list_users: 'Access user list',
    list_patients: 'Access patients list',

    manager_create_agenda: 'Create/edit schedule',
    manager_assign_requester: 'Assign requester to a unit',
    manager_assign_specialist: 'Assign specialist to a unit',
    manager_see_other_users_agenda: "Access other users' schedules",
    manager_transfer_agenda: 'Transfer schedule',

    service_do_screening: 'Perform screening',
    service_follow_up: 'Access Status/Follow-up screen',
    service_create: 'Schedule for other users',
    service_create_other_users: 'Access My Services',
    service_invite: 'Invite to call',
    service_agenda_specialist: 'Take on elective service',
    service_urgent_specialist: 'Take on urgent service',
    service_chat_specialist: 'Take on chat service',
    service_agenda_requester: 'Perform elective service',
    service_urgent_requester: 'Perform urgent service',
    service_chat_requester: 'Perform chat service',

    report_general: 'Access general reports',
    report_nps: 'Evaluate NPS (Net Promoter Score)',
    report_production: 'Access production reports',
    report_service: 'Access service reports',
    report_patient: 'Access patient reports',
    report_agenda: 'Access schedule report (released vs. consumed)',

    document_medical_certificate: 'Issue medical certificate',
    document_prescription: 'Issue prescription',
    document_medical_report: 'Issue medical report',
    document_request_referral: 'Request referral',
    document_request_test: 'Request test'
  }
}

export default profile_enUS
