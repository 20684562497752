import { useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ContentFooter from '../../../components/content/footer'
import { useProfileForm } from '../../../hooks/useProfile'
import PermissionsAccordion from './permissions-accordion'
import PermissionEnum, { ADMIN_PERMISSIONS } from '../../../types/permissions'
import UpdateAccessProfileSkeleton from './skeleton'
import { FloppyDisk, PencilSimpleLine } from '@phosphor-icons/react'
import InputField from '../../../components/input'
import Content from '../../../components/content'
import Button from '../../../components/button'
import { ROUTES } from '../../../routes/path'
import DeleteProfile from './delete-modal'
import ContentForm from '../../../components/content/form'

const permissions = Object.keys(PermissionEnum).map(item => item as PermissionEnum)

const AccessProfileForm = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { id } = useParams<{ id?: string }>()

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const handleModal = () => setOpenDeleteModal(current => !current)

  const isNew = id === 'new'
  const isEdit = state === 'edit'
  const isDetail = state === 'detail'

  const { formik, isLoading } = useProfileForm(id)

  const title = useMemo(() => {
    if (formik.isSubmitting || isLoading) return 'Carregando'
    if (isNew) return 'Novo perfil de acesso'
    if (isEdit) return 'Editar perfil de acesso'
    return 'Perfil de acesso'
  }, [formik, isLoading, isEdit, isNew])

  const filterPermissionsStartWith = (start: string): PermissionEnum[] => {
    return permissions.filter(p => p.startsWith(start) && !ADMIN_PERMISSIONS.includes(p))
  }

  const registerPermissions = filterPermissionsStartWith('register_')
  const listPermissions = filterPermissionsStartWith('list_')
  const managerPermissions = filterPermissionsStartWith('manager_')
  const servicePermissions = filterPermissionsStartWith('service_')
  const reportPermissions = filterPermissionsStartWith('report_')

  const handleBack = () => {
    navigate(ROUTES.profile.list)
  }

  return (
    <Content
      title={title}
      onBack={handleBack}
      rightContent={
        isDetail && (
          <Button
            size='sm'
            label='Editar Perfil'
            variant='secondary'
            iconLeft={<PencilSimpleLine size={12} />}
            onClick={() => navigate('.', { state: 'edit' })}
          />
        )
      }
    >
      {isLoading ? (
        <UpdateAccessProfileSkeleton />
      ) : (
        <ContentForm hasBackButton={!isDetail}>
          <form onSubmit={formik.handleSubmit} className='col-span-3'>
            <div className='mb-6 flex gap-4'>
              <div className='flex flex-1 flex-col justify-center'>
                <InputField
                  name='name'
                  maxLength={80}
                  placeholder='Nome'
                  label='Perfil de acesso'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name ?? ''}
                  state={
                    formik.touched.name && formik.errors.name
                      ? 'error'
                      : isDetail
                        ? 'disabled'
                        : 'default'
                  }
                  hint={formik.touched.name && formik.errors.name ? formik.errors.name : ''}
                />
                {!isDetail && (
                  <span className='mt-2 text-body-sm text-neutralContent-primary'>
                    Será utilizado para atribuir permissões aos usuários cadastrados
                  </span>
                )}
              </div>
            </div>

            <div className='flex flex-col gap-4'>
              <div className='flex flex-col'>
                <span className='text-heading-xs text-neutralContent-primary'>Permissões</span>
                <span className='text-body-sm text-neutralContent-primary'>
                  Permissões são selecionadas de acordo com a categoria
                </span>
              </div>

              {/* Admin Module */}
              <PermissionsAccordion
                title='admin'
                isDisable={isDetail}
                permissionsList={ADMIN_PERMISSIONS}
                currentPermissions={formik.values.permissions || []}
                onChange={values => formik.setFieldValue('permissions', values)}
              />

              {/* Register Module */}
              <PermissionsAccordion
                title='registrations'
                isDisable={isDetail}
                permissionsList={registerPermissions}
                currentPermissions={formik.values.permissions || []}
                onChange={values => formik.setFieldValue('permissions', values)}
              />

              {/* List Module */}
              <PermissionsAccordion
                title='listings'
                isDisable={isDetail}
                permissionsList={listPermissions}
                currentPermissions={formik.values.permissions || []}
                onChange={values => formik.setFieldValue('permissions', values)}
              />

              {/* Manger Module */}
              <PermissionsAccordion
                title='management'
                isDisable={isDetail}
                permissionsList={managerPermissions}
                currentPermissions={formik.values.permissions || []}
                onChange={values => formik.setFieldValue('permissions', values)}
              />

              {/* Services Module */}
              <PermissionsAccordion
                title='services'
                isDisable={isDetail}
                permissionsList={servicePermissions}
                currentPermissions={formik.values.permissions || []}
                onChange={values => formik.setFieldValue('permissions', values)}
              />

              {/* Reports Module */}
              <PermissionsAccordion
                title='reports'
                isDisable={isDetail}
                permissionsList={reportPermissions}
                currentPermissions={formik.values.permissions || []}
                onChange={values => formik.setFieldValue('permissions', values)}
              />
            </div>

            {!isNew && (
              <DeleteProfile
                profile={formik.values}
                closeModal={handleModal}
                isModalOpen={openDeleteModal}
              />
            )}

            {state !== 'detail' && (
              <ContentFooter
                className={`flex bg-neutralBackground-primary ${!isNew ? ' justify-between' : ' justify-end'}`}
              >
                {isNew ? (
                  <Button
                    size='md'
                    type='button'
                    label='Cancelar'
                    variant='secondary'
                    className='mr-auto'
                    onClick={() => navigate(-1)}
                  />
                ) : (
                  <Button
                    size='md'
                    type='button'
                    state='error'
                    label='Excluir'
                    variant='secondary'
                    onClick={() => handleModal()}
                  />
                )}

                {isEdit && (
                  <Button
                    size='md'
                    type='button'
                    label='Cancelar'
                    variant='secondary'
                    className='ml-auto mr-4'
                    onClick={() => navigate('.', { state: 'detail' })}
                  />
                )}
                <Button
                  type='submit'
                  iconLeft={<FloppyDisk size={20} />}
                  size='md'
                  label='Salvar'
                  isLoading={isLoading}
                />
              </ContentFooter>
            )}
          </form>
        </ContentForm>
      )}
    </Content>
  )
}

export default AccessProfileForm
