const anamense_form_enUS = {
  title: 'Criar novo template',

  fields: {
    client: 'Cliente',
    unit: 'Unidade',
    specialty: 'Especialidade'
  },
  placeholder: 'Selecione',
  actions: {
    save: 'Criar template',
    add: 'Adicionar questão',
    select_an_option: 'Selecione uma opção'
  }
}

export default anamense_form_enUS
