import UnitPermissionsEnum from '../../../types/unit-permissions'

const permissions_units_ptBR = {
  permissions: {
    [UnitPermissionsEnum.chat_consultation]: 'Chat',
    [UnitPermissionsEnum.elective_care_consultation]: 'Eletivos',
    [UnitPermissionsEnum.emergency_care_consultation]: 'Pronto atendimentos',

    [UnitPermissionsEnum.teleconsultation_type]: 'Teleconsulta',
    [UnitPermissionsEnum.teleinterconsultation_type]: 'Teleinterconsulta',

    [UnitPermissionsEnum.write_permission]: 'Permissão de Gravação',
    [UnitPermissionsEnum.invite_patient]: 'Convidar Paciente',
    [UnitPermissionsEnum.invite_professional]: 'Convidar profissional'
  }
}

export default permissions_units_ptBR
