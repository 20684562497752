import { useAuth } from '../../contexts/auth'
import PermissionEnum from '../../types/permissions'

type Props = {
  children: React.ReactNode
  permission?: PermissionEnum
  permissions?: PermissionEnum[]
}

const ProtectedComponent: React.FC<Props> = ({ children, permission, permissions }) => {
  const { hasAccess } = useAuth()
  if (
    (!!permission && !hasAccess(permission)) ||
    (!!permissions && !permissions.some(permission_item => hasAccess(permission_item)))
  )
    return <></>
  return <>{children}</>
}

export default ProtectedComponent
