import { Hospital } from '@phosphor-icons/react'
import { Client, Unit } from '../../types'
import Avatar from '../avatar'
import Checkbox from '../checkbox'
import { useListUnitsClient } from '../../hooks/useClient'
import Radio from '../radio'
import { useAuth } from '../../contexts/auth'
import PermissionEnum from '../../types/permissions'

type Props = {
  multiple?: boolean
  selectedClients: Client[]
  selectedUnits: Unit[]
  handleSelectedUnits: (units: Unit[]) => void
}

const UnitList: React.FC<Props> = ({
  multiple,
  selectedClients,
  handleSelectedUnits,
  selectedUnits
}) => {
  return (
    <div className='flex flex-col gap-2'>
      {selectedClients.map(client => (
        <UnitsClientList
          key={client.id}
          client={client}
          multiple={multiple}
          handleSelectedUnits={handleSelectedUnits}
          selectedUnits={selectedUnits}
        />
      ))}
    </div>
  )
}

const UnitsClientList: React.FC<{
  multiple?: boolean
  client: Client
  selectedUnits: Unit[]
  handleSelectedUnits: (units: Unit[]) => void
}> = ({ client, multiple, handleSelectedUnits, selectedUnits }) => {
  const { hasAccess, user } = useAuth()

  const { data: units, isFetching } = useListUnitsClient(
    client.id!,
    {
      getFromUser: !hasAccess(PermissionEnum.list_units)
    },
    user || undefined
  )

  const handleSelect = (toSelect: boolean, unit: Unit, client: Client) => {
    if (toSelect) {
      handleSelectedUnits(
        multiple ? [...selectedUnits, { ...unit, client }] : [{ ...unit, client }]
      )
    } else {
      handleSelectedUnits(multiple ? selectedUnits.filter(item => item.id !== unit.id) : [])
    }
  }

  const handleSelectAll = (toSelect: boolean) => {
    if (!units?.data) return

    if (toSelect) {
      const newUnits = units.data
        .filter(unit => !selectedUnits.some(selected => selected.id === unit.id))
        .map(unit => ({ ...unit, client }))
      handleSelectedUnits([...selectedUnits, ...newUnits])
    } else {
      handleSelectedUnits(selectedUnits.filter(unit => !units.data.some(u => u.id === unit.id)))
    }
  }

  const areAllSelected = units?.data?.every(unit =>
    selectedUnits.some(selected => selected.id === unit.id)
  )
  const isIndeterminate =
    !areAllSelected &&
    units?.data?.some(unit => selectedUnits.some(selected => selected.id === unit.id))

  return (
    <div className='flex w-full flex-col items-center' key={client.id}>
      <div className='my-4 flex w-full items-center'>
        <Avatar client={client} className='mr-4' size='sm' />
        <div className='mr-auto text-label-md text-neutralContent-primary'>{client.name}</div>
        {isFetching ? (
          <div className='ml-auto h-5 w-20 animate-pulse bg-gray-200' />
        ) : (
          <>
            <div className='flex gap-2'>
              <div className='text-body-sm text-neutralContent-tertiary'>{`${units?.data?.length || 0} Unidades`}</div>

              {multiple && (
                <div className='mr-4'>
                  <Checkbox
                    isChecked={
                      Boolean(areAllSelected) && Boolean(units?.data && units?.data?.length > 0)
                    }
                    isIndeterminate={isIndeterminate}
                    onClick={() => handleSelectAll(!areAllSelected)}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div
        className={`w-full rounded-xl px-4 ${units?.data.length === 0 ? '' : 'border border-neutralBorder-default '}`}
      >
        {isFetching ? (
          <Skeleton />
        ) : (
          <>
            {units?.data?.length === 0 && (
              <span className='text-label-sm'>Nenhuma unidade encontrada</span>
            )}
            {units?.data?.map((unit: Unit) => (
              <div key={unit.id} className='flex w-full items-center gap-4'>
                <Hospital size={24} color='#0F121A' className='my-4' />
                <div className='mr-auto text-label-md text-neutralContent-primary'>{unit.name}</div>
                {multiple ? (
                  <Checkbox
                    isChecked={selectedUnits.some(item => item.id === unit.id)}
                    onClick={toSelect => handleSelect(toSelect, unit, client)}
                  />
                ) : (
                  <Radio
                    size='md'
                    state='enabled'
                    selected={selectedUnits.some(item => item.id === unit.id)}
                    onChange={() =>
                      handleSelect(!selectedUnits.some(item => item.id === unit.id), unit, client)
                    }
                  />
                )}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  )
}

const Skeleton: React.FC = () => {
  return (
    <div className='flex w-full flex-col'>
      {Array.from({ length: 3 }, (_, index_row) => (
        <div key={index_row} className='flex w-full items-center py-4'>
          <div className='mr-4 h-9 w-9 animate-pulse rounded-xl bg-gray-200' />
          <div className='mr-auto h-5 w-36 animate-pulse bg-gray-200' />
          <div className='h-5 w-5 animate-pulse rounded-md bg-gray-200' />
        </div>
      ))}
    </div>
  )
}

export default UnitList
