import { useEffect, useState } from 'react'
import Table from '../../components/table'
import { ROUTES } from '../../routes/path'
import Button from '../../components/button'
import Content from '../../components/content'
import { PaginationParams } from '../../types'
import { useNavigate } from 'react-router-dom'
import SearchField from '../../components/search'
import PermissionEnum from '../../types/permissions'
import { useListClients } from '../../hooks/useClient'
import { Info, Plus } from '@phosphor-icons/react'
import InputSkeleton from '../../components/input/skeleton'
import ProtectedComponent from '../../components/protected'
import { formatDate } from '../../helpers/formatDate'
import Tag from '../../components/tag'
import { useTranslation } from 'react-i18next'

const pageSize = 10

const ClientsPage: React.FC = () => {
  const { t } = useTranslation('client_list')
  const [params, setParams] = useState<PaginationParams>({ filter: '', page: 0, pageSize })
  const [totalPages, setTotalPages] = useState(0)
  const navigate = useNavigate()

  const { data: clients, isLoading, isFetching } = useListClients(params)

  useEffect(() => {
    if (clients) {
      setTotalPages(Math.ceil(clients.totalItems / pageSize))
    }
  }, [clients])

  const columns = [
    { header: t('columns.name'), accessor: 'name' },
    {
      header: t('columns.contract_period'),
      accessor: 'date',
      className: 'sm:max-w-[200px] flex justify-center '
    },
    {
      header: t('columns.status'),
      accessor: 'status',
      className: 'sm:max-w-[200px] ] flex justify-center '
    },
    {
      header: t('columns.units_qty'),
      accessor: 'units_qty',
      className: 'sm:max-w-[200px] flex justify-center '
    },
    { header: t('columns.actions'), accessor: 'actions', className: 'sm:max-w-[120px]' }
  ]

  const tableData =
    clients?.data?.map(client => ({
      name: client.name,
      date:
        client.contract_start && client.contract_end
          ? `${formatDate(client.contract_start)} ${t('contract_period.from_to')} ${formatDate(client.contract_end)}`
          : t('contract_period.no_date'),
      status: (
        <div>
          <Tag
            label={client.is_active ? t('tags.active') : t('tags.inactive')}
            variant='secondary'
            size='sm'
            color={client.is_active ? 'green' : 'neutral'}
          />
        </div>
      ),
      units_qty: (
        <div>
          <Tag
            label={`${client.units?.length}/${client.units_limit}`}
            variant='secondary'
            size='sm'
            color={'blue'}
          />
        </div>
      ),
      actions: (
        <ProtectedComponent permission={PermissionEnum.list_clients}>
          <Button
            size='xs'
            label={t('actions.details')}
            variant='secondary'
            className='ml-auto'
            iconLeft={<Info size={12} />}
            onClick={() => navigate(ROUTES.client.details.replace(':id', client?.id || ''))}
          />
        </ProtectedComponent>
      )
    })) ?? []

  const handleFilter = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setParams({ filter: value, page: 0, pageSize })
  }

  const handlePage = (newPage: number) => {
    setParams(oldState => ({ ...oldState, page: newPage }))
  }

  return (
    <Content
      title={t('title')}
      rightContent={
        <ProtectedComponent permission={PermissionEnum.register_client}>
          <Button
            size='sm'
            label={t('actions.new_client')}
            iconLeft={<Plus size={12} />}
            onClick={() => {
              navigate(ROUTES.client.form.replace(':id', 'new'))
            }}
          />
        </ProtectedComponent>
      }
    >
      <div className={`flex h-[calc(100dvh-250px)] flex-col`}>
        <div className='pb-4 sm:max-w-64 '>
          {isLoading ? (
            <InputSkeleton isRounded />
          ) : (
            <SearchField
              maxLength={100}
              value={params.filter || ''}
              onChange={handleFilter}
              placeholder={t('search_placeholder')}
            />
          )}
        </div>
        <Table
          data={tableData}
          columns={columns}
          isLoading={isFetching}
          totalPages={totalPages}
          currentPage={params.page}
          onPageChange={handlePage}
        />
      </div>
    </Content>
  )
}

export default ClientsPage
