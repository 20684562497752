const client_form_enUS = {
  GENERAL_INFORMATION: {
    title: 'Personal Information',
    subtitle: "Fill in the user's personal information"
  },
  PROFILE: {
    title: 'Access Profiles',
    subtitle: 'Select the access profile for this user on the platform'
  },
  fields: {
    personal: {
      contract_start: 'Contract term',
      contract_end: ' ‎',
      name: 'Name',
      cpf: 'CPF',
      cnpj: 'CNPJ',
      email: 'Email',
      phone: 'Phone',
      cep: 'ZIP Code',
      address: 'Address',
      address_number: 'Number',
      district: 'District',
      complement: 'Complement',
      state: 'State',
      city: 'City'
    },
    profile: {
      units_limit: 'Number of units',
      units_limit_hint: 'Select how many units the client can register',
      specialties: 'Specialties',
      allow_profile_access_to_client: 'Allow user registration by the client',
      allowed_profiles: 'Access profiles allowed for registration by the client',
      primary_color: 'Client primary color',
      primary_color_hint: 'Select manually or enter the hexadecimal code',
      no_image_selected: 'No image selected',
      uploading: 'Uploading...',
      completed: 'Completed',
      error: 'Error uploading'
    }
  },
  actions: {
    select: 'Select',
    select_specialties: 'Select specialties',
    select_profiles: 'Select access profiles',
    change_file: 'Change file',
    select_file: 'Select file',
    activate: 'Activate',
    inactivate: 'Inactivate',
    save: 'Save',
    next: 'Next',
    create_client: 'Create client'
  }
}

export default client_form_enUS
