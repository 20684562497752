const transfer_consultation_enUS = {
  title: 'Transfer Consultations',
  subtitle: 'Transfer one or more consultations from one specialist to another',
  from: 'Transfer from',
  to: 'To',
  fields: {
    title: 'Select the specialist',
    search_placeholder: 'Search for a specialist'
  },
  columns: {
    date: 'Date',
    patient: 'Patient',
    consultation: 'Consultation',
    status: 'Status',
    unit: 'Unit',
    consultation_type: {
      elective: 'Elective',
      urgency: 'Urgency'
    },
    status_type: {
      SCHEDULED: 'Scheduled'
    }
  },
  actions: {
    select: 'Select specialist',
    cancel: 'Cancel',
    transfer: 'Transfer consultations',
    confirm: 'Confirm',
    search_placeholder: 'Search for a patient'
  }
}

export default transfer_consultation_enUS
