import React from 'react'

const BlankCardSkeleton: React.FC = () => {
  return (
    <div className='animate-pulse space-y-4 rounded-xl border border-neutralBorder-default p-4'>
      <div className='h-4 w-3/4 rounded bg-neutralBorder-default' />
      <div className='h-4 w-1/2 rounded bg-neutralBorder-default' />
      <div className='h-4 w-full rounded bg-neutralBorder-default' />
    </div>
  )
}

export default BlankCardSkeleton
