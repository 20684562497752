/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next'
import { Specialty } from '../../../../../types'
import BlankCard from '../../../../../components/blank-card'
import Avatar from '../../../../../components/avatar'
import Button from '../../../../../components/button'

interface Props {
  onChange: () => void
  label: string
  avatar: string
}

const SelectedCard: React.FC<Props> = ({ label, avatar, onChange }) => {
  const { t } = useTranslation('agendav2_list')

  return (
    <BlankCard className='flex h-14 items-center justify-between  p-2'>
      <div className='flex items-center gap-2'>
        <Avatar name={avatar} size='sm' />
        <span className='text-label-md text-neutralContent-secondary'>{label}</span>
      </div>
      <Button label='Alterar' onClick={onChange} variant='secondary' size='sm' />
    </BlankCard>
  )
}

export default SelectedCard
