import BlankCard from '../../components/blank-card'
import SearchField from '../../components/search'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Content from '../../components/content'
import { useAuth } from '../../contexts/auth'
import Avatar from '../../components/avatar'
import { ROUTES } from '../../routes/path'
import { useMemo, useState } from 'react'
import { Client } from '../../types'

const AgendaV2Page: React.FC = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation('agendav2_list')

  const [params, setParams] = useState({ filter: '' })

  const clients = useMemo(
    () =>
      user?.clients
        ?.map(item => item.client!)
        .filter(item => item.name?.toUpperCase().includes(params.filter.toUpperCase())) || [],
    [user, params]
  )

  const handleFilter = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setParams({ filter: value })
  }

  const navigateToCalendar = (client_id: string) => {
    navigate(ROUTES.agenda.calendar.replace(':client_id', client_id))
  }

  return (
    <Content title={t('title')}>
      <div className='mb-9'>
        <SearchField
          maxLength={100}
          value={params.filter}
          onChange={handleFilter}
          className='py-3'
          placeholder={t('search_placeholder')}
        />
      </div>

      <div className='grid grid-cols-1 gap-4 overflow-y-auto md:grid-cols-2 lg:grid-cols-4'>
        {clients?.map((client: Client) => (
          <BlankCard
            key={client.id}
            className='cursor-pointer hover:border hover:border-primaryBrand-primary'
          >
            <div
              className='flex items-center gap-3 '
              onClick={() => navigateToCalendar(client?.id || '')}
            >
              <Avatar client={client} />
              <span className='text-label-md text-neutralContent-primary'>{client.name}</span>
            </div>
          </BlankCard>
        ))}
      </div>
    </Content>
  )
}

export default AgendaV2Page
