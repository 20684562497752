import { useTranslation } from 'react-i18next'
import React, { useEffect, useRef } from 'react'
import { ChatMessage } from './ChatMessage'
import { LogItem } from './LogItem'
import { useChatConnection } from '../../../../hooks/useChatConnection'
import { MessageInput } from './MessageInput'
import { ChatHeader } from './ChatHeader'
import { ConsultationMessage, MessageType } from '../../../../types'

type Props = { id: string }

export const ChatConnection: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation('consultation_details')
  const { message, messages, setMessage, handleSendMessage, handleKeyPress } = useChatConnection(id)

  const bottomRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Sempre que as mensagens mudarem, o scroll será ajustado para o fim
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  return (
    <div className='mb-6 mr-6 mt-[104px] flex h-[calc(100dvh-128px)] flex-col gap-5 rounded-xl bg-white p-5'>
      {/* HEADER */}
      <ChatHeader />

      {/* MESSAGES */}
      <div className='flex-4 flex-1 overflow-y-auto p-4'>
        <ChatMessages messages={messages} />
        <div ref={bottomRef} />
      </div>

      {/* INPUT */}
      <div className='sticky bottom-0 border-gray-200 bg-white '>
        <MessageInput
          message={message}
          setMessage={setMessage}
          handleKeyPress={handleKeyPress}
          buttonLabel={t('actions.sent')}
          handleSendMessage={handleSendMessage}
          placeholder={t('chat.textarea_placeholder')}
        />
      </div>
    </div>
  )
}

export const ChatMessages: React.FC<{ messages: ConsultationMessage[] }> = ({ messages }) => {
  return (
    <>
      {messages.map(message => (
        <React.Fragment key={message.id}>
          {message.type === MessageType.LOG_ENTERED || message.type === MessageType.LOG_EXITED ? (
            <LogItem
              user={message.user?.name || ''}
              hour={message.created_at || ''}
              message={message.message || ''}
              type={message.type}
            />
          ) : (
            <ChatMessage
              userId={message.user?.id || ''}
              userName={message.user?.name || ''}
              message={message.message || ''}
              hour={message.created_at || ''}
            />
          )}
        </React.Fragment>
      ))}
    </>
  )
}
