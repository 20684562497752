import BlankCardSkeleton from '../../../../components/blank-card/skeleton'
import { scaleNumberToTime } from '../../../../helpers/scaleTime'
import { Dot, Stethoscope } from '@phosphor-icons/react'
import SearchField from '../../../../components/search'
import Avatar from '../../../../components/avatar'
import Card from '../../../../components/card'
import Tag from '../../../../components/tag'
import { Scale } from '../../../../types'
import EmptyScale from '../empty-scale'
import { useState } from 'react'

interface Props {
  isLoading?: boolean
  scales?: Scale[]
  onAddNewScale: () => void
  onClickScale: (scale: Scale) => void
}

const ScaleList: React.FC<Props> = ({ onAddNewScale, onClickScale, isLoading, scales }) => {
  const [params, setParams] = useState<{ filter: string }>({ filter: '' })

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setParams({ filter: event.target.value })
  }

  return (
    <>
      <div className='relative mt-4 inline-flex gap-3'>
        <SearchField
          value={params.filter}
          className='flex-grow'
          onChange={handleFilter}
          placeholder={'Procurar'}
        />
      </div>

      {isLoading ? (
        <>
          <BlankCardSkeleton />
          <BlankCardSkeleton />
          <BlankCardSkeleton />
        </>
      ) : (
        <>
          {scales?.length ? (
            scales?.map(scale => (
              <Card
                key={scale?.id}
                onClick={() => onClickScale(scale)}
                isActive={scale.status === 'PUBLISHED'}
                className='cursor-pointer transition-all hover:opacity-65'
              >
                <div className='flex w-full flex-col gap-1 p-3 pl-0'>
                  <div className='bg-ne flex w-full justify-between '>
                    <div>{scale?.specialty?.name}</div>
                    {scale.status === 'DRAFT' ? (
                      <Tag size='sm' label={'Rascunho'} color='neutral' variant='primary' />
                    ) : (
                      <Tag size='sm' label={'Publicada'} color='green' variant='secondary' />
                    )}
                  </div>

                  <div className='flex gap-1 text-label-sm text-neutralContent-tertiary'>
                    {scale.time_intervals.map(interval => (
                      <div className='' key={interval.id}>
                        {scaleNumberToTime(interval.start_time)}-
                        {scaleNumberToTime(interval.end_time)}
                      </div>
                    ))}
                    <Dot size={20} />
                    <div className=''>
                      {scale.units.length} {'Unidade'}
                      {scale.units.length > 1 ? 's' : ''}
                    </div>
                  </div>

                  <div className='gap-1'>
                    {scale?.specialists?.length && scale?.specialists?.length > 0 ? (
                      scale.specialists.map(({ specialist }) => (
                        <div
                          key={specialist?.id}
                          className='flex items-center gap-1 text-label-sm text-neutralContent-tertiary'
                        >
                          <Avatar name={specialist?.name} size='xxs' color='neutral' />
                          {specialist?.name}
                        </div>
                      ))
                    ) : (
                      <div className='flex items-center gap-2 text-label-sm text-highlightRed-pure'>
                        <div className='flex h-5 w-5 items-center justify-center rounded-full bg-highlightRed-pure'>
                          <Stethoscope color='white' size={12} />
                        </div>
                        {'Sem especialista definido'}
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            ))
          ) : (
            <div className='mt-12'>
              <EmptyScale onNewScale={onAddNewScale} />
            </div>
          )}
        </>
      )}
    </>
  )
}

export default ScaleList
