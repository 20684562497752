import { Dashboard, PaginationParams, PaginationResponse } from '../types'
import api from './api.service'

class DashboardService {
  static async list(params: PaginationParams) {
    const response = await api.get<PaginationResponse<Dashboard>>('/dashboard', {
      params
    })
    return response.data
  }

  static async getGuestToken(id: string) {
    const response = await api.get<string>('/dashboard/' + id)
    return response.data
  }
}

export default DashboardService
