import { CalendarBlank, Hospital, IdentificationCard } from '@phosphor-icons/react'
import { Consultation, CONSULTATION_STATUS, FOLLOW_UP_DISPLAY_MODE } from '../../../types'
import { format } from 'date-fns'
import { getTimeDiff } from '../../../helpers/getTimeDiff'

interface Props {
  consultation: Consultation
  displayMode?: FOLLOW_UP_DISPLAY_MODE
}

export const ConsultationInfo: React.FC<Props> = ({
  consultation,
  displayMode = FOLLOW_UP_DISPLAY_MODE.CARD
}) => {
  return (
    <div className='my-2 mr-auto flex flex-col gap-1'>
      <div className='flex items-center gap-2'>
        <IdentificationCard />
        <span className='text-body-sm text-neutralContent-primary'>
          {consultation.specialty?.name}
        </span>
      </div>
      <div className='flex items-center gap-2'>
        <CalendarBlank />
        <span className='text-body-sm text-neutralContent-primary'>
          {consultation.status === CONSULTATION_STATUS.IN_ROOM
            ? 'Iniciado há ' + getTimeDiff(new Date(consultation.started_at!), new Date())
            : format(consultation.start_date || consultation.created_at || '', 'dd/MM/yyyy HH:mm')}
        </span>
      </div>
      {displayMode === FOLLOW_UP_DISPLAY_MODE.CARD && (
        <div className='flex items-center gap-2'>
          <Hospital />
          <span className='text-body-sm text-neutralContent-primary'>
            {consultation.unit?.client?.name} - {consultation.unit?.name}
          </span>
        </div>
      )}
    </div>
  )
}
