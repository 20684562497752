const ContentFooter: React.FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className
}) => {
  return (
    // prettier-ignore
    <div className={`${className} shadow-footer z-10 bg-white fixed sm:absolute bottom-0 left-0 right-0 sm:bottom-[-104px] sm:left-[-24px] sm:right-[-24px] flex px-6 py-4 `}>
      {children}
    </div>
  )
}

export default ContentFooter
