import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns'

export const getTimeDiff = (dateOne: Date, dateTwo: Date) => {
  const minutesInWait = Math.abs(differenceInMinutes(dateTwo, dateOne))
  if (minutesInWait < 60) {
    return `${minutesInWait} min`
  }

  const hoursInWait = Math.abs(differenceInHours(dateTwo, dateOne))
  if (hoursInWait < 24) {
    return `${hoursInWait} horas`
  }

  const daysInWait = Math.abs(differenceInDays(dateTwo, dateOne))
  return `${daysInWait} dias`
}
