import { useTranslation } from 'react-i18next'
import Tab from '../../../components/tabs'
import Content from '../../../components/content'
import { useParams } from 'react-router-dom'
import { useMemo } from 'react'
import { Loading } from 'semente-js'
import { useGetAnamnesisTemplateByConsultation } from '../../../hooks/useAnamnesis'

const Anamnesis: React.FC = () => {
  const { t } = useTranslation('anamnesis')
  const { id } = useParams()
  const { data, isFetching } = useGetAnamnesisTemplateByConsultation(id)

  const tabs = useMemo(
    () => [
      {
        label: t('tabs.patient_identification'),
        content: <></>,
        disabled: false
      },
      {
        label: t('tabs.specialty'),
        content: <></>,
        disabled: !data
      },
      {
        label: t('tabs.diagnosticHypothesis'),
        content: <div>Conteúdo da Hipótese Diagnóstica</div>,
        disabled: true
      }
    ],
    [data, t]
  )

  return <Content>{isFetching ? <Loading /> : <Tab tabs={tabs} />}</Content>
}

export default Anamnesis
