import React, { ReactNode, useEffect, useState } from 'react'

interface SegmentedButtonProps {
  isLoading?: boolean
  labels?: string[]
  icons?: ReactNode[]
  selectedIndex?: number
  onChange?: (index: number) => void
  className?: string
}

const SegmentedButton: React.FC<SegmentedButtonProps> = ({
  isLoading,
  labels = [],
  icons = [],
  selectedIndex = 0,
  onChange,
  className
}) => {
  const [selected, setSelected] = useState<number>(selectedIndex)

  const handleClick = (index: number) => {
    if (!isLoading) {
      setSelected(index)
      onChange?.(index)
    }
  }

  useEffect(() => {
    if (Number.isInteger(selectedIndex)) setSelected(selectedIndex)
  }, [selectedIndex])

  const items = labels.length > 0 ? labels : icons

  return (
    <div
      className={`${className} inline-flex h-10 items-center rounded-xl border border-neutralBorder-default`} // Use inline-flex ao invés de flex
    >
      {items.map((item, index) => (
        <div
          key={index}
          onClick={() => handleClick(index)}
          className={`ml-1 mr-1 flex h-8 cursor-pointer items-center rounded-lg px-4 transition-all ${isLoading && 'animate-pulse'}
            ${selected === index ? ' border-2 border-neutralBorder-brand' : 'border-none hover:bg-gray-100'}
            ${selected === index && 'bg-highlightBlue-light'}
          `}
        >
          {typeof item === 'string' ? (
            <span className={`${selected === index && 'text-primaryBrand-primary'} text-label-sm`}>
              {item}
            </span>
          ) : (
            <span className={`${selected === index && 'text-primaryBrand-primary'} text-label-sm`}>
              {item}
            </span>
          )}
        </div>
      ))}
    </div>
  )
}

export default SegmentedButton
