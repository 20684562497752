const agenda_enUS = {
  title: 'Schedule',
  search_placeholder: 'Search by name or CPF',
  columns: {
    professional: 'Professional',
    cpf: 'CPF',
    type_of_agenda: 'Type of Schedule',
    types: {
      fixed: 'Fixed',
      custom: 'Custom'
    },
    details: 'View schedule'
  },
  actions: {
    new: 'Create Schedule'
  }
}

export default agenda_enUS
