import { useQuery } from '@tanstack/react-query'
import AnamnesisService from '../services/anamnesis.service'
import { PaginationParams } from '../types'
import AnamnesisTemplateService from '../services/anamnesis-template.service'

export const useGetAnamnesisByConsultation = (consultation_id?: string) => {
  return useQuery({
    queryKey: ['getAnamnesis', consultation_id],
    queryFn: () => (consultation_id ? AnamnesisService.getByConsultationId(consultation_id) : null)
  })
}

export const useGetAnamnesisTemplate = (id?: string) => {
  return useQuery({
    queryKey: ['getAnamnesisTemplate', id],
    queryFn: () => (id ? AnamnesisTemplateService.getById(id) : null),
    placeholderData: prev => prev
  })
}

export const useGetAnamnesisTemplateByConsultation = (id?: string) => {
  return useQuery({
    queryKey: ['getAnamnesisTemplateByConsultation', id],
    queryFn: () => (id ? AnamnesisTemplateService.getByConsultationId(id) : null),
    placeholderData: prev => prev
  })
}

export const useListAnamnesisTemplate = (params: PaginationParams) => {
  return useQuery({
    queryKey: ['listAnamnesisTemplate', params],
    queryFn: () => AnamnesisTemplateService.list(params),
    placeholderData: prev => prev
  })
}

export const useGetQuestionsDefault = () => {
  return useQuery({
    queryKey: ['anamnesisQuestionsDefault'],
    queryFn: () => AnamnesisService.getQuestionsDefault()
  })
}
