import { useNavigate, useParams } from 'react-router-dom'
import { useGetCount, useListUnitsClient } from '../../../../hooks/useClient'
import Tag from '../../../../components/tag'
import ProtectedComponent from '../../../../components/protected'
import PermissionEnum from '../../../../types/permissions'
import { ROUTES } from '../../../../routes/path'
import { Info, Plus } from '@phosphor-icons/react'
import Button from '../../../../components/button'
import Content from '../../../../components/content'
import InputSkeleton from '../../../../components/input/skeleton'
import SearchField from '../../../../components/search'
import Table from '../../../../components/table'
import { useEffect, useState } from 'react'
import { PaginationParams } from '../../../../types'
import { useTranslation } from 'react-i18next'
import ButtonSkeleton from '../../../../components/button/skeleton'

const pageSize = 10

const UnitPage: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('unit_list')
  const { client_id } = useParams<{ client_id: string }>()

  const [params, setParams] = useState<PaginationParams>({ filter: '', page: 0, pageSize })
  const [totalPages, setTotalPages] = useState(0)

  const { data, isLoading, isFetching } = useListUnitsClient(client_id!, params)
  const { data: count, isFetching: isFetchingCount } = useGetCount(client_id!)

  useEffect(() => {
    if (data) {
      setTotalPages(Math.ceil(data.totalItems / pageSize))
    }
  }, [data])

  const columns = [
    { header: t('columns.name'), accessor: 'name' },
    {
      header: t('columns.type'),
      accessor: 'type',
      className: 'sm:max-w-[250px] flex justify-center '
    },
    {
      header: t('columns.cnpj'),
      accessor: 'cnpj',
      className: 'sm:max-w-[200px] ] flex justify-center '
    },
    {
      header: t('columns.status'),
      accessor: 'status',
      className: 'sm:max-w-[200px] flex justify-center '
    },
    { header: '', accessor: 'actions', className: 'sm:max-w-[120px]' }
  ]

  const tableData =
    data?.data?.map(unit => ({
      name: unit.name,
      type: (
        <span className='text-body-md text-neutralContent-primary'>{unit.unit_type?.name}</span>
      ),
      cnpj: <span className='text-body-md text-neutralContent-primary'>{unit.cnpj}</span>,
      status: (
        <div>
          <Tag
            label={unit.is_active ? t('tags.active') : t('tags.inactive')}
            variant='secondary'
            size='sm'
            color={unit.is_active ? 'green' : 'neutral'}
          />
        </div>
      ),
      actions: (
        <ProtectedComponent permission={PermissionEnum.list_units}>
          <Button
            size='xs'
            label={t('actions.details')}
            variant='secondary'
            className='ml-auto'
            iconLeft={<Info size={12} />}
            onClick={() =>
              navigate(
                ROUTES.client.unit.detail
                  .replace(':client_id', client_id || '')
                  .replace(':id', unit.id || '')
              )
            }
          />
        </ProtectedComponent>
      )
    })) ?? []

  const handleFilter = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setParams({ page: 0, pageSize, filter: value })
  }

  const handlePage = (newPage: number) => {
    setParams(oldState => ({ ...oldState, page: newPage }))
  }

  const handleBack = () => {
    navigate(ROUTES.client.details.replace(':id', client_id || ''))
  }

  return (
    <Content
      title={t('title')}
      onBack={handleBack}
      rightContent={
        isFetchingCount ? (
          <ButtonSkeleton />
        ) : (
          (count?.total || 0) < (count?.limit || 0) && (
            <ProtectedComponent permission={PermissionEnum.register_unit}>
              <Button
                size='sm'
                label={t('actions.register_unit')}
                iconLeft={<Plus size={12} />}
                onClick={() => {
                  navigate(
                    ROUTES.client.unit.form
                      .replace(':id', 'new')
                      .replace(':client_id', client_id || '')
                  )
                }}
              />
            </ProtectedComponent>
          )
        )
      }
    >
      <div className='pb-4 sm:max-w-96 '>
        {isLoading ? (
          <InputSkeleton isRounded />
        ) : (
          <SearchField
            maxLength={100}
            onChange={handleFilter}
            value={params.filter || ''}
            placeholder={t('search_placeholder')}
          />
        )}
      </div>
      <Table
        hasBackButton
        data={tableData}
        columns={columns}
        isLoading={isFetching}
        totalPages={totalPages}
        currentPage={params.page}
        onPageChange={handlePage}
      />
    </Content>
  )
}

export default UnitPage
