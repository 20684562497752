import React, { ReactNode, useState, useRef, useEffect } from 'react'
import { CaretDown, CaretUp } from '@phosphor-icons/react'

interface AccordionProps {
  title: ReactNode | string
  placeholder?: ReactNode | string
  subtitle?: ReactNode | string
  children: ReactNode
  className?: string
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  placeholder,
  subtitle,
  children,
  className
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [height, setHeight] = useState('0px')
  const contentRef = useRef<HTMLDivElement>(null)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    if (contentRef.current) {
      setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px')
    }
  }, [isOpen, children])

  return (
    <div
      className={`relative rounded-xl border border-neutralBorder-default bg-white py-1 ${className}`}
    >
      <div className='flex w-full items-center justify-between rounded-xl bg-white p-4'>
        <div className='flex w-full cursor-pointer justify-between' onClick={toggleAccordion}>
          <div onClick={e => e.stopPropagation()}>
            <span className='label-md font-bold'>{title}</span>
            {!!placeholder && <div className='flex gap-2'>{placeholder}</div>}
          </div>
          <div className='flex items-center'>
            {!!subtitle && (
              <span className='label-sm mr-3 text-neutralContent-tertiary'>{subtitle}</span>
            )}
            <span>{isOpen ? <CaretUp size={24} /> : <CaretDown size={24} />}</span>
          </div>
        </div>
      </div>
      <div
        ref={contentRef}
        style={{ maxHeight: height }}
        className={`${isOpen ? 'overflow-visible ' : 'overflow-hidden '} transition-all  ease-in-out`}
      >
        <div className='p-4'>{children}</div>
      </div>
    </div>
  )
}

export default Accordion
