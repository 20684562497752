/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next'
import { useGetDocuments } from '../../../../hooks/usePatient'
import Button from '../../../../components/button'
import { format } from 'date-fns'
import { Eye } from '@phosphor-icons/react'
import Modal from '../../../../components/modal'
import { useState } from 'react'
import { MevoFile } from '../../../../types'
import FileService from '../../../../services/file.service'
import { useToastContext } from '../../../../contexts/toast'
import Skeleton from './skeleton-page'

const Documents: React.FC<{
  id: string
}> = ({ id }) => {
  const { t } = useTranslation('patient_detail')
  const { toast } = useToastContext()

  const [currentFile, setCurrentFile] = useState<MevoFile>()
  const [pdfURL, setPdfURL] = useState<string>()

  const { data, isFetching } = useGetDocuments(id)

  const getDocumentsByCategory = (category: string) => {
    if (!Array.isArray(data)) return []
    return data.filter((doc: any) => doc.category === category)
  }

  const loadPdf = async (file: MevoFile) => {
    setCurrentFile(file)
    try {
      const url = await FileService.getMevoFile(file.id!)
      setPdfURL(url)
    } catch (_error) {
      toast.error('Falha ao carregar o PDF')
    }
  }

  const closeModal = () => {
    setCurrentFile(undefined)
    setPdfURL(undefined)
  }

  return (
    <>
      {isFetching ? (
        <Skeleton />
      ) : (
        <div className='mt-9 h-[calc(100vh-340px)] overflow-y-scroll'>
          <div className='mb-9 flex flex-col flex-wrap gap-3 '>
            <span className='text-heading-xs text-neutralContent-primary'>
              {t('medical_certificates')}
            </span>
            <div className='flex flex-wrap gap-2'>
              {!!getDocumentsByCategory('ATESTADO').length ? (
                getDocumentsByCategory('ATESTADO').map((medical_certificate: any) => (
                  <Button
                    key={medical_certificate.id}
                    label={format(new Date(medical_certificate.created_at), 'dd/MM/yyyy')}
                    iconRight={<Eye size={16} />}
                    variant='secondary'
                    size='sm'
                    onClick={() => loadPdf(medical_certificate)}
                  />
                ))
              ) : (
                <span>{t('no_attach')}</span>
              )}
            </div>
          </div>

          <div className='mb-9 flex flex-col flex-wrap gap-3 '>
            <span className='text-heading-xs text-neutralContent-primary'>
              {t('prescriptions')}
            </span>
            <div className='flex flex-wrap gap-2'>
              {!!getDocumentsByCategory('RECEITA').length ? (
                getDocumentsByCategory('RECEITA').map((prescription: any) => (
                  <Button
                    key={prescription.id}
                    label={format(new Date(prescription.created_at), 'dd/MM/yyyy')}
                    iconRight={<Eye size={16} />}
                    variant='secondary'
                    size='sm'
                    onClick={() => loadPdf(prescription)}
                  />
                ))
              ) : (
                <span>{t('no_attach')}</span>
              )}
            </div>
          </div>

          <div className='mb-9 flex flex-col flex-wrap gap-3 '>
            <span className='text-heading-xs text-neutralContent-primary'>{t('referrals')}</span>
            <div className='flex flex-wrap gap-2'>
              {!!getDocumentsByCategory('ENCAMINHAMENTO').length ? (
                getDocumentsByCategory('ENCAMINHAMENTO').map((referral: any) => (
                  <Button
                    key={referral.id}
                    label={format(new Date(referral.created_at), 'dd/MM/yyyy')}
                    iconRight={<Eye size={16} />}
                    variant='secondary'
                    size='sm'
                    onClick={() => loadPdf(referral)}
                  />
                ))
              ) : (
                <span>{t('no_attach')}</span>
              )}
            </div>
          </div>

          <div className='mb-9 flex flex-col flex-wrap gap-3 '>
            <span className='text-heading-xs text-neutralContent-primary'>
              {t('test_requests')}
            </span>
            <div className='flex flex-wrap gap-2'>
              {!!getDocumentsByCategory('EXAME').length ? (
                getDocumentsByCategory('EXAME').map((test_request: any) => (
                  <Button
                    key={test_request.id}
                    label={format(new Date(test_request.created_at), 'dd/MM/yyyy')}
                    iconRight={<Eye size={16} />}
                    variant='secondary'
                    size='sm'
                    onClick={() => loadPdf(test_request)}
                  />
                ))
              ) : (
                <span>{t('no_attach')}</span>
              )}
            </div>
          </div>

          <div className='mb-9 flex flex-col flex-wrap gap-3 '>
            <span className='text-heading-xs text-neutralContent-primary'>
              {t('medical_reports')}
            </span>
            <div className='flex flex-wrap gap-2'>
              {!!getDocumentsByCategory('RELATORIO').length ? (
                getDocumentsByCategory('RELATORIO').map((medical_report: any) => (
                  <Button
                    key={medical_report.id}
                    label={format(new Date(medical_report.created_at), 'dd/MM/yyyy')}
                    iconRight={<Eye size={16} />}
                    variant='secondary'
                    size='sm'
                    onClick={() => loadPdf(medical_report)} // Adicionado evento de clique
                  />
                ))
              ) : (
                <span>{t('no_attach')}</span>
              )}
            </div>
          </div>

          <Modal
            onClose={closeModal}
            isOpen={!!currentFile}
            title={currentFile?.category}
            className='w-[calc(100vw-80px)]'
          >
            {pdfURL ? (
              <iframe src={pdfURL} className='h-[calc(100vh-100px)] w-full' />
            ) : (
              <p>Carregando PDF...</p>
            )}
          </Modal>
        </div>
      )}
    </>
  )
}

export default Documents
