import { useQuery } from '@tanstack/react-query'
import SpecialtyService from '../services/specialty.service'

export const useListSpecialtiesFromUnit = (unit_id?: string) => {
  return useQuery({
    queryKey: ['specialtyListFromUnit', { unit_id }],
    queryFn: () => (unit_id ? SpecialtyService.listSpecialtyFromUnit(unit_id) : null)
  })
}

export const useListSpecialty = (
  page: number,
  pageSize: number,
  filter: string,
  client_id?: string
) => {
  return useQuery({
    queryKey: ['specialtyList', { page, pageSize, filter }],
    queryFn: () => SpecialtyService.listSpecialty({ page, pageSize, filter, client_id })
  })
}

export const useListBySpecialist = (specialist_id?: string) => {
  return useQuery({
    queryKey: ['specialtyListBySpecialist', specialist_id],
    queryFn: () =>
      specialist_id
        ? SpecialtyService.listSpecialtyBySpecialist(specialist_id)
        : Promise.resolve([])
  })
}
