const InputSkeleton: React.FC<{ isRounded?: boolean; showLabel?: boolean; className?: string }> = ({
  isRounded,
  className,
  showLabel
}) => {
  return (
    <div className={`flex flex-col ${className}`}>
      {showLabel && <div className='mb-2 h-5 w-20 animate-pulse rounded bg-gray-200' />}
      <div
        className={`flex h-12 items-center gap-[16px] border-[1px] border-neutralBorder-default ${
          isRounded ? 'rounded-full' : 'rounded-[12px]'
        }`}
      >
        <div className='mx-4 h-4 w-full animate-pulse rounded bg-gray-200' />
      </div>
    </div>
  )
}

export default InputSkeleton
