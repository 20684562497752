const patient_form_ptBR = {
  title_create: 'Novo paciente',
  title_update: 'Atualizar paciente',
  other: 'Outro',
  actions: {
    next: 'Próxima',
    back: 'Anterior',
    end_create: 'Finalizar cadastro',
    end_update: 'Atualizar cadastro'
  },
  stepper: {
    general: 'Identificação Básica',
    contact: 'Contato e Endereço',
    details: 'Dados Sociais e Demográficos',
    medical: 'Histórico e Informações Clínicas'
  },
  fields: {
    name: 'Nome',
    document: 'CPF',
    mother_name: 'Nome da Mãe',
    mother_unknown: 'Mãe desconhecida',
    race: 'Raça',
    nationality: 'Nacionalidade',
    birth_state: 'Estado de nascimento',
    birth_city: 'Cidade de nascimento',
    naturalization_date: 'Data de naturalização',
    birth_country: 'País de nascimento',
    passport_country: 'País do passaporte',
    passport_number: 'Número do passaporte',
    representative_legal_name: 'Nome do representante legal',
    select_date: 'Selecione a data',
    select: 'Selecione',
    not_applicable: 'Não aplicável',
    email: 'E-mail principal',
    phone: 'Telefone Principal',
    secondary_phone: 'Telefone Alternativo (opcional)',
    street: 'Endereço Completo',
    number: 'Número',
    complement: 'Complemento',
    neighborhood: 'Bairro',
    state: 'Estado',
    city: 'Cidade',
    zip: 'CEP',

    passport_country_placeholder: 'Selecione',
    passport_number_placeholder: 'Número',
    validity: 'Validade',
    document_number: 'Número',
    document_number_placeholder: 'Número',

    issue_agency: 'Orgão Emissor e UF',
    issue_agency_placeholder: 'Orgão Emissor e UF',
    send_date: 'Data de Emissão',

    representative_legal_proximity: {
      label: 'Grau de Parentesco',
      placeholder: 'Selecione'
    },
    representative_legal_document: {
      label: 'CPF do Guardião ou Representante Legal',
      placeholder: 'CPF do Guardião ou Representante Legal'
    },

    weight: 'Peso',
    height: 'Altura',
    allergy: 'Alergias conhecidas',
    'chronic-diseases': 'Doenças crônicas',
    'health-conditions': 'Condições de Saúde',
    'surgical-history': 'Histórico Cirúrgico',
    dosage_name: 'Medicamento',
    dosage: 'Dosagem',
    dosage_instructions: 'Posologia',

    cpf: 'CPF',
    enter: 'Digite',
    cns_number: 'Número do CNS',
    full_name: 'Nome completo',
    social_name_optional: 'Nome social (opcional)',
    birthdate: 'Data de nascimento',
    gender_optional: 'Gênero (opcional)',
    gender: 'Gênero',
    document_type: 'Tipo de Documento',
    rg: 'R.G.',
    other: 'Outro',
    selects: {
      genders: {
        cisgender_woman: 'Mulher cisgênero',
        transgender_woman: 'Mulher transgênero',
        cisgender_man: 'Homem cisgênero',
        transgender_man: 'Homem transgênero',
        non_binary: 'Não-binário',
        other: 'Outro'
      },
      document_types: {
        rg: 'R.G.',
        passport: 'Passaporte'
      },
      races: {
        white: 'Branca',
        black: 'Preta',
        brown: 'Parda',
        yellow: 'Amarela',
        indigenous: 'Indígena',
        no_information: 'Sem Informação'
      }
    }
  },
  placeholders: {
    dosage_name: 'Selecione',
    dosage: 'Especifique',
    dosage_instructions: 'Especifique',
    weight: 'kg',
    height: 'cm',
    email: 'E-mail principal',
    phone: '(00) 00000-0000',
    secondary_phone: '(00) 00000-0000',
    street: 'Endereço Completo',
    number: 'Número',
    complement: 'Complemento',
    neighborhood: 'Bairro',
    select: 'Selecione',
    zip: 'CEP'
  },
  selects: {
    nationality: {
      BR: 'Brasileira',
      OTHER: 'Outra'
    },
    sexes: {
      masculine: 'Masculino',
      feminine: 'Feminino'
    }
  }
}

export default patient_form_ptBR
