import Button from '../../../components/button'
import Content from '../../../components/content'
import ContentFooter from '../../../components/content/footer'
import ContentForm from '../../../components/content/form'
import InputField from '../../../components/input'
import { useUpdatePasswordFormik } from '../../../hooks/forms/useUserForm'

const PasswordPage = () => {
  const passwordFormik = useUpdatePasswordFormik()

  return (
    <Content title='Trocar senha'>
      <form onSubmit={passwordFormik.handleSubmit} className='h-full'>
        <ContentForm>
          <InputField
            id='password'
            name='password'
            type='password'
            label='Nova senha'
            onBlur={passwordFormik.handleBlur}
            value={passwordFormik.values.password}
            onChange={passwordFormik.handleChange}
            state={
              passwordFormik.touched.password && passwordFormik.errors.password
                ? 'error'
                : 'default'
            }
            hint={
              passwordFormik.touched.password && passwordFormik.errors.password
                ? passwordFormik.errors.password
                : undefined
            }
          />

          <InputField
            type='password'
            id='confirmPassword'
            name='confirmPassword'
            label='Confirme a nova senha'
            onBlur={passwordFormik.handleBlur}
            value={passwordFormik.values.confirmPassword}
            onChange={passwordFormik.handleChange}
            state={
              passwordFormik.touched.confirmPassword && passwordFormik.errors.confirmPassword
                ? 'error'
                : 'default'
            }
            hint={
              passwordFormik.touched.confirmPassword && passwordFormik.errors.confirmPassword
                ? passwordFormik.errors.confirmPassword
                : undefined
            }
          />
        </ContentForm>

        <ContentFooter className='justify-end'>
          <Button type='submit' size='md' label='Salvar' isLoading={passwordFormik.isSubmitting} />
        </ContentFooter>
      </form>
    </Content>
  )
}

export default PasswordPage
