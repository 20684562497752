import { CaretRight } from '@phosphor-icons/react'
import Avatar from '../../../components/avatar'
import { Client } from '../../../types'

type Props = {
  multiple?: boolean
  selectedClients: Client[]
  handleSelectedClients: (clients: Client[]) => void
  clients: Client[]
}

const ClientListFilter: React.FC<Props> = ({ handleSelectedClients, clients }) => {
  const handleSelect = (client: Client) => {
    handleSelectedClients([client])
  }

  return (
    <div className='flex h-full flex-col gap-2 '>
      {!clients ? (
        <Skeleton />
      ) : (
        clients?.map(client => (
          <div
            className='flex w-full cursor-pointer items-center justify-between py-4 '
            key={client.id}
            onClick={() => {
              handleSelect(client)
            }}
          >
            <div className='flex items-center'>
              <Avatar client={client} className='mr-4' size='sm' />
              <div className='text-label-md text-neutralContent-primary'>{client.name}</div>
            </div>
            <div>
              <CaretRight />
            </div>
          </div>
        ))
      )}
    </div>
  )
}

const Skeleton: React.FC = () => {
  return (
    <div className='flex w-full flex-col'>
      {Array.from({ length: 6 }, (_, index_row) => (
        <div key={index_row} className='flex w-full items-center py-4'>
          <div className='mr-4 h-9 w-9 animate-pulse rounded-full bg-gray-200' />
          <div className='mr-auto h-5 w-36 animate-pulse bg-gray-200' />
          <div className='h-5 w-5 animate-pulse rounded-md bg-gray-200' />
        </div>
      ))}
    </div>
  )
}

export default ClientListFilter
