/* eslint-disable @typescript-eslint/no-unused-vars */
import { genders, sexes } from '../user-form/user-personal-information-form'
import ButtonSkeleton from '../../../components/button/skeleton'
import ProtectedComponent from '../../../components/protected'
import ClientUnitsList from '../user-form/client-units-list'
import { MapPin, PencilSimple } from '@phosphor-icons/react'
import { useNavigate, useParams } from 'react-router-dom'
import PermissionEnum from '../../../types/permissions'
import { useGetUser } from '../../../hooks/useUser'
import Content from '../../../components/content'
import Button from '../../../components/button'
import { USER_FORM_STEPS } from '../user-form'
import { useTranslation } from 'react-i18next'
import { ROUTES } from '../../../routes/path'
import { format } from 'date-fns'
import { formatBirthDate } from '../../../helpers/formatBirthDate'
import Avatar from '../../../components/avatar'

const UserDetailsPage: React.FC = () => {
  const { t } = useTranslation('user_details')
  const { id } = useParams<{ id: string }>()
  const { data: user, isFetching: isFetchingUser } = useGetUser(id || '')
  const navigate = useNavigate()

  const onEdit = (step: USER_FORM_STEPS) => {
    navigate(ROUTES.user.edit.replace(':id', id!).replace(':form_step', step))
  }

  const sexFormatted = (): string => {
    if (user?.sex) {
      if (sexes.includes(user?.sex)) return t(`sessions.personal_info.${user?.sex}`)
      return user?.sex
    }
    return '-'
  }

  const title = () => {
    return (
      <div className='flex items-center gap-2'>
        <Avatar imageID={user?.image_id} name={user?.name} />
        <span className='text-heading-sm '>{user?.name || 'Carregando...'}</span>
      </div>
    )
  }

  return (
    <Content title={title()} onBack={() => navigate(ROUTES.user.list)}>
      <div className='mx-[-16px] grid h-[calc(100dvh-280px)] grid-cols-1 content-start gap-x-12 gap-y-16 overflow-y-auto overflow-x-hidden px-4 pb-20 sm:grid-cols-2'>
        <div className='w-full sm:col-span-2'>
          <div className='flex w-full items-center text-heading-sm text-neutralContent-primary '>
            {t('sessions.personal_info.title')}
            {isFetchingUser ? (
              <ButtonSkeleton />
            ) : (
              <ProtectedComponent permission={PermissionEnum.register_user}>
                <Button
                  size='sm'
                  variant='secondary'
                  className='ml-auto'
                  label={t('actions.edit')}
                  onClick={() => onEdit(USER_FORM_STEPS.PERSONAL_INFORMATION)}
                  iconLeft={<PencilSimple size={16} />}
                />
              </ProtectedComponent>
            )}
          </div>

          <div className='mt-4 grid grid-cols-1 rounded-xl border px-4 sm:col-span-2 sm:grid-cols-2'>
            <InfoDetail
              isLoading={isFetchingUser}
              label={t('sessions.personal_info.birthdate')}
              value={user?.birthdate ? formatBirthDate(user.birthdate) : '-'}
            />
            <InfoDetail
              isLoading={isFetchingUser}
              label={t('sessions.personal_info.mother_name')}
              value={user?.mother_name || t('sessions.personal_info.mother_unknown')}
            />
            <InfoDetail
              isLoading={isFetchingUser}
              label={t('sessions.personal_info.sex')}
              value={sexFormatted()}
            />
            <InfoDetail
              isLoading={isFetchingUser}
              label={t('sessions.personal_info.gender')}
              value={
                genders.includes(user?.gender as string)
                  ? t('sessions.personal_info.gender')
                  : (user?.gender as string)
              }
            />
            <InfoDetail
              isLoading={isFetchingUser}
              label={t('sessions.personal_info.race')}
              value={!!user?.race ? t(`sessions.personal_info.${user?.race}`) : ''}
            />
            <InfoDetail
              isLoading={isFetchingUser}
              label={t('sessions.personal_info.nationality')}
              value={user?.nationality}
            />
            <InfoDetail
              isLoading={isFetchingUser}
              label={t('sessions.personal_info.birth_state')}
              value={user?.birth_state}
            />
            <InfoDetail
              isLoading={isFetchingUser}
              label={t('sessions.personal_info.birth_city')}
              value={user?.birth_city}
            />
          </div>
        </div>

        {!isFetchingUser && !!user?.clients?.length && (
          <div className='w-full  sm:col-span-2'>
            <div className='mb-4 text-heading-sm text-neutralContent-primary'>
              {t('sessions.clients.title')}
            </div>

            <ClientUnitsList userClients={user?.clients || []} />
          </div>
        )}

        <div className='w-full'>
          <div className='flex w-full items-center text-heading-sm text-neutralContent-primary'>
            {t('sessions.contact.title')}
            {isFetchingUser ? (
              <ButtonSkeleton />
            ) : (
              <ProtectedComponent permission={PermissionEnum.register_user}>
                <Button
                  size='sm'
                  variant='secondary'
                  className='ml-auto'
                  label={t('actions.edit')}
                  onClick={() => onEdit(USER_FORM_STEPS.CONTACT)}
                  iconLeft={<PencilSimple size={16} />}
                />
              </ProtectedComponent>
            )}
          </div>
          <div className='mt-4 rounded-xl border px-4'>
            <InfoDetail
              isLoading={isFetchingUser}
              label={t('sessions.contact.email')}
              value={user?.email}
              className='border-b'
            />
            <InfoDetail
              isLoading={isFetchingUser}
              label={t(`sessions.contact.${user?.phone_type}`)}
              value={user?.phone}
            />
          </div>
        </div>
        <div>
          <div className='flex w-full items-center text-heading-sm text-neutralContent-primary'>
            {t('sessions.documents.title')}
            {isFetchingUser ? (
              <ButtonSkeleton />
            ) : (
              <ProtectedComponent permission={PermissionEnum.register_user}>
                <Button
                  size='sm'
                  variant='secondary'
                  className='ml-auto'
                  label={t('actions.edit')}
                  onClick={() => onEdit(USER_FORM_STEPS.DOCUMENTS)}
                  iconLeft={<PencilSimple size={16} />}
                />
              </ProtectedComponent>
            )}
          </div>
          <div className='mt-4 rounded-xl border px-4'>
            <InfoDetail
              isLoading={isFetchingUser}
              label={t('sessions.documents.document')}
              value={user?.document}
              className='border-b'
            />
            <InfoDetail
              isLoading={isFetchingUser}
              label={t(`sessions.documents.cns`)}
              value={user?.cns}
            />
          </div>
        </div>

        <div className='sm:col-span-2'>
          <div className='flex w-full items-center text-heading-sm text-neutralContent-primary '>
            {t('sessions.address.title')}
            {isFetchingUser ? (
              <ButtonSkeleton />
            ) : (
              <ProtectedComponent permission={PermissionEnum.register_user}>
                <Button
                  size='sm'
                  variant='secondary'
                  className='ml-auto'
                  label={t('actions.edit')}
                  onClick={() => onEdit(USER_FORM_STEPS.ADDRESS)}
                  iconLeft={<PencilSimple size={16} />}
                />
              </ProtectedComponent>
            )}
          </div>
          <div className='mt-4 flex items-center rounded-xl border px-4'>
            <MapPin size={24} className='mr-4' />
            <InfoDetail
              isLoading={isFetchingUser}
              label={`${user?.address || '-'}, ${user?.address_number || '-'} ${user?.complement}`}
              value={`${user?.district || '-'}, ${user?.city || '-'} - ${user?.state || '-'}, ${user?.cep || '-'}`}
            />
          </div>
        </div>

        <div className='sm:col-span-2'>
          <div className='flex w-full items-center text-heading-sm text-neutralContent-primary'>
            {t('sessions.professional_info.title')}
            {isFetchingUser ? (
              <ButtonSkeleton />
            ) : (
              <ProtectedComponent permission={PermissionEnum.register_user}>
                <Button
                  size='sm'
                  variant='secondary'
                  className='ml-auto'
                  label={t('actions.edit')}
                  onClick={() => onEdit(USER_FORM_STEPS.PROFESSIONAL_INFORMATION)}
                  iconLeft={<PencilSimple size={16} />}
                />
              </ProtectedComponent>
            )}
          </div>

          <div className='mt-6 text-heading-xs text-neutralContent-primary'>
            {t('sessions.professional_info.specialties')}
          </div>

          {!isFetchingUser && (
            <div className='mt-4 rounded-xl border px-4'>
              {user?.specialties?.map(({ specialty }) => (
                <InfoDetail
                  isLoading={isFetchingUser}
                  key={specialty?.id}
                  className='border-b'
                  label={specialty?.name || ''}
                  value={`${specialty?.council?.name} (${specialty?.council?.document_name})`}
                />
              ))}
            </div>
          )}

          <div className='mt-9 text-heading-xs text-neutralContent-primary'>
            {t('sessions.professional_info.records')}
          </div>

          {!isFetchingUser && (
            <div className='mt-4 rounded-xl border px-4'>
              {user?.councils?.map(({ council, document, uf }) => (
                <InfoDetail
                  isLoading={isFetchingUser}
                  key={council?.id}
                  label={council?.name || ''}
                  value={`${document}/${uf}`}
                  className='border-b'
                />
              ))}
            </div>
          )}
        </div>
        <div className='sm:col-span-2'>
          <div className='flex w-full items-center text-heading-sm text-neutralContent-primary'>
            {t('sessions.profile.title')}
            {isFetchingUser ? (
              <ButtonSkeleton />
            ) : (
              <ProtectedComponent permission={PermissionEnum.register_user}>
                <Button
                  size='sm'
                  variant='secondary'
                  className='ml-auto'
                  label={t('actions.edit')}
                  onClick={() => onEdit(USER_FORM_STEPS.PROFILE)}
                  iconLeft={<PencilSimple size={16} />}
                />
              </ProtectedComponent>
            )}
          </div>

          {!isFetchingUser && (
            <div className='mt-4 flex flex-col items-center rounded-xl border px-4'>
              {user?.profile && (
                <div className='p-4 text-label-md text-neutralContent-primary'>
                  {user?.profile?.name}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Content>
  )
}

const InfoDetail: React.FC<{
  label: string
  value?: string
  className?: string
  isLoading?: boolean
}> = ({ label, value, className, isLoading }) => {
  return (
    <div className={`flex flex-col py-4 ${className}`}>
      <label className='text-label-md text-neutralContent-primary'>{label}</label>
      {isLoading ? (
        <div className='mt-1 h-4 w-9/12 animate-pulse rounded bg-gray-200' />
      ) : (
        <span className='mt-1 text-body-sm text-neutralContent-secondary'>{value || '-'}</span>
      )}
    </div>
  )
}

export default UserDetailsPage
