const patient_detail_ptBR = {
  last_consultations: 'Últimos atendimentos',
  birth_date: 'Data de nascimento',
  weight: 'Peso',
  height: 'Altura',
  gender: 'Gênero',
  mother_name: 'Nome da mãe',
  race: 'Raça/cor',
  nationality: 'Nacionalidade',
  naturalness: 'Naturalidade',
  cpf: 'CPF',
  cns_number: 'Número do CNS',
  address_complete: 'Endereço completo',

  principal_email: 'E-mail principal',
  principal_phone: 'Telefone principal',
  alternative_phone: 'Telefone alternativo',
  rg: 'RG',
  rg_issuer: 'Órgão emissor',
  issuer_date: 'Data de emissão',

  basic_identification: 'Identificação básica',
  demographic_information: 'Dados demográficas',
  contact_information: 'Informações de contato',
  documentation: 'Documentação',
  address: 'Endereço',

  medications_in_use: 'Medicamentos em uso',
  allergies: 'Alergias',
  cronic_diseases: 'Condições crônicas',
  surgical_history: 'Histórico cirúrgico',

  medical_certificates: 'Atestados',
  prescriptions: 'Receitas',
  referrals: 'Encaminhamentos',
  test_requests: 'Solicitações de exames',
  medical_reports: 'Laudos',

  no_attach: 'Sem arquivos anexados',
  no_info: 'Sem informações',
  no_consultations: 'Sem consultas feitas para esse paciente',
  not_informed: 'Não informado',

  tabs: {
    resume: 'Resumo',
    personal_data: 'Dados pessoais',
    clinical_information: 'Informações clínicas',
    consultations: 'Atendimentos',
    document: 'Documentos'
  },
  types: {
    chat: 'Chat',
    elective_care: 'Eletivo',
    emergency_care: 'Pronto atendimento'
  },
  actions: {
    update: 'Editar',
    see_more: 'Ver mais'
  }
}

export default patient_detail_ptBR
