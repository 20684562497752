import React, { useEffect, useState } from 'react'
import SelectMonthCalendar from '../../../components/calendar/select-month-calendar'
import { useNewScaleForm, useGetScaleById } from '../../../hooks/useScale'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { formatMonthYear } from '../../../helpers/formatMonthYear'
import { ArrowLeft, FloppyDisk } from '@phosphor-icons/react'
import { useGetClient } from '../../../hooks/useClient'
import BlankCard from '../../../components/blank-card'
import { Scale, ScaleForm } from '../../../types'
import Button from '../../../components/button'
import Avatar from '../../../components/avatar'
import { useTranslation } from 'react-i18next'
import NewScaleForm from './new-scale-forms'
import { isSameDay, parse } from 'date-fns'
import { Loading } from 'semente-js'
import Content from '../../../components/content'

const ScaleFormPage: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('agendav2_list')
  const [selectedDates, setSelectedDates] = useState<Date[]>([])

  const params = useParams<Record<string, string | undefined>>()
  const { client_id, scale_id } = params || {}

  const [searchParams] = useSearchParams()
  const date = searchParams.get('date')
  const mode = scale_id === 'new' ? 'create' : 'update'

  const { data: clientData } = useGetClient(client_id)

  const { data: scaleData, isFetching } = useGetScaleById(scale_id)

  const mapScaleToScaleForm = (scale: Scale): ScaleForm => ({
    id: scale?.id,
    client_id,
    dates: [scale.date],
    duration: scale.duration,
    specialty_id: scale.specialty_id,
    units: scale.units.map(u => u.unit_id),
    time_intervals: scale.time_intervals,
    specialists: scale.specialists.map(s => ({
      specialist_id: s.specialist_id,
      price: s.price,
      time_intervals: s.time_intervals,
      specialist: s.specialist
    })),
    status: scale.status
  })

  const initialScaleForm = scaleData ? mapScaleToScaleForm(scaleData) : undefined

  const formik = useNewScaleForm(client_id as string, mode, initialScaleForm)

  useEffect(() => {
    if (date) {
      setSelectedDates([new Date(date)])
      formik.setFieldValue('dates', [date])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  useEffect(() => {
    if (selectedDates.length > 0) {
      formik.setFieldValue(
        'dates',
        selectedDates.map(date => date.toISOString().split('T')[0])
      )
    } else {
      formik.setFieldValue('dates', [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates])

  const toggleDateSelection = (day: Date) => {
    const isSelected = selectedDates?.some(selectedDate => isSameDay(selectedDate, day))
    if (isSelected) {
      setSelectedDates((selectedDates || []).filter(selectedDate => !isSameDay(selectedDate, day)))
    } else {
      setSelectedDates([...(selectedDates || []), day])
    }
  }

  if (isFetching) return <Loading />

  return (
    <Content title=''>
      <div
        className={`grid h-[calc(100vh-180px)]  gap-6 ${mode === 'create' ? 'grid-cols-[260px,1px,1fr]' : 'grid-cols-1'}`}
      >
        {mode === 'create' && (
          <>
            <div className='flex flex-col gap-6'>
              <Button
                size='sm'
                label='Voltar'
                variant='tertiary'
                className='w-fit'
                iconLeft={<ArrowLeft />}
                onClick={() => navigate(-1)}
              />

              <span className='text-label-md text-neutralBorder-brand'>
                Selecione as datas da escala:
              </span>

              <SelectMonthCalendar
                selectedDates={selectedDates}
                onUpdateDates={setSelectedDates}
                onSelectDate={toggleDateSelection}
                dateOfMonth={date ? new Date(date) : new Date()}
              />
            </div>

            <div className='h-full bg-gray-300' />
          </>
        )}

        <div className='relative h-full overflow-hidden px-3'>
          <Button
            color=''
            size='sm'
            label='Voltar'
            variant='tertiary'
            iconLeft={<ArrowLeft />}
            onClick={() => navigate(-1)}
          />

          <div className='flex max-h-[calc(100vh-165px)] min-h-[300px] w-full flex-col gap-5 overflow-y-auto pb-[200px]'>
            <div className='flex items-center justify-between'>
              <span className='text-center text-heading-md text-neutralContent-primary'>
                {mode === 'create'
                  ? t('new_scale')
                  : formatMonthYear(parse(scaleData!.date!, 'yyyy-MM-dd', new Date()), true)}
              </span>

              <BlankCard className='flex h-14 items-center justify-center'>
                <div className='flex items-center justify-center gap-3'>
                  <Avatar name={clientData?.name} size='sm' />
                  <span className='text-label-md text-neutralContent-primary'>
                    {clientData?.name || ''}
                  </span>
                </div>
              </BlankCard>
            </div>

            <NewScaleForm client={clientData!} formik={formik} scaleData={scaleData ?? undefined} />
          </div>

          <div className='absolute bottom-0 left-0 right-0 bg-white pt-4'>
            <Button
              className='ml-auto'
              iconLeft={<FloppyDisk />}
              isLoading={formik.isSubmitting}
              onClick={() => formik.handleSubmit()}
              state={!formik.isValid || formik.isSubmitting ? 'disable' : 'enable'}
              label={mode === 'create' ? t('actions.create_scale') : t('actions.update_scale')}
            />
          </div>
        </div>
      </div>
    </Content>
  )
}

export default ScaleFormPage
