import { ReactNode } from 'react'

interface Props {
  title: string | ReactNode
  subtitle?: string | ReactNode
  leftContent?: ReactNode
  rightContent?: ReactNode
  className?: string
  onClick?: () => void
  placeholder?: string
}

const Item: React.FC<Props> = ({
  title,
  subtitle,
  leftContent,
  rightContent,
  className,
  onClick,
  placeholder
}) => {
  const handleClick = () => {
    onClick?.()
  }

  return (
    <>
      {!!placeholder && <span>{placeholder}</span>}
      <div className={`flex items-center ${className}`} onClick={handleClick}>
        <div className='flex items-center justify-start gap-1'>
          {leftContent}
          <div className='flex flex-col'>
            <label className='text-label-md text-neutralContent-primary'>{title}</label>
            {!!subtitle && (
              <span className='text-body-sm text-neutralContent-secondary'>{subtitle}</span>
            )}
          </div>
        </div>
        {rightContent}
      </div>
    </>
  )
}

export default Item
