import { useSearchParams } from 'react-router-dom'
import Content from '../../components/content'
import { useAuth } from '../../contexts/auth'
import { useEffect } from 'react'
import { useToastContext } from '../../contexts/toast'
import { useTranslation } from 'react-i18next'

const HomePage = () => {
  const { t } = useTranslation('home')
  const { user } = useAuth()
  const [params, setParams] = useSearchParams()
  const { toast } = useToastContext()

  useEffect(() => {
    if (params.get('error')) {
      toast.error(params.get('error') || '')
      params.delete('error')
      setParams(params)
    }
  }, [params, setParams, toast])

  const getSalutation = () => {
    const hours = new Date().getHours()
    if (hours >= 5 && hours < 12) return 'good_morning'
    if (hours < 18) return 'good_afternoon'
    return 'good_night'
  }

  return (
    <Content title={t(getSalutation(), { name: user?.name || '' })}>
      <></>
    </Content>
  )
}

export default HomePage
