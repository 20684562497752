const user_form_ptBR = {
  PERSONAL_INFORMATION: {
    title: 'Informações pessoais',
    subtitle: 'Preencha os dados pessoais do usuário'
  },
  CONTACT: {
    title: 'Contato',
    subtitle: 'Preencha os dados de contato do usuário'
  },
  ADDRESS: {
    title: 'Endereço',
    subtitle: 'Preencha os dados de endereço do usuário'
  },
  DOCUMENTS: {
    title: 'Documentos',
    subtitle: 'Preencha os documentos do usuário'
  },
  PROFESSIONAL_INFORMATION: {
    title: 'Informações profissionais',
    subtitle: 'Preencha os dados de profissão do usuário'
  },
  PROFILE: {
    title: 'Perfis de acesso',
    subtitle: 'Selecione o perfil de acesso desse usuário na plataforma'
  },
  fields: {
    name: 'Nome completo',
    social_name: 'Nome social (opcional)',
    birthdate: 'Data de nascimento',
    mother_unknown: 'Mãe desconhecida',
    mother_name: 'Nome completo da mãe',
    sex: 'Sexo',
    gender: 'Gênero (opcional)',
    other_gender: 'Qual o genêro?',
    other_sex: 'Qual o sexo?',
    race: 'Raça/Cor (opcional)',
    nationality: 'Nacionalidade',
    birth_state: 'Estado de nascimento',
    birth_city: 'Município de nascimento',
    naturalization_date: 'Data de naturalização',
    birth_country: 'País de nascimento',
    passport_country: 'País emissor do passaporte',
    passport_number: 'Número do passaporte',
    user_from_client: 'Usuário de um cliente',
    clients: 'Clientes',
    select_clients: 'Selecione os clientes que este usuário poderá visualizar',
    select_clients_modal: 'Selecione os clientes',
    units_selected: '{{value}} Unidades selecionadas',
    select_units_modal: 'Selecione as unidades',

    email: 'Email',
    phone: 'Número de telefone',
    cell: 'Número de celular',
    phone_type: 'Tipo de telefone',

    address: 'Logradouro (opcional)',
    address_number: 'Número (opcional)',
    complement: 'Complemento (opcional)',
    state: 'Estado (opcional)',
    city: 'Cidade (opcional)',
    district: 'Bairro (opcional)',

    no_image_selected: 'Nenhuma imagem selecionada',
    uploading: 'Carregando...',
    completed: 'Concluída',
    error: 'Erro ao carregar',

    document: 'CPF',
    cns: 'CNS',

    is_health_professional: 'O usuário é um profissional de saúde',
    specialties: 'Especialidades médica',
    select_specialties: 'Selecione as especialidades',
    records: {
      title: 'Registros',
      subtitle: 'Insira as registros solicitados para cada conselho',
      description: 'Esse é o conselho regional das especialidades acima',
      register_number: 'Número de registro',
      uf: 'UF'
    },
    profile: 'Perfil',
    select_client_profile: 'Selecione o perfil de acesso',
    no_profile_selected: 'Nenhum perfil selecionado'
  },
  selects: {
    nationality: {
      BR: 'Brasileiro',
      OTHER: 'Outra'
    },
    sexes: {
      masculine: 'Masculino',
      feminine: 'Feminino',
      other: 'Outros'
    },
    genders: {
      cisgender_woman: 'Mulher cisgênero',
      transgender_woman: 'Mulher transgênero',
      cisgender_man: 'Homem cisgênero',
      transgender_man: 'Homem transgênero',
      non_binary: 'Não-binário',
      other: 'Outro'
    },
    races: {
      white: 'Branca',
      black: 'Preta',
      brown: 'Parda',
      yellow: 'Amarela',
      indigenous: 'Indígena',
      no_information: 'Sem Informação'
    },
    phone_types: {
      cell: 'Celular',
      phone: 'Fixo'
    }
  },
  actions: {
    save: 'Salvar',
    cancel: 'Cancelar',
    continue: 'Continue',
    finish: 'Finalizar cadastro',
    select: 'Selecionar',
    remove: 'Remover',
    see_units: 'Ver unidades',
    select_profile: 'Selecionar perfil',
    change_file: 'Trocar arquivo',
    select_file: 'Selecionar arquivo'
  }
}

export default user_form_ptBR
