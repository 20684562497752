import { useTranslation } from 'react-i18next'
import Button from '../../../components/button'
import ContentFooter from '../../../components/content/footer'
import { useUserContactFormik } from '../../../hooks/forms/useUserForm'
import ContentForm from '../../../components/content/form'
import InputField from '../../../components/input'
import Chip from '../../../components/chip'
import { User } from '../../../types'
import InputSkeleton from '../../../components/input/skeleton'
import ButtonSkeleton from '../../../components/button/skeleton'

type Props = {
  isUpdate: boolean
  isLoading: boolean
  user: Partial<User>
  onUpdate: (values: Partial<User>) => void
  onSave: (values: Partial<User>) => void
  onCancel: () => void
}

const UserContactForm: React.FC<Props> = ({
  onSave,
  onCancel,
  isUpdate,
  onUpdate,
  user,
  isLoading
}) => {
  const { t } = useTranslation('user_form')
  const formik = useUserContactFormik(isUpdate ? onUpdate : onSave, user)

  return (
    <form onSubmit={formik.handleSubmit}>
      {isLoading ? (
        <Skeleton />
      ) : (
        <ContentForm hasSubtitle hasBackButton>
          <InputField
            id='email'
            name='email'
            type='text'
            label={t('fields.email')}
            onBlur={formik.handleBlur}
            value={formik.values.email!}
            onChange={formik.handleChange}
            state={formik.touched.email && formik.errors.email ? 'error' : 'default'}
            hint={formik.touched.email && formik.errors.email ? formik.errors.email : undefined}
          />

          <div />

          <div>
            <label className='label-md text-neutralContent-primary'>{t('fields.phone_type')}</label>
            <div className='mt-1 flex w-full gap-2'>
              <Chip
                size='xl'
                className='flex-1'
                label={t('selects.phone_types.cell')}
                isSelected={formik.values.phone_type === 'cell'}
                onClick={() => formik.setFieldValue('phone_type', 'cell')}
              />

              <Chip
                size='xl'
                className='flex-1'
                label={t('selects.phone_types.phone')}
                isSelected={formik.values.phone_type === 'phone'}
                onClick={() => formik.setFieldValue('phone_type', 'phone')}
              />
            </div>
          </div>

          <InputField
            id='phone'
            name='phone'
            type='phone'
            onBlur={formik.handleBlur}
            value={formik.values.phone!}
            onChange={formik.handleChange}
            label={t(`fields.${formik.values.phone_type}`)}
            state={formik.touched.phone && formik.errors.phone ? 'error' : 'default'}
            hint={formik.touched.phone && formik.errors.phone ? formik.errors.phone : undefined}
          />
        </ContentForm>
      )}
      <ContentFooter>
        {isLoading ? (
          <ButtonSkeleton className='ml-0 mr-auto' />
        ) : (
          <Button
            size='md'
            type='button'
            onClick={onCancel}
            variant='secondary'
            label={t('actions.cancel')}
            isLoading={formik.isSubmitting}
          />
        )}
        {isLoading ? (
          <ButtonSkeleton />
        ) : (
          <Button
            size='md'
            type='submit'
            className='ml-auto'
            label={t(isUpdate ? 'actions.save' : 'actions.continue')}
            isLoading={formik.isSubmitting}
            state={!formik.isValid ? 'disable' : 'enable'}
          />
        )}
      </ContentFooter>
    </form>
  )
}

const Skeleton = () => {
  return (
    <ContentForm hasSubtitle hasBackButton>
      <InputSkeleton showLabel /> <div />
      <InputSkeleton showLabel />
      <InputSkeleton showLabel />
    </ContentForm>
  )
}

export default UserContactForm
