import React from 'react'

const DashboardCardSkeleton: React.FC = () => {
  return (
    <div className='flex w-full animate-pulse items-center gap-3 rounded-xl border-[1px] border-solid border-neutralBorder-default p-3'>
      <div className='flex h-9 w-9 items-center justify-center rounded-lg bg-neutralBorder-default' />
      <div className='h-2 w-1/2 rounded bg-neutralBorder-default' />
    </div>
  )
}

export default DashboardCardSkeleton
