import React from 'react'

interface SwitchProps {
  isChecked: boolean
  onChange: () => void
  disabled?: boolean
  className?: string
}

const Switch: React.FC<SwitchProps> = ({ isChecked, onChange, disabled = false, className }) => {
  return (
    <label className={`relative flex items-center justify-between text-xl ${className}`}>
      <input
        disabled={disabled}
        type='checkbox'
        className='peer absolute left-1/2 h-full w-full -translate-x-1/2 cursor-pointer appearance-none rounded-md'
        checked={isChecked}
        onChange={onChange}
      />
      <span className='ml-4 flex h-8 w-[52px] flex-shrink-0 cursor-pointer items-center rounded-full bg-neutralBackground-tertiary p-1 duration-300 ease-in-out after:h-7 after:w-7 after:rounded-full after:bg-white after:shadow-lg after:duration-300 hover:after:opacity-90 peer-checked:bg-primaryBrand-primary peer-checked:after:translate-x-[18px]' />
    </label>
  )
}

export default Switch
