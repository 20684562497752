import React, { useCallback, useEffect, useState } from 'react'
import FileService from '../../../../../../services/file.service'
import Modal from '../../../../../../components/modal'
import { Button } from 'semente-js'
import { FileContent } from './file-content'
import { FileType, ViewFileModalProps } from './types'

const videoExtensions = ['mp4', 'avi', 'mov', 'mkv', 'webm']
const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp']

const getExtensionType = (fileExtension: string): FileType | null => {
  if (videoExtensions.includes(fileExtension)) return 'video'
  if (imageExtensions.includes(fileExtension)) return 'image'
  return null
}

export const ViewFileModal: React.FC<ViewFileModalProps> = ({
  onClose,
  document,
  onPreviousFile,
  onNextFile
}) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null)
  const [type, setType] = useState<FileType | null>(null)

  const [hasError, setHasError] = useState<boolean>(false)
  const [isFileSupported, setIsFileSupported] = useState<boolean>(true)
  const [isCached, setIsCached] = useState<boolean>(false)

  const reset = () => {
    setHasError(false)
    setIsFileSupported(true)
    setFileUrl(null)
    setIsCached(false)
  }

  const handleTypeNotSupported = () => {
    setType(null)
    setHasError(false)
    setIsFileSupported(false)
  }

  const handleClose = () => {
    onClose()
  }

  const handleError = () => {
    if (!isCached) {
      setHasError(true)
      return
    }

    sessionStorage.removeItem(document.id!)

    loadFile()
      .then(r => r)
      .catch(() => {
        setHasError(true)
      })
  }

  const handleFile = useCallback((fileUrl: string, fileName: string): void => {
    const fileExtension = fileName.split('.').pop()?.toLowerCase()
    if (!fileExtension) return
    const type = getExtensionType(fileExtension)

    if (!type) {
      handleTypeNotSupported()
      return
    }
    setType(type)
    setFileUrl(fileUrl)
  }, [])

  const loadFile = useCallback(
    async (signal?: AbortSignal) => {
      const cachedData = sessionStorage.getItem(document.id!)
      if (cachedData) {
        const { fileUrl, fileName } = JSON.parse(cachedData)
        setIsCached(true)
        handleFile(fileUrl, fileName)
        setFileUrl(fileUrl)
        return
      }

      if (signal?.aborted) return
      const fileUrl = await FileService.get(document.id!)
      setFileUrl(fileUrl)

      if (!document.file_name) return

      handleFile(fileUrl, document.file_name)

      sessionStorage.setItem(
        document.id!,
        JSON.stringify({
          fileUrl,
          fileName: document.file_name
        })
      )
    },
    [document.file_name, document.id, handleFile]
  )

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (!document?.id) {
      return () => {
        controller.abort()
      }
    }

    if (signal.aborted) {
      return () => {}
    }

    loadFile(signal)
      .then(r => r)
      .catch(() => {
        setHasError(true)
      })

    return () => {
      controller.abort()
    }
  }, [document?.id, loadFile])

  return (
    <Modal
      isOpen
      onClose={handleClose}
      title='Visualizar arquivo'
      className={'flex h-[80%] w-[80%] flex-col overflow-visible'}
      contentClassName='flex max-h-full h-full flex-col justify-space-between gap-[20px] overflow-hidden'
    >
      <div className='flex max-h-[85%] max-w-full flex-1 items-center justify-center overflow-hidden'>
        {hasError && <p>Não foi possível carregar o arquivo.</p>}

        {!hasError && (
          <FileContent
            fileUrl={fileUrl}
            handleError={handleError}
            type={type}
            document={document}
            isSupported={isFileSupported}
          />
        )}
      </div>
      <div className='flex w-full justify-center gap-[33%] text-center'>
        <Button
          layout='circle'
          className={'bg-gray-400'}
          iconName={'arrow-left'}
          onClick={() => {
            onPreviousFile()
            reset()
          }}
        />

        <Button
          layout='circle'
          className={'bg-gray-400'}
          iconName={'arrow-right'}
          onClick={() => {
            onNextFile()
            reset()
          }}
        />
      </div>
    </Modal>
  )
}
