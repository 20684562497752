import AccordionSkeleton from '../../../components/accordion/skeleton'
import ButtonSkeleton from '../../../components/button/skeleton'
import CheckboxSkeleton from '../../../components/checkbox/skeleton'
import ContentFooter from '../../../components/content/footer'
import InputSkeleton from '../../../components/input/skeleton'

const UpdateAccessProfileSkeleton: React.FC = () => {
  return (
    <div className='flex flex-col gap-6'>
      <div className='flex w-full gap-4'>
        <InputSkeleton showLabel className='flex-1' />
        <InputSkeleton showLabel className='flex-1' />
      </div>

      <div>
        <div className='mb-4'>
          <div className='w-60 animate-pulse rounded bg-gray-200 p-4' />
        </div>

        <AccordionSkeleton>
          <div className='space-y-4'>
            <CheckboxSkeleton />
            <CheckboxSkeleton />
            <CheckboxSkeleton />
          </div>
        </AccordionSkeleton>

        <AccordionSkeleton>
          <div className='space-y-4'>
            <CheckboxSkeleton />
            <CheckboxSkeleton />
            <CheckboxSkeleton />
          </div>
        </AccordionSkeleton>
      </div>

      <ContentFooter className='w-full'>
        <ButtonSkeleton className='w-20' />
      </ContentFooter>
    </div>
  )
}

export default UpdateAccessProfileSkeleton
