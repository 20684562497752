import { ArrowLeft, ArrowRight } from '@phosphor-icons/react'

type Props = {
  onClickPreviousMonth: VoidFunction
  onClickNextMonth: VoidFunction
  date: Date
}

const MonthSelectCalendar: React.FC<Props> = ({ onClickNextMonth, onClickPreviousMonth, date }) => {
  const formatMonthYear = (date: Date) => {
    const formattedDate = date.toLocaleString('pt-BR', { month: 'long', year: 'numeric' })
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
  }

  return (
    <div className='flex items-center gap-4 '>
      <button onClick={onClickPreviousMonth}>
        <ArrowLeft className='text-primaryBrand-primary' size={18} />
      </button>
      <span className='text-center text-heading-md  text-neutralContent-primary'>
        {formatMonthYear(date)}
      </span>
      <button onClick={onClickNextMonth}>
        <ArrowRight className='text-primaryBrand-primary' size={18} />
      </button>
    </div>
  )
}

export default MonthSelectCalendar
