const transfer_consultation_ptBR = {
  title: 'Transferir atendimentos',
  subtitle: 'Transfira um ou mais atendimentos de um profissional especialista para outro',
  from: 'Transferir de',
  to: 'Para',
  fields: {
    title: 'Selecione o especialista',
    search_placeholder: 'Procure por um especialista'
  },
  columns: {
    date: 'Data',
    patient: 'Paciente',
    consultation: 'Atendimento',
    status: 'Status',
    unit: 'Unidade',
    consultation_type: {
      elective: 'Eletivo',
      urgency: 'Urgencia'
    },
    status_type: {
      SCHEDULED: 'Agendado'
    }
  },
  actions: {
    select: 'Selecionar profissional',
    cancel: 'Cancelar',
    transfer: 'Transferir atendimentos',
    confirm: 'Confirmar',
    search_placeholder: 'Busque por um paciente'
  }
}

export default transfer_consultation_ptBR
