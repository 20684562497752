import React from 'react'
import { Dashboard } from '../../types'
import { IconProps } from '@phosphor-icons/react'
import DashboardCardSkeleton from './skeleton'

interface Props {
  item: Dashboard & { Icon: React.FC<IconProps> }
  onClick?: () => void
}

const DashboardCard: React.FC<Props> = ({ item, onClick }) => {
  return 'id' in item ? (
    <button
      onClick={onClick}
      className='flex w-full items-center gap-3 rounded-xl border-[1px] border-solid border-neutralBorder-default p-3'
    >
      <div className='flex h-9 w-9 items-center justify-center rounded-lg bg-primaryBrand-dark'>
        <item.Icon className='size-4 text-white' />
      </div>

      <span className='text-base font-bold text-neutralContent-primary'>{item.name}</span>
    </button>
  ) : (
    <DashboardCardSkeleton />
  )
}

export default DashboardCard
