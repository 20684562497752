const agenda_form_enUS = {
  choose_agenda_type: 'What type of schedule do you want to create?',
  fixed: {
    title: 'Fixed Schedule',
    description: `Choose the days of the week and fixed times when the professional will be available for appointments.<br /><br />Ideal for professionals who maintain a regular weekly appointment routine`
  },
  custom: {
    title: 'Custom Schedule',
    description:
      'Choose specific days and times, without following a fixed routine, when the professional will be available for appointments.<br /><br />Ideal for professionals with variable appointment times that change from week to week'
  },
  event_form: {
    from: 'From',
    until: 'Until',
    unit: 'Unit',
    select: 'Select',
    duration: 'Appointment Duration'
  },
  fixed_form: {
    title: 'Create Fixed Schedule',
    subtitle:
      'Choose the days of the week and fixed times when the professional will be available for appointments',
    fields: {
      professional: 'Professional',
      period_label: 'Attendance period',
      empty: '‎',
      period_start: 'Start date',
      period_end: 'End date',
      title_availability: 'Set Availability for Appointments',
      subtitle_availability:
        'The days and times will be made available for scheduling according to the defined settings',
      add_period: 'Add period'
    }
  },
  custom_form: {
    title: 'Create Custom Schedule',
    subtitle:
      'Choose specific days and times, without following a fixed routine, when the professional will be available for appointments'
  },
  actions: {
    select_an_option: 'Select an option',
    create_agenda: 'Create agenda'
  },
  days_of_week: {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    0: 'Sunday'
  }
}

export default agenda_form_enUS
