import { ClientDomain } from '../contexts/auth'
import { Client, PaginationParams, PaginationResponse, Unit, User } from '../types'
import api from './api.service'
import { validate } from 'uuid'

export type ListUnitParams = {
  specialty_id?: string
  getFromUser?: boolean
  user?: User | null
} & PaginationParams

class ClientService {
  static create(body: Partial<Client>) {
    return api.post<User>('/client', body)
  }

  static async getByDomain() {
    const domain = window.location.hostname
    const response = await api.get<ClientDomain>('/client/domain/' + domain)
    return response.data
  }

  static async getById(id: string) {
    if (!validate(id)) return undefined

    const response = await api.get<Client>('/client/' + id)
    return response.data
  }

  static async countUnits(id: string) {
    if (!validate(id)) return undefined

    const response = await api.get<{ total: number; limit: number }>(
      '/client/' + id + '/count_units'
    )
    return response.data
  }

  static async list(params: PaginationParams) {
    const response = await api.get<PaginationResponse<Client>>('/client', {
      params
    })
    return response.data
  }

  static async getUnitById(unit_id: string, client_id: string) {
    const response = await api.get<Unit>(`/client/${client_id}/unit/${unit_id}`)
    return response.data
  }

  static async listUnits(client_id: string, params?: ListUnitParams) {
    const response = await api.get<PaginationResponse<Unit>>(`/client/${client_id}/unit`, {
      params
    })
    return response.data
  }

  static update(id: string, body: Partial<Client>) {
    return api.put<User>('/client/' + id, body)
  }

  static activation(id: string) {
    return api.put<Client>(`/client/${id}/activation`)
  }

  static activationUnit(id: string) {
    return api.put<Unit>(`/clients/units/${id}/activation`)
  }

  static createUnit(client_id: string, body: Partial<Unit>) {
    return api.post('/client/' + client_id + '/unit', body)
  }

  static updateUnit(id: string, body: Partial<Unit>) {
    return api.put<Unit>('/client/unit/' + id, body)
  }
}

export default ClientService
