import user_form from './user-form/pt-BR'
import user_list from './user-list/pt-BR'
import user_details from './user-details/pt-BR'
import client_form from './client-form/pt-BR'
import profiles from './profiles/pt-BR'
import permissions_units from './permissions-units/pt-BR'
import agenda_list from './agenda-list/pt-BR'
import agenda_form from './agenda-form/pt-BR'
import unit_form from './unit-form/pt-BR'
import unit_detail from './unit-detail/pt-BR'
import unit_list from './unit_list/pt-BR'
import client_list from './client-list/pt-BR'
import consultation_list from './consultation-list/pt-BR'
import consultation_form from './consultation-form/pt-BR'
import consultation_details from './consultation-details/pt-BR'
import patient_list from './patient-list/pt-BR'
import patient_form from './patient-form/pt-BR'
import transfer_consultation from './transfer-consultation/pt-BR'
import anamnesis from './anamnesis/pt-BR'
import anamnesis_list from './anamnese-list/pt-BR'
import anamnesis_form from './anamnese_form/pt-BR'
import unit_type from './unit-type/pt-BR'
import consultation_follow_up from './consultation-follow-up/pt-BR'
import agendav2_list from './agenda-v2-list/pt-BR'
import patient_detail from './patient_detail/pt-BR'

const ptBR = {
  languages: {
    ptBR: 'Português',
    enUS: 'Inglês'
  },
  main_menu: {
    home: 'Início',
    agenda: 'Agenda',
    consultation: 'Atendimentos',
    elective_consultation: 'Eletivos',
    emergency_consultation: 'Pronto atendimentos',
    chat_consultation: 'Chat',
    accesses: 'Acessos',
    clients: 'Clientes',
    users: 'Usuários',
    patients: 'Pacientes',
    management: 'Gestão',
    profile_access: 'Perfis de Acesso',
    unit_type: 'Tipos de Unidade',
    councils: 'Conselhos',
    specialties: 'Especialidades',
    anamnesis_template: 'Templates de Anamnese',
    pending_consultation: 'Pendentes',
    in_progress_consultation: 'Em andamento',
    dashboards: 'Relatórios'
  },
  user_menu: {
    my_account: 'Minha conta',
    update_password: 'Atualizar senha',
    logout: 'Sair'
  },
  home: {
    good_morning: 'Bom dia, {{name}}.',
    good_afternoon: 'Boa tarde, {{name}}.',
    good_night: 'Boa noite, {{name}}.'
  },
  calendar: {
    week_day: {
      0: 'Domingo',
      1: 'Segunda',
      2: 'Terça',
      3: 'Quarta',
      4: 'Quinta',
      5: 'Sexta',
      6: 'Sabado'
    }
  },
  profiles,
  user_form,
  user_list,
  user_details,
  client_form,
  permissions_units,
  agenda_list,
  agenda_form,
  unit_form,
  unit_detail,
  unit_list,
  client_list,
  consultation_list,
  consultation_form,
  consultation_details,
  patient_list,
  patient_form,
  transfer_consultation,
  anamnesis,
  anamnesis_list,
  anamnesis_form,
  unit_type,
  consultation_follow_up,
  agendav2_list,
  patient_detail
}

export default ptBR
