import React, { createContext, ReactNode, useContext, useState } from 'react'

interface MenuContextProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

const MenuContext = createContext<MenuContextProps | undefined>(undefined)

export const MenuProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return <MenuContext.Provider value={{ isOpen, setIsOpen }}>{children}</MenuContext.Provider>
}

export const useMenu = () => {
  const context = useContext(MenuContext)
  if (!context) {
    throw new Error('useMenu must be used within a FileProvider')
  }
  return context
}
