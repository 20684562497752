import React from 'react'
import BlankCard from '../../../../components/blank-card'
import {
  ArrowsVertical,
  Cake,
  CalendarBlank,
  Chats,
  GenderFemale,
  GenderMale,
  GenderNeuter,
  HandbagSimple,
  Hospital,
  IdentificationCard,
  Webcam
} from '@phosphor-icons/react'
import { CARE_TYPE, CONSULTATION_STATUS, Patient } from '../../../../types'
import { format, isPast } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useGetConsultations } from '../../../../hooks/usePatient'
import Tag from '../../../../components/tag'
import { getTimeDiff } from '../../../../helpers/getTimeDiff'
const Resume: React.FC<{
  id: string
  patientData: Patient | null | undefined
}> = ({ id, patientData }) => {
  const { t } = useTranslation('patient_detail')
  const { data, isFetching } = useGetConsultations(id as string)

  const getIconGender = () => {
    if (patientData?.gender?.includes('Mulher')) {
      return <GenderFemale size={20} />
    } else if (patientData?.gender?.includes('Homem')) {
      return <GenderMale size={20} />
    }
    return <GenderNeuter size={20} />
  }

  const lastThreeConsultations = data?.consultations
    ? [...data.consultations]
        .sort((a, b) => new Date(b.created_at!).getTime() - new Date(a.created_at!).getTime())
        .slice(0, 3)
    : []

  console.log('date', patientData?.birthdate)

  return (
    <div>
      {!isFetching ? (
        <>
          <div className='flex flex-wrap gap-4'>
            <BlankCard className='flex h-16 w-64 items-center gap-4'>
              <Cake size={20} />
              <div className='flex flex-col'>
                <span className='text-label-sm text-neutralContent-primary'>
                  {patientData?.birthdate
                    ? new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                        new Date(patientData?.birthdate)
                      )
                    : t('not_informed')}
                </span>
                <span className='text-body-sm text-neutralContent-secondary'>
                  {t('birth_date')}
                </span>
              </div>
            </BlankCard>
            <BlankCard className='flex h-16 w-64 items-center gap-4'>
              {getIconGender()}
              <div className='flex flex-col'>
                <span className='text-label-sm text-neutralContent-primary'>
                  {!!patientData?.gender ? patientData?.gender : t('not_informed')}
                </span>
                <span className='text-body-sm text-neutralContent-secondary'>{t('gender')}</span>
              </div>
            </BlankCard>

            <BlankCard className='flex h-16 w-64 items-center gap-4'>
              <ArrowsVertical size={20} />
              <div className='flex flex-col'>
                <span className='text-label-sm text-neutralContent-primary'>
                  {!!patientData?.height ? `${patientData?.height}cm` : t('not_informed')}
                </span>
                <span className='text-body-sm text-neutralContent-secondary'>{t('height')}</span>
              </div>
            </BlankCard>

            <BlankCard className='flex h-16 w-64 items-center gap-4'>
              <HandbagSimple size={20} />
              <div className='flex flex-col'>
                <span className='text-label-sm text-neutralContent-primary'>
                  {!!patientData?.weight ? `${patientData?.weight}kg` : t('not_informed')}
                </span>
                <span className='text-body-sm text-neutralContent-secondary'>{t('weight')}</span>
              </div>
            </BlankCard>
          </div>

          <div className='mt-9'>
            <span className='text-heading-sm'>{t('last_consultations')}</span>

            <div className='mt-2 flex flex-wrap gap-2'>
              {lastThreeConsultations?.map((consultation, index) => (
                <BlankCard key={index.toString()} className='h-36 w-80 '>
                  <div className='flex justify-between gap-3'>
                    <div className='flex gap-2'>
                      <div className='inline-flex'>
                        <Tag
                          label={
                            consultation.care_type === CARE_TYPE.CHAT ? (
                              <Chats size={12} />
                            ) : (
                              <Webcam size={12} />
                            )
                          }
                          variant='secondary'
                          color={
                            consultation.care_type === CARE_TYPE.ELECTIVE_CARE ? 'blue' : 'red'
                          }
                        />
                      </div>
                      <div>
                        <span
                          className={`text-label-md ${consultation.care_type === CARE_TYPE.ELECTIVE_CARE ? 'text-primaryBrand-primary' : 'text-highlightRed-pure'}`}
                        >
                          {t(`types.${consultation.care_type}`)}
                        </span>
                      </div>
                      <div>
                        <Tag
                          size={'xs'}
                          color='gray'
                          variant='tertiary'
                          label={'#' + consultation.code}
                        />
                      </div>
                    </div>
                    <div className='inline-flex h-5'>
                      {consultation.status === CONSULTATION_STATUS.IN_ROOM ? (
                        <Tag label='Em andamento' color='green' size='xs' />
                      ) : isPast(new Date(consultation.start_date || consultation.created_at!)) ? (
                        <Tag
                          label={
                            getTimeDiff(
                              new Date(consultation.start_date || consultation.created_at!),
                              new Date()
                            ) + ' em espera'
                          }
                          color='red'
                          size='xs'
                        />
                      ) : (
                        <Tag
                          label={
                            'Iniciará em ' +
                            getTimeDiff(
                              new Date(consultation.start_date || consultation.created_at!),
                              new Date()
                            )
                          }
                          color='blue'
                          size='xs'
                        />
                      )}
                    </div>
                  </div>

                  <div className='mt-3 flex-col gap-2'>
                    <div className='flex items-center gap-2'>
                      <IdentificationCard size={16} />
                      <span className='text-body-sm'>{consultation.specialty?.name ?? 'n/a'}</span>
                    </div>

                    <div className='flex items-center gap-2'>
                      <CalendarBlank size={16} />
                      <span className='text-body-sm'>
                        {consultation.created_at
                          ? format(new Date(consultation.created_at), 'dd/MM/yyyy')
                          : 'n/a'}
                      </span>
                    </div>

                    <div className='flex items-center gap-2'>
                      <Hospital size={16} />
                      <span className='text-body-sm'>{consultation.unit?.name ?? 'n/a'}</span>
                    </div>
                  </div>
                </BlankCard>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className='flex gap-3'>
          {Array.from({ length: 4 }, (_, index_row) => (
            <div
              key={index_row}
              className='h-12 w-64 animate-pulse space-y-4 rounded-xl border border-neutralBorder-default p-4'
            >
              <div className='h-3 w-3/4 rounded bg-neutralBorder-default' />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Resume
