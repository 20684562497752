import { PaperPlaneTilt } from '@phosphor-icons/react'
import Button from '../../../../components/button'
import TextareaField from '../../../../components/text-area'

type MessageInputProps = {
  message: string
  setMessage: React.Dispatch<React.SetStateAction<string>>
  handleSendMessage: () => void
  handleKeyPress: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void
  placeholder: string
  buttonLabel: string
}

export const MessageInput: React.FC<MessageInputProps> = ({
  message,
  setMessage,
  handleSendMessage,
  handleKeyPress,
  placeholder,
  buttonLabel
}) => {
  return (
    <div>
      <TextareaField
        value={message}
        onChange={e => setMessage(e.target.value)}
        placeholder={placeholder}
        onKeyPress={handleKeyPress}
        state={'default'}
      />
      <div className='mt-4 flex justify-end'>
        <Button
          label={buttonLabel}
          onClick={handleSendMessage}
          iconLeft={<PaperPlaneTilt size={18} />}
        />
      </div>
    </div>
  )
}
