import React, { forwardRef, useEffect, useState } from 'react'
import InputField from '../../../../../components/input'
import { Props } from '../types'
import DatePickerWithCalendar from '../../../../../components/datepicker'
import Dropdown, { DropdownOption } from '../../../../../components/dropdown'
import { useTranslation } from 'react-i18next'
import { genders } from '../../../../users/user-form/user-personal-information-form'
import * as DocumentForms from './documents'
import { usePatientGeneralFormik } from '../../../../../hooks/forms/usePatientForm'
import { SpinnerGap } from '@phosphor-icons/react'

export const document_types = {
  rg: DocumentForms['GeneralRegister'],
  passport: DocumentForms['Passaport']
}

const PatientGeneralForm = forwardRef<HTMLButtonElement, Props>(
  ({ handleSubmit, handleStateSubmit, value }, ref) => {
    const [_, setIsOtherGender] = useState(false)
    const formik = usePatientGeneralFormik(handleSubmit, value)
    const { t } = useTranslation('patient_form')

    useEffect(() => {
      handleStateSubmit(!formik.isValid)
    }, [formik.isValid, handleStateSubmit])

    const handleGenderChange = (option?: DropdownOption | null) => {
      if (!!option?.value) formik.setFieldValue('gender', option?.value)
      setIsOtherGender(option?.label === t('other'))
    }

    const DocumentForm = document_types[formik.values.document_type as keyof typeof document_types]

    return formik.isSubmitting ? (
      <div className='flex h-full w-full items-center justify-center'>
        <SpinnerGap size={20} className='animate-spin' />
      </div>
    ) : (
      <form onSubmit={formik.handleSubmit} className='flex flex-col gap-8'>
        <div className='flex gap-6'>
          <InputField
            id='cpf'
            name='cpf'
            type='cpf'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            label={t('fields.cpf')}
            placeholder={t('fields.enter')}
            value={formik.values.cpf || ''}
            className='max-w-[328px]'
            hint={formik.touched.cpf && formik.errors.cpf ? formik.errors.cpf : undefined}
            state={formik.touched.cpf && formik.errors.cpf ? 'error' : 'default'}
          />
          <InputField
            type='cns'
            id='cns_number'
            name='cns_number'
            placeholder={t('fields.cns_number')}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            label={t('fields.cns_number')}
            className='max-w-[375px]'
            value={formik.values.cns_number || ''}
            hint={
              formik.touched.cns_number && formik.errors.cns_number
                ? formik.errors.cns_number
                : undefined
            }
            state={formik.touched.cns_number && formik.errors.cns_number ? 'error' : 'default'}
          />
        </div>

        <InputField
          id='name'
          name='name'
          placeholder={t('fields.full_name')}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          label={t('fields.full_name')}
          value={formik.values.name || ''}
          hint={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
          state={formik.touched.name && formik.errors.name ? 'error' : 'default'}
        />

        <InputField
          id='social_name'
          name='social_name'
          placeholder={t('fields.full_name')}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          label={t('fields.social_name_optional')}
          value={formik.values.social_name || ''}
          hint={
            formik.touched.social_name && formik.errors.social_name
              ? formik.errors.social_name
              : undefined
          }
          state={formik.touched.social_name && formik.errors.social_name ? 'error' : 'default'}
        />

        <div className='flex gap-6'>
          <DatePickerWithCalendar
            name='birthdate'
            label={t('fields.birthdate')}
            placeholder={t('fields.birthdate')}
            onChange={val => formik.setFieldValue('birthdate', val)}
            onBlur={formik.handleBlur}
            className='max-w-[328px] '
            value={!!formik.values.birthdate ? new Date(formik.values.birthdate) : null}
            disabled={formik.isSubmitting}
            error={formik.errors.birthdate}
            touched={formik.touched.birthdate}
            limit={{ maxDate: new Date(), minDate: new Date(1900, 0, 1) }}
          />

          <Dropdown
            name='gender'
            label={t('fields.gender_optional')}
            onBlur={formik.handleBlur}
            value={formik.values.gender!}
            className='max-w-[328px] '
            placeholder={t('fields.gender')}
            onChangeValue={handleGenderChange}
            options={genders.map(value => ({
              value: (value !== 'other' ? t(`fields.selects.genders.${value}`) : null) as string,
              label: value !== 'other' ? t(`fields.selects.genders.${value}`) : t('other')
            }))}
          />
        </div>

        <Dropdown
          name='document_type'
          label={t('fields.document_type')}
          onBlur={formik.handleBlur}
          value={formik.values.document_type as string}
          className='max-w-[328px] flex-1'
          placeholder={t('fields.rg')}
          onChangeValue={e => formik.setFieldValue('document_type', e?.value)}
          options={Object.keys(document_types).map(value => ({
            value: value as string,
            label: value !== 'other' ? t(`fields.selects.document_types.${value}`) : t('other')
          }))}
        />

        {DocumentForm && (
          <div className='flex w-full flex-col rounded-lg border-[1px] border-solid border-neutralBorder-default p-6'>
            <DocumentForm formik={formik} />
          </div>
        )}

        <button ref={ref} className='hidden' disabled={!formik.isValid} type='submit' />
      </form>
    )
  }
)

PatientGeneralForm.displayName = 'PatientGeneralForm'

export default PatientGeneralForm
