import { useEffect, useState } from 'react'
import Content from '../../components/content'
import Table from '../../components/table'
import { useListUsers } from '../../hooks/useUser'
import Button from '../../components/button'
import { Check, Plus, X } from '@phosphor-icons/react'
import SearchField from '../../components/search'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes/path'
import { Client, PaginationParams, Unit } from '../../types'
import InputSkeleton from '../../components/input/skeleton'
import ProtectedComponent from '../../components/protected'
import PermissionEnum from '../../types/permissions'
import { useTranslation } from 'react-i18next'
import Tag from '../../components/tag'
import ClientSelect from '../../components/dropdown/client-dropdown'
import UnitSelect from '../../components/dropdown/unit-dropdown'

const pageSize = 10

const UsersPage: React.FC = () => {
  const [params, setParams] = useState<PaginationParams>({ filter: '', page: 0, pageSize })
  const { t } = useTranslation('user_list')
  const [totalPages, setTotalPages] = useState(0)
  const [client, setClient] = useState<Client>()
  const [unit, setUnit] = useState<Unit>()
  const navigate = useNavigate()

  const { data: users, isLoading, isFetching } = useListUsers(params, client?.id, unit?.id)

  useEffect(() => {
    if (users) {
      setTotalPages(Math.ceil(users.totalItems / 10))
    }
  }, [users])

  const columns = [
    { header: t('header.name'), accessor: 'name' },
    { header: t('header.email'), accessor: 'email' },
    { header: t('header.health_professional'), accessor: 'health_professional' },
    { header: t('header.status'), accessor: 'status' }
  ]

  const tableData =
    users?.data?.map(user => ({
      id: user.id,
      name: user.name,
      email: user.email,
      health_professional: user.is_health_professional ? <Check size={16} /> : <X size={16} />,
      status: (
        <Tag
          size='xs'
          color={user.is_active ? 'green' : 'red'}
          label={t(user.is_active ? 'status.active' : 'status.inactive')}
          variant='secondary'
        />
      )
    })) ?? []

  const handleFilter = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setParams({ filter: value, page: 0, pageSize })
  }

  const handlePage = (newPage: number) => {
    setParams(oldState => ({ ...oldState, page: newPage }))
  }

  return (
    <Content
      title='Usuários'
      rightContent={
        <ProtectedComponent permission={PermissionEnum.register_user}>
          <Button
            size='sm'
            iconLeft={<Plus size={12} />}
            label='Novo usuário'
            onClick={() => {
              navigate(ROUTES.user.new)
            }}
          />
        </ProtectedComponent>
      }
    >
      <div className={`flex h-[calc(100dvh-250px)] flex-col`}>
        <div className='mb-4 hidden gap-4 md:flex'>
          <ClientSelect
            name='client'
            label='Cliente'
            onBlur={() => {}}
            onChange={setClient}
            value={client?.id || ''}
          />
          <UnitSelect
            name='unit'
            label='Unidade'
            onBlur={() => {}}
            onChange={setUnit}
            client_id={client?.id}
            value={unit?.id || ''}
          />
        </div>
        <div className='pb-4 sm:max-w-64 '>
          {isLoading ? (
            <InputSkeleton isRounded />
          ) : (
            <SearchField
              placeholder='Procure pelo nome...'
              onChange={handleFilter}
              value={params.filter || ''}
              maxLength={100}
            />
          )}
        </div>
        <Table
          hasBackButton
          data={tableData}
          columns={columns}
          isLoading={isFetching}
          totalPages={totalPages}
          currentPage={params.page}
          onPageChange={handlePage}
          onClickRow={row => navigate(ROUTES.user.details.replace(':id', row.id!))}
        />
      </div>
    </Content>
  )
}

export default UsersPage
