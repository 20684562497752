export const formatBirthDate = (date: Date | string | null | undefined): string => {
  if (!date) {
    return '-'
  }

  const parsedDate = date instanceof Date ? date : new Date(date)

  if (isNaN(parsedDate.getTime())) {
    return '-'
  }

  const utcDay = parsedDate.getUTCDate()
  const utcMonth = parsedDate.getUTCMonth() + 1
  const utcYear = parsedDate.getUTCFullYear()

  const formattedDate = `${String(utcDay).padStart(2, '0')}/${String(utcMonth).padStart(
    2,
    '0'
  )}/${utcYear}`

  return formattedDate
}
