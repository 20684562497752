import api from './api.service'

class FileService {
  static async upload(formData: FormData, consultation_id = '', folder = '') {
    const urlConsultation = consultation_id ? `?consultation_id=${consultation_id}` : ''

    const url = `/file/${folder}${urlConsultation}`

    return (
      await api.post<{ id: string }>(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    ).data
  }

  static async uploadMultipleFiles(files: File[], consultation_id = '', folder = '', token = '') {
    const formData = new FormData()

    files.forEach(file => {
      formData.append('files', file)
    })

    const baererToken = token || localStorage.getItem('authToken')

    const urlConsultation = consultation_id ? `?consultation_id=${consultation_id}` : ''
    const url = `/file/multiple${folder}${urlConsultation}`

    return (
      await api.post<{ id: string[] }>(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${baererToken}`
        }
      })
    ).data
  }

  static async get(id: string) {
    const response = await api.get('/file/' + id, { responseType: 'blob' })

    return URL.createObjectURL(response.data)
  }

  static async getConsultationFile(id: string) {
    const response = await api.get<File[]>('/file/consultation/' + id)

    return response.data
  }

  static async getMevoFile(id: string) {
    const response = await api.get('/file/mevo/' + id, { responseType: 'blob' })

    return URL.createObjectURL(response.data)
  }

  static async sendMevoFileByEmail(id: string) {
    return api.post<string>('/file/mevo/email/' + id)
  }

  static async download(id: string) {
    const response = await api.get('/file/' + id + '/download', { responseType: 'blob' })

    return response
  }
}

export default FileService
