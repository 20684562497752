import { useMutation, useQueryClient } from '@tanstack/react-query'
import { validateCNPJ } from '../../helpers/validadeCNPJ'
import ClientService from '../../services/client.service'
import { useToastContext } from '../../contexts/toast'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes/path'
import { useFormik } from 'formik'
import { Unit } from '../../types'
import * as Yup from 'yup'
import { validateCPF } from '../../helpers/validateCPF'

export const useUnitFormik = (unit_id?: string, client_id?: string) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { toast } = useToastContext()

  const validationSchema = Yup.object({
    name: Yup.string().required('O nome da unidade é obrigatório.'),
    cnes: Yup.string().required('O CNES é obrigatório.'),
    cnpj: Yup.string()
      .min(18, 'CNPJ deve ter 14 dígitos')
      .required('O CNPJ é obrigatório.')
      .test('is-valid-cnpj', 'CNPJ inválido', value => validateCNPJ(value)),
    phone: Yup.string().required('O telefone é obrigatório'),
    email: Yup.string().email('Email inválido').required('O email é obrigatório'),
    address: Yup.string().required('O endereço é obrigatório.'),
    address_number: Yup.string().required('O número do endereço é obrigatório.'),
    district: Yup.string().required('O bairro é obrigatório.'),
    city: Yup.string().required('A cidade é obrigatória.'),
    state: Yup.string().required('O estado é obrigatório.'),
    cepUnknown: Yup.boolean(),
    cep: Yup.string().when('cepUnknown', {
      is: false,
      otherwise: schema => schema.optional(),
      then: schema => schema.required('CEP é obrigatório')
    }),
    technical_manager_name: Yup.string().required('O nome do responsável técnico é obrigatório.'),
    technical_manager_council_id: Yup.string().required('O conselho obrigatório.'),
    technical_manager_doc: Yup.string()
      .required('O CPF do responsável técnico é obrigatório.')
      .test('is-valid-cpf', 'CPF inválido', value => validateCPF(value)),
    technical_manager_council_uf: Yup.string().required(
      'O CRM do responsável técnico é obrigatório.'
    ),
    unit_type_id: Yup.string().required('O tipo de unidade é obrigatório.'),
    specialties: Yup.array()
      .of(
        Yup.object().shape({
          specialty_id: Yup.string().required('ID da especialidade é obrigatório')
        })
      )
      .min(1, 'Ao menos uma especialidade deve ser selecionada.'),
    is_active: Yup.boolean(),
    permissions: Yup.array().of(Yup.string())
  })

  const formik = useFormik<Unit>({
    initialValues: {
      name: '',
      cnes: '',
      cnpj: '',
      phone: '',
      email: '',
      address: '',
      address_number: '',
      district: '',
      city: '',
      state: '',
      cep: '',
      technical_manager_name: '',
      technical_manager_doc: '',
      technical_manager_council_doc: '',
      unit_type_id: '',
      specialties: [],
      cepUnknown: false,
      is_active: true,
      permissions: []
    },
    validationSchema,
    onSubmit: values => {
      const formattedValues = {
        cep: values.cep,
        name: values.name,
        cnes: values.cnes,
        cnpj: values.cnpj,
        city: values.city,
        phone: values.phone,
        email: values.email,
        state: values.state,
        address: values.address,
        district: values.district,
        is_active: values.is_active,
        permissions: values.permissions,
        unit_type_id: values.unit_type_id,
        address_number: values.address_number,
        technical_manager_doc: values.technical_manager_doc,
        technical_manager_name: values.technical_manager_name,
        technical_manager_council_uf: values.technical_manager_council_uf,
        technical_manager_council_id: values.technical_manager_council_id,
        technical_manager_council_doc: values.technical_manager_council_doc,
        specialties: values.specialties?.map(s => ({ specialty_id: s.specialty_id }))
      }
      saveMutation.mutate(formattedValues)
    }
  })

  const saveMutation = useMutation({
    mutationFn: async (values: Unit) => {
      if (unit_id && unit_id !== 'new') {
        await ClientService.updateUnit(unit_id, values)
      } else {
        await ClientService.createUnit(client_id!, values)
      }
    },
    onSuccess: () => {
      toast.success(unit_id ? 'Unidade atualizada com sucesso.' : 'Unidade criada com sucesso.')
      queryClient.invalidateQueries({ queryKey: ['units'] })
      navigate(ROUTES.client.unit.list.replace(':client_id', client_id || ''))
    },
    onError: error => {
      toast.error(error.message || 'Algo deu errado')
    }
  })

  return formik
}
