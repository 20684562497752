import { useEffect, FocusEvent, useMemo } from 'react'
import { Client } from '../../types'
import Dropdown from '.'
import { useAuth } from '../../contexts/auth'
import UnitPermissionsEnum from '../../types/unit-permissions'

type Props = {
  name: string
  label: string
  hint?: string
  value: string
  error?: boolean
  disabled?: boolean
  placeholder?: string
  unit_permission?: UnitPermissionsEnum
  onChange: (client?: Client) => void
  onBlur: (e: FocusEvent<unknown, Element>) => void
}

const ClientSelect: React.FC<Props> = ({ onChange, label, unit_permission, ...props }) => {
  const { user, reloadCurrentUser } = useAuth()

  useEffect(() => {
    reloadCurrentUser()
  }, [reloadCurrentUser])

  const options = useMemo(
    () =>
      (unit_permission
        ? user?.clients?.filter(client =>
            client.units?.some(item => item.unit?.permissions?.includes(unit_permission))
          )
        : user?.clients
      )?.map(client => ({
        value: client?.client_id || '',
        label: client?.client?.name || ''
      })) || [],
    [user, unit_permission]
  )

  useEffect(() => {
    if (user?.clients) {
    }
  }, [user])

  const handleSelect = (option: { value: string; label: string } | null) => {
    if (!option) onChange(undefined)

    const item = user?.clients?.find(item => item.client_id === option?.value)
    onChange(item?.client)
  }

  return <Dropdown label={label} options={options} onChangeValue={handleSelect} {...props} />
}

export default ClientSelect
