import { ListConsultationProps } from '../hooks/useConsultation'
import {
  Consultation,
  ConsultationMessage,
  ConsultationUploadFile,
  PaginationParams,
  PaginationResponse
} from '../types'
import api from './api.service'

export type ConsultationListResponse = ListConsultationProps & PaginationResponse<Consultation>

class ConsultationService {
  static async list(params: ListConsultationProps) {
    const response = await api.get<ConsultationListResponse>('/consultation', {
      params
    })

    return response.data
  }

  static async getById(id: string) {
    const response = await api.get<Consultation>('/consultation/' + id)
    return response.data
  }

  static create(body: Partial<Consultation>) {
    return api.post<Consultation>('/consultation', body)
  }

  static update(id: string, body: Partial<Consultation>) {
    return api.put<Consultation>('/consultation/' + id, body)
  }

  static transfer(specialist_id: string, new_specialist_id: string, consultations: string[]) {
    return api.post(`/consultation/specialist/${specialist_id}/transfer`, {
      new_specialist_id,
      consultations
    })
  }

  static delete(id: string) {
    return api.delete('/consultation/' + id)
  }

  static getConsultationBySpecialist(id: string, params: PaginationParams) {
    return api.get('/consultation/specialist/' + id, {
      params
    })
  }

  static async finishConsultation(id: string) {
    return (await api.post(`/consultation/${id}/finish`)).data
  }

  static async startConsultation(id: string) {
    return (await api.post<Consultation>(`/consultation/${id}/start`)).data
  }

  static async getMessagesByConsultationId(consultation_id: string) {
    const response = await api.get<ConsultationMessage[]>(
      `/consultation/${consultation_id}/messages`
    )
    return response.data
  }

  static async saveMessage(consultation_id: string, message: string) {
    const response = await api.post<ConsultationMessage>(
      `/consultation/${consultation_id}/messages`,
      { message }
    )
    return response.data
  }

  static async addFiles(id: string, fileIds: string[]) {
    return api.put(`/consultation/${id}`, { fileIds })
  }

  static async generateConsultationUploadFile(consultation_id: string) {
    const response = await api.post<ConsultationUploadFile>(
      `/consultation/${consultation_id}/generate-upload-file`
    )
    return response.data
  }

  static async validateConsultationUploadFileCode(upload_file_id: string, code: number) {
    const response = await api.post<string>(
      `/consultation/upload-file/${upload_file_id}/validate-code`,
      { code }
    )
    return response.data
  }

  static sendInviteToProfessional(
    consultation_id: string,
    body: { name: string; email: string; birthdate: string }
  ) {
    return api.post(`/consultation/${consultation_id}/invite`, body)
  }

  static sendInviteToPatient(consultation_id: string) {
    return api.post(`/consultation/${consultation_id}/invite-patient`)
  }

  static async sendNpsFeedback(score: number, description?: string) {
    return api.post('/nps-feedback', { score, description })
  }

  static getLastConsultationsByPatient(id: string, params: PaginationParams) {
    return api.get(`/consultation/${id}/last_consultations`, {
      params
    })
  }
}

export default ConsultationService
