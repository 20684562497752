import React from 'react'
import Content from '../../../components/content'
import BlankCard from '../../../components/blank-card'
import Item from '../../../components/item'

const ClientDetailsSkeleton: React.FC = () => {
  return (
    <Content
      title={<div className='h-8 w-24 animate-pulse  rounded-lg bg-gray-300' />}
      leftContent={<div className='h-20 w-20 animate-pulse rounded-full bg-gray-300' />}
      onBack={() => {}}
    >
      <div className='mx-[-24px] max-h-[calc(100vh-190px)] overflow-auto px-6'>
        <div className='mt-1 flex w-full flex-col'>
          <div className='mb-4 flex items-center justify-between'>
            <h1 className='text-heading-sm text-neutralContent-primary'>
              <div className='h-7 w-64 animate-pulse rounded bg-gray-300' />
            </h1>
            <div className='h-8 w-20 animate-pulse rounded bg-gray-300' />
          </div>
          <BlankCard className='grid w-full grid-cols-1 gap-4 md:grid-cols-2'>
            {Array.from({ length: 5 }).map((_, index) => (
              <Item
                key={index}
                leftContent={<div className='mr-3 h-6 w-6 animate-pulse rounded bg-gray-300' />}
                title={<div className='mb-2 h-3 w-52 animate-pulse rounded bg-gray-300' />}
                subtitle={<div className='h-3 w-44 animate-pulse rounded bg-gray-300' />}
              />
            ))}
          </BlankCard>
        </div>

        <div className='my-10 mt-6 flex w-full flex-col'>
          <div className='mb-4 flex items-center justify-between'>
            <h1 className='text-heading-sm text-neutralContent-primary'>
              <div className='h-7 w-64 animate-pulse rounded bg-gray-300' />
            </h1>
            <div className='h-8 w-20 animate-pulse rounded bg-gray-300' />
          </div>
          <div>
            <BlankCard className='grid w-full grid-cols-1 gap-4 pt-4 md:grid-cols-2'>
              <Item
                className='w-full cursor-pointer justify-between'
                leftContent={<div className='mr-3 h-6 w-6 animate-pulse rounded bg-gray-300' />}
                title={<div className='mb-2 h-3 w-52 animate-pulse rounded bg-gray-300' />}
                subtitle={<div className='h-3 w-44 animate-pulse rounded bg-gray-300' />}
              />
            </BlankCard>
          </div>

          <div className='mt-4'>
            <BlankCard className='grid w-full grid-cols-1 gap-4 md:grid-cols-2'>
              <div className='flex items-center'>
                <div className='mr-3 h-6 w-6 animate-pulse rounded bg-gray-300' />
                <div className='mb-2 h-3 w-52 animate-pulse rounded bg-gray-300' />{' '}
              </div>
            </BlankCard>
          </div>
          <div className='mt-4'>
            <BlankCard className='grid w-full grid-cols-1 gap-4 md:grid-cols-2'>
              <div className='flex items-center'>
                <div className='mr-3 h-6 w-6 animate-pulse rounded bg-gray-300' />
                <div className='mb-2 h-3 w-52 animate-pulse rounded bg-gray-300' />{' '}
              </div>
            </BlankCard>
          </div>
        </div>

        <div className='pb-[200px]' />
      </div>
    </Content>
  )
}

export default ClientDetailsSkeleton
