import React, { ReactNode } from 'react'
import Button from '../button'
import { ArrowLeft } from '@phosphor-icons/react'

interface ContentProps {
  title?: string | ReactNode
  subtitle?: string
  rightContent?: ReactNode
  children: ReactNode
  className?: string
  boxClassName?: string
  onBack?: () => void
  leftContent?: ReactNode
}

const Content: React.FC<ContentProps> = ({
  title,
  subtitle,
  rightContent,
  boxClassName,
  children,
  onBack,
  className = '',
  leftContent
}) => {
  return (
    <div className={`h-[calc(100dvh-64px)] max-w-[100vw] sm:p-6`}>
      <div
        className={`${className} relative block h-full overflow-y-hidden bg-neutralBackground-primary px-4 py-4 sm:rounded-2xl sm:p-6 `}
      >
        {Boolean(onBack) && (
          <Button
            color=''
            size='sm'
            label='Voltar'
            onClick={onBack}
            variant='tertiary'
            iconLeft={<ArrowLeft />}
            className='absolute top-6 z-20'
          />
        )}

        <div
          className={`flex min-h-[20px] items-center sm:flex-row ${title && onBack && 'mt-[44px]'}`}
        >
          {!!leftContent && <div className='mr-3 flex justify-start'>{leftContent}</div>}
          {!!title && (
            <h1 className='block text-heading-xl text-neutralContent-primary'>{title}</h1>
          )}
          <div className='mt-3 flex w-full flex-1 justify-end sm:mt-0'>{rightContent}</div>
        </div>

        {subtitle && (
          <h3 className='mt-3 block w-full text-body-md text-neutralContent-secondary'>
            {subtitle}
          </h3>
        )}

        <div className={`${boxClassName} relative`}>
          <div className={`h-full flex-1 ${!!title && 'mt-3 sm:mt-9'}`}>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default Content
