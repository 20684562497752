import { format } from 'date-fns'

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString)
  return format(date, 'dd/MM/yy HH:mm')
}

export const formatOnlyDate = (dateString: string): string => {
  const date = new Date(dateString)
  return format(date, 'dd/MM/yy')
}

export const formatOnlyHour = (dateString: string): string => {
  const date = new Date(dateString)
  return format(date, 'HH:mm')
}
