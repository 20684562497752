import ProtectedLayout from '../components/protected-layout'
import ForgotPasswordPage from '../pages/forgotPassword'
import { createBrowserRouter } from 'react-router-dom'
import LoginPage from '../pages/login'
import HomePage from '../pages/home'
import { ROUTES } from './path'
import UsersPage from '../pages/users'
import ShowcasePage from '../pages/showcase'
import UserFormPage from '../pages/users/user-form'
import UnitTypePage from '../pages/setting/unit-type'
import CouncilPage from '../pages/setting/council'
import SpecialtyPage from '../pages/setting/specialty'
import AccessProfile from '../pages/setting/access-profile'
import AccessProfileForm from '../pages/setting/access-profile/access-profile-form'
import NotificationsPage from '../pages/notifications'
import ClientsPage from '../pages/clients'
import PasswordPage from '../pages/setting/password'
import MyAccountPage from '../pages/setting/my-account'
import PatientsPage from '../pages/patients'
import ClientDetailsPage from '../pages/clients/details'
import ClientFormPage from '../pages/clients/client-form'
import UserDetailsPage from '../pages/users/user-details'
import UnitPage from '../pages/clients/details/unit'
import UnitFormPage from '../pages/clients/details/unit-form'
import UnitDetailPage from '../pages/clients/details/unit-detail'
import ConsultationPage from '../pages/consultation'
import ConsultationFormPage from '../pages/consultation/consultation-form'
import ConsultationDetailsPage from '../pages/consultation/consultation-details'
import PatientFormPage from '../pages/patients/form'
import Anamnesis from '../pages/consultation/anamnesis'
import AnamnesisTemplatePage from '../pages/anamnese'
import AnamnesisForm from '../pages/anamnese/form'
import { ExternalUploadFileByQRCode } from '../pages/external-upload-files'
import FollowUpPage from '../pages/follow-up'
import AgendaV2Page from '../pages/agenda-v2'
import CalendarPage from '../pages/agenda-v2/calendar'
import ScaleFormPage from '../pages/agenda-v2/scale_form'
import ScaleDayPage from '../pages/agenda-v2/scale_day'
import PatientDetailPage from '../pages/patients/details'
import DashboardsPage from '../pages/dashboards'
import ConsultationJoinPage from '../pages/consultation/consultation-join'
import ProfessionalConsultationJoinPage from '../pages/consultation/professional-consultation-join'

export const router = createBrowserRouter([
  {
    path: ROUTES.login,
    element: <LoginPage />
  },
  {
    path: ROUTES.consultation.join,
    element: <ConsultationJoinPage />
  },
  {
    path: ROUTES.consultation.professional_join,
    element: <ProfessionalConsultationJoinPage />
  },
  {
    path: ROUTES.showcase,
    element: <ShowcasePage />
  },
  {
    path: ROUTES.forgotPassword,
    element: <ForgotPasswordPage />
  },
  {
    path: ROUTES.consultation.interaction.send_document,
    element: <ExternalUploadFileByQRCode />
  },
  {
    path: ROUTES.home,
    element: <ProtectedLayout />,
    children: [
      { index: true, element: <HomePage /> },
      { path: ROUTES.myAccount, element: <MyAccountPage /> },
      { path: ROUTES.updatePassword, element: <PasswordPage /> },
      { path: ROUTES.notifications, element: <NotificationsPage /> },

      { path: ROUTES.agenda.list, element: <AgendaV2Page /> },
      { path: ROUTES.agenda.calendar, element: <CalendarPage /> },
      { path: ROUTES.agenda.scale_form, element: <ScaleFormPage /> },
      { path: ROUTES.agenda.scale_day, element: <ScaleDayPage /> },

      { path: ROUTES.consultation.list, element: <ConsultationPage /> },
      { path: ROUTES.consultation.new, element: <ConsultationFormPage /> },
      { path: ROUTES.consultation.edit, element: <ConsultationFormPage /> },
      { path: ROUTES.consultation.details, element: <ConsultationDetailsPage /> },
      { path: ROUTES.consultation.anamnesis, element: <Anamnesis /> },
      { path: ROUTES.consultation.follow_up, element: <FollowUpPage /> },

      {
        path: ROUTES.anamnesis_template.list,
        element: <AnamnesisTemplatePage />
      },
      {
        path: ROUTES.anamnesis_template.form,
        element: <AnamnesisForm />
      },

      { path: ROUTES.user.list, element: <UsersPage /> },
      { path: ROUTES.user.new, element: <UserFormPage /> },
      { path: ROUTES.user.edit, element: <UserFormPage /> },
      { path: ROUTES.user.details, element: <UserDetailsPage /> },

      { path: ROUTES.client.list, element: <ClientsPage /> },
      { path: ROUTES.client.form, element: <ClientFormPage /> },
      { path: ROUTES.client.details, element: <ClientDetailsPage /> },
      { path: ROUTES.client.unit.list, element: <UnitPage /> },
      { path: ROUTES.client.unit.form, element: <UnitFormPage /> },
      { path: ROUTES.client.unit.detail, element: <UnitDetailPage /> },

      { path: ROUTES.patient.list, element: <PatientsPage /> },
      { path: ROUTES.patient.form, element: <PatientFormPage /> },
      { path: ROUTES.patient.details, element: <PatientDetailPage /> },

      { path: ROUTES.council.list, element: <CouncilPage /> },
      { path: ROUTES.unitType.list, element: <UnitTypePage /> },
      { path: ROUTES.specialty.list, element: <SpecialtyPage /> },
      { path: ROUTES.profile.list, element: <AccessProfile /> },
      { path: ROUTES.profile.form, element: <AccessProfileForm /> },
      { path: ROUTES.dashboards.detail, element: <DashboardsPage /> }
    ]
  }
])
