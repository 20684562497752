import { FOLLOW_UP_DISPLAY_MODE } from '../../types'

const FollowUpSkeleton: React.FC<{
  displayMode: FOLLOW_UP_DISPLAY_MODE
}> = ({ displayMode }) => {
  return (
    <div
      className={`grid gap-4 ${displayMode === FOLLOW_UP_DISPLAY_MODE.CARD ? 'grid-cols-3' : 'grid-cols-1'}`}
    >
      {Array.from({ length: 9 }, (_, index_row) => (
        <div
          key={index_row}
          className={`w-full rounded bg-gray-200 ${displayMode === FOLLOW_UP_DISPLAY_MODE.CARD ? 'h-[350px]' : 'h-[80px]'}`}
        />
      ))}
    </div>
  )
}

export default FollowUpSkeleton
