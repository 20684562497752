import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetUnitClient, useUnitActivationStatus } from '../../../../hooks/useClient'
import Content from '../../../../components/content'
import { ROUTES } from '../../../../routes/path'
import Button from '../../../../components/button'
import { EyeSlash, PencilSimple } from '@phosphor-icons/react'
import { useGetCity, useGetState } from '../../../../hooks/useLocation'
import Tag from '../../../../components/tag'
import UnitDetailSkeleton from './skeleton'
import InactivateUnit from './unit-inactivate-modal'
import { useTranslation } from 'react-i18next'
import { formatValue } from '../../../../components/input/format'
import ProtectedComponent from '../../../../components/protected'
import PermissionEnum from '../../../../types/permissions'
import ContentForm from '../../../../components/content/form'

const UnitDetailPage: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('unit_detail')

  const [modalInactivate, setModalInactivate] = useState(false)

  const { id, client_id } = useParams<{ id: string; client_id: string }>()
  const { data: unit, isFetching } = useGetUnitClient(id || '', client_id || '')
  const { data: state } = useGetState(unit?.state)
  const { data: city } = useGetCity(unit?.city)

  const { mutate: activationStatus } = useUnitActivationStatus(id as string, client_id as string)

  const handleRedirectUnits = () => {
    navigate(ROUTES.client.unit.list.replace(':client_id', client_id || ''))
  }

  const handleActivation = () => {
    if (!unit?.is_active) activationStatus()
    else setModalInactivate(true)
  }

  const handleGoToEdit = () => {
    navigate(
      ROUTES.client.unit.form.replace(':id', id || '').replace(':client_id', client_id || '')
    )
  }

  return (
    <>
      {isFetching ? (
        <UnitDetailSkeleton />
      ) : (
        <Content
          title={unit?.name || ''}
          rightContent={
            <ProtectedComponent permission={PermissionEnum.register_unit}>
              <div className='flex items-center gap-2'>
                <Button
                  type='button'
                  state={'enable'}
                  size='sm'
                  iconLeft={<EyeSlash size={18} />}
                  label={unit?.is_active ? t('fields.inactivate') : t('fields.activate')}
                  onClick={handleActivation}
                  variant='secondary'
                />

                <Button
                  type='button'
                  state={'enable'}
                  size='sm'
                  iconLeft={<PencilSimple size={18} />}
                  label={t('fields.edit_info')}
                  onClick={handleGoToEdit}
                />
              </div>
            </ProtectedComponent>
          }
          onBack={handleRedirectUnits}
        >
          <ContentForm>
            <div className='flex flex-col'>
              <h1 className='text-label-lg text-neutralContent-primary'>{t('fields.name')}</h1>
              <label className='text-body-sm text-neutralContent-secondary'>{unit?.name}</label>
            </div>

            <div className='flex flex-col'>
              <h1 className='text-label-lg text-neutralContent-primary'>{t('fields.cnpj')}</h1>
              <label className='text-body-sm text-neutralContent-secondary'>
                {formatValue('cnpj', unit?.cnpj || '')}
              </label>
            </div>
            <div className='flex flex-col'>
              <h1 className='text-label-lg text-neutralContent-primary'>{t('fields.cnes')}</h1>
              <label className='text-body-sm text-neutralContent-secondary'>{unit?.cnes}</label>
            </div>

            <div className='flex flex-col md:col-span-3'>
              <h1 className='text-label-lg text-neutralContent-primary'>
                {t('fields.technical_manager')}
              </h1>
              <label className='text-body-sm text-neutralContent-secondary'>
                NOME: {unit?.technical_manager_name} / CRM: {unit?.technical_manager_council_doc} /
                CPF:
                {unit?.technical_manager_doc}
              </label>
            </div>

            <div className='flex flex-col'>
              <h1 className='text-label-lg text-neutralContent-primary'>{t('fields.cep')}</h1>
              <label className='text-body-sm text-neutralContent-secondary'>{unit?.cep}</label>
            </div>

            <div className='flex flex-col'>
              <h1 className='text-label-lg text-neutralContent-primary'>{t('fields.address')}</h1>
              <label className='text-body-sm text-neutralContent-secondary'>{unit?.address}</label>
            </div>

            <div className='flex flex-col'>
              <h1 className='text-label-lg text-neutralContent-primary'>
                {t('fields.address_number')}
              </h1>
              <label className='text-body-sm text-neutralContent-secondary'>
                {unit?.address_number}
              </label>
            </div>

            <div className='flex flex-col'>
              <h1 className='text-label-lg text-neutralContent-primary'>{t('fields.district')}</h1>
              <label className='text-body-sm text-neutralContent-secondary'>{unit?.district}</label>
            </div>

            <div className='flex flex-col'>
              <h1 className='text-label-lg text-neutralContent-primary'>{t('fields.city')}</h1>
              <label className='text-body-sm text-neutralContent-secondary'>{city?.nome}</label>
            </div>

            <div className='flex flex-col'>
              <h1 className='text-label-lg text-neutralContent-primary'>{t('fields.state')}</h1>
              <label className='text-body-sm text-neutralContent-secondary'>{state?.nome}</label>
            </div>

            <div className='md:col-span-3'>
              <h1 className='mb-4 text-label-lg text-neutralContent-primary'>
                {t('fields.specialties')}
              </h1>
              <div className='flex gap-4 '>
                {unit?.specialties?.map(specialty => (
                  <Tag
                    className='mr-2'
                    key={specialty.id}
                    color='blue'
                    size='sm'
                    variant='secondary'
                    label={specialty.specialty?.name || ''}
                  />
                ))}
              </div>
            </div>
            <div className='md:col-span-3'>
              <h1 className='mb-4 text-label-lg text-neutralContent-primary'>
                {t('fields.permissions')}
              </h1>
              <div className='flex gap-4 '>
                {unit?.permissions?.map(permission => (
                  <Tag
                    className='mr-2'
                    key={permission}
                    color='blue'
                    size='sm'
                    variant='secondary'
                    label={t(`permissions_units:permissions.${permission}`)}
                  />
                ))}
              </div>
            </div>
          </ContentForm>
        </Content>
      )}
      <InactivateUnit
        isModalOpen={modalInactivate}
        id={id as string}
        client_id={client_id as string}
        closeModal={() => setModalInactivate(false)}
      />
    </>
  )
}

export default UnitDetailPage
