import React from 'react'

export type RadioSize = 'lg' | 'md' | 'sm'
export type RadioState = 'enabled' | 'disabled'

interface RadioProps {
  size: RadioSize
  selected: boolean
  state: RadioState
  onChange?: () => void
  borderColor?: string
  className?: string
}

const sizeClasses: Record<RadioSize, string> = {
  lg: 'w-6 h-6',
  md: 'w-5 h-5',
  sm: 'w-4 h-4'
}

const innerSizeClasses: Record<RadioSize, string> = {
  lg: 'w-3 h-3',
  md: 'w-2.5 h-2.5',
  sm: 'w-2 h-2'
}

const stateClasses: Record<RadioState, string> = {
  enabled: 'cursor-pointer',
  disabled: 'cursor-not-allowed opacity-50'
}

const Radio: React.FC<RadioProps> = ({
  size,
  selected,
  state,
  onChange,
  borderColor,
  className
}) => {
  const handleClick = () => {
    if (state === 'enabled' && onChange) {
      onChange()
    }
  }

  const getBorderClasses = () => {
    if (state === 'disabled') {
      return selected
        ? 'border-neutralBackground-disabled bg-neutralBackground-disabled'
        : 'border-neutralContent-disabled'
    }
    return borderColor
      ? ''
      : selected
        ? 'border-primaryBrand-primary'
        : 'border-neutralBorder-default'
  }

  const getInnerCircleClasses = () => {
    if (state === 'disabled' && selected) {
      return 'bg-neutralContent-disabled'
    }
    return borderColor ? '' : 'bg-primaryBrand-primary'
  }

  return (
    <div
      className={`relative flex items-center justify-center ${sizeClasses[size]} ${stateClasses[state]} ${className}`}
      onClick={handleClick}
    >
      <input type='radio' className='absolute h-0 w-0 opacity-0' checked={selected} readOnly />
      <div
        className={`rounded-full border-4 transition-all ${getBorderClasses()} ${sizeClasses[size]}`}
        style={borderColor ? { borderColor: borderColor } : {}}
      >
        {selected && (
          <div
            className={`absolute inset-0 m-auto rounded-full ${getInnerCircleClasses()} ${innerSizeClasses[size]}`}
            style={borderColor ? { backgroundColor: borderColor } : {}}
          />
        )}
      </div>
    </div>
  )
}

export default Radio
