import { useTranslation } from 'react-i18next'
import Content from '../../../../components/content'
import Banner from '../../../../components/banner'
import { Info, X } from '@phosphor-icons/react'
import Checkbox from '../../../../components/checkbox'
import Radio from '../../../../components/radio'
import Dropdown from '../../../../components/dropdown'
import TextareaField from '../../../../components/text-area'
import { AnamnesisTemplate, QuestionOption } from '../../../../types'
import Button from '../../../../components/button'

type Props = {
  template: AnamnesisTemplate
  onBack: () => void
}

const AnamnesisTemplatePreview: React.FC<Props> = ({ template, onBack }) => {
  const { t } = useTranslation('anamnesis_form')
  const { questions } = template

  const renderOptionPreview = (type: string, options: string[]) => {
    switch (type) {
      case 'checkbox':
        return options.map((option, index) => (
          <div key={index} className='flex items-center gap-2'>
            <Checkbox isChecked={false} state='disable' onClick={() => {}} />
            <span>{option}</span>
          </div>
        ))
      case 'radio':
        return options.map((option, index) => (
          <div key={index} className='flex items-center gap-2'>
            <Radio size='md' selected={false} state='enabled' />
            <span>{option}</span>
          </div>
        ))
      case 'dropdown':
        return (
          <Dropdown
            key={type}
            name='dropdown-preview'
            label=''
            value=''
            options={options.map(option => ({ value: option, label: option }))}
            onChangeValue={() => {}}
            placeholder='Selecione uma opção'
          />
        )
      case 'textarea':
        return (
          <div key={type}>
            <TextareaField
              onChange={() => {}}
              value=''
              className='w-full rounded border border-gray-300 p-2'
              placeholder='Resposta aberta'
            />
          </div>
        )
      default:
        return null
    }
  }

  return (
    <Content
      title={''}
      rightContent={<Button variant='tertiary' iconRight={<X size={22} />} onClick={onBack} />}
    >
      <div className='max-h-[calc(100vh-250px)] overflow-y-auto pt-5'>
        <Banner
          leftIcon={<Info size={22} />}
          color='blue'
          title={t('banner_warn.title')}
          message={t('banner_warn.message')}
        />

        <div className='mt-4 space-y-4'>
          {questions?.map((question: QuestionOption, index: number) => (
            <div key={index}>
              <h3 className='text-lg font-bold text-neutralContent-primary'>{question.title}</h3>
              <div
                className={`mt-2 ${
                  question.type === 'checkbox' || question.type === 'radio'
                    ? 'flex items-center gap-6'
                    : ''
                }`}
              >
                {renderOptionPreview(question.type || '', question.options || [])}
              </div>
              <span className='text-body-sm text-highlightRed-pure'>
                {question.required && '*Resposta obrigatória'}
              </span>
            </div>
          ))}
        </div>
      </div>
    </Content>
  )
}

export default AnamnesisTemplatePreview
