import { ServerConnection } from '../types'
import api from './api.service'

class SessionService {
  static async createConnection(id: string, screenShare?: boolean) {
    return (
      await api.post<ServerConnection>(`/session/${id}/connection`, {
        ...(screenShare && {
          screenShare
        })
      })
    ).data
  }

  static async createPatientConnection(id: string, screenShare?: boolean) {
    return (
      await api.post<ServerConnection>(`/patient-session/${id}/connection`, {
        ...(screenShare && {
          screenShare
        })
      })
    ).data
  }

  static async createProfessionalConnection(id: string, screenShare?: boolean) {
    return (
      await api.post<ServerConnection>(`/professional-session/${id}/connection`, {
        ...(screenShare && {
          screenShare
        })
      })
    ).data
  }
}

export default SessionService
