import { AnamnesisTemplate, PaginationParams, PaginationResponse } from '../types'
import api from './api.service'

class AnamnesisTemplateService {
  static async list(params: PaginationParams) {
    const response = await api.get<PaginationResponse<AnamnesisTemplate>>('/anamnesis-template', {
      params
    })
    return response.data
  }

  static delete(id: string) {
    return api.delete('/anamnesis-template/' + id)
  }

  static update(id: string, body: Partial<AnamnesisTemplate>) {
    return api.put('/anamnesis-template/' + id, body)
  }

  static create(body: Partial<AnamnesisTemplate>) {
    return api.post('/anamnesis-template', body)
  }

  static async getById(id: string) {
    const response = await api.get<AnamnesisTemplate>('/anamnesis-template/' + id)
    return response.data
  }

  static async getByConsultationId(id: string) {
    const response = await api.get<AnamnesisTemplate>('/anamnesis-template/consultation/' + id)
    return response.data
  }
}

export default AnamnesisTemplateService
