import { Council, PaginationParams, PaginationResponse } from '../types'
import api from './api.service'

class CouncilService {
  static createCouncil({ name, document_name }: Partial<Council>) {
    return api.post<Council>('/council', {
      name,
      document_name
    })
  }

  static async listCouncil(params: PaginationParams) {
    const response = await api.get<PaginationResponse<Council>>('/council', {
      params
    })
    return response.data
  }

  static async listAllWithSpecialtiesCouncil() {
    const response = await api.get<Council[]>('/council/specialties')
    return response.data
  }

  static updateCouncil(id: string, data: Partial<Council>) {
    return api.put<void>('/council/' + id, data)
  }

  static deleteCouncil(id: string) {
    return api.delete<void>('/council/' + id)
  }
}

export default CouncilService
