import React, { createContext, useContext, useState, ReactNode } from 'react'

interface ChatContextProps {
  startedChats: Record<string, boolean>
  setStartedChat: (id: string, started: boolean) => void
  resetStartedChat: (id: string) => void
}

const ChatContext = createContext<ChatContextProps | undefined>(undefined)

export const ChatProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [startedChats, setStartedChats] = useState<Record<string, boolean>>({})

  const setStartedChat = (id: string, started: boolean) => {
    setStartedChats(prev => ({ ...prev, [id]: started }))
  }

  const resetStartedChat = (id: string) => {
    setStartedChats(prev => {
      const updatedChats = { ...prev }
      delete updatedChats[id] // Remover o id de startedChats
      return updatedChats
    })
  }

  return (
    <ChatContext.Provider value={{ startedChats, setStartedChat, resetStartedChat }}>
      {children}
    </ChatContext.Provider>
  )
}

export const useChatContext = () => {
  const context = useContext(ChatContext)
  if (!context) {
    throw new Error('useChatContext must be used within a ChatProvider')
  }
  return context
}
