export function scaleTimeToNumber(time: string): number {
  const [hours, minutes] = time.split(':').map(Number)
  return hours * 60 + minutes
}

export function scaleNumberToTime(minutes: number): string {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  return `${String(hours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}`
}

export function scaleNumberToDate(minutes: number, baseDate: Date): Date {
  const date = new Date(baseDate)
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  date.setHours(hours, remainingMinutes, 0, 0)
  return date
}
