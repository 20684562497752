import { useTranslation } from 'react-i18next'
import { MainMenuItem } from '../menu-item-list/menus'

const MenuDivider: React.FC<{ item: MainMenuItem; isOpen?: boolean }> = ({ item, isOpen }) => {
  const { t } = useTranslation('main_menu')

  return item?.name && isOpen ? (
    <div className='mx-3 mb-1 mt-3 border-t pt-3 text-label-xs text-neutralContent-tertiary'>
      {t(item.name)}
    </div>
  ) : (
    <hr className='mx-3 my-2 h-[1px] bg-neutralBorder-default' />
  )
}

export default MenuDivider
