interface SliderProps {
  min: number
  max: number
  step: number
  value: number
  onChange: (value: number) => void
}

export const Slider: React.FC<SliderProps> = ({ min, max, step, value, onChange }) => {
  return (
    <div className='flex w-full flex-col items-center gap-4 space-x-2'>
      <input
        type='range'
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={e => onChange(Number(e.target.value))}
        className='slider-thumb h-2 w-full cursor-pointer appearance-none rounded-lg bg-primaryBrand-primary accent-primaryBrand-primary'
      />
      <div className='flex w-full justify-between px-2 '>
        {[...Array(max - min + 1)].map((_, index) => (
          <span className='text-body-md text-neutralContent-tertiary' key={index}>
            {min + index}
          </span>
        ))}
      </div>
    </div>
  )
}
