import Button from '../../../../../components/button'
import Modal from '../../../../../components/modal'

interface Props {
  open: boolean
  onClose: () => void
  onPublish: () => void
  isPending: boolean
}

const ConfirmPublishScaleModal = ({
  open: isModalOpen,
  onClose: closeModal,
  onPublish,
  isPending
}: Props) => {
  return (
    <Modal
      title={'Publicar escala?'}
      isOpen={isModalOpen}
      onClose={() => {
        closeModal()
      }}
    >
      <div className='mb-1 w-96'>
        <p className='block text-body-md text-neutralContent-tertiary'>
          Os horários ficarão disponíveis para agendamento nas unidades selecionadas após a
          publicação.
        </p>
        <br />
      </div>

      <div className='mt-4 flex w-[400px]  justify-between'>
        <div className='flex w-full items-center justify-end'>
          <Button
            type='button'
            label='Cancelar'
            size='sm'
            variant='tertiary'
            onClick={closeModal}
          />
          <Button
            onClick={onPublish}
            label='Publicar escala'
            size='sm'
            variant='primary'
            state='enable'
            isLoading={isPending}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmPublishScaleModal
