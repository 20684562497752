const units_form_enUS = {
  fields: {
    name: 'Name',
    unit_type: 'Unit Type',
    cnpj: 'CNPJ',
    cnes: 'National Health Establishment Register (CNES)',
    email: 'Email',
    phone: 'Phone',
    technical_manager: 'Technical Manager',
    address: 'Address',
    address_number: 'Number',
    district: 'District',
    cep: 'Postal Code',
    state: 'State',
    city: 'City',
    specialties: 'Specialties',
    select_specialties: 'Select specialties',
    permissions: 'Permissions',
    edit_unit: 'Editar unidade',
    save: 'Save',
    create_unit: 'Create unit',
    unit_details: 'Unit Details',
    unit_address: 'Unit Address',
    create: 'Create'
  }
}

export default units_form_enUS
