import { useTranslation } from 'react-i18next'
import InputField from '../../../../../components/input'
import Modal from '../../../../../components/modal'
import { useInviteProfessional } from '../../../../../hooks/useConsultation'
import Button from '../../../../../components/button'
import { PaperPlaneTilt } from '@phosphor-icons/react'
import DatePicker from '../../../../../components/datepicker'

interface Props {
  isModalOpen: boolean
  closeModal: () => void
  consultation_id: string
}

const InviteProfessionalModal = ({ isModalOpen, closeModal, consultation_id }: Props) => {
  const { t } = useTranslation('consultation_details')

  const formik = useInviteProfessional(consultation_id, closeModal)

  const handleCancel = () => {
    closeModal()
  }

  return (
    <Modal
      title={t('invite_professional_modal.title')}
      isOpen={isModalOpen}
      onClose={handleCancel}
      className={'overflow-visible'}
    >
      <form onSubmit={formik.handleSubmit}>
        {' '}
        <div className='w-[400px] max-w-[90vw]'>
          <div className='mb-1'>
            <p className='text-body-md text-neutralContent-tertiary'>
              {t('invite_professional_modal.subtitle')}
            </p>
            <br />
          </div>

          <div className='my-4 flex w-[400px] flex-col justify-center gap-2'>
            <InputField
              name='name'
              onBlur={formik.handleBlur}
              value={formik.values.name || ''}
              onChange={formik.handleChange}
              label={t('invite_professional_modal.inputs.name')}
              state={formik.errors.name ? 'error' : 'default'}
            />
            <InputField
              name='email'
              onBlur={formik.handleBlur}
              value={formik.values.email || ''}
              onChange={formik.handleChange}
              label={t('invite_professional_modal.inputs.email')}
              state={formik.errors.email ? 'error' : 'default'}
            />
            <DatePicker
              name='birthdate'
              label={t('invite_professional_modal.inputs.birthdate')}
              placeholder='Selecione uma data'
              onChange={val => formik.setFieldValue('birthdate', val)}
              onBlur={formik.handleBlur}
              value={!!formik.values.birthdate ? new Date(formik.values.birthdate) : null}
              disabled={formik.isSubmitting}
              error={formik.errors.birthdate}
              touched={formik.touched.birthdate}
            />
          </div>
        </div>
        <div className='mt-4 flex justify-end'>
          <Button
            type='button'
            label={t('actions.cancel')}
            size='md'
            className='text-black'
            variant='tertiary'
            onClick={handleCancel}
          />
          <Button
            type='submit'
            size='md'
            label={t('actions.invite')}
            variant='primary'
            isLoading={formik.isSubmitting}
            iconRight={<PaperPlaneTilt />}
          />
        </div>
      </form>
    </Modal>
  )
}

export default InviteProfessionalModal
