import React, { FocusEvent } from 'react'
import { WarningCircle } from '@phosphor-icons/react'
import Dropdown, { DropdownOption } from '.'
type TimeSelectProps = {
  name: string
  label: string
  value?: number
  error?: boolean
  disabled?: boolean
  placeholder?: string
  hint?: string
  onChangeValue: (value: number) => void
  onBlur?: (e: FocusEvent<unknown, Element>) => void
}

const predefinedOptions: DropdownOption[] = [
  { value: '15', label: '15 minutos' },
  { value: '20', label: '20 minutos' },
  { value: '25', label: '25 minutos' },
  { value: '30', label: '30 minutos' },
  { value: '45', label: '45 minutos' },
  { value: '60', label: '60 minutos' }
]

const TimeSelect: React.FC<TimeSelectProps> = ({
  name,
  label,
  value = 30,
  error,
  disabled,
  placeholder = 'Selecione a duração',
  hint,
  onBlur,
  onChangeValue,
  ...rest
}) => {
  const handleDropdownChange = (option: DropdownOption | null) => {
    if (option) {
      onChangeValue(parseInt(option.value, 10))
    } else {
      onChangeValue(0)
    }
  }
  return (
    <div className='flex flex-col gap-2'>
      {!!label && (
        <label
          htmlFor={name}
          className={`label-sm mb-2 block ${disabled ? 'text-neutralContent-disabled' : 'text-neutralContent-primary'}`}
        >
          {label}
        </label>
      )}
      <Dropdown
        label=''
        labelClassName='mb-[-16px]'
        name={name}
        value={!!value ? value.toString() : ''}
        options={predefinedOptions}
        onChangeValue={handleDropdownChange}
        placeholder={placeholder}
        disabled={disabled}
        error={error}
        className=''
        {...rest}
      />

      {hint && error && (
        <div
          className={`body-sm flex items-center ${error ? 'text-highlightRed-pure' : 'text-neutralContent-secondary'}`}
        >
          {!!error && <WarningCircle size={16} />}
          <span className='ml-2'>{hint}</span>
        </div>
      )}
    </div>
  )
}

export default TimeSelect
