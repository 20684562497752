import { PaginationParams, PaginationResponse, Specialty } from '../types'
import api from './api.service'

class SpecialtyService {
  static createSpecialty({ name, document_name, council_id }: Partial<Specialty>) {
    return api.post<Specialty>('/specialty', {
      name,
      document_name,
      council_id
    })
  }

  static async listSpecialtyBySpecialist(id: string) {
    const response = await api.get<Specialty[]>('/specialty/specialist/' + id)
    return response.data
  }

  static async listSpecialtyFromUnit(unit_id: string) {
    const response = await api.get<Specialty[]>('/specialty/unit/' + unit_id)
    return response.data
  }

  static async listSpecialty(params: PaginationParams & { client_id?: string }) {
    const response = await api.get<PaginationResponse<Specialty>>('/specialty', {
      params
    })
    return response.data
  }

  static updateSpecialty(id: string, data: Partial<Specialty>) {
    return api.put<void>('/specialty/' + id, data)
  }

  static deleteSpecialty(id: string) {
    return api.delete<void>('/specialty/' + id)
  }
}

export default SpecialtyService
