import { Plus } from '@phosphor-icons/react/dist/ssr'
import Content from '../../components/content'
import ProtectedComponent from '../../components/protected'
import PermissionEnum from '../../types/permissions'
import { ROUTES } from '../../routes/path'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/button'
import { useEffect, useState } from 'react'
import { PaginationParams } from '../../types'
import { useTranslation } from 'react-i18next'
import InputSkeleton from '../../components/input/skeleton'
import SearchField from '../../components/search'
import Table from '../../components/table'
import { useListPatients } from '../../hooks/forms/usePatientForm'
import { ArrowRight } from '@phosphor-icons/react'
const pageSize = 10

const PatientsPage = () => {
  const { t } = useTranslation('patient_list')

  const navigate = useNavigate()
  const [params, setParams] = useState<PaginationParams>({ filter: '', page: 0, pageSize })
  const [totalPages, setTotalPages] = useState(0)

  const { data: patients, isLoading, isFetching } = useListPatients(params)

  useEffect(() => {
    if (patients) {
      setTotalPages(Math.ceil(patients.totalItems / pageSize))
    }
  }, [patients])

  const redirectToDetails = (id: string) => {
    navigate(ROUTES.patient.details.replace(':id', id))
  }

  const columns = [
    { header: t('columns.name'), accessor: 'name' },
    {
      header: t('columns.document'),
      accessor: 'document',
      className: 'sm:max-w-[200px] flex justify-center '
    },
    {
      header: '',
      accessor: 'actions',
      className: 'sm:max-w-[200px] flex justify-center '
    }
  ]

  const tableData =
    patients?.data?.map(patient => ({
      name: patient?.name,
      document: patient.cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'),
      actions: () => (
        <Button
          label='Acessar prontuário'
          variant='secondary'
          size='sm'
          iconRight={<ArrowRight size={12} />}
          onClick={() => redirectToDetails(patient.cpf as string)}
        />
      )
    })) ?? []

  const handleFilter = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setParams({ filter: value, page: 0, pageSize })
  }

  const handlePage = (newPage: number) => {
    setParams(oldState => ({ ...oldState, page: newPage }))
  }

  return (
    <Content
      title={t('title')}
      rightContent={
        <ProtectedComponent permission={PermissionEnum.report_patient}>
          <Button
            size='sm'
            iconLeft={<Plus size={12} />}
            label={t('actions.new')}
            onClick={() => {
              navigate(ROUTES.patient.new)
            }}
          />
        </ProtectedComponent>
      }
    >
      <div className={`flex h-[calc(100dvh-250px)] flex-col`}>
        <div className='pb-4 sm:max-w-64 '>
          {isLoading ? (
            <InputSkeleton isRounded />
          ) : (
            <SearchField
              maxLength={100}
              value={params.filter || ''}
              onChange={handleFilter}
              placeholder={t('search_placeholder')}
            />
          )}
        </div>
        <Table
          data={tableData}
          columns={columns}
          isLoading={isFetching}
          totalPages={totalPages}
          currentPage={params.page}
          onPageChange={handlePage}
        />
      </div>
    </Content>
  )
}

export default PatientsPage
