/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import PatientService from '../../services/patient.service'
import { useToastContext } from '../../contexts/toast'
import * as Yup from 'yup'
import { PaginationParams, Patient } from '../../types'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes/path'
import { validateCPF } from '../../helpers/validateCPF'

export const useListPatients = (params: PaginationParams) => {
  return useQuery({
    queryKey: ['listPatients', params],
    queryFn: () => PatientService.list(params),
    placeholderData: prev => prev
  })
}

export const useGetPatient = (document: string) => {
  return useQuery({
    queryKey: ['getPatient', document],
    queryFn: () => (document.length >= 11 ? PatientService.getByDocument(document) : null),
    enabled: !!document,
    retry: false
  })
}

export const useAddPatient = (
  closeModal?: () => void,
  onSuccess?: (patient: Patient) => void,
  redirectToPatientList?: boolean
) => {
  const { toast } = useToastContext()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const validationSchema = Yup.object({
    name: Yup.string().required('Preencha o nome'),
    document: Yup.string()
      .required('Preencha o CPF')
      .test('is-valid-cpf', 'CPF inválido', value => validateCPF(value))
  })

  const initialValues = {
    name: '',
    document: ''
  }

  const mutation = useMutation({
    mutationFn: (newPatient: Partial<Patient>) => PatientService.create(newPatient),
    onSuccess: data => {
      toast.success('Paciente criado')
      queryClient.invalidateQueries({ queryKey: ['getPatient', 'listPatients'] })
      !!redirectToPatientList ? navigate(ROUTES.patient.list) : null
      formik.resetForm()
      closeModal?.()
      //@ts-expect-error
      onSuccess?.(data)
    },
    onError: error => {
      toast.error(error.message || 'Algo deu errado')
    }
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => {
      mutation.mutate(values)
    },
    enableReinitialize: true
  })

  return formik
}

export const usePatientGeneralFormik = (
  onSubmit: (values: Partial<Patient>) => Promise<void>,
  patient: Partial<Patient>
) => {
  const { toast } = useToastContext()
  const validationSchema = Yup.object({
    cpf: Yup.string()
      .required('Preencha o documento')
      .test('is-valid-cpf', 'CPF inválido', value => validateCPF(value)),
    cns_number: Yup.string().required('Preencha o cns'),
    name: Yup.string().required('Preencha o nome'),
    social_name: Yup.string().nullable(),
    birthdate: Yup.date().required('Preencha a data de nascimento').nullable(),
    document_type: Yup.string().required('Selecione o tipo de documento').nullable(),
    document_number: Yup.string().required('Preencha o número do documento').nullable(),
    document_agency: Yup.string().required('Preencha o campo').nullable(),
    document_date: Yup.date().required('Preencha a data').nullable(),
    document_validity: Yup.date().nullable()
  })

  const initialValues = {
    cpf: patient.cpf || '',
    cns_number: patient.cns_number || '',
    name: patient.name || '',
    social_name: patient.social_name || '',
    birthdate: patient.birthdate || '',
    gender: patient.gender || '',
    document_type: patient.document_type || '',
    document_number: patient.document_number || '',
    document_agency: patient.document_agency || '',
    document_date: patient.document_date || '',
    document_validity: patient.document_validity || ''
  } as Partial<Patient>

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async e => await handleSubmitForm(e)
  })

  const handleSubmitForm = async (e: Partial<Patient>) => {
    try {
      await PatientService.validateParams(e)
      await onSubmit(e)
    } catch (error) {
      toast.error((error as Error)?.message || 'Algo deu errado')
    }
  }

  return formik
}

export const usePatientContactFormik = (
  onSubmit: (values: Partial<Patient>) => Promise<void>,
  patient: Partial<Patient>
) => {
  const { toast } = useToastContext()
  const validationSchema = Yup.object({
    email: Yup.string().email('E-mail inválido').required('Preencha o e-mail'),
    primary_phone: Yup.string().required('Preencha o telefone principal'),
    secondary_phone: Yup.string().optional(),
    address_code: Yup.string().required('Preencha o campo'),
    address: Yup.string().required('Preencha o campo'),
    address_number: Yup.string().required('Preencha o campo'),
    address_neighborhood: Yup.string().required('Preencha o campo'),
    address_state: Yup.string().required('Preencha o campo'),
    address_city: Yup.string().required('Preencha o campo'),
    address_complement: Yup.string().required('Preencha o campo')
  })

  const initialValues = {
    email: patient.email,
    primary_phone: patient.primary_phone,
    secondary_phone: patient.secondary_phone,
    address_code: patient.address_code || '',
    address: patient.address || '',
    address_number: patient.address_number || '',
    address_neighborhood: patient.address_neighborhood || '',
    address_state: patient.address_state || '',
    address_city: patient.address_city || '',
    address_complement: patient.address_complement || ''
  } as Partial<Patient>

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async e => await handleSubmitForm(e)
  })

  const handleSubmitForm = async (e: Partial<Patient>) => {
    try {
      await PatientService.validateParams(e)
      await onSubmit(e)
    } catch (error) {
      toast.error((error as Error)?.message || 'Algo deu errado')
    }
  }

  return formik
}

export const usePatientDetailFormik = (
  onSubmit: (values: Partial<Patient>) => Promise<void>,
  patient: Partial<Patient>
) => {
  const { toast } = useToastContext()
  const validationSchema = Yup.object({
    mother_unknown: Yup.boolean(),
    mother_name: Yup.string().when('mother_unknown', {
      is: false,
      then: schema => schema.required('Nome da mãe é obrigatório'),
      otherwise: schema => schema.optional()
    }),
    race: Yup.string().required('Raça é obrigatório'),
    nationality: Yup.string().optional(),
    birth_state: Yup.string().when('nationality', {
      is: 'BR',
      then: schema => schema.required('Estado do nascimento é obrigatório'),
      otherwise: schema => schema.optional()
    }),
    birth_city: Yup.string().when('nationality', {
      is: 'BR',
      then: schema => schema.required('Cidade de nascimento é obrigatório'),
      otherwise: schema => schema.optional()
    }),
    representative_legal_unknown: Yup.boolean(),
    guardian_name: Yup.string().when('representative_legal_unknown', {
      is: false,
      then: schema => schema.required('Nome do representante legal é obrigatório'),
      otherwise: schema => schema.optional()
    }),
    guardian_cpf: Yup.string().when('representative_legal_unknown', {
      is: false,
      then: schema =>
        schema
          .required('Documento do representante legal é obrigatório')
          .test('is-valid-cpf', 'CPF inválido', value => validateCPF(value)),
      otherwise: schema => schema.optional()
    }),
    guardian_state: Yup.string().when('representative_legal_unknown', {
      is: false,
      then: schema => schema.required('Grau de parentesco do representante legal é obrigatório'),
      otherwise: schema => schema.optional()
    })
  })

  const initialValues: Partial<Patient> & {
    mother_unknown: boolean
    representative_legal_unknown: boolean
  } = {
    mother_unknown: (patient as Patient & { mother_unknown: boolean })?.mother_unknown || false,
    mother_name: patient?.mother_name || '',
    representative_legal_unknown:
      (patient as Patient & { representative_legal_unknown: boolean })
        ?.representative_legal_unknown || false,
    guardian_name: patient?.guardian_name || '',
    guardian_cpf: patient?.guardian_cpf || '',
    guardian_state: patient?.guardian_state || '',
    race: patient?.race || '',
    nationality: patient?.nationality || 'BR',
    birth_state: patient?.birth_state || '',
    birth_city: patient?.birth_city || ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async e => await handleSubmitForm(e)
  })

  const handleSubmitForm = async (e: Partial<Patient>) => {
    try {
      await PatientService.validateParams(e)
      await onSubmit(e)
    } catch (error) {
      toast.error((error as Error)?.message || 'Algo deu errado')
    }
  }

  return formik
}

export const usePatientMedicineFormik = (
  onSubmit: (values: Partial<Patient>) => Promise<void>,
  patient: Partial<Patient>
) => {
  const { toast } = useToastContext()
  const validationSchema = Yup.object({
    weight: Yup.string().required('Preencha o peso'),
    height: Yup.string().required('Preencha a altura'),
    allergies: Yup.array().optional(),
    chronic_diseases: Yup.array().optional(),
    health_conditions: Yup.array().optional(),
    surgical_history: Yup.array().optional(),
    medicines: Yup.array().optional()
  })

  const initialValues = {
    weight: patient.weight,
    height: patient.height,
    allergies: patient.allergies || [],
    chronic_diseases: patient.chronic_diseases || [],
    health_conditions: patient.health_conditions || [],
    surgical_history: patient.surgical_history || [],
    medicines: patient.medicines || [{}]
  } as Partial<Patient>

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async e => await handleSubmitForm(e)
  })

  const handleSubmitForm = async (e: Partial<Patient>) => {
    try {
      await PatientService.validateParams(e)
      await onSubmit(e)
    } catch (error) {
      toast.error((error as Error)?.message || 'Algo deu errado')
    }
  }

  return formik
}
