import { useEffect, useState } from 'react'
import Button from '../../../components/button'
import Content from '../../../components/content'
import { PencilSimple, Plus } from '@phosphor-icons/react'
import { useListSpecialty } from '../../../hooks/useSpecialty'
import { Specialty } from '../../../types'
import SearchField from '../../../components/search'
import Table from '../../../components/table'
import SpecialtyFormModal from './specialty-form-modal'
import InputSkeleton from '../../../components/input/skeleton'
import Tag from '../../../components/tag'

const columns = [
  { header: 'Especialidade', accessor: 'name' },
  { header: 'Conselho', accessor: 'council' },
  { header: 'Documento', accessor: 'document' },
  { header: 'Status', accessor: 'is_active', className: 'sm:max-w-[120px]' },
  { header: '', accessor: 'actions', className: 'sm:max-w-[120px]' }
]

const SpecialtyPage = () => {
  const [filter, setFilter] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [modalData, setModalData] = useState<{
    isOpen: boolean
    specialty?: Specialty
  }>({
    isOpen: false,
    specialty: undefined
  })

  const handleOpenUpdateModal = (specialty?: Specialty) => setModalData({ isOpen: true, specialty })

  const { data, isLoading, isFetching, refetch } = useListSpecialty(currentPage, 10, filter)

  const tableData =
    data?.data?.map((specialty: Specialty) => ({
      name: specialty.name,
      council: specialty.council?.name || '-',
      document: specialty.document_name || '',
      is_active: (
        <Tag
          size='sm'
          variant='secondary'
          label={specialty.is_active ? 'Ativo' : 'Inativo'}
          color={specialty.is_active ? 'green' : 'neutral'}
        />
      ),
      actions: (
        <Button
          size='sm'
          label='Editar'
          variant='secondary'
          className='ml-auto'
          iconLeft={<PencilSimple size={12} />}
          onClick={() => handleOpenUpdateModal(specialty)}
        />
      )
    })) ?? []

  useEffect(() => {
    if (data) {
      setTotalPages(Math.ceil(data.totalItems / 10))
    }
  }, [data])

  const onCloseModal = () => {
    refetch()
    setModalData({ isOpen: false, specialty: undefined })
  }

  return (
    <Content
      title='Especialidade'
      rightContent={
        <Button
          size='sm'
          label='Nova Especialidade'
          iconLeft={<Plus size={12} />}
          onClick={() => handleOpenUpdateModal()}
        />
      }
    >
      <div className='flex h-[calc(100%-70px)] flex-col'>
        <div className='mb-4 w-64'>
          {isLoading ? (
            <InputSkeleton isRounded />
          ) : (
            <SearchField
              placeholder='Procure pelo nome...'
              onChange={({ target }) => {
                setFilter(target.value)
                setCurrentPage(0)
              }}
              value={filter}
              maxLength={100}
            />
          )}
        </div>
        <Table
          data={tableData}
          columns={columns}
          isLoading={isFetching}
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      </div>

      <SpecialtyFormModal
        closeModal={onCloseModal}
        isModalOpen={modalData.isOpen}
        specialty={modalData.specialty}
      />
    </Content>
  )
}

export default SpecialtyPage
