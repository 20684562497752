import { NavLink } from 'react-router-dom'
import { MainMenuItem } from '../menu-item-list/menus'
import { useTranslation } from 'react-i18next'

type Props = {
  item: MainMenuItem
  onNavigate?: () => void
}

const MenuItem: React.FC<Props> = ({ item, onNavigate }) => {
  const { t } = useTranslation('main_menu')

  return (
    <NavLink
      key={item.route}
      to={item.route || '/'}
      onClick={onNavigate}
      className={({ isActive }) =>
        `flex min-h-10 items-center overflow-hidden text-nowrap rounded-xl px-3 text-label-sm text-neutralContent-primary transition-all ${isActive ? 'bg-primaryBrand-primary text-white' : 'hover:bg-primaryBrand-light'}`
      }
    >
      <div className='mr-3'>{item.Icon && <item.Icon size={20} />}</div>
      {t(item.name || '')}
    </NavLink>
  )
}

export default MenuItem
